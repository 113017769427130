import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Close from '@mui/icons-material/Close';
import { getCategories, getCountries } from 'apis/public';
import FilterItem from 'components/Search/FilterItem';
import FilterPriceRange from 'components/Search/FilterPrice';
import { useEffect, useRef, useState } from 'react';
import colors from 'constants/colors';
import SearchContext from 'context/SearchDealContext';

const LISTING_TYPES = [
  {
    name: 'All',
    id: 'all',
  },
  {
    name: 'Selling',
    id: 'Selling',
  },
  {
    name: 'Buying',
    id: 'Buying',
  },
];

const STATUSES = [
  {
    name: 'All',
    id: 'all',
  },
  {
    name: 'Ongoing',
    id: 'Live',
  },
  {
    name: 'Expired',
    id: 'Expired',
  },
];

function SearchFilterGroup({
  position,
  drawer,
  filter,
  updateFilter,
}: {
  position: 'drawer' | 'on-page';
  drawer?: { isOpen: boolean; onClose?: () => void; onClearAll?: () => void; onApply?: () => void };
  filter: any;
  updateFilter: any;
}) {
  const searchContext = SearchContext.useContext();
  const footerRef = useRef<HTMLDivElement | null>(null);

  const [status, setStatus] = useState(STATUSES[0]);
  const [type, setType] = useState(LISTING_TYPES[0]);
  const [avoidingFooterHeight, setAvoidingFooterHeight] = useState(0);

  useEffect(() => {
    if (filter.status && filter.status !== status.id) {
      const findStatus = STATUSES.find((item) => item.id === filter.status);
      if (findStatus) setStatus(findStatus);
    } else {
      setType(STATUSES[0]);
    }
  }, [filter.status]);

  useEffect(() => {
    if (filter.type && filter.type !== type.id) {
      const findType = LISTING_TYPES.find((item) => item.id === filter.type);
      if (findType) setType(findType);
    } else {
      setType(LISTING_TYPES[0]);
    }
  }, [filter.type]);

  useEffect(() => {
    setAvoidingFooterHeight(footerRef.current?.clientHeight ?? 0);
  }, []);

  return (
    <>
      {position === 'on-page' && (
        <Stack direction="row" gap={2} flexWrap="wrap">
          <FilterItem
            placeholder="Category"
            onLoad={getCategories}
            value={filter.category_ids}
            onChange={(v: any) => updateFilter('category_ids', v)}
            isMultiple
          />
          <FilterItem placeholder="Country" onLoad={getCountries} value={filter.country_ids} onChange={(v: any) => updateFilter('country_ids', v)} isMultiple />
          <FilterItem placeholder="Listing Type" onChange={(v: string) => updateFilter('type', v)} options={LISTING_TYPES} value={type} />
          <FilterPriceRange onChange={updateFilter} data={{ min_price: filter?.min_price, max_price: filter?.max_price }} />
          <FilterItem placeholder="Status" onChange={(v: string) => updateFilter('status', v)} options={STATUSES} value={status} />
        </Stack>
      )}

      {position === 'drawer' && (
        <Drawer anchor="top" open={drawer?.isOpen} onClose={drawer?.onClose} PaperProps={{ sx: { height: '100%' } }}>
          <Stack flex={1} overflow="hidden">
            <Stack justifyContent="center" alignItems="center" direction="row" py={1} px={2}>
              <Typography fontWeight={700}>Filters</Typography>
              <IconButton onClick={drawer?.onClose} sx={{ position: 'absolute', left: '8px', top: 0 }}>
                <Close />
              </IconButton>
            </Stack>

            <Stack flex={1} p={2} gap={3} overflow="scroll">
              <FilterItem
                titleInLineClearButton="Category"
                placeholder="Select Category"
                onLoad={getCategories}
                value={filter.category_ids}
                onChange={(v: any) => updateFilter('category_ids', v)}
                isMultiple
                borderRadius="10px"
              />
              <FilterItem
                titleInLineClearButton="Country"
                placeholder="Select Country"
                onLoad={getCountries}
                value={filter.country_ids}
                onChange={(v: any) => updateFilter('country_ids', v)}
                isMultiple
                borderRadius="10px"
              />
              <FilterItem
                titleInLineClearButton="Listing Type"
                type="radio"
                onChange={(v: string) => {
                  updateFilter('type', v);
                }}
                options={LISTING_TYPES}
                value={type}
              />
              <FilterPriceRange type="full" onChange={updateFilter} data={filter} />
              <FilterItem titleInLineClearButton="Status" type="radio" onChange={(v: string) => updateFilter('status', v)} options={STATUSES} value={status} />
              <Box height={avoidingFooterHeight} />
            </Stack>

            <Stack
              ref={footerRef}
              flexDirection="row"
              justifyContent="space-between"
              bgcolor={colors.White}
              boxShadow={`0px 4px 21px ${colors.Shadow}`}
              zIndex={1}
              py={1.5}
            >
              <Button variant="text" type="submit" color="primary" sx={{ m: '0', px: 4, py: 2 }} onClick={drawer?.onClearAll}>
                clear all
              </Button>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                sx={{ mr: 3, px: 4, py: 2 }}
                onClick={drawer?.onApply}
                disabled={searchContext.hasError}
              >
                apply
              </Button>
            </Stack>
          </Stack>
        </Drawer>
      )}
    </>
  );
}

export default SearchFilterGroup;
