import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from 'constants/colors';
import { renderDateByFormat } from 'utils/dateFormat';
import { CHAT_STATUSES } from 'interface/chat';
import ChipStatus from 'components/Layout/ChipStatus';
import { CHAT_LIST_VIEW_MODE } from './ChatList';

function ChatListItem({
  viewMode,
  participants,
  deal,
  updatedAt,
  lastMessage,
  unreadMessages,
  chatName,
  chatStatus,
}: {
  viewMode?: CHAT_LIST_VIEW_MODE;
  participants?: any[];
  deal?: any;
  updatedAt: string;
  lastMessage: any;
  unreadMessages: any;
  chatName?: any;
  chatStatus?: any;
}) {
  if (viewMode === CHAT_LIST_VIEW_MODE.CHAT) {
    return (
      <Box>
        <Stack mb="5px" direction="row">
          <Box flex={1}>
            <Tooltip title={participants?.[0]?.username}>
              <Typography fontSize="14px" textAlign="left" fontWeight={deal ? 500 : 700} color={colors.BaseText} lineHeight="15px">
                @{participants?.[0]?.username.length > 10 ? `${participants?.[0]?.username.substring(0, 10)}...` : participants?.[0]?.username.substring(0, 10)}
              </Typography>
            </Tooltip>
          </Box>
          <Box flex={1}>
            <Typography fontSize="13px" textAlign="right" fontWeight={700} color={colors.BaseText} lineHeight="15px">
              {renderDateByFormat(updatedAt, true, true)}
            </Typography>
          </Box>
        </Stack>
        {deal?.id && (
          <Typography variant="h5" textAlign="left" color={colors.BaseText}>
            {deal.name}
          </Typography>
        )}
        {lastMessage && (
          <Stack direction="row" justifyContent="space-between">
            <Typography textAlign="left" color={colors.BaseText} lineHeight={1} fontSize="14px" mt="6px">
              {lastMessage.substring(0, 20)}...
            </Typography>
            {unreadMessages > 0 && (
              <Box
                bgcolor={colors.Secondary500}
                color={colors.White}
                borderRadius="100%"
                fontSize="12px"
                width="20px"
                height="20px"
                display="flex"
                justifyContent="center"
                alignContent="center"
              >
                {unreadMessages}
              </Box>
            )}
          </Stack>
        )}
      </Box>
    );
  }
  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap={1}>
        <Typography color={colors.Header} fontWeight={500} fontSize="16px">
          {chatName}
        </Typography>
        {unreadMessages > 0 && chatStatus === CHAT_STATUSES.OPEN && (
          <Box
            bgcolor={colors.Secondary500}
            color={colors.White}
            borderRadius="100%"
            fontSize="12px"
            width="20px"
            height="20px"
            display="flex"
            justifyContent="center"
            alignContent="center"
          >
            {unreadMessages}
          </Box>
        )}
        {chatStatus !== CHAT_STATUSES.OPEN && <ChipStatus status={chatStatus} />}
      </Stack>
      <Typography fontSize="12px" fontWeight={700} color={colors.Primary900} textTransform="uppercase">
        {renderDateByFormat(updatedAt)}
      </Typography>
    </>
  );
}
export default ChatListItem;
