import Stack from '@mui/material/Stack';
import colors from 'constants/colors';
import { ReactElement, forwardRef } from 'react';

interface ISjScrollBoxProps {
  children: ReactElement[] | ReactElement;
  direction?: 'column' | 'row';
  id?: string;
  style?: any;
  trackColor?: string;
}
const SjScrollBox = forwardRef((props: ISjScrollBoxProps, ref) => {
  const { children, id, style, direction, trackColor } = props;
  return (
    <Stack
      direction={direction ?? 'column'}
      id={id}
      sx={{
        ...style,
        overflowY: 'auto',
        '&:hover': {
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors.BaseSecondaryText,
          },
        },
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: trackColor ?? 'transparent',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'transparent' },
      }}
      ref={ref}
    >
      {children}
    </Stack>
  );
});

export default SjScrollBox;
