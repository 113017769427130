import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import colors from 'constants/colors';
import { useNavigate } from 'react-router-dom';

function CategoryItem({ id, name }: { id: number; name: string }) {
  const navigate = useNavigate();
  return (
    <Grid xs={2} sm={4} md={4} key={id} item={true}>
      <Box
        p={2}
        bgcolor={colors.White}
        mr={3}
        borderRadius="16px"
        mb={3}
        boxShadow="0px 4px 21px rgba(105, 88, 26, 0.15);"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(`/search?category_ids=${id}&category_name=${name}`)}
      >
        <Typography color={colors.Secondary} fontSize={18} fontWeight={600}>
          {name}
        </Typography>
      </Box>
    </Grid>
  );
}

export default CategoryItem;
