/* eslint-disable no-param-reassign */
export const sortByAlphabet = (arr: any) => {
  const map = arr.reduce((acc: any, val: any) => {
    const char = val.name.charAt(0).toUpperCase();
    acc[char] = [].concat(acc[char] || [], val);
    return acc;
  }, {});
  const res = Object.keys(map).map((el) => ({
    letter: el,
    list: map[el],
  }));
  return res;
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
