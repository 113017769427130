import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';

interface ISjDatePickerProps {
  label: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
  helpText?: string;
}

function SjDatePicker({ label, placeholder, name, disabled, minDate, maxDate, helpText }: ISjDatePickerProps) {
  const { values, touched, errors, setValues, setFieldTouched } = useFormikContext<any>();

  const renderHelpText = () => {
    if (touched[name] && Boolean(errors[name])) {
      return errors[name]?.toString();
    }
    if (helpText) {
      return helpText;
    }
    return '';
  };

  return (
    <Box mb={4}>
      {label && (
        <InputLabel shrink error={touched[name] && Boolean(errors[name])} sx={{ whiteSpace: 'break-spaces' }}>
          {label}
        </InputLabel>
      )}
      <DesktopDatePicker
        minDate={new Date()}
        value={dayjs(values[name] ?? '')}
        inputFormat="DD-MM-YYYY"
        onChange={(e) => {
          if (e) {
            setValues({
              ...values,
              [name]: dayjs(e),
            });
          } else {
            setValues({
              ...values,
              [name]: null,
            });
          }
        }}
        className="MuiDatePicker"
        renderInput={(p: any) => (
          <TextField
            {...p}
            fullWidth
            error={touched[name] && Boolean(errors[name])}
            helperText={renderHelpText()}
            onBlur={() => setFieldTouched(name, true, true)}
          />
        )}
      />
    </Box>
  );
}

export default SjDatePicker;
