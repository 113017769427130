import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { IUser, UserType } from 'interface/user';

function ProfileName({ profile, variant, sx }: { profile: IUser; variant?: Variant; sx?: any }) {
  const getName = () => {
    if (profile.fullname) {
      return profile.fullname;
    }
    return `${profile.username}`;
  };
  return (
    <Typography variant={variant ?? 'inherit'} sx={sx ?? {}}>
      {getName()}
    </Typography>
  );
}

export default ProfileName;
