/* eslint-disable react/jsx-no-useless-fragment */
import { FormControlLabel, Radio, RadioGroup, Typography, Stack, SxProps, Theme } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import colors from 'constants/colors';
import { FormikValues, useFormikContext } from 'formik';

interface ISjRadioProps {
  label?: string;
  name: string;
  options: string[] | { id: string; label: string }[];
  isBold?: boolean;
  direction?: string;
}

function SjRadio({ label, name, options, isBold, direction }: ISjRadioProps) {
  const { values, touched, errors, setFieldValue } = useFormikContext<FormikValues>();

  const renderOption = (item: string | { id: string; label: string }) => {
    const value = typeof item === 'string' ? item : item.id;
    const l = typeof item === 'string' ? item : item.label;

    return (
      <FormControlLabel
        onChange={(e: any) => {
          setFieldValue(name, value);
        }}
        checked={values[name] === value}
        key={value}
        value={value}
        control={
          <Radio
            sx={{
              '&.Mui-checked': {
                color: colors.Primary,
              },
              '.MuiTypography-root': {
                fontWeight: 500,
              },
            }}
          />
        }
        label={l}
        sx={{ marginRight: 3 }}
      />
    );
  };

  return (
    <Stack mb={4}>
      {label && (
        <InputLabel shrink error={touched[name] && Boolean(errors[name])} sx={{ whiteSpace: 'break-spaces' }}>
          {label}
        </InputLabel>
      )}
      <RadioGroup sx={{ flexDirection: direction } as SxProps<Theme>}>{options.map((item) => renderOption(item))}</RadioGroup>
      {!!errors[name] && touched[name] && (
        <Typography color={colors.Error400} fontSize="14px" fontWeight={500}>
          ∙ {errors[name]?.toString()}
        </Typography>
      )}
    </Stack>
  );
}

SjRadio.defaultProps = {
  direction: 'unset',
};

export default SjRadio;
