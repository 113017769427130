import styled from '@emotion/styled';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import { Fab, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import colors from 'constants/colors';
import { isArray, toLower } from 'lodash';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Paragraph: React.FC<React.PropsWithChildren<{}>> = function (props) {
  return (
    <Box display="block" mt="24px">
      {props.children}
    </Box>
  );
};

const SubtitleWrapper = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 44px;
  margin-botton: auto;

  &:hover .child {
    opacity: 1;
  }
`;
const StyledLinkIcon = styled(LinkIcon)`
  opacity: 0.5;
  color: ${colors.BaseText};
  margin-left: 5px;
  font-size: 18px;
`;

const Subtitle: React.FC<React.PropsWithChildren<{ id?: string }>> = function (props) {
  function getId() {
    if (typeof props.children === 'string') {
      const lowerId = toLower(props.children);
      const textOnlyId = lowerId.replace(/[^a-zA-Z]+/g, ' ').trim();
      const blankFormatedId = textOnlyId.replace(/  +/g, ' ');
      const withCaret = blankFormatedId.replace(/ +/g, '-');
      return withCaret;
    }
    return props?.id;
  }

  const location = useLocation();
  const id = getId();
  return (
    <SubtitleWrapper to={`${location.pathname}#${id ?? ''}`} id={id}>
      <Typography variant="h4" sx={{ textTransform: 'capitalize' }}>
        {typeof props.children === 'string' ? toLower(props.children) : props.children}
      </Typography>
      <StyledLinkIcon className="child" />
    </SubtitleWrapper>
  );
};

const NewLine: React.FC<React.PropsWithChildren<{}>> = function (props) {
  return <Typography>{props.children}</Typography>;
};

const Inline: React.FC<React.PropsWithChildren<{ bold?: boolean; fontStyle?: string; textDecoration?: string }>> = function (props) {
  return (
    <Typography fontWeight={props?.bold ? 700 : undefined} display="inline" sx={{ fontStyle: props?.fontStyle, textDecoration: props?.textDecoration }}>
      {props.children}
    </Typography>
  );
};
Inline.defaultProps = {
  bold: true,
};

const GoTopButton = styled(Fab)`
  background: ${colors.White};
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
`;

type DescribedSubparagraph = { title?: string; content: React.ReactNode };
export type StaticPageProps = {
  title: string;
  content: (DescribedSubparagraph | React.ReactNode)[];
};

function StaticPage(props: React.PropsWithChildren<StaticPageProps>) {
  const onClickGoTop = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  const renderGoTopButton = () => (
    <Box position="fixed" bottom={115} right={15} zIndex={2}>
      <GoTopButton onClick={onClickGoTop}>
        <KeyboardArrowUpIcon />
      </GoTopButton>
    </Box>
  );

  const renderParagraphContent = (paragraph: { index: number; content: React.ReactNode[] | React.ReactNode }) => (
    <StaticPage.Paragraph>
      {isArray(paragraph.content) ? (
        paragraph.content.map((subpagraph, subpagraphIndex) => (
          // eslint-disable-next-line react/no-array-index-key
          <NewLine key={`paragraph-${paragraph.index}-sub-${subpagraphIndex}`}>{subpagraph}</NewLine>
        ))
      ) : (
        <NewLine>{paragraph.content}</NewLine>
      )}
    </StaticPage.Paragraph>
  );

  return (
    <Container sx={{ my: 3 }}>
      <SJBreadcrumbs title={props.title} />

      <Box alignItems="flex-end">
        <Box justifyContent="end">
          <Typography variant="h1" mb={5} mt={['16px', '20px', '60px']}>
            {props.title}
          </Typography>
        </Box>

        {props.content.map((paragraph, paragraphIndex) =>
          (paragraph as DescribedSubparagraph)?.title ? (
            <>
              <Subtitle>{(paragraph as DescribedSubparagraph).title}</Subtitle>
              {renderParagraphContent({ index: paragraphIndex, content: (paragraph as DescribedSubparagraph).content })}
            </>
          ) : (
            renderParagraphContent({ index: paragraphIndex, content: paragraph as React.ReactNode })
          ),
        )}
      </Box>

      {renderGoTopButton()}
    </Container>
  );
}

type CompoundPage = typeof StaticPage & {
  Paragraph: typeof Paragraph;
  Subtitle: typeof Subtitle;
  NewLine: typeof NewLine;
  Inline: typeof Inline;
};

StaticPage.Paragraph = Paragraph;
StaticPage.Subtitle = Subtitle;
StaticPage.NewLine = NewLine;
StaticPage.Inline = Inline;

export default StaticPage as CompoundPage;
