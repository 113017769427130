import { ICurrency, IFormattedCurrency } from 'interface/public';
import { IReduxState } from 'interface/redux';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrency, updateCurrency } from 'redux/currency/action';

const useList = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<IFormattedCurrency[]>([]);

  const fetchList = async () => {
    try {
      let resolve: ((value: any) => any) | null = null;
      let reject: ((reason: any) => void) | null = null;
      const promise = new Promise<any>((_resolve, _reject) => {
        resolve = _resolve;
        reject = _reject;
      });
      dispatch(fetchCurrency({ resolve: resolve!, reject: reject! }));
      const nextList = await promise;
      setData(nextList);
    } catch (error) {
      console.log('Failed to fetch currency list!');
    }
  };

  useEffect(() => {
    fetchList();
  }, []);

  return { data };
};

const useCurrency = () => {
  const dispatch = useDispatch();
  const data = useSelector((state: IReduxState) => state.currency?.data);
  const status = useSelector((state: IReduxState) => state.currency?.status);

  const update = (nextCurrency: IFormattedCurrency | null) => {
    dispatch(updateCurrency(nextCurrency));
  };

  return { data, status, update };
};

const useOnChangeEffect = (cb: (nextCurrency: IFormattedCurrency | null) => any) => {
  const data = useSelector((state: IReduxState) => state.currency?.data);
  const status = useSelector((state: IReduxState) => state.currency?.status);

  useEffect(() => {
    if (status !== 'success') {
      return;
    }

    cb(data);
  }, [data, status]);
};

const format = (unformatted: ICurrency) => ({
  label: `${unformatted.symbol} (${unformatted.code})`,
  value: unformatted.id,
  code: unformatted.code,
  symbol: unformatted.symbol,
});

const UserCurrency = {
  useOnChangeEffect,
  useList,
  useCurrency,
  format,
};

export default UserCurrency;
