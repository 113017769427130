import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import colors from 'constants/colors';
import { ReactElement } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

function SingleCard({ children }: { children: ReactElement[] }) {
  const navigate = useNavigate();
  return (
    <Container sx={{ bgcolor: colors.White }}>
      <Box my={2} py={2}>
        <Box position="relative" textAlign="center" mb={4}>
          <Box position="absolute" top={10}>
            <ArrowBackIcon
              onClick={() => {
                navigate('/');
              }}
            />
          </Box>
          <Link href="/" sx={{ '&:hover': { color: 'inherit' }, marginLeft: '0 !important' }}>
            <img src={`${process.env.PUBLIC_URL}/sj-logo.png`} alt="List & Deal" height={60} />
          </Link>
        </Box>
        {children}
      </Box>
    </Container>
  );
}

export default SingleCard;
