import React from 'react';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { formatMoney } from 'utils/formatMoney';
import colors from 'constants/colors';
import { useChatContainerContext } from 'context/ChatContainerContext';
import { CHAT_TYPES } from 'interface/chat';
import { useNavigate } from 'react-router-dom';
import { useChatMessageContext } from 'context/ChatMessageContext';
import SjImage from 'components/Layout/SjImage';
import { DEALSTATUS } from 'interface/deal';
import ChatInviteToRoomButton from '../ChatInviteToRoom';

function ChatBoxItemHeader({ handleInvited, roomId, invited }: { handleInvited: () => void; roomId?: string; invited: boolean }) {
  const navigate = useNavigate();
  const { activeChat: chat, hideInfo } = useChatContainerContext();
  const { messages } = useChatMessageContext();

  const { item: deal } = chat;
  const isBlocked = chat?.item?.status === DEALSTATUS.BLOCKED;
  const DealWrapper: React.ElementType = isBlocked ? Box : Link;

  const renderRoomButton = () => (
    <>
      {roomId && (chat.type === CHAT_TYPES.LISTING_CHAT_MINE || chat.type === CHAT_TYPES.LISTING_CHAT_GENERAL) && (
        <Button variant="contained" sx={{ fontSize: '14px', py: 1 }} onClick={() => navigate(`/rooms?id=${roomId}`)}>
          Room
        </Button>
      )}
      {!roomId &&
        chat.type === CHAT_TYPES.LISTING_CHAT_MINE &&
        !chat.groupRoomId &&
        !invited &&
        (messages?.[messages.length - 1]?.type !== 'INVITATION' || messages?.[messages.length - 1]?.status !== 'PENDING') && (
          <Stack>
            <ChatInviteToRoomButton onInvited={handleInvited} />
          </Stack>
        )}
    </>
  );

  return (
    <Box borderBottom={`1px solid ${colors.BaseGrey}`}>
      {chat?.item?.id && !hideInfo && (
        <Stack
          pt={2}
          px={2}
          pb={2}
          sx={{
            justifyContent: 'space-between',
            alignItems: ['flex-start', 'center', 'center', 'center'],
            flexDirection: ['column', 'row', 'row', 'row'],
            ml: [2, 2, 2, 0],
          }}
        >
          <DealWrapper href={`/deal/view/${chat.item.id}`} style={{ position: 'relative' }}>
            <Stack direction="row" sx={{ mb: [1, 1, 0, 0], opacity: isBlocked ? 0.5 : 1 }}>
              <SjImage url={chat.item.photo} width={40} height={40} objectFit="cover" alt={chat.item.title} />
              <Box ml={2} textAlign="left">
                <Typography fontWeight={600} color={colors.BaseText}>
                  {chat.item.name}
                </Typography>
                <Typography fontWeight={700} color={colors.BaseText}>
                  {`${chat.item.symbol} ${formatMoney(chat.item.minPrice)} - ${chat.item.symbol} ${formatMoney(chat.item.maxPrice)}`}
                </Typography>
              </Box>
            </Stack>
          </DealWrapper>
          {deal?.status === DEALSTATUS.OPEN && renderRoomButton()}
        </Stack>
      )}
    </Box>
  );
}

export default ChatBoxItemHeader;
