import { Box, Button, Modal, Stack, Typography, IconButton } from '@mui/material';
import { useState } from 'react';
import { modalStyle } from 'themes/modal';
import CloseIcon from '@mui/icons-material/Close';
import { blockReportedListing } from 'apis/deal';

function BlockReportedListing({ id, onBlockedSuccess }: { id: string; onBlockedSuccess: () => void }) {
  const [isShowPopup, setIsShowPopup] = useState(false);

  const submit = async () => {
    try {
      await blockReportedListing(id);
      setIsShowPopup(false);
      onBlockedSuccess();
    } catch (err) {
      console.log('err', err);
    }
  };
  return (
    <>
      <Button onClick={() => setIsShowPopup(true)}>Block</Button>
      <Modal open={isShowPopup} onClose={() => setIsShowPopup(false)}>
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={() => setIsShowPopup(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h3" textAlign="center" mb={4}>
            Block Listing
          </Typography>
          <Box my={4}>This action cannot be undone. Are you sure that you want to block this listing ?</Box>
          <Stack direction="row" gap={3}>
            <Button variant="outlined" type="button" color="info" onClick={() => setIsShowPopup(false)} fullWidth>
              Cancel
            </Button>
            <Button variant="contained" type="button" fullWidth onClick={submit}>
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}

export default BlockReportedListing;
