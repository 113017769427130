import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLazyQuery, useQuery as useApolloQuery, LazyQueryHookOptions } from '@apollo/react-hooks';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import colors from 'constants/colors';
import { useEffect, useState } from 'react';
import { CHECK_HAS_ANY_ROOM, GET_ROOMS, GET_ROOM_BY_ID } from 'apis/graphql';
import { convertChatBoxItemApi, orderRoomsByActiveRoom } from 'utils/convertApiChatToWebChat';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { ROOMS_LIMIT_PER_LOAD } from 'constants/index';
import { IChat, CHAT_STATUSES, CONVERSATION_TYPE } from 'interface/chat';
import { CircularProgress, IconButton } from '@mui/material';
import useQuery from 'utils/useQuery';
import ChatList, { CHAT_LIST_VIEW_MODE } from 'components/Chat/ChatList';
import ChatItem from 'components/Chat/ChatItem';
import RoomRightSideBar from 'components/Room/RoomRightSidebar';
import RoomLeftSidebar from 'components/Room/RoomLeftSidebar';
import ChatEmpty from 'components/Chat/ChatEmpty';
import { useMainContext } from 'context/MainContext';
import RoomsSearch from 'components/Chat/Search/SearchMultipleRooms';
import { ArrowBack } from '@mui/icons-material';
import { IRoomsGlobalSearchSelectedMessage } from 'interface/room';
import SJFooter from 'components/Layout/SjFooter';
import { useBreakpoints } from 'themes';
import UserCurrency from 'utils/userCurrency';
import SjHelmet from 'components/Layout/SjHelmet';

const chatFilters = [
  {
    name: 'All',
    value: '',
  },
  {
    name: 'Active',
    value: CHAT_STATUSES.OPEN,
  },
  {
    name: 'Cancelled',
    value: CHAT_STATUSES.CANCELLED,
  },
  {
    name: 'Completed',
    value: CHAT_STATUSES.COMPLETED,
  },
  {
    name: 'Archived',
    value: CHAT_STATUSES.ARCHIVED,
  },
];

function RoomsPage() {
  const query = useQuery();
  const MainContext = useMainContext();
  const isUpLg = useBreakpoints((breakpoints) => breakpoints.up('lg'));
  const isUpMd = useBreakpoints((breakpoints) => breakpoints.up('md'));

  const currentUserId = useSelector((state: IReduxState) => state?.auth.user?.id?.toString() ?? '');
  const [filter, setFilter] = useState<string>(CHAT_STATUSES.OPEN);
  const [nextToken, setNextToken] = useState();
  const [page, setPage] = useState(0);
  const [getRooms, { data: roomData }] = useLazyQuery(GET_ROOMS);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const { data: existsRoom, loading } = useApolloQuery(CHECK_HAS_ANY_ROOM, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const [getRoomById] = useLazyQuery(GET_ROOM_BY_ID);

  const [selectedMsg, setSelectedMsg] = useState<IRoomsGlobalSearchSelectedMessage | undefined>(undefined);
  const [rooms, setRooms] = useState<IChat[]>([]);
  const [activeRoom, setActiveRoom] = useState<IChat | null>(null);
  const [showRightSidebar, setShowRightSidebar] = useState(window.innerWidth > 1200);
  const [showLeftSidebar, setShowLeftSidebar] = useState<boolean>(true);

  const roomId = query.get('id');

  useEffect(() => {
    setFilter(chatFilters[1].value);
  }, []);

  const onRemovedCurrentUserFromActiveRoom = () => {
    setShowLeftSidebar(true);
    setShowRightSidebar(false);
  };

  const moveActiveRoomToTop = () => {
    if (activeRoom) {
      setRooms([activeRoom, ...rooms.filter((item) => item.id !== activeRoom.id)]);
    }
  };

  const fetchRooms = <IRoom,>(opts?: {
    notAttachNextToken?: boolean;
    onPrepareOptions?: (defaultOptions: Partial<LazyQueryHookOptions<IRoom[], any>>) => Partial<LazyQueryHookOptions<IRoom[], any>>;
  }) => {
    const variables: any = {
      type: 'LISTING_GROUP',
      limit: ROOMS_LIMIT_PER_LOAD,
    };
    if (nextToken && !opts?.notAttachNextToken) {
      variables.nextToken = nextToken;
    }
    if (filter) {
      variables.status = filter;
    }
    const onPrepareOptions = opts?.onPrepareOptions ?? ((option: Partial<LazyQueryHookOptions<IRoom[], any>>) => option);
    const nextOptions = onPrepareOptions?.({ variables, fetchPolicy: 'no-cache', nextFetchPolicy: 'standby' });

    getRooms(nextOptions);
  };

  const getActiveRoom = async (rid: string) => {
    const { data } = await getRoomById({ variables: { id: rid }, fetchPolicy: 'network-only' });

    if (data.room) {
      setFilter(data.room.roomStatus);
      setActiveRoom(convertChatBoxItemApi(data.room, currentUserId));
    } else {
      setActiveRoom(null);
    }
    fetchRooms();
  };

  useEffect(() => {
    if (roomId) {
      getActiveRoom(roomId);
    } else {
      fetchRooms();
    }
  }, [roomId]);

  useEffect(() => {
    if (page > 0) {
      fetchRooms();
    }
  }, [page]);

  useEffect(() => {
    setPage(0);
    fetchRooms();
  }, [filter]);

  useEffect(() => {
    if (roomData?.rooms?.items) {
      const roomDataItems = orderRoomsByActiveRoom(roomData.rooms.items, currentUserId, activeRoom);
      const roomItems = page === 0 ? roomDataItems : [...rooms, ...roomDataItems];
      setRooms(roomItems);
      if (activeRoom === null && roomItems.length > 0) {
        setActiveRoom(roomItems[0]);
      }
    }
    setNextToken(roomData?.rooms.nextToken);
  }, [roomData]);

  const display = !showLeftSidebar ? 'block' : 'none';

  useEffect(() => {
    if (existsRoom?.existsRoom?.exists) {
      MainContext.setPageType('chat');
      return () => MainContext.setPageType('normal');
    }
    MainContext.setPageType('no-result');
    return () => MainContext.setPageType('normal');
  }, [loading, rooms.length]);

  useEffect(() => {
    if (isUpLg) {
      return;
    }
    SJFooter.current.setVisible(false);
    return () => SJFooter.current.setVisible(true);
  }, [isUpLg]);

  UserCurrency.useOnChangeEffect(async () => {
    setNextToken(undefined);
    setPage(0);
    fetchRooms({
      onPrepareOptions: ({ variables, ...rest }) => {
        const nextVariables = { ...variables };
        delete nextVariables.nextToken;
        const onCompleted = (data: any) => {
          const roomDataItems = orderRoomsByActiveRoom(data.rooms.items, currentUserId, activeRoom);
          const foundRoom = roomDataItems.find((r) => r.id === activeRoom?.id);
          setRooms(roomDataItems);
          setActiveRoom(foundRoom ?? roomDataItems[0]);
        };
        return { variables: nextVariables, ...rest, onCompleted };
      },
    });
  });

  const render = () => {
    if (loading) {
      return (
        <Stack sx={{ height: window.innerHeight - 300, justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Stack>
      );
    }

    return (
      <>
        <RoomLeftSidebar isShow={showLeftSidebar}>
          <ChatList
            header={
              <>
                {!isSearchMode ? (
                  <Stack p={2} direction="row" flexWrap="wrap">
                    {chatFilters.map((item) => (
                      <Chip
                        label={item.name}
                        key={item.value}
                        onClick={() => {
                          setNextToken(undefined);
                          setFilter(item.value);
                        }}
                        sx={{
                          mb: 1,
                          mr: 1,
                          fontSize: '14px',
                          fontWeight: 500,
                          bgcolor: filter === item.value ? colors.White : 'transparent',
                          border: `1px solid ${colors.White}`,
                          p: '15px 6px',
                        }}
                      />
                    ))}
                  </Stack>
                ) : (
                  <Box py={2}>
                    <IconButton
                      onClick={() => {
                        setIsSearchMode(false);
                        setActiveRoom(rooms?.[0] ?? null);
                      }}
                    >
                      <ArrowBack sx={{ color: '#023047' }} />
                    </IconButton>
                  </Box>
                )}

                <RoomsSearch
                  onSearching={(isSearch: boolean) => setIsSearchMode(isSearch)}
                  isSearchMode={isSearchMode}
                  activeRoom={activeRoom}
                  setActiveRoom={setActiveRoom}
                  setSelectedMsg={setSelectedMsg}
                  setShowLeftSidebar={setShowLeftSidebar}
                />
              </>
            }
            chats={rooms}
            setActiveChat={setActiveRoom}
            activeChat={activeRoom}
            viewMode={CHAT_LIST_VIEW_MODE.ROOM}
            page={page}
            setPage={setPage}
            nextToken={nextToken}
            setShowLeftSidebar={setShowLeftSidebar}
            hiddenBody={isSearchMode}
            headerProps={{ ...(isSearchMode && { sx: { height: '100%' } }) }}
          />
        </RoomLeftSidebar>

        <Box flex={1} bgcolor={colors.White} position="relative">
          {!showRightSidebar && (
            <IconButton
              sx={{
                display: [display, display, 'none', 'none'],
                position: 'absolute',
                left: 0,
                top: 12,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                zIndex: 2,
              }}
              onClick={() => setShowLeftSidebar(true)}
            >
              <ArrowBackIosIcon sx={{ width: 30 }} />
            </IconButton>
          )}
          {activeRoom && (
            <ChatItem
              tab="LISTING_GROUP"
              chats={rooms}
              setChats={setRooms}
              activeChat={activeRoom}
              setActiveChat={setActiveRoom}
              selectedMsg={selectedMsg}
              moveActiveRoomToTop={moveActiveRoomToTop}
              onRemovedCurrentUserFromActiveRoom={onRemovedCurrentUserFromActiveRoom}
            />
          )}
        </Box>
        {activeRoom && (isUpMd || !showLeftSidebar) && <RoomRightSideBar activeRoom={activeRoom} setShow={setShowRightSidebar} isShow={showRightSidebar} />}
      </>
    );
  };

  return (
    <>
      <SjHelmet title="Rooms" />
      {!loading && !existsRoom?.existsRoom?.exists ? (
        <Box textAlign="center" flex={1} alignSelf="center">
          <ChatEmpty type={CONVERSATION_TYPE.ROOM} />
        </Box>
      ) : (
        <Container sx={{ my: 3, ...(!loading && { display: 'flex', flex: 1 }) }}>
          <Stack sx={{ borderRadius: '16px', overflow: 'hidden', ...(!loading && { flex: 1, flexDirection: 'row', width: '100%' }) }}>
            {/* <SJBreadcrumbs title="Rooms" /> */}
            <Stack
              sx={{
                boxShadow: '0px 4px 21px rgba(105, 88, 26, 0.15)',
                ...(!loading && { flex: 1, flexDirection: 'row', position: 'relative', display: 'flex' }),
              }}
            >
              {render()}
            </Stack>
          </Stack>
        </Container>
      )}
    </>
  );
}

export default RoomsPage;
