import { Close } from '@mui/icons-material';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import colors from 'constants/colors';
import { useEffect, useState } from 'react';

function ChatSearch({ onSearch, p, bgColor }: { onSearch: (value: string) => void; p?: number; bgColor?: string }) {
  const [search, setSearch] = useState('');

  useEffect(() => {
    setSearch('');
  }, []);

  const onSearchRoom = (value: string) => {
    setSearch(value);
    onSearch(value);
  };

  return (
    <Box sx={{ position: 'sticky', top: 0, zIndex: 1, px: p ?? 2, pt: 3, mb: 2 }}>
      <OutlinedInput
        fullWidth
        placeholder="Search chats by keywords or usernames"
        onChange={(e) => onSearchRoom(e.target.value)}
        value={search}
        sx={{
          backgroundColor: search.length > 0 ? colors.Primary : colors.White,
        }}
        endAdornment={
          search.length > 0 && (
            <InputAdornment position="end">
              <Close
                onClick={() => {
                  onSearchRoom('');
                }}
                sx={{ cursor: 'pointer' }}
              />
            </InputAdornment>
          )
        }
      />
    </Box>
  );
}

export default ChatSearch;
