import { Box, Link, Stack } from '@mui/material';
import colors from 'constants/colors';
import { useLocation } from 'react-router-dom';
import { setDisplayDesktopOnly } from 'themes';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import SjGlobalCurrency from './SjGlobalCurrency';

export type IMenuItemProps = {
  label: string;
  link: string;
  type: string;
  totalUnread?: number;
};

function SjDesktopMenu({ menuItems }: { menuItems: IMenuItemProps[] }) {
  const location = useLocation();
  const auth = useSelector((state: IReduxState) => state.auth);
  const checkSelected = (item: IMenuItemProps) => location.pathname.toLowerCase().includes(item.type.toLowerCase());
  return (
    <Stack direction="row" alignItems="center" spacing={2} display={setDisplayDesktopOnly('flex')}>
      {auth.user &&
        menuItems.map((item) => (
          <Link
            href={item.link}
            key={item.label}
            sx={{
              color: checkSelected(item) ? colors.Secondary : colors.Header,
              borderBottom: checkSelected(item) ? `2px solid ${colors.Secondary}` : 'none',
            }}
          >
            <Stack direction="row" alignItems="center">
              {item.label}
              {typeof item.totalUnread === 'number' && item.totalUnread > 0 && (
                <Box
                  sx={{
                    ml: '3px',
                    bgcolor: colors.Secondary,
                    width: 18,
                    height: 18,
                    color: colors.White,
                    fontSize: 11,
                    borderRadius: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {item.totalUnread}
                </Box>
              )}
            </Stack>
          </Link>
        ))}
      <SjGlobalCurrency />
    </Stack>
  );
}

export default SjDesktopMenu;
