import { useLocation } from 'react-router-dom';
import SjTabs from './SjTabs';

const SIDEBAR_MENU = [
  {
    title: 'My Profile',
    href: '/my-profile',
    value: 'MY_PROFILE',
  },
  {
    title: 'Change Password',
    href: '/my-profile/change-password',
    value: 'CHANGE_PASSWORD',
  },
];
function EditProfileSidebar() {
  const location = useLocation();
  const picked = (() => {
    const found = SIDEBAR_MENU.find((menu) => menu.href === location.pathname);
    return found?.value;
  })();

  return <SjTabs data={SIDEBAR_MENU} value={picked ?? 'MY_PROFILE'} />;
}

export default EditProfileSidebar;
