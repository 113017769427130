/* eslint-disable no-debugger */
import { GridProps } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import React, { CSSProperties } from 'react';
import { useBreakpoints } from 'themes';

type SjTabsScreenProps = {
  title: React.ComponentProps<typeof SJBreadcrumbs>['title'];
  CustomTitle?: React.ReactNode;
  left: React.ReactNode;
  right: React.ReactNode;
  leftGridProps?: GridProps;
  rightGridProps?: GridProps;
  rightCardStyle?: CSSProperties;
  rightCardContentStyle?: CSSProperties;
};

function SjTabsScreen(props: SjTabsScreenProps) {
  const isUpLg = useBreakpoints((breakpoints) => breakpoints.up('lg'));

  return (
    <Container sx={{ mb: 2 }}>
      {props.CustomTitle ? props.CustomTitle : <SJBreadcrumbs title={props.title} />}
      <Grid container spacing={6} mt={0}>
        <Grid xs={12} lg={3} {...(isUpLg ? {} : { py: 0 })} {...(props?.leftGridProps ?? {})}>
          {props.left}
        </Grid>

        <Grid xs={12} lg={9} {...(isUpLg ? {} : { p: '0 0 24px 0' })} {...(props?.rightGridProps ?? {})}>
          {!!props.right && (
            <Card
              sx={{
                borderTopLeftRadius: '40px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: '40px',
                borderBottomLeftRadius: 0,
                p: 3,
              }}
              style={{
                ...(isUpLg ? { minWidth: '400px' } : { p: 0, borderRadius: 0 }),
                ...(props.rightCardStyle ?? {}),
              }}
            >
              <CardContent sx={{ flex: 1 }} style={props.rightCardContentStyle ?? {}}>
                {props.right}
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default SjTabsScreen;
