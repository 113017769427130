import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, IconButton, MenuItem, Stack, Typography, TypographyProps, useTheme } from '@mui/material';
import Link from '@mui/material/Link';
import colors from 'constants/colors';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setDisplayMobileOnly, useBreakpoints } from 'themes';
import Listener, { EVENT_NAME } from 'utils/listener';

export type IMenuItemCustomRender = {
  renderComp: () => React.ReactNode;
};
export type IMenuItemProps = {
  label: string;
  link: string;
  labelProps?: TypographyProps;
  totalUnread?: number;
};

export type SjMobileMenuItem = IMenuItemProps | IMenuItemCustomRender;

function SjMobileMenu({ menuItems }: { menuItems: SjMobileMenuItem[] }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDownSm = useBreakpoints((breakpoints) => breakpoints.down('sm'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onChangeAnchorEl = (nextTarget?: null) => (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(nextTarget === undefined ? e.currentTarget : nextTarget);
  };

  const onClickItem = (item: IMenuItemProps) => (_: React.MouseEvent<HTMLElement>) => {
    navigate(item.link);
    onChangeAnchorEl(null)(_);
  };

  useEffect(() => {
    const removeListener = Listener.addListener(EVENT_NAME.CLOSE_MOBILE_MENU, onChangeAnchorEl(null));
    return () => removeListener();
  }, []);

  const renderItem = (item: SjMobileMenuItem, index: number) => {
    const customRenderItem = item as IMenuItemCustomRender;
    const key = `mobile-menu-${index}`;
    if (customRenderItem.renderComp) {
      return (
        <Box key={key} py="12px">
          {customRenderItem.renderComp()}
        </Box>
      );
    }
    const menuItem = item as IMenuItemProps;
    return (
      <MenuItem
        key={key}
        onClick={onClickItem(menuItem)}
        style={{ paddingRight: theme.spacing(1.5), paddingLeft: theme.spacing(1.5) }}
        sx={{
          alignItems: 'center',
          '.MuiTypography-root': theme.typography.h4,
          height: '48px',
        }}
      >
        <Typography>{menuItem.label}</Typography>

        {typeof menuItem.totalUnread === 'number' && menuItem.totalUnread > 0 && (
          <Box
            sx={{
              ml: 1,
              bgcolor: colors.Secondary,
              width: 18,
              height: 18,
              color: colors.White,
              fontSize: 11,
              borderRadius: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {menuItem.totalUnread}
          </Box>
        )}
      </MenuItem>
    );
  };

  return (
    <Box display={setDisplayMobileOnly('block')} mr="16px">
      <IconButton sx={{ p: 0 }} onClick={onChangeAnchorEl()}>
        <MenuIcon />
      </IconButton>

      <Drawer open={open} onClose={onChangeAnchorEl(null)} PaperProps={{ sx: { right: 0, bottom: 0, background: colors.Primary, p: '24px' } }}>
        <Box position="relative" flex={1}>
          <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Link href="/" sx={{ '&:hover': { color: 'inherit' }, marginLeft: '0 !important' }} onClick={onChangeAnchorEl(null)}>
              <img src={`${process.env.PUBLIC_URL}/sj-logo.png`} alt="List & Deal" height={isDownSm ? 46 : 55} />
            </Link>

            <IconButton onClick={onChangeAnchorEl(null)}>
              <CloseIcon />
            </IconButton>
          </Stack>

          <Box py="12px">{menuItems.map(renderItem)}</Box>
        </Box>
      </Drawer>
    </Box>
  );
}

export default SjMobileMenu;
