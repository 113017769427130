import { Box, Pagination, Stack, Typography } from '@mui/material';
import { getDeals } from 'apis/deal';
import { getUserInfo } from 'apis/user';
import SjHelmet from 'components/Layout/SjHelmet';
import ListingsComponent from 'components/Listing/Listings';
import ProfileInfo from 'components/Profile/ProfileInfo';
import SjTabsScreen from 'components/Profile/SjTabsScreen';
import IDeal from 'interface/deal';
import { IFilter } from 'interface/filter';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showToast } from 'redux/toast/action';
import { paginationNumber } from 'utils/common';
import UserCurrency from 'utils/userCurrency';

function UserProfile() {
  const dispatch = useDispatch();
  const Currency = UserCurrency.useCurrency();

  const [deals, setDeals] = useState<IDeal[] | []>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const params = useParams<{ id: string }>();
  const [filter, setFilter] = useState<IFilter>({
    p: 1,
    l: 18,
    owner_id: +params.id!,
    convert_currency_id: Currency.data?.value,
  });
  const [profile, setProfile] = useState<any>({});

  useEffect(() => {
    if (params.id) {
      getUserInfo(params.id).then((resp) => {
        setProfile(resp);
      });
    }
  }, []);

  useEffect(() => {
    if (filter.p !== 0) {
      getDeals(filter).then((resp) => {
        setLoading(false);
        setDeals(resp.data);
        setTotal(resp.total_count);
      });
    }
  }, [filter]);

  UserCurrency.useOnChangeEffect(async (nextCurrency) => {
    try {
      const convert_currency_id = nextCurrency?.value;
      const nextFilter = { ...filter, convert_currency_id };
      await getDeals(nextFilter);
      setFilter(nextFilter);
    } catch (error) {
      dispatch(showToast({ type: 'error', title: 'Failed to fetch details!' }));
    }
  });

  return (
    <>
      <SjHelmet title="User Profile" />
      <SjTabsScreen
        title=""
        CustomTitle={<Box mt={3} />}
        leftGridProps={{ lg: 4 }}
        rightGridProps={{ lg: 8, mt: [0, 3, 3, 0] }}
        left={<ProfileInfo profile={profile} />}
        right={
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 6 }}>
              <Typography variant="h2">Listings</Typography>
            </Stack>
            <ListingsComponent deals={deals} columns={4} hideShadow actions={['view_chat', 'view_chat']} loading={loading} hideOwner />
            {!!total && filter.p && filter.l && total > filter.l && (
              <Pagination
                count={paginationNumber(total, filter.l)}
                page={filter.p}
                sx={{ flex: 1 }}
                onChange={(event: React.ChangeEvent<unknown>, page: number) => {
                  setFilter({
                    ...filter,
                    p: page,
                  });
                }}
              />
            )}
          </>
        }
      />
    </>
  );
}

export default UserProfile;
