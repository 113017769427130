import { Button, Card, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import authProvider from 'apis/auth';

import SjInput from 'components/Form/SjInput';
import { Formik, FormikErrors } from 'formik';
import { isPasswordStrength } from 'utils/regex';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { showToast } from 'redux/toast/action';
import { useDispatch } from 'react-redux';
import SjHelmet from 'components/Layout/SjHelmet';

function ResetPasswordPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useDispatch();
  const token: string = params.get('token') || '';
  const submit = (values: { new_password: string; new_password_confirm: string }) => {
    authProvider
      .resetPassword({ token, ...values })
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        dispatch(
          showToast({
            type: 'error',
            title: error?.response?.data?.error?.message ?? 'Oops! Something went wrong!.',
          }),
        );
      });
  };

  const render = () => {
    return (
      <Formik
        initialValues={{ new_password: '', new_password_confirm: '' }}
        onSubmit={submit}
        validate={(values) => {
          const errors: FormikErrors<{
            new_password: string;
            new_password_confirm: string;
          }> = {};
          if (!values.new_password) {
            errors.new_password = 'Please enter new password.';
          } else if (!isPasswordStrength(values.new_password)) {
            errors.new_password = 'Please ensure you follow the password rules.';
          } else if (!values.new_password_confirm) {
            errors.new_password_confirm = 'Please confirm the password';
          } else if (values.new_password_confirm !== values.new_password) {
            errors.new_password_confirm = 'Confirm password does not match!';
          }
          return errors;
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <SjInput name="new_password" label="New Password" placeholder="New Password" type="password" />
            <SjInput name="new_password_confirm" label="Confirm New Password" placeholder="Confirm New Password" type="password" />
            <Button variant="contained" type="submit" fullWidth sx={{ height: 52 }} disabled={isSubmitting}>
              Reset Password
            </Button>
          </form>
        )}
      </Formik>
    );
  };

  return (
    <Stack alignItems="center" marginTop={10}>
      <SjHelmet title="Reset Password" />
      <Card sx={{ minWidth: ['100%', '100%', 500], margin: '0 auto', border: 'none', boxShadow: 'none' }} variant="outlined">
        <Typography variant="h2" textAlign="center" mb={6}>
          Reset Password
        </Typography>
        {render()}
      </Card>
    </Stack>
  );
}

export default ResetPasswordPage;
