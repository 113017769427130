import { memo, useEffect, useRef, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import colors from 'constants/colors';

function InputMessage({ onSend }: { onSend: (text: string) => void }) {
  const [text, setText] = useState('');
  const inputMessageRef = useRef<HTMLInputElement>(null);
  const [isShiftLeft, setIsShiftLeft] = useState(false);

  useEffect(() => {
    if (inputMessageRef.current) {
      inputMessageRef.current?.focus();
    }
  }, []);

  return (
    <Stack direction="row" sx={{ minHeight: '50px', borderTop: `1px solid ${colors.BaseGrey}` }}>
      <Input
        multiline
        inputRef={inputMessageRef}
        value={text}
        onChange={(e) => {
          if (e.target.value.trim().length > 0) {
            setText(e.target.value);
          } else {
            setText('');
          }
        }}
        onKeyUp={(e) => {
          if (e.code === 'ShiftLeft') {
            setIsShiftLeft(true);
          } else if (!isShiftLeft && !e.shiftKey && e.code === 'Enter' && text.trim().length > 0) {
            onSend(text.trim());
            setText('');
            setIsShiftLeft(false);
          } else {
            setIsShiftLeft(false);
          }
        }}
        fullWidth
        sx={{
          pt: 1,
          pb: 1,
          px: 3,
          '&:after, &:before': {
            borderBottom: 'none !important',
          },
        }}
        placeholder="Write a message..."
      />
      <IconButton
        type="button"
        onClick={() => {
          onSend(text);
          setText('');
        }}
        sx={{ height: '50px' }}
        disabled={text.length === 0}
      >
        <SendIcon />
      </IconButton>
    </Stack>
  );
}

export default memo(InputMessage);
