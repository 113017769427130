import dayjs from 'dayjs';

export const renderDateByFormat = (date: string, hourOnly?: boolean, isHumanDate?: boolean, isFromNow?: boolean, format?: string) => {
  if (format) {
    return dayjs(date).format(format);
  }
  if (isFromNow) {
    return dayjs(date).fromNow();
  }

  const year = dayjs(date).format('YYYY');
  const currentYear = dayjs().format('YYYY');
  if (isHumanDate) {
    const day = dayjs(date).format('DD/MM/YYYY');
    const now = dayjs().format('DD/MM/YYYY');
    if (hourOnly) {
      if (day === now) return dayjs(date).format('HH:mm');
      if (year === currentYear) return dayjs(date).format('DD MMM');
      return dayjs(date).format('MMM YYYY');
    }
    if (day === now) {
      return 'Today';
    }
  }
  if (hourOnly) {
    return dayjs(date).format('HH:mm');
  }
  if (currentYear === year) {
    return dayjs(date).format('DD MMM');
  }
  return dayjs(date).format('DD MMM YYYY');
};
