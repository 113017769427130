/* eslint-disable react/jsx-no-duplicate-props */
import styled from '@emotion/styled';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { tooltipClasses, TooltipProps, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import colors from 'constants/colors';
import { FormikValues, useFormikContext } from 'formik';
import { ReactElement, useEffect, useState } from 'react';
import { formatMoney } from 'utils/formatMoney';
import { isNumber } from 'utils/regex';

interface ISjInputProps {
  label?: string;
  type?: string;
  placeholder?: string;
  name: string;
  noBorder?: boolean;
  disabled?: boolean;
  maxLength?: number;
  onFocus?: () => void;
  tooltip?: ReactElement;
  helpText?: string;
  mode?: 'currency';
  mb?: number | string;
  maxChars?: number;
  endAdornment?: any;
  onBlur?: any;
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(() => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: colors.White,
      filter: 'drop-shadow(0px 4px 21px rgba(105, 88, 26, 0.15))',
      color: colors.BaseText,
      fontSize: 16,
      borderRadius: '16px',
      minHeight: '30px',
    },
    '& .MuiTooltip-arrow': {
      color: colors.White,
      borderWidth: '10px',
      fontSize: 40,
    },
  };
});

function SjInput({
  label,
  placeholder,
  name,
  type,
  noBorder,
  disabled,
  maxLength,
  onFocus,
  tooltip,
  helpText,
  mode,
  mb = 4,
  maxChars,
  endAdornment,
  onBlur,
}: ISjInputProps) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));

  const { values, handleChange, touched, errors, setFieldValue, status, setTouched, handleBlur } = useFormikContext<FormikValues>();
  const [inputType, setInputType] = useState('text');

  const handleClickShowPassword = () => setInputType(inputType === 'password' ? 'text' : 'password');
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (type) {
      setInputType(type);
    }
  }, [type]);

  const renderHelpText = () => {
    if (touched[name] && errors[name]) {
      if (helpText) {
        return (
          <p style={{ margin: 0 }}>
            {helpText}
            <br />

            {errors[name]
              ?.toString()
              .split('\n')
              .map(
                (item: string) =>
                  item && (
                    <p key={item} style={{ margin: 0 }}>
                      ∙ {item}
                    </p>
                  ),
              )}
          </p>
        );
      }
      return (
        <p style={{ margin: 0 }}>
          {errors[name]
            ?.toString()
            .split('\n')
            .map(
              (item: string) =>
                item && (
                  <p key={item} style={{ margin: 0 }}>
                    ∙ {item}
                  </p>
                ),
            )}
        </p>
      );
    }
    if (helpText) {
      return helpText;
    }
    return '';
  };

  const getPlaceholder = () => {
    if (typeof status === 'boolean' && !status) {
      return 'Loading...';
    }
    return placeholder ?? '';
  };

  const onTextChange = (e: React.ChangeEvent<any>) => {
    if (mode === 'currency') {
      const money = e.target.value.split(',').join('').split('.').join('');
      if (money === '' || isNumber(money)) {
        setFieldValue(name, formatMoney(e.target.value, { defaultValue: '' }));
      }
    } else {
      const newText = e.target.value;
      if (maxChars && newText && newText.length > 0) {
        setFieldValue(name, newText.slice(0, maxChars));
      } else {
        handleChange(e);
      }
    }
  };

  const endAdornmentRender = () => {
    if (type === 'password') {
      return (
        <InputAdornment position="end">
          <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
            {inputType === 'text' ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      );
    }
    if (endAdornment) {
      return endAdornment;
    }
    return null;
  };

  const input =
    type === 'checkbox' ? (
      <>
        <Checkbox id={name} onChange={(e) => setFieldValue(name, e.target.checked)} checked={values[name]} sx={{ p: 0 }} />
        <InputLabel
          shrink
          error={touched[name] && Boolean(errors[name])}
          sx={{ whiteSpace: 'break-spaces', color: disabled ? colors.BaseSecondaryText : colors.Header, mb: 0, ml: '8px' }}
        >
          {label}
        </InputLabel>
      </>
    ) : (
      <>
        {!!label && (
          <InputLabel
            shrink
            error={touched[name] && Boolean(errors[name])}
            sx={{ whiteSpace: 'break-spaces', color: disabled ? colors.BaseSecondaryText : colors.Header }}
          >
            {label}
          </InputLabel>
        )}

        <TextField
          id={name}
          rows={4}
          multiline={type === 'textarea'}
          placeholder={getPlaceholder()}
          value={values[name] ?? ''}
          onChange={onTextChange}
          onFocus={() => {
            if (onFocus) {
              onFocus();
            }
          }}
          onBlur={(e) => {
            handleBlur(e);
            if (typeof onBlur === 'function') {
              onBlur(values[name]);
            }
          }}
          error={touched[name] && Boolean(errors[name])}
          FormHelperTextProps={{ sx: { mr: 0 } }}
          helperText={renderHelpText()}
          type={inputType}
          className={noBorder ? 'MuiInputNoBorder' : ''}
          disabled={disabled}
          inputProps={{ maxLength, sx: { minHeight: '27px' } }}
          InputProps={{
            endAdornment: endAdornmentRender(),
          }}
        />
      </>
    );

  return (
    <Box mb={mb} {...(type === 'checkbox' ? { display: 'flex', alignItems: 'center' } : { display: 'block', justifyContent: 'space-between' })}>
      {!tooltip ? (
        input
      ) : (
        <LightTooltip
          disableFocusListener
          title={tooltip}
          arrow
          placement={isMediumScreen ? 'right' : 'bottom'}
          enterTouchDelay={isMediumScreen ? undefined : 0}
        >
          <div>{input}</div>
        </LightTooltip>
      )}
    </Box>
  );
}

export default SjInput;
