const scope = process.env.REACT_APP_NAMESPACE;

export const SET_CURRENCY = `${scope}/SET_CURRENCY`;

export const UPDATE_CURRENCY = `${scope}/START_CHAT`;
export const UPDATE_CURRENCY_SUCCESS = `${scope}/UPDATE_CURRENCY_SUCCESS`;
export const UPDATE_CURRENCY_FAILURE = `${scope}/UPDATE_CURRENCY_FAILURE`;

export const FETCH_CURRENCY = `${scope}/FETCH_CURRENCY`;
export const FETCH_CURRENCY_SUCCESS = `${scope}/FETCH_CURRENCY_SUCCESS`;
export const FETCH_CURRENCY_FAILURE = `${scope}/FETCH_CURRENCY_FAILURE`;
