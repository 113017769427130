/* eslint-disable no-param-reassign */
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { uploadImage } from 'apis/public';
import colors from 'constants/colors';
import { FormikValues, useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showToast } from 'redux/toast/action';

export enum IFileKind {
  MEDIA = 'MEDIA',
  CHART = 'CHART',
  ANALYSE = 'analyse',
  FAQ = 'faq',
}

export const MAXIMUM_FILE = 3000000;

function SjFile({
  name,
  accept = 'image/png,image/jpg',
  kind,
  onChange,
  sx,
  uploadPhoto,
  removePhoto,
}: {
  name: string;
  accept?: string;
  kind?: IFileKind;
  onChange?: any;
  sx?: SxProps<any>;
  uploadPhoto?: { wrapper?: { sx?: SxProps<any> }; text?: { sx?: SxProps<any> } };
  removePhoto?: { button?: { sx?: SxProps<any> } };
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { setFieldValue } = useFormikContext<FormikValues>();
  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();

  const handleChange = (targetFiles: File[]) => {
    setUploading(true);
    const formData = new FormData();
    let fileLength = 0;
    targetFiles.forEach((file: File) => {
      if (file.size <= MAXIMUM_FILE) {
        fileLength += 1;
        formData.append('files', file);
      } else {
        dispatch(
          showToast({
            type: 'error',
            title: `Your uploaded photo exceeds the maximum file size of ${MAXIMUM_FILE / 1000000}MB.`,
            description: '',
          }),
        );
        setUploading(false);
      }
    });
    if (fileLength > 0) {
      formData.append('dir', kind ?? 'users');
      uploadImage(formData).then((res) => {
        setFieldValue(name, res[0].img_url);
        setUploading(false);
        if (typeof onChange === 'function') {
          onChange(res[0].img_url);
        }
      });
    }
  };

  const handleUpload = (e: FileList) => {
    handleChange(Array.from(e));
  };

  return (
    <Box sx={{ display: ['block', 'flex'], ...(sx ?? {}) }}>
      <Box sx={{ pr: '24px', ...(uploadPhoto?.wrapper?.sx ?? {}) }}>
        <label htmlFor="input-file-upload">
          <input
            type="file"
            hidden
            id="input-file-upload"
            accept={accept}
            onChange={(e) => {
              if (e.target.files && !uploading) {
                handleUpload(e.target.files);
              }
              e.target.value = '';
            }}
            ref={inputRef}
          />
          <Typography
            sx={{
              border: `2px solid ${colors.Secondary}`,
              width: '200px',
              borderRadius: '100px',
              height: '52px',
              textTransform: 'uppercase',
              fontWeight: 700,
              color: colors.Secondary,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              ...(uploadPhoto?.text?.sx ?? {}),
            }}
          >
            Upload A Photo
          </Typography>
        </label>
      </Box>
      <Button
        variant="text"
        sx={{ px: [0, 4], py: [4, 0], fontWeight: 700, fontSize: '1rem', height: '52px', borderRadius: 900, ...(removePhoto?.button?.sx ?? {}) }}
        onClick={() => {
          if (inputRef !== null && !uploading) {
            setFieldValue(name, '');
            if (typeof onChange === 'function') {
              onChange('');
            }
          }
        }}
      >
        REMOVE PHOTO
      </Button>
    </Box>
  );
}

export default SjFile;
