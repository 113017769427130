import Typography from '@mui/material/Typography';

import DeleteOutlineTwoToneIcon from '@mui/icons-material/DeleteOutlineTwoTone';
import { IconButton, Link, Stack } from '@mui/material';
import SjImage from 'components/Layout/SjImage';
import colors from 'constants/colors';
import { IInvitee, ParticipantRole } from 'interface/deal';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';

import { getUserProfileLink } from 'apis/user';
import { useBreakpoints } from 'themes';

export default function Invitee({
  type,
  invitee,
  viewerRole,
  isLoading,
  ownerId,
  onRemove,
}: {
  type?: 'invitee' | 'participant';
  invitee: IInvitee;
  viewerRole: ParticipantRole;
  isLoading: boolean;
  ownerId: string;
  onRemove?: (invitee: IInvitee) => void;
}) {
  const isDownMd = useBreakpoints((breakpoints) => breakpoints.down('md'));
  const hrefToProfile = useSelector((state: IReduxState) => getUserProfileLink(invitee.id, state.auth.user?.id.toString()));

  const isRemoveable = (): boolean => {
    const inviteeRole = invitee.role || ParticipantRole.PARTICIPANT;
    const isInvitedByOwner = invitee.invited_by?.id === ownerId;
    return (
      (viewerRole === ParticipantRole.OWNER && [ParticipantRole.PARTICIPANT].includes(inviteeRole)) ||
      (!isInvitedByOwner && viewerRole === ParticipantRole.PARTNER && [ParticipantRole.PARTICIPANT].includes(inviteeRole))
    );
  };

  const isAccepted = [ParticipantRole.OWNER, ParticipantRole.PARTNER].includes(invitee.role || ParticipantRole.PARTICIPANT) || invitee.is_accept;

  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <SjImage url={invitee.avatar_url} width={45} height={45} objectFit="cover" borderRadius={999} alt={invitee.name} />
      <Stack flex={1} flexDirection="column" alignItems="flex-start" width="calc(100% - 116px)">
        {isDownMd ? (
          <>
            {!!invitee.username && (
              <Link href={hrefToProfile} fontWeight={600} color="#4A4A68" textAlign="left" width="100%" noWrap>
                {`@${invitee.username}`}
              </Link>
            )}

            <Link href={hrefToProfile} fontWeight={600} color="#4A4A68" textAlign="left" width="100%" noWrap>
              {invitee.user_email}
            </Link>
          </>
        ) : (
          <Link href={hrefToProfile} fontWeight={600} color="#4A4A68" textAlign="left" width="100%" noWrap>
            {!invitee.username ? invitee.user_email : `@${invitee.username} (${invitee.user_email})`}
          </Link>
        )}

        <Stack sx={{ width: '100%', alignItems: 'flex-start' }}>
          {invitee.role && (
            <Typography color={colors.BaseSecondaryText} fontWeight={500} fontSize="14px">
              {invitee.role}
            </Typography>
          )}
          {invitee.invited_by && !invitee.role && (
            <Stack
              direction={isDownMd ? 'column' : 'row'}
              alignItems={isDownMd ? 'flex-start' : 'center'}
              justifyContent="space-between"
              textAlign={isDownMd ? 'left' : 'center'}
              flex={1}
              width="100%"
            >
              <Typography color={colors.BaseSecondaryText} fontWeight={500} fontSize="14px" noWrap>{`Added by ${
                invitee.invited_by.fullname ? `@${invitee.invited_by.fullname}` : invitee.invited_by.email
              }`}</Typography>

              <Stack direction="row" alignItems={isDownMd ? 'flex-start' : 'flex-end'}>
                {!isAccepted && invitee.invitation_status !== 'REJECTED' && (
                  <Typography
                    fontSize="16px"
                    fontStyle="italic"
                    color={colors.BaseSecondaryText}
                    fontWeight={400}
                    overflow="visible"
                    {...(isDownMd ? { mt: 0.75, noWrap: true } : { pl: 1.5 })}
                  >
                    Pending Acceptance
                  </Typography>
                )}
                {invitee.invitationStatus === 'REJECTED' && (
                  <Typography
                    fontSize="16px"
                    fontStyle="italic"
                    color={colors.BaseSecondaryText}
                    fontWeight={400}
                    overflow="visible"
                    {...(isDownMd ? { mt: 0.75, noWrap: true } : { pl: 1.5 })}
                  >
                    Rejected
                  </Typography>
                )}
                {!isDownMd && isRemoveable() && (
                  <IconButton
                    type="button"
                    disabled={isLoading}
                    onClick={() => {
                      onRemove?.(invitee);
                    }}
                    sx={{ borderRadius: 1, paddingY: 0 }}
                  >
                    <DeleteOutlineTwoToneIcon sx={{ color: colors.Error500 }} />
                    <Typography fontSize="16px" color={colors.Error500} fontWeight={500} overflow="visible">
                      Remove
                    </Typography>
                  </IconButton>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>

      {((isDownMd && isRemoveable()) || type === 'invitee') && (
        <IconButton
          type="button"
          disabled={isLoading}
          onClick={() => {
            onRemove?.(invitee);
          }}
          sx={{ borderRadius: 1, paddingY: 0 }}
        >
          <DeleteOutlineTwoToneIcon sx={{ color: isAccepted || type === 'invitee' ? colors.Error500 : colors.BaseSecondaryText }} />
        </IconButton>
      )}
    </Stack>
  );
}

Invitee.defaultProps = {
  type: 'participant',
};
