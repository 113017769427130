import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import colors from 'constants/colors';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';
import { Search } from 'react-feather';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import uniqBy from 'lodash/uniqBy';
import { getPaperPropsFilterSearchStyle, getSelectPropsStyle, useBreakpoints } from 'themes';
import { Radio, RadioGroup, RadioGroupProps, Stack } from '@mui/material';

type Option = {
  name: string;
  id: string;
};
function FilterItem({
  onChange,
  type,
  titleInLineClearButton,
  placeholder,
  onLoad,
  isMultiple,
  options,
  value,
  borderRadius,
}: {
  onChange: (v: any, t?: any) => void;
  type?: 'radio' | 'select';
  titleInLineClearButton?: string;
  onLoad?: any;
  isMultiple?: boolean;
  options?: Option[];
  value?: any;
  placeholder?: string;
  borderRadius?: string;
}) {
  const isDownMd = useBreakpoints((breakpoints) => breakpoints.down('md'));

  const [filter, setFilter] = useState({
    l: 30,
    p: 0,
    search: '',
  });
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [selectedIds, setSelectedIds] = useState<any>([]);
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const [showClearAll, setShowClearAll] = useState(false);
  const anchorEl = useRef<any>();

  const onClear = () => {
    setSelectedIds([]);
    onChange([]);
  };

  const onChangeRadioGroup: RadioGroupProps['onChange'] = (event) => {
    const unparsedOption = event.target.value;
    const option = JSON.parse(unparsedOption) as Option | null;
    onChange(option?.id);
  };

  const onClickRadio = (option: Option) => () => {
    onChange(option?.id);
  };

  useEffect(() => {
    if (selectedIds && selectedIds.length > 0 && isMultiple) {
      setShowClearAll(true);
    } else {
      setShowClearAll(false);
    }
  }, [selectedIds]);

  useEffect(() => {
    if (isMultiple) {
      if (value.length > 0) {
        if (data.length === 0 && onLoad) {
          onLoad({
            l: 30,
            p: 1,
            ids: value,
          }).then((resp: any) => {
            setSelectedData(resp.data);
            setSelectedIds(value);
          });
        } else {
          setSelectedIds(value);
        }
      } else {
        setSelectedIds([]);
        setSelectedData([]);
      }
    } else if (typeof value === 'object') {
      setSelectedData(value);
      setSelectedIds(value.id);
    } else if (typeof value === 'string') {
      setSelectedIds(value);
    }
  }, [value, open]);

  const onLoadData = () => {
    setLoading(true);

    onLoad(filter).then((resp: any) => {
      let d = filter.p === 1 ? resp.data : [...data, ...resp.data];

      if (selectedData && isMultiple && !filter.search) {
        d = [...selectedData, ...d];
        d = sortBy(d, (item: any) => selectedIds.indexOf(item.id) === -1);
      }

      d = uniqBy(d, (e: any) => {
        return e.id;
      });

      setData(d);
      setTotal(resp.total_count);

      setTimeout(() => {
        setLoading(false);
      });
    });
  };

  useEffect(() => {
    if (options) {
      setData(options);
    }
  }, [options]);

  useEffect(() => {
    if (filter.p && typeof onLoad === 'function') {
      onLoadData();
    }
  }, [filter]);

  const debounceSearch = debounce((e) => {
    setFilter({
      ...filter,
      p: 1,
      search: e.target.value,
    });
  }, 1500);

  const handleChange = (e: any) => {
    const findSelected = data.find((item: any) => (typeof e.target.value === 'object' ? item.id === e.target.value[0] : item.id === e.target.value));
    setSelectedIds(e.target.value);
    const selectedOpts: any = [];
    if (isMultiple) {
      e.target.value.map((v: any) => {
        const opts = data.find((item: any) => item.id === v);
        if (opts) {
          selectedOpts.push(opts);
        }
      });
      setSelectedData([...selectedData, ...selectedOpts]);
    }
    if (typeof onChange === 'function') {
      onChange(e.target.value, findSelected);
    }
  };

  const renderValue = (selected: any) => {
    const defaultData = selectedData ?? data;

    const findSelected = Array.isArray(defaultData)
      ? defaultData.find((item: any) =>
          typeof selected === 'object' ? item.id.toString() === selected[0].toString() : item.id.toString() === selected.toString(),
        )
      : defaultData;

    if (findSelected) {
      if (isMultiple) {
        return (
          <Stack sx={{ flexDirection: 'row', overflow: 'hidden', ml: 1, justifyContent: 'flex-start' }}>
            <Typography key={selected[0]} noWrap>
              {findSelected?.name}
            </Typography>
            {selectedIds.length > 1 && <Typography>{`, +${selectedIds.length - 1}`}</Typography>}
          </Stack>
        );
      }
      return findSelected.name;
    }
    return '';
  };

  useEffect(() => {
    if (open) {
      if (filter.p === 0) {
        setFilter({
          ...filter,
          search: '',
          p: 1,
        });
      } else if (selectedIds.length > 0) {
        setData(sortBy(data, (item: any) => selectedIds.indexOf(item.id) === -1));
      }
    } else {
      setFilter({
        ...filter,
        p: 0,
        search: '',
      });
    }
  }, [open]);

  const renderRadio = (option: Option) => {
    return (
      <Button
        type="button"
        variant="text"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          p: 0,
          ml: -1,
          minWidth: '150px',
          justifyContent: 'flex-start',
          textTransform: 'none',
        }}
        fullWidth={false}
        onClick={onClickRadio(option)}
        disableTouchRipple
        disableRipple
      >
        <Radio sx={{ '&.Mui-checked': { color: colors.Primary }, '.MuiTypography-root': { fontWeight: 500 } }} value={JSON.stringify(option)} />
        <Typography variant="body1">{option.name}</Typography>
      </Button>
    );
  };

  const renderDropDownIcon = useCallback(() => {
    const sx = { height: '20px', width: '20px', mr: 2, cursor: 'pointer' };

    if (loading) {
      return (
        <Box>
          <CircularProgress sx={{ height: '15px !important', width: '15px !important', mr: 2, mt: '5px' }} />
        </Box>
      );
    }
    if (open) {
      return <KeyboardArrowUpIcon sx={sx} onClick={() => setOpen(true)} />;
    }
    return <KeyboardArrowDownIcon sx={sx} onClick={() => setOpen(true)} />;
  }, [loading, open]);

  const renderSearchField = useMemo(
    () => (
      <ListSubheader sx={{ mt: 0, mb: isDownMd && selectedIds.length > 0 ? 6 : 0 }}>
        <TextField
          id={`${placeholder}-search-field`}
          sx={{
            mt: 2,
            '& .MuiOutlinedInput-root': {
              pl: '10px',
            },
            '& .MuiInputBase-inputAdornedStart': {
              pl: 0,
            },
          }}
          autoFocus
          placeholder="Type to search..."
          fullWidth
          onChange={(e) => {
            setLoading(true);
            debounceSearch(e);
          }}
          onKeyDown={(e) => {
            if (e.key !== 'Escape') {
              e.stopPropagation();
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </ListSubheader>
    ),
    [selectedIds.length, isDownMd],
  );

  return (
    <Box ref={anchorEl}>
      {!!titleInLineClearButton && (
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={1.5}>
          <Typography variant="h4">{titleInLineClearButton}</Typography>
          {type === 'select' && (
            <Button type="button" variant="text" sx={{ fontWeight: 600 }} onClick={onClear}>
              Clear
            </Button>
          )}
        </Stack>
      )}

      {type === 'radio' && (
        <RadioGroup onChange={onChangeRadioGroup} value={JSON.stringify(value)}>
          {options?.map(renderRadio)}
        </RadioGroup>
      )}

      {type === 'select' && (
        <Select
          open={open}
          fullWidth
          value={selectedIds}
          onClose={() => setOpen(false)}
          onOpen={() => {
            setOpen(true);
          }}
          onChange={handleChange}
          multiple={isMultiple}
          label=""
          MenuProps={{
            anchorEl: anchorEl.current,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            PaperProps: getPaperPropsFilterSearchStyle(showClearAll, isMultiple),
          }}
          renderValue={renderValue}
          sx={getSelectPropsStyle(selectedIds, isMultiple, borderRadius, placeholder)}
          startAdornment={
            placeholder ? (
              <InputAdornment position="start">
                <Typography sx={{ padding: '12px 0 13px 15px', fontWeight: 500, color: colors.BaseText }} onClick={() => setOpen(!open)}>
                  {`${placeholder} ${selectedIds.length > 0 ? ':' : ''}`}{' '}
                </Typography>
              </InputAdornment>
            ) : undefined
          }
          IconComponent={renderDropDownIcon}
        >
          {onLoad && renderSearchField}
          {showClearAll && !titleInLineClearButton && (
            <ListSubheader
              sx={{
                height: '50px',
                textAlign: 'right',
                justifyContent: 'center',
                top: '450px',
                right: 0,
                borderTop: `1px solid ${colors.BaseGrey}`,
              }}
            >
              <Button
                type="button"
                variant="text"
                fullWidth
                sx={{
                  justifyContent: 'flex-end',
                  pl: '10px',
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  '&:hover': {
                    background: 'inherit',
                    textDecoration: 'underline',
                  },
                }}
                onClick={onClear}
              >
                Clear All
              </Button>
            </ListSubheader>
          )}
          {data.length === 0 && !loading && (
            <MenuItem sx={{ fontWeight: 500, color: colors.BaseText }}>
              <Typography sx={{ padding: '5px 10px', fontWeight: 500, color: colors.Base600 }}>No results</Typography>
            </MenuItem>
          )}
          {data.length === 0 && loading && (
            <MenuItem>
              <Typography sx={{ padding: '5px 10px', fontWeight: 500, color: colors.Base600 }}>Loading...</Typography>
            </MenuItem>
          )}
          {data.map((item: any) => (
            <MenuItem value={item.id} key={item.id} sx={{ fontWeight: 500, color: colors.BaseText }}>
              {isMultiple && <Checkbox checked={selectedIds.indexOf(item.id) > -1} />}
              <Typography sx={{ padding: '5px 10px', fontWeight: 500 }}>{item.name}</Typography>
            </MenuItem>
          ))}
          {!loading && data.length < total && (
            <MenuItem sx={{ height: '50px', mt: showClearAll ? 2 : 0 }}>
              <Button
                type="button"
                variant="text"
                fullWidth
                sx={{
                  fontWeight: 500,
                  justifyContent: 'flex-start',
                  pl: '10px',
                  textTransform: 'capitalize',
                  '&:hover': {
                    background: 'inherit',
                    textDecoration: 'underline',
                  },
                }}
                onClick={() =>
                  setFilter({
                    ...filter,
                    p: filter.p + 1,
                  })
                }
              >
                Show more
              </Button>
            </MenuItem>
          )}
        </Select>
      )}
    </Box>
  );
}

FilterItem.defaultProps = {
  type: 'select',
};

type CompoundFilterItem = typeof FilterItem & {
  Option: Option;
};

export default FilterItem as CompoundFilterItem;
