import { CHAT_STATUSES, CHAT_TYPES, IChat, IChatGraphqlResponse, IChatParticipant, ROOM_ROLE } from 'interface/chat';
import dayjs from 'dayjs';
import { IUser } from 'interface/user';
import { IPhoto } from 'interface/public';

import IDeal, { ParticipantRole } from 'interface/deal';
import { getDisplayName } from './getDisplayName';

export const convertChatUserAPI = (user: {
  id: string;
  avatarUrl: string;
  username: string;
  roomRole: string;
  invitedBy: string;
  email: string;
  invitationStatus?: string;
}): IChatParticipant => {
  return {
    id: user.id,
    avatar: user.avatarUrl,
    fullname: user.username,
    roomRole: user.roomRole,
    invitedBy: user.invitedBy,
    email: user.email,
    invitationStatus: user.invitationStatus,
  };
};

export const convertChatBoxItemApi = (chatResponse: IChatGraphqlResponse, currentUserId: string): IChat => {
  const c = chatResponse;
  const owner = c.users?.filter((u) => u.roomRole === ROOM_ROLE.OWNER)?.[0]?.id;

  const currentParticipant = c.users
    ?.filter((u) => u.id.toString() === currentUserId.toString())
    ?.map((p) => ({
      id: p.id,
      avatar: p.avatarUrl,
      fullname: p.username,
      roomRole: p.roomRole,
      invitedBy: p.invitedBy,
      email: p.email,
      invitationStatus: p.invitationStatus,
    }))?.[0];

  const chat: IChat = {
    id: c.id,
    name: c.name ?? '',
    updatedAt: c.updatedAt,
    createdAt: c.createdAt,
    unreadMessages: c.unreadMessages,
    groupRoomId: c.groupRoomId,
    lastMessage: c.lastMessage,
    type: c.roomType,
    status: c.roomStatus,
    onwerId: owner?.toString(),
    currentParticipant,
    participants: c.users
      ?.filter((u) => u.id.toString() !== currentUserId.toString())
      .map((p) => ({
        id: p.id,
        username: p.username,
        avatar: p.avatarUrl,
        fullname: p.username,
        roomRole: p.roomRole,
        invitedBy: p.invitedBy,
        email: p.email,
        invitationStatus: p.invitationStatus,
      })),
  };

  if (c.item) {
    chat.item = {
      id: c.item.id,
      photo: c.item.imageUrl,
      name: c.item.title,
      symbol: c.item.currency.symbol,
      minPrice: c.item.displayMinPrice,
      maxPrice: c.item.displayMaxPrice,
      ownerId: c.item.itemOwnerId,
      status: c.item.status,
    };
  }
  return chat;
};

export const orderRoomsByActiveRoom = (data: IChatGraphqlResponse[], currentUserId: string, room?: IChat | null, isExcludedActiveRoom?: boolean) => {
  const convertResponseToChat = data.map((item: IChatGraphqlResponse) => convertChatBoxItemApi(item, currentUserId));
  return convertResponseToChat;
  // const chatsWithoutRoomWantToOrder = data.filter((item: IChatGraphqlResponse) => item.id !== room?.id);
  // const convertResponseToChat = chatsWithoutRoomWantToOrder.map((item: IChatGraphqlResponse) => convertChatBoxItemApi(item, currentUserId));
  // if (room && !isExcludedActiveRoom) {
  //   return [room, ...convertResponseToChat];
  // }
  // return convertResponseToChat;
};

export const handleSetRoomDataToRooms = (chatItems: any, currentUserId: string, chatItemFromParamIdData?: IChat) => {
  if (chatItems && chatItems.length > 0) {
    const chatsWithoutChatItemFromParamId = chatItems.filter((item: IChat) => item.id !== chatItemFromParamIdData?.id);
    const chatsWithConversion = chatsWithoutChatItemFromParamId.map((item: IChatGraphqlResponse) => convertChatBoxItemApi(item, currentUserId));
    if (chatItemFromParamIdData) {
      return {
        data: [chatItemFromParamIdData, ...chatsWithConversion],
        loading: false,
      };
    }
    return {
      data: [...chatsWithConversion],
      loading: false,
    };
  }
  return {
    data: [],
    loading: false,
  };
};

const renderPhoto = (photo: IPhoto) => {
  if (photo.content_type?.includes('video') && photo.thumbnail_url) {
    return photo.thumbnail_url;
  }
  return photo.url;
};

export const generateNewChat = (tab: CHAT_TYPES, user?: IUser, listing?: IDeal): IChat => {
  const participant = user
    ? {
        id: user.id.toString(),
        avatar: user.avatar,
        fullname: getDisplayName(user),
        email: user.email,
        username: user.username,
      }
    : {
        id: listing?.owner.id.toString() ?? '',
        avatar: listing?.owner.avatar,
        fullname: getDisplayName(listing?.owner),
        roomRole: ParticipantRole.OWNER.toUpperCase(),
        email: listing?.owner.email || '',
      };

  const chat: IChat = {
    id: 'created-chat',
    type: tab,
    unreadMessages: 0,
    onwerId: user?.id.toString() ?? '',
    status: CHAT_STATUSES.OPEN,
    updatedAt: dayjs().toISOString(),
    createdAt: dayjs().toISOString(),
    participants: [participant],
  };

  if (listing) {
    chat.item = {
      id: listing.id,
      photo: listing.photos?.[0] ? renderPhoto(listing.photos?.[0]) : '',
      name: listing.name,
      symbol: listing.currency.symbol,
      minPrice: listing.display_min_price,
      maxPrice: listing.display_max_price,
      ownerId: listing.owner.id.toString(),
    };
  }

  return chat;
};
