import { DEALSTATUS } from 'interface/deal';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-gallery-carousel/dist/index.css';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Edit } from '@mui/icons-material';
import colors from 'constants/colors';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { DealContext } from 'context/DealContext';
import { renderDateByFormat } from 'utils/dateFormat';
import { useBreakpoints } from 'themes';
import { IconButton, ListItemIcon, MenuItem, MenuList, Popover } from '@mui/material';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import DealCloseAction from './DealActions/DealCloseActions';
import DealPrice from './DealPrice';

function DealInfo(props: { noCountries?: boolean; hasActions?: boolean }) {
  const navigate = useNavigate();
  const { deal, rooms } = useContext(DealContext);
  const isOwner = useSelector((state: IReduxState) => state?.auth?.user?.id === deal.owner.id);
  const dealStatus = dayjs(deal.available_until).isBefore(dayjs()) ? DEALSTATUS.EXPIRED : deal?.status;
  const isDownLg = useBreakpoints((breakpoints) => breakpoints.down('lg'));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const onClickMore = (type: 'open' | 'close' | 'edit-listing') => (event: any) => {
    const nextAnchorEl = (event as React.MouseEvent<HTMLButtonElement>)?.currentTarget;
    switch (type) {
      case 'open':
        setAnchorEl(nextAnchorEl);
        return;

      case 'edit-listing':
        setAnchorEl(null);
        navigate(`/deal/edit/${deal.id}`);
        return;

      case 'close':
      default:
        setAnchorEl(null);
    }
  };

  const renderRecord = (recordProps: { title: string; message: string }) => (
    <>
      <Typography variant="h6" letterSpacing="0.36px">
        {recordProps.title}
      </Typography>
      <Typography sx={{ fontWeight: '400' }}>{recordProps.message}</Typography>
    </>
  );

  return (
    <Box>
      {!props?.noCountries && (
        <Stack direction="row" alignItems="center" gap={0.2} mt={3} mb={1}>
          <FmdGoodIcon sx={{ fill: colors.White, stroke: colors.BaseSecondaryText, mr: '2px', strokeWidth: 2 }} />
          <Typography color={colors.BaseText}>
            {deal.countries.slice(0, 3).map((item, index) => (
              <span key={item.id}>{`${item.name}${index < 2 && index < deal.countries.length - 1 ? ', ' : ''} `}</span>
            ))}
            {deal.countries.length > 3 ? '...' : ''}
          </Typography>
        </Stack>
      )}
      <Stack gap={1} mt={2} mb={3}>
        <Stack direction="row" alignItems="flex-start">
          <Typography variant="h2" flex={1} py={1} sx={{ wordWrap: 'break-word', ...(isDownLg && { fontSize: '28px !important', overflow: 'hidden' }) }}>
            {`${deal.name ?? ''} `}
            {dealStatus === DEALSTATUS.CLOSED && <Chip label={dealStatus} color="error" variant="filled" />}
            {dealStatus === DEALSTATUS.EXPIRED && <Chip label={dealStatus} sx={{ bgcolor: colors.Primary700, color: colors.White }} variant="filled" />}
          </Typography>

          {props.hasActions && isOwner && (
            <>
              <IconButton onClick={onClickMore('open')}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={onClickMore('close')}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                PaperProps={{
                  sx: { borderRadius: '16px' },
                }}
              >
                <MenuList dense>
                  <Box p={1}>
                    <MenuItem onClick={onClickMore('edit-listing')}>
                      <ListItemIcon>
                        <Edit />
                      </ListItemIcon>
                      <Typography color={colors.BaseText}>Edit listing</Typography>
                    </MenuItem>
                  </Box>
                  <Divider />

                  <Box p={1}>
                    <DealCloseAction roomsLength={rooms?.totalRoom ?? 0} />
                  </Box>
                </MenuList>
              </Popover>
            </>
          )}
        </Stack>
        <DealPrice
          currencySymbol={deal.currency?.symbol}
          minPrice={deal.display_min_price}
          maxPrice={deal.display_max_price}
          style={isDownLg ? { fontSize: '21px !important' } : {}}
          variant="h3"
        />
      </Stack>

      <Stack flexDirection="column" gap="24px">
        <Stack flexDirection={['column', 'column', 'column', 'row']} gap="24px">
          <Stack flexDirection="row" gap="24px" justifyContent="space-between">
            <Stack flex={1} minWidth="156px">
              {renderRecord({ title: 'POSTED', message: renderDateByFormat(deal.created_at) })}
            </Stack>
            <Stack flex={1} minWidth="156px">
              {renderRecord({ title: 'UPDATED', message: renderDateByFormat(deal.updated_at) })}
            </Stack>
          </Stack>

          <Stack flexDirection={['column', 'column', 'column', 'row']} gap="24px">
            {deal.available_until && (
              <Stack minWidth="156px">{renderRecord({ title: 'AVAILABLE UNTIL', message: renderDateByFormat(deal.available_until) })}</Stack>
            )}
            <Stack>
              {renderRecord({
                title: 'CATEGORY',
                message:
                  deal.categories?.reduce((accumulator, currentValue) => `${accumulator}${accumulator.length > 0 ? ',' : ''} ${currentValue.name}`, '') ?? '',
              })}
            </Stack>
          </Stack>
        </Stack>

        <Stack flexDirection="row" justifyContent="space-between">
          <Stack flex={1} flexDirection="row" gap="24px" justifyContent="space-between">
            <Stack {...(isDownLg ? { flex: 1 } : { width: '156px' })}>
              {renderRecord({
                title: 'STATUS',
                message: (dealStatus === DEALSTATUS.EXPIRED ? 'Expired' : dealStatus) ?? '',
              })}
            </Stack>

            <Stack flex={1}>{renderRecord({ title: 'LISTING TYPE', message: deal.type })}</Stack>
          </Stack>
          <Stack flex={[0, 0, 0, 1]} />
        </Stack>
      </Stack>

      <Divider sx={{ mt: 3 }} />
      <Box mt={3}>
        <Typography color={colors.BaseText} fontWeight={400}>
          {deal.description}
        </Typography>
      </Box>
    </Box>
  );
}

export default DealInfo;
