/* eslint-disable no-console */
import { IChat } from 'interface/chat';
import { IRoomsGlobalSearchSelectedMessage } from 'interface/room';
import { createContext, useContext } from 'react';

export type IChangeField = {
  field: string;
  value: string;
};

export type ChatContainerContextType = {
  activeChat: any;
  setActiveChat: (chat: IChat) => void;
  updateChat?: (chatId: string, changes: IChangeField[]) => void;
  scrollToBottom: boolean;
  setScrollToBottom: (shouldScroll: boolean) => void;
  updateUnreadMessage?: (chat: IChat, increased: boolean) => void;
  searchRoom: (e: any) => void;
  hideInfo?: boolean;
  selectedMsg?: IRoomsGlobalSearchSelectedMessage;
  moveActiveRoomToTop?: () => void;
};

export const ChatContainerContext = createContext<ChatContainerContextType>({
  activeChat: null,
  setActiveChat: (chat: IChat) => console.log(chat),
  scrollToBottom: true,
  setScrollToBottom: (shouldScroll: boolean) => {
    console.warn(`trigger scroll: ${shouldScroll}`);
  },
  updateChat: (chatId: string, changes: IChangeField[]) => console.log(chatId),
  updateUnreadMessage: (chat: IChat, increased: boolean) => console.warn(chat),
  searchRoom: (e) => {},
  moveActiveRoomToTop: () => {},
});

export const useChatContainerContext = (): ChatContainerContextType => useContext(ChatContainerContext);
