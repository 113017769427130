import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { getCategories } from 'apis/public';
import { sortByAlphabet } from 'utils/sortAlphabet';
import CategoryList from 'category/CategoryList';
import CategoryItem from 'category/CategoryItem';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Stack } from '@mui/material';
import SjHelmet from 'components/Layout/SjHelmet';

function CategoriesPage() {
  const [data, setData] = useState<any>([]);
  const fetchCategories = async () => {
    getCategories({
      l: -1,
      p: 1,
    }).then((resp) => {
      const res = sortByAlphabet(resp.data);
      setData(res);
    });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Container sx={{ mt: [12, 12, 12, 6] }}>
      <SjHelmet title="Categories" />
      <Typography variant="h2" mb={5}>
        Categories
      </Typography>
      {data.map((group: any) => (
        <Box key={group.letter} mb={5}>
          <CategoryList
            data={group.list}
            title={{
              text: group.letter,
              size: 'h3',
            }}
          />
        </Box>
      ))}
      <Box zIndex={1} position="relative">
        <Stack mb={5} alignItems="center" justifyContent="space-between" direction="row">
          <Typography variant="h3">Others</Typography>
        </Stack>
        <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <CategoryItem id={105} name="Others" />
        </Grid2>
      </Box>
    </Container>
  );
}

export default CategoriesPage;
