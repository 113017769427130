import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TelegramIcon,
  TelegramShareButton,
} from 'react-share';
import { showToast } from 'redux/toast/action';

import { LinkOutlined, MoreVert } from '@mui/icons-material';
import { Share2 } from 'react-feather';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { MouseEvent, useEffect, useState } from 'react';
import { likeDeal, unlikeDeal } from 'apis/deal';
import IDeal from 'interface/deal';
import colors from 'constants/colors';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getShareContent } from 'utils/shareDeal';
import { IReduxState } from 'interface/redux';
import { useNavigate } from 'react-router-dom';

function ListingItemActions({ deal, actions, onUpdated }: { deal: IDeal; actions?: string[]; onUpdated: any }) {
  const [hasShare, setHasShare] = useState(false);
  const [hasLike, setHasLike] = useState(false);
  const [isShowingAll, setIsShowingAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const host = window.location.origin;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const listingUrl = `${host}/deal/view/${deal.id}`;
  const { auth } = useSelector((state: IReduxState) => state);
  const { shareContent, emailSubject, emailContent } = getShareContent(deal.name, listingUrl);

  useEffect(() => {
    if (actions) {
      setHasLike(actions?.includes('like'));
      setHasShare(actions?.includes('share'));
    }
  }, [actions]);

  const handleLike = () => {
    if (!auth.user?.id) {
      navigation('/likes');
      return;
    }
    if (deal.liked) {
      unlikeDeal(deal.id).then(() => {
        if (typeof onUpdated === 'function') {
          onUpdated(deal.id, [
            {
              field: 'liked',
              value: false,
            },
            {
              field: 'total_likes',
              value: deal.total_likes - 1,
            },
          ]);
        }
      });
    } else {
      likeDeal(deal.id).then((resp) => {
        if (typeof onUpdated === 'function') {
          onUpdated(deal.id, [
            {
              field: 'liked',
              value: true,
            },
            {
              field: 'total_likes',
              value: deal.total_likes + 1,
            },
          ]);
        }
      });
    }
  };

  return (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchorEl(null);
          setIsShowingAll(false);
        }}
      >
        <Stack direction="row" gap={1} sx={{ pt: 1, px: 1 }}>
          <WhatsappShareButton url={shareContent}>
            <WhatsappIcon size={30} borderRadius={8} />
          </WhatsappShareButton>
          <FacebookShareButton url={listingUrl} quote={shareContent}>
            <FacebookIcon size={30} borderRadius={8} />
          </FacebookShareButton>
          <TwitterShareButton url={shareContent}>
            <TwitterIcon size={30} borderRadius={8} />
          </TwitterShareButton>
          <Box
            sx={{
              width: '30px',
              height: '30px',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'grey',
              display: 'flex',
              borderRadius: '4px',
            }}
            onClick={async () => {
              const showSuccess = () => {
                dispatch(
                  showToast({
                    type: 'success',
                    title: 'Copied!',
                    description: listingUrl,
                  }),
                );
              };
              if (navigator.canShare && navigator.canShare()) {
                try {
                  const shareData = {
                    title: emailSubject,
                    text: shareContent,
                    url: shareContent,
                  };
                  await navigator.share(shareData);
                  showSuccess();
                } catch (err) {
                  navigator.clipboard.writeText(listingUrl).then(() => {
                    showSuccess();
                  });
                }
              } else {
                navigator.clipboard.writeText(listingUrl).then(() => {
                  showSuccess();
                });
              }
            }}
          >
            <LinkOutlined style={{ width: '22px', height: '22px', color: 'white' }} />
          </Box>
          {isShowingAll && (
            <>
              <EmailShareButton url={emailContent} subject={emailSubject}>
                <EmailIcon size={30} borderRadius={8} />
              </EmailShareButton>
              <LinkedinShareButton url={listingUrl} summary={shareContent} title={emailSubject} source={listingUrl}>
                <LinkedinIcon size={30} borderRadius={8} />
              </LinkedinShareButton>
              <TelegramShareButton url={shareContent}>
                <TelegramIcon size={30} borderRadius={8} />
              </TelegramShareButton>
            </>
          )}
          {!isShowingAll && (
            <Box
              sx={{
                width: '30px',
                height: '30px',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'grey',
                display: 'flex',
                borderRadius: '4px',
              }}
              onClick={async () => {
                setIsShowingAll((preState) => !preState);
              }}
            >
              <MoreVert style={{ width: '22px', height: '22px', color: 'white' }} />
            </Box>
          )}
        </Stack>
      </Popover>

      {(hasShare || hasLike) && (
        <Stack direction="row" justifyContent="space-between" mx={-1} mt={2}>
          {hasLike && (
            <Stack direction="row" alignItems="center">
              <IconButton type="button" onClick={handleLike}>
                {deal.liked ? <FavoriteIcon sx={{ color: colors.Error500 }} /> : <FavoriteBorderIcon />}
              </IconButton>
              <Typography fontSize="14px !important" fontWeight={500}>
                {deal.total_likes}
              </Typography>
            </Stack>
          )}
          {hasShare && (
            <IconButton type="button" onClick={handleClick} aria-describedby={id}>
              <Share2 />
            </IconButton>
          )}
        </Stack>
      )}
    </>
  );
}

export default ListingItemActions;
