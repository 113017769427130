import { Stack, Typography } from '@mui/material';
import { getSettings } from 'apis/public';
import SjHelmet from 'components/Layout/SjHelmet';
import { useEffect, useState } from 'react';
import SimplePage from 'themes/SimplePage';

function ContactScreen() {
  const [email, setEmail] = useState('');

  useEffect(() => {
    getSettings().then((resp) => {
      setEmail(resp.contact_email);
    });
  }, []);

  const details = (
    <Stack
      direction={['column', 'column', 'row', 'row', 'row']}
      gap={[3, 3, 3, 30, 30]}
      alignItems={['flex-start', 'flex-start', 'center', 'center', 'center']}
    >
      <Typography variant="h2" ml={['0px', '0px', '106px']} width={['auto', 'auto', 370, 370, 370]} textAlign="right">
        Got a question?
      </Typography>
      <div>
        <div>
          Drop us an email at <a href={`mailto:${email}`}>{email}</a>.
        </div>
        <div>We will get back to you as soon as we can!</div>
      </div>
    </Stack>
  );
  const contactData = {
    title: 'Contact Us',
    details,
  };
  return (
    <>
      <SjHelmet title="Contact" />
      <SimplePage data={contactData} />
    </>
  );
}

export default ContactScreen;
