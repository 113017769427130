import SjHelmet from 'components/Layout/SjHelmet';
import { Link, useLocation } from 'react-router-dom';
import StaticPage, { StaticPageProps } from 'themes/StaticPage';

function PrivacyScreen() {
  const location = useLocation();

  const content: StaticPageProps['content'] = [
    <>
      This privacy notice for LIST & DEAL (<StaticPage.Inline>"we," "us,"</StaticPage.Inline> or
      <StaticPage.Inline>"our"</StaticPage.Inline>), describes how and why we might collect, store, use, and/or share ("
      <StaticPage.Inline>process</StaticPage.Inline>") your information when you use our services ("
      <StaticPage.Inline>Services</StaticPage.Inline>"), such as when you:
    </>,
    [
      (
        <>
          • Visit our website at <Link to="http://WWW.LISTNDEAL.COM">http://WWW.LISTNDEAL.COM</Link> (listndeal.com), or any website of ours that links to this
          privacy Notice
        </>
      ) as React.ReactNode,
      '• Download and use our mobile application (LIST & DEAL), or any other application of ours that links to this privacy notice',
      '• Engage with us in other related ways, including any sales, marketing, or events',
    ],
    <>
      <StaticPage.Inline>Questions or concerns?</StaticPage.Inline> Reading this privacy notice will help you understand your privacy rights and choices. If you
      do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at
      listndeal.com.
    </>,
    {
      title: 'Summary Of Key Points',
      content: (
        <StaticPage.Inline fontStyle="italic">
          This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following
          each key point or by using our table of contents below to find the section you are looking for.
        </StaticPage.Inline>
      ),
    },
    <>
      <StaticPage.Inline>What personal information do we process?</StaticPage.Inline> When you visit, use, or navigate our Services, we may process personal
      information depending on how you interact with LIST & DEAL and the Services, the choices you make, and the products and features you use. Learn more about{' '}
      <StaticPage.Inline textDecoration="underline" bold={false}>
        personal information you disclose to us
      </StaticPage.Inline>
      .
    </>,
    <>
      <StaticPage.Inline>Do we process any sensitive personal information?</StaticPage.Inline> We may process sensitive personal information when necessary with
      your consent or as otherwise permitted by applicable law. Learn more about{' '}
      <StaticPage.Inline textDecoration="underline" bold={false}>
        sensitive information we process
      </StaticPage.Inline>
      .
    </>,
    <>
      <StaticPage.Inline>Do we receive any information from third parties?</StaticPage.Inline> We do not receive any information from third parties.
    </>,
    <>
      <StaticPage.Inline>How do we process your information?</StaticPage.Inline> We process your information to provide, improve, and administer our Services,
      communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your
      consent. We process your information only when we have a valid legal reason to do so. Learn more about{' '}
      <StaticPage.Inline textDecoration="underline" bold={false}>
        how we process your information
      </StaticPage.Inline>
      .
    </>,
    <>
      <StaticPage.Inline>In what situations and with which parties do we share personal information?</StaticPage.Inline> We may share information in specific
      situations and with specific third parties. Learn more about{' '}
      <StaticPage.Inline textDecoration="underline" bold={false}>
        when and with whom we share your personal information
      </StaticPage.Inline>
      .
    </>,
    <>
      <StaticPage.Inline>How do we keep your information safe?</StaticPage.Inline> We have organizational and technical processes and procedures in place to
      protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100%
      secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and
      improperly collect, access, steal, or modify your information. Learn more about{' '}
      <StaticPage.Inline textDecoration="underline" bold={false}>
        how we keep your information safe
      </StaticPage.Inline>
      .
    </>,
    <>
      What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your
      personal information. Learn more about{' '}
      <StaticPage.Inline textDecoration="underline" bold={false}>
        your privacy rights
      </StaticPage.Inline>
      .
    </>,
    <>
      <StaticPage.Inline>How do you exercise your rights?</StaticPage.Inline> The easiest way to exercise your rights is by by contacting us. We will consider
      and act upon any request in accordance with applicable data protection laws.
    </>,
    <>
      Want to learn more about what LIST & DEAL does with any information we collect?{' '}
      <StaticPage.Inline textDecoration="underline" bold={false}>
        Review the privacy notice in full
      </StaticPage.Inline>
      .
    </>,
    {
      title: 'Table Of Contents',
      content: [
        <Link key={`${location.pathname}#what-information-do-we-collect`} to={`${location.pathname}#what-information-do-we-collect`}>
          1 WHAT INFORMATION DO WE COLLECT?
        </Link>,
        <Link key={`${location.pathname}#how-do-we-process-your-information`} to={`${location.pathname}#how-do-we-process-your-information`}>
          2 HOW DO WE PROCESS YOUR INFORMATION?
        </Link>,
        <Link key={`${location.pathname}#processing-your-information`} to={`${location.pathname}#processing-your-information`}>
          3 PROCESSING YOUR INFORMATION
        </Link>,
        <Link
          key={`${location.pathname}#when-and-with-whom-do-we-share-your-personal-information`}
          to={`${location.pathname}#when-and-with-whom-do-we-share-your-personal-information`}
        >
          4 WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </Link>,
        <Link key={`${location.pathname}#what-is-our-stance-on-third-party-websites`} to={`${location.pathname}#what-is-our-stance-on-third-party-websites`}>
          5 WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
        </Link>,
        <Link
          key={`${location.pathname}#do-we-use-cookies-and-other-tracking-technologies`}
          to={`${location.pathname}#do-we-use-cookies-and-other-tracking-technologies`}
        >
          6 DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </Link>,
        <Link key={`${location.pathname}#how-long-do-we-keep-your-information`} to={`${location.pathname}#how-long-do-we-keep-your-information`}>
          7 HOW LONG DO WE KEEP YOUR INFORMATION?
        </Link>,
        <Link key={`${location.pathname}#how-do-we-keep-your-information-safe`} to={`${location.pathname}#how-do-we-keep-your-information-safe`}>
          8 HOW DO WE KEEP YOUR INFORMATION SAFE?
        </Link>,
        <Link key={`${location.pathname}#do-we-collect-information-from-minors`} to={`${location.pathname}#do-we-collect-information-from-minors`}>
          9 DO WE COLLECT INFORMATION FROM MINORS?
        </Link>,
        <Link key={`${location.pathname}#what-are-your-privacy-rights`} to={`${location.pathname}#what-are-your-privacy-rights`}>
          10 WHAT ARE YOUR PRIVACY RIGHTS?
        </Link>,
        <Link key={`${location.pathname}#do-we-make-updates-to-this-notice`} to={`${location.pathname}#do-we-make-updates-to-this-notice`}>
          11 DO WE MAKE UPDATES TO THIS NOTICE?
        </Link>,
        <Link key={`${location.pathname}#how-can-you-contact-us-about-this-notice`} to={`${location.pathname}#how-can-you-contact-us-about-this-notice`}>
          12 HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </Link>,
        <Link
          key={`${location.pathname}#how-can-you-review-update-or-delete-the-data-we-collect-from-you`}
          to={`${location.pathname}#how-can-you-review-update-or-delete-the-data-we-collect-from-you`}
        >
          13 HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </Link>,
      ],
    },
    {
      title: 'What Information Do We Collect?',
      content: <StaticPage.Inline>Personal information you disclose to us</StaticPage.Inline>,
    },
    <StaticPage.Inline key="inline-collect-personal">We collect personal information that you provide to us.</StaticPage.Inline>,
    'We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.',
    [
      <>
        <StaticPage.Inline>Personal Information Provided by You.</StaticPage.Inline> The personal information that we collect depends on the context of your
        interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the
        following:
      </>,
      '• names',
      '• phone numbers',
      '• email addresses',
      '• mailing addresses',
      '• usernames',
      '• passwords',
      '• contact preferences',
      '• contact or authentication data',
      '• billing addresses',
      '• business interests',
    ],
    <>
      <StaticPage.Inline>Sensitive Information.</StaticPage.Inline> When necessary, with your consent or as otherwise permitted by applicable law, we process
      the following categories of sensitive information:
    </>,
    <>
      <StaticPage.Inline>Application Data.</StaticPage.Inline> If you use our application(s), we also may collect the following information if you choose to
      provide us with access or permission:
    </>,
    <>
      <StaticPage.Inline> • Geolocation Information.</StaticPage.Inline> We may request access or permission to track location-based information from your
      mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our
      access or permissions, you may do so in your device's settings.
    </>,
    <>
      <StaticPage.Inline> • Mobile Device Access.</StaticPage.Inline> We may request access or permission to certain features from your mobiledevice, storage,
      calendar, camera, bluetooth, microphone, reminders, sensors, sms messages, social media accounts, and other features. If you wish to change our access or
      permissions, you may do so in your device's settings.
    </>,
    <>
      <StaticPage.Inline> • Mobile Device Data.</StaticPage.Inline> We automatically collect device information (such as your mobile device ID, model, and
      manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and
      version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our
      application(s), we may also collect information about the phone network associated with your mobile device, your mobile device’s operating system or
      platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.
    </>,
    <>
      <StaticPage.Inline> • Push Notifications.</StaticPage.Inline> We may request to send you push notifications regarding your account or certain features of
      the application(s). If you wish to opt out from receiving these types of communications, you may turn them off in your device's settings.
    </>,
    <>
      This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and
      reporting purposes.
    </>,
    <>
      All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
    </>,
    <StaticPage.Inline key="Information automatically collected">Information automatically collected</StaticPage.Inline>,
    <StaticPage.Inline key="Some information — such as your Internet Protocol">
      Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our
      Services.
    </StaticPage.Inline>,
    <>
      We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like
      your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating
      system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical
      information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting
      purposes.
    </>,
    'Like many businesses, we also collect information through cookies and similar technologies.',
    [
      'The information we collect includes:',
      <>
        <StaticPage.Inline bold={false} textDecoration="italic">
          • Log and Usage Data.
        </StaticPage.Inline>{' '}
        Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our
        Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser
        type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed,
        searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called
        "crash dumps"), and hardware settings).
      </>,
      <>
        <StaticPage.Inline bold={false} textDecoration="italic">
          • Device Data.
        </StaticPage.Inline>{' '}
        We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device
        used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location,
        browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
      </>,
      <>
        <StaticPage.Inline bold={false} textDecoration="italic">
          • Location Data.
        </StaticPage.Inline>{' '}
        We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect
        depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation
        data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing
        access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain
        aspects of the Services.
      </>,
    ],
    {
      title: 'How Do We Process Your Information?',
      content: (
        <StaticPage.Inline>
          We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with your consent.
        </StaticPage.Inline>
      ),
    },
    <StaticPage.Inline key="We process your personal information">
      We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
    </StaticPage.Inline>,
    [
      <>
        • <StaticPage.Inline>To facilitate account creation and authentication and otherwise manage user accounts.</StaticPage.Inline> We may process your
        information so you can create and log in to your account, as well as keep your account in working order.
      </>,
      <>
        • <StaticPage.Inline>To deliver and facilitate delivery of services to the user.</StaticPage.Inline> We may process your information to provide you with
        the requested service.
      </>,
      <>
        • <StaticPage.Inline>To respond to user inquiries/offer support to users.</StaticPage.Inline> We may process your information to respond to your
        inquiries and solve any potential issues you might have with the requested service.
      </>,
      <>
        • <StaticPage.Inline>To send administrative information to you.</StaticPage.Inline> We may process your information to send you details about our
        products and services, changes to our terms and policies, and other similar information.
      </>,
      <>
        • <StaticPage.Inline>To enable user-to-user communications.</StaticPage.Inline> We may process your information if you choose to use any of our
        offerings that allow for communication with another user.
      </>,
      <>
        • <StaticPage.Inline>To request feedback.</StaticPage.Inline> We may process your information when necessary to request feedback and to contact you
        about your use of our Services.
      </>,
      <>
        • <StaticPage.Inline>To send you marketing and promotional communications.</StaticPage.Inline> We may process the personal information you send to us
        for our marketing purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time. For more
        information, see "
        <StaticPage.Inline bold={false} textDecoration="underline">
          WHAT ARE YOUR PRIVACY RIGHTS?
        </StaticPage.Inline>{' '}
        " below.
      </>,
      <>
        • <StaticPage.Inline>To deliver targeted advertising to you.</StaticPage.Inline> We may process your information to develop and display personalized
        content and advertising tailored to your interests, location, and more.
      </>,
      <>
        • <StaticPage.Inline>To protect our Services.</StaticPage.Inline> We may process your information as part of our efforts to keep our Services safe and
        secure, including fraud monitoring and prevention.
      </>,
      <>
        • <StaticPage.Inline>To identify usage trends.</StaticPage.Inline> We may process information about how you use our Services to better understand how
        they are being used so we can improve them.
      </>,
      <>
        • <StaticPage.Inline>To determine the effectiveness of our marketing and promotional campaigns.</StaticPage.Inline> We may process your information to
        better understand how to provide marketing and promotional campaigns that are most relevant to you.
      </>,
      <>
        • <StaticPage.Inline>To save or protect an individual's vital interest.</StaticPage.Inline> We may process your information when necessary to save or
        protect an individual’s vital interest, such as to prevent harm.
      </>,
    ],
    {
      title: 'Processing Your Information',
      content: (
        <StaticPage.Inline>
          We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under
          applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to
          protect your rights, or to fulfill our legitimate business interests.
        </StaticPage.Inline>
      ),
    },
    {
      title: 'When And With Whom Do We Share Your Personal Information?',
      content: (
        <StaticPage.Inline>
          We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under
          applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to
          protect your rights, or to fulfill our legitimate business interests.
        </StaticPage.Inline>
      ),
    },
    [
      <>
        • <StaticPage.Inline>Business Transfers.</StaticPage.Inline> We may share or transfer your information in connection with, or during negotiations of,
        any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
      </>,
      <>
        • <StaticPage.Inline>When we use Google Maps Platform APIs.</StaticPage.Inline> We may share your information with certain Google Maps Platform APIs
        (e.g., Google Maps API, Places API). We use certain Google Maps Platform APIs to retrieve certain information when you make location-specific requests.
        A full list of what we use information for can be found in this section and in the previous section titled "
        <StaticPage.Inline bold={false} textDecoration="underline">
          HOW DO WE PROCESS YOUR INFORMATION?
        </StaticPage.Inline>
        ". We obtain and store on your device ("cache") your location. You may revoke your consent anytime by contacting us at the contact details provided at
        the end of this document. The Google Maps Platform APIs that we use store and access cookies and other information on your devices. If you are a user
        currently in the European Economic Area (EU countries, Iceland, Liechtenstein, and Norway) or the United Kingdom, please take a look at our Cookie
        Notice.
      </>,
      <>
        • <StaticPage.Inline>Affiliates.</StaticPage.Inline> We may share your information with our affiliates, in which case we will require those affiliates
        to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or
        that are under common control with us.
      </>,
      <>
        • <StaticPage.Inline>Business Partners.</StaticPage.Inline> We may share your information with our business partners to offer you certain products,
        services, or promotions.
      </>,
      <>
        • <StaticPage.Inline>Other Users.</StaticPage.Inline> When you share personal information (for example, by posting comments, contributions, or other
        content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be
        publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a
        social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly,
        other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
      </>,
      <>
        • <StaticPage.Inline>Offer Wall.</StaticPage.Inline> Our application(s) may display a third-party hosted "offer wall." Such an offer wall allows
        third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer.
        Such an offer wall may appear in our application(s) and be displayed to you based on certain data, such as your geographic area or demographic
        information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A
        unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the
        relevant reward.
      </>,
    ],
    {
      title: 'What Is Our Stance On Third-party Websites?',
      content: (
        <StaticPage.Inline>
          We are not responsible for the safety of any information that you share with third parties that we may link to or who advertise on our Services, but
          are not affiliated with, our Services.
        </StaticPage.Inline>
      ),
    },
    <>
      The Services, including our offer wall, may link to third-party websites, online services, or mobile applications and/or contain advertisements from third
      parties that are not affiliated with us and which may link to other websites, services, or applications. Accordingly, we do not make any guarantee
      regarding any such third parties, and we will not be liable for any loss or damage caused by the use of such third-party websites, services, or
      applications. The inclusion of a link towards a third-party website, service, or application does not imply an endorsement by us. We cannot guarantee the
      safety and privacy of data you provide to any third parties. Any data collected by third parties is not covered by this privacy notice. We are not
      responsible for the content or privacy and security practices and policies of any third parties, including other websites, services, or applications that
      may be linked to or from the Services. You should review the policies of such third parties and contact them directly to respond to your questions.
    </>,
    {
      title: 'Do We Use Cookies And Other Tracking Technologies?',
      content: <StaticPage.Inline>We may use cookies and other tracking technologies to collect and store your information.</StaticPage.Inline>,
    },
    {
      title: 'HOW LONG DO WE KEEP YOUR INFORMATION?',
      content: (
        <StaticPage.Inline>
          We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.
        </StaticPage.Inline>
      ),
    },
    <>
      We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention
      period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your
      personal information for longer than sixty (60) months past the termination of the user's account.
    </>,
    <>
      When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is
      not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information
      and isolate it from any further processing until deletion is possible.
    </>,
    {
      title: 'HOW DO WE KEEP YOUR INFORMATION SAFE?',
      content: (
        <StaticPage.Inline>We aim to protect your personal information through a system of organizational and technical security measures.</StaticPage.Inline>
      ),
    },
    <>
      We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information
      we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage
      technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will
      not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your
      personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a
      secure environment.
    </>,
    {
      title: 'DO WE COLLECT INFORMATION FROM MINORS?',
      content: <StaticPage.Inline>We do not knowingly collect data from or market to children under 18 years of age.</StaticPage.Inline>,
    },
    <>
      We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18. If we
      learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to
      promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at
      listndeal.com.
    </>,
    {
      title: 'WHAT ARE YOUR PRIVACY RIGHTS?',
      content: [
        'We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:',
        '• Receiving help through our customer support channels;',
        '• Participation in customer surveys or contests; and',
        '• Facilitation in the delivery of our Services and to respond to your inquiries.',
      ],
    },
    <StaticPage.Inline key="How do we use and share your personal">How do we use and share your personal information?</StaticPage.Inline>,
    <>More information about our data collection and sharing practices can be found in this privacy notice.</>,
    <>You may contact us or by referring to the contact details at the bottom of this document.</>,
    <>
      If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have
      been validly authorized to act on your behalf.
    </>,
    <StaticPage.Inline key="Will your information be shared with anyone else">Will your information be shared with anyone else?</StaticPage.Inline>,
    <>
      We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service
      provider is a for-profit entity that processes the information on our behalf, following the same strict privacy protection obligations mandated by the
      CCPA.
    </>,
    <>
      We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and
      demonstration. This is not considered to be "selling" of your personal information.
    </>,
    <>
      LIST & DEAL has not sold any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. LIST & DEAL
      will not sell personal information in the future belonging to website visitors, users, and other consumers.
    </>,
    <StaticPage.Inline key="Your rights with respect to your personal data">Your rights with respect to your personal data</StaticPage.Inline>,
    <StaticPage.Inline key="Right to request deletion of the" bold={false} textDecoration="underline">
      Right to request deletion of the data — Request to delete
    </StaticPage.Inline>,
    <>
      You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your
      personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to
      free speech, our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities.
    </>,
    <StaticPage.Inline key="Right to be informed — Request to know" bold={false} textDecoration="underline">
      Right to be informed — Request to know
    </StaticPage.Inline>,
    [
      'Depending on the circumstances, you have a right to know:,',
      '• whether we collect and use your personal information;,',
      '• the categories of personal information that we collect;,',
      '• the purposes for which the collected personal information is used;,',
      '• whether we sell or share personal information to third parties;,',
      '• the categories of personal information that we sold, shared, or disclosed for a business purpose;,',
      '• the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose;,',
      '• the business or commercial purpose for collecting, selling, or sharing personal information; and,',
      '• the specific pieces of personal information we collected about you.,',
      'In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request.,',
    ],
    <StaticPage.Inline key="Right to Non-Discrimination" bold={false} textDecoration="underline">
      Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
    </StaticPage.Inline>,
    'We will not discriminate against you if you exercise your privacy rights.',
    <StaticPage.Inline key="Right to Limit Use and Disclosure" bold={false} textDecoration="underline">
      Right to Limit Use and Disclosure of Sensitive Personal Information
    </StaticPage.Inline>,
    [
      'If the business collects any of the following:',
      "• social security information, drivers' licenses, state ID cards, passport numbers",
      '• account login information',
      '• credit card numbers, financial account information, or credentials allowing access to such accounts',
      '• precise geolocation',
      '• racial or ethnic origin, religious or philosophical beliefs, union membership',
      '• the contents of email and text, unless the business is the intended recipient of the communication',
      '• genetic data, biometric data, and health data',
      '• data concerning sexual orientation and sex life',
    ],
    'you have the right to direct that business to limit its use of your sensitive personal information to that use which is necessary to perform the Services.',
    <>
      Once a business receives your request, they are no longer allowed to use or disclose your sensitive personal information for any other purpose unless you
      provide consent for the use or disclosure of sensitive personal information for additional purposes.
    </>,
    <>
      Please note that sensitive personal information that is collected or processed without the purpose of inferring characteristics about a consumer is not
      covered by this right, as well as the publicly available information.
    </>,
    'To exercise your right to limit use and disclosure of sensitive personal information, please contact us on listndeal.com.',
    <StaticPage.Inline key="Verification process" bold={false} textDecoration="underline">
      Verification process
    </StaticPage.Inline>,
    <>
      Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system.
      These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For
      instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with
      the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to
      us. We may also use other verification methods as the circumstances dictate.
    </>,
    <>
      We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will
      avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already
      maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention
      purposes. We will delete such additionally provided information as soon as we finish verifying you.
    </>,
    <StaticPage.Inline key="Other privacy rights" bold={false} textDecoration="underline">
      Other privacy rights
    </StaticPage.Inline>,
    [
      '• You may object to the processing of your personal information.',
      '• You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.',
      '• You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.',
      '• You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.',
    ],
    <>
      To exercise these rights, you can contact us or by referring to the contact details at the bottom of this document. If you have a complaint about how we
      handle your data, we would like to hear from you.
    </>,
    {
      title: 'DO WE MAKE UPDATES TO THIS NOTICE?',
      content: <StaticPage.Inline>Yes, we will update this notice as necessary to stay compliant with relevant laws.</StaticPage.Inline>,
    },
    <>
      We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be
      effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of
      such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting
      your information.
    </>,
    {
      title: 'HOW CAN YOU CONTACT US ABOUT THIS NOTICE?',
      content: 'If you have questions or comments about this notice, you may contact us at listndeal.com.',
    },
    {
      title: 'HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?',
      content: `Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to review, update, or delete your personal information, please contact us at listndeal.com.`,
    },
  ];

  return (
    <>
      <SjHelmet title="Privacy" />
      <StaticPage title="Privacy Policy" content={content} />
    </>
  );
}
export default PrivacyScreen;
