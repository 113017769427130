import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ProfileName from 'components/Profile/ProfileName';
import colors from 'constants/colors';
import { IReduxState } from 'interface/redux';
import { IUser } from 'interface/user';
import { useSelector } from 'react-redux';
import { renderDateByFormat } from 'utils/dateFormat';

function ListingItemOwner({ owner, createdAt }: { owner: IUser; createdAt?: string }) {
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUserId = auth.user?.id;
  return (
    <Link href={currentUserId === owner.id ? '/my-profile' : `/profile/${owner.id}`}>
      <Stack direction="row" gap={1} alignItems="center" mb={2}>
        <Avatar src={owner?.avatar} alt={owner.id?.toString()} sx={{ width: 32, height: 32 }} />
        <Stack sx={{ flex: 1, width: 'calc(100% - 56px)', overflow: 'hidden' }}>
          <ProfileName
            profile={owner}
            sx={{
              '&:hover': { color: colors.BaseText },
              fontSize: '14px',
              fontWeight: 500,
              whiteSpace: 'nowrap',
              margin: 0,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          />
          {createdAt && <Box sx={{ fontWeight: 400, fontSize: 12 }}>{renderDateByFormat(createdAt)}</Box>}
        </Stack>
      </Stack>
    </Link>
  );
}

export default ListingItemOwner;
