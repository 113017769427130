/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/react-hooks';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import { Close, Done } from '@mui/icons-material';
import Box from '@mui/material/Box';

import { dowloadFromUrl } from 'apis/download';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Typography from '@mui/material/Typography';
import { Edit, CheckCircle, XCircle, LogOut, Download } from 'react-feather';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { modalStyle } from 'themes/modal';
import colors from 'constants/colors';
import { useBreakpoints } from 'themes';
import { EXPORT_ROOM, LEAVE_ROOM, UPDATE_ROOM_NAME, UPDATE_ROOM_STATUS } from 'apis/graphql';
import { useChatContainerContext } from 'context/ChatContainerContext';
import { getDisplayName } from 'utils/getDisplayName';
import { CHAT_STATUSES, MESSAGE_STATUSES, MESSAGE_TYPES } from 'interface/chat';

import { ParticipantRole } from 'interface/deal';
import ChipStatus from 'components/Layout/ChipStatus';
import RoomSearchContent from '../Search/SearchSingleRoom';
import RoomInvitees from '../Invitees/RoomInvitees';

export const modalContent: {
  [x: string]: {
    header: string;
    content: string;
    submitBtn: string;
    bgColor: string;
    color: string;
  };
} = {
  [CHAT_STATUSES.CANCELLED]: {
    header: 'Are you sure you want to cancel this Room?',
    content: 'You and all participants will still be able to access this room, but chat and uploading of documents will be disabled.',
    submitBtn: 'yes, cancel room',
    bgColor: colors.Error300,
    color: colors.Error500,
  },
  [CHAT_STATUSES.COMPLETED]: {
    header: 'Are you sure you want to mark this room as Complete?',
    content: 'You and all participants will still be able to access this room, but chat and uploading of documents will be disabled.',
    submitBtn: 'yes, mark as complete',
    bgColor: colors.Success300,
    color: colors.Success500,
  },
  [CHAT_STATUSES.ARCHIVED]: {
    header: 'Are you sure you want to archive this Room?',
    content: 'This room will be permanently moved to your “Archived” tab.',
    submitBtn: 'yes, archive room',
    bgColor: '#e2e2df',
    color: colors.Secondary500,
  },
  LEAVE: {
    header: 'Are you sure you want to leave this Room??',
    content: 'You will no longer be able to access it.',
    submitBtn: 'leave room',
    bgColor: colors.Error500,
    color: colors.Error500,
  },
};

export type IRoomMenu = {
  color: string;
  onClick: any;
  label: string;
  icon: ReactElement;
};

function RoomBoxItemHeader({ updateAfterChangedRoomName }: { updateAfterChangedRoomName: any }) {
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUser = auth.user?.id.toString() ?? '';

  const isDownMd = useBreakpoints((breakpoints) => breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [updateRoomInfo] = useMutation(UPDATE_ROOM_NAME);
  const [exportRoomConversation] = useMutation(EXPORT_ROOM);
  const [updateRoomStatus] = useMutation(UPDATE_ROOM_STATUS);
  const [leaveRoom] = useMutation(LEAVE_ROOM);
  const [roomName, setRoomName] = useState('');

  const editNameInputRef = useRef<HTMLInputElement>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { activeChat: chat, updateChat } = useChatContainerContext();
  const [isEditMode, setIsEditMode] = useState(false);
  const ownerUser = chat.item?.ownerId?.toString();
  const [isModal, setIsModal] = useState<false | CHAT_STATUSES | 'LEAVE'>(false);
  const [menuItems, setMenuItems] = useState<IRoomMenu[] | []>([]);

  const userRoomRole = chat.currentParticipant?.roomRole?.toLowerCase();

  useEffect(() => {
    if (isEditMode) {
      setRoomName(chat.name);
      setTimeout(() => {
        editNameInputRef.current?.focus();
      });

      setAnchorEl(null);
    }
  }, [isEditMode]);

  const updateRoom = () => {
    updateRoomInfo({
      variables: {
        input: {
          name: roomName,
          roomId: chat.id,
        },
      },
    });
    updateAfterChangedRoomName(
      {
        id: `changed-name-${dayjs().format('DDMMYYYYhhmm')}`,
        type: MESSAGE_TYPES.ROOM_UPDATED,
        status: MESSAGE_STATUSES.NORMAL,
        roomId: chat.id,
        content: {
          text: `${getDisplayName(auth.user)} renamed the room to ${roomName}`,
        },
        owner: {
          id: currentUser,
        },
      },
      roomName,
    );
  };

  const onSubmit = (modal: CHAT_STATUSES | 'LEAVE') => {
    if (modal === 'LEAVE') {
      leaveRoom({
        variables: {
          input: {
            roomId: chat.id,
          },
        },
      });
    } else {
      updateRoomStatus({
        variables: {
          input: {
            status: modal,
            roomId: chat.id,
          },
        },
      });
    }

    if (typeof updateChat === 'function') {
      updateChat(chat.id, [
        {
          field: 'status',
          value: modal,
        },
      ]);
    }
    setIsModal(false);
    setAnchorEl(null);
  };

  const renderModal = (modal: CHAT_STATUSES | 'LEAVE') => {
    const content = modalContent[modal];
    return (
      <Modal open={typeof isModal === 'string'} onClose={() => setIsModal(false)}>
        <Box sx={modalStyle} textAlign="center">
          <IconButton
            aria-label="close"
            onClick={() => setIsModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <Typography mt={3} variant="h3">
            {content.header}
          </Typography>
          <Typography sx={{ my: 4 }} color={colors.Header}>
            {content.content}
          </Typography>
          <Stack justifyContent="center" alignItems="center">
            <Button
              variant="contained"
              sx={{
                padding: '12px 32px',
                mb: 3,
                width: 280,
                background: content.color,
                '&:hover': {
                  background: content.color,
                },
              }}
              onClick={() => onSubmit(modal)}
            >
              {content.submitBtn}
            </Button>
            <Button onClick={() => setIsModal(false)}>Go Back</Button>
          </Stack>
        </Box>
      </Modal>
    );
  };

  useEffect(() => {
    const menus = [];
    if (currentUser === ownerUser) {
      if (chat.status === CHAT_STATUSES.OPEN) {
        menus.push(
          {
            label: 'Edit Room Name',
            onClick: () => setIsEditMode(true),
            color: colors.BaseSecondaryText,
            icon: <Edit color={colors.BaseSecondaryText} />,
          },
          {
            label: 'Mark as Complete',
            onClick: () => setIsModal(CHAT_STATUSES.COMPLETED),
            color: colors.Success,
            icon: <CheckCircle color={colors.Success} />,
          },
          {
            label: 'Cancel Room',
            onClick: () => setIsModal(CHAT_STATUSES.CANCELLED),
            color: colors.Error500,
            icon: <XCircle color={colors.Error500} />,
          },
        );
      } else if (chat.status === CHAT_STATUSES.COMPLETED || chat.status === CHAT_STATUSES.CANCELLED) {
        menus.push({
          label: 'Archive Room',
          onClick: () => setIsModal(CHAT_STATUSES.ARCHIVED),
          color: colors.BaseSecondaryText,
          icon: <LogOut color={colors.BaseSecondaryText} />,
        });
      }
    } else if (
      userRoomRole !== ParticipantRole.PARTNER.toLowerCase() &&
      userRoomRole !== ParticipantRole.OWNER.toLowerCase() &&
      chat.status === CHAT_STATUSES.OPEN
    ) {
      menus.push({
        label: 'Leave Room',
        onClick: () => setIsModal('LEAVE'),
        color: colors.BaseSecondaryText,
        icon: <Edit color={colors.BaseSecondaryText} />,
      });
    }
    setMenuItems(menus);
  }, [auth, chat]);

  const exportRoom = () => {
    exportRoomConversation({
      variables: {
        input: {
          roomId: chat.id,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    }).then((res: any) => {
      dowloadFromUrl(res.data.exportRoomConversation.url, `ListnDeal-${chat.name}-${dayjs().format('DDMMYYYYHHMM')}`);
      setAnchorEl(null);
    });
  };

  return (
    <Stack px={2} pt={2} pb={1} sx={{ borderBottom: '1px solid #dddcdb' }}>
      {typeof isModal === 'string' && renderModal(isModal)}
      {isEditMode ? (
        <Stack direction="row" ml={[3, 3, 0, 0, 0]} mr={2}>
          <Box flex={1} mr={2}>
            <TextField
              inputProps={{ maxLength: 50 }}
              inputRef={editNameInputRef}
              value={roomName}
              onChange={(e) => {
                const text = e.target.value;
                setRoomName(text.length > 50 ? text.slice(0, 50) : text);
              }}
              onKeyPress={(e) => {
                if (e.code === 'Enter') {
                  updateRoom();
                  setIsEditMode(false);
                }
              }}
              onBlur={(e) => {
                setTimeout(() => setIsEditMode(false), 300);
              }}
            />
          </Box>
          <IconButton
            aria-label="delete"
            sx={{ width: '45px', height: '45px' }}
            onClick={(e) => {
              if (roomName.length > 0 && roomName !== chat.name) {
                updateRoom();
              }
              setIsEditMode(false);
            }}
          >
            <Done sx={{ color: colors.Success }} />
          </IconButton>
          <IconButton aria-label="delete" sx={{ width: '45px', height: '45px' }}>
            <Close sx={{ color: colors.Error500 }} />
          </IconButton>
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <Tooltip title={chat.name}>
              <Typography variant="h2" mr={2} ml={[3, 3, 0, 0]}>
                {isDownMd && chat.name.length > 20 ? `${chat.name.slice(0, 15)}...` : chat.name}
              </Typography>
            </Tooltip>

            <ChipStatus status={chat.status} />
          </Stack>

          <IconButton onClick={handleClick} sx={{ width: 35, height: 35, mr: 3 }}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              sx: {
                borderRadius: '16px',
              },
            }}
            MenuListProps={{
              sx: {
                borderRadius: '16px',
                p: 0,
                '& .MuiButtonBase-root.MuiMenuItem-root': {
                  p: 2,
                  ':not(:last-child)': {
                    borderBottom: '1px solid #dddcdb',
                  },
                },
              },
            }}
          >
            {menuItems.map((item) => (
              <MenuItem key={item.label} onClick={item.onClick}>
                {item.icon}
                <Typography sx={{ ml: 1, color: item.color, fontWeight: 500 }}>{item.label}</Typography>
              </MenuItem>
            ))}
            <MenuItem onClick={exportRoom}>
              <Download />
              <Typography sx={{ ml: 1, fontWeight: 500 }}>Download</Typography>
            </MenuItem>
          </Menu>
        </Stack>
      )}
      <RoomSearchContent />
      <RoomInvitees />
    </Stack>
  );
}

export default RoomBoxItemHeader;
