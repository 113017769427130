/* eslint-disable no-console */
import { IChat } from 'interface/chat';
import { IRoomsGlobalSearchSelectedMessage } from 'interface/room';
import { createContext, useContext } from 'react';

export type RoomSearchContextType = {
  search: string;
  activeTab: string;
  activeChat: IChat | null;
  setActiveChat: (chat: IChat) => void;
  onLoadMore: (name: string, token: string) => void;
  onViewMore: (name: string) => void;
  setSelectedMsg: (msg: IRoomsGlobalSearchSelectedMessage) => void;
  setShowLeftSidebar: (s: boolean) => void;
};

export const RoomSearchContext = createContext<RoomSearchContextType>({
  search: '',
  activeChat: null,
  activeTab: '',
  setActiveChat: (chat: IChat) => console.log(chat),
  onLoadMore: (name: string, token: string) => console.log(name),
  onViewMore: (name: string) => console.log(name),
  setSelectedMsg: (msg: IRoomsGlobalSearchSelectedMessage) => console.log(msg),
  setShowLeftSidebar: (s: boolean) => console.log(s),
});

export const useRoomSearchContext = (): RoomSearchContextType => useContext(RoomSearchContext);
