/* eslint-disable no-console */
import { IFilter } from 'interface/filter';
import React, { createContext, useContext, useRef, useState } from 'react';

type SearchContextRefs = {
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  setPriceRange: React.Dispatch<React.SetStateAction<{ min: string; max: string }>>;
  setFilterAndLoadDeals: (onFilter: (nextFilter: IFilter) => IFilter) => any;
};

type SearchErrors = {
  price: string;
};

type SearchContextType = {
  addRefs: (refs: Partial<SearchContextRefs>) => void;
  errors: SearchErrors;
  hasError: boolean;
  setErrors: React.Dispatch<React.SetStateAction<SearchErrors>>;
} & SearchContextRefs;

const Context = createContext<SearchContextType>({
  addRefs: () => console.warn('No implementation! Please use SearchContext.withContext.'),
  setSearch: () => console.warn('No implementation! Please use SearchContext.withContext & context.addRefs to set up refs!'),
  setPriceRange: () => console.warn('No implementation! Please use SearchContext.withContext & context.addRefs to set up refs!'),
  setFilterAndLoadDeals: () => console.log('No implementation or you not on Searh Page! Please check again!'),
  errors: { price: '' },
  hasError: false,
  setErrors: () => console.warn('No implementation!'),
});

const useSearchContext = (): SearchContextType => useContext(Context);

const withSearchProvider = <Props extends object>(Comp: React.ComponentType<Props>) =>
  function (props: Props) {
    const [errors, setErrors] = useState<SearchErrors>({ price: '' });
    const refs = useRef<SearchContextRefs>({
      setSearch: () => console.warn('No implementation! Please use context.addRefs to set up refs'),
      setPriceRange: () => console.warn('No implementation! Please use context.addRefs to set up refs'),
      setFilterAndLoadDeals: () => console.warn('No implementation! Please use context.addRefs to set up refs'),
    });
    const hasError = Object.values(errors).some((errorMessage) => !!errorMessage);

    const addRefs: SearchContextType['addRefs'] = (moreRefs) => {
      refs.current = { ...(refs.current ?? {}), ...moreRefs };
    };

    const setSearch: SearchContextRefs['setSearch'] = (nextSearch) => {
      refs.current.setSearch(nextSearch);
    };

    const setPriceRange: SearchContextRefs['setPriceRange'] = (nextPriceRange) => {
      refs.current.setPriceRange(nextPriceRange);
    };

    const setFilterAndLoadDeals: SearchContextRefs['setFilterAndLoadDeals'] = (onFilter) => {
      refs.current.setFilterAndLoadDeals(onFilter);
    };

    return (
      <Context.Provider value={{ addRefs, setSearch, setPriceRange, setFilterAndLoadDeals, errors, setErrors, hasError }}>
        <Comp {...props} />
      </Context.Provider>
    );
  };

const SearchContext = {
  useContext: useSearchContext,
  withProvider: withSearchProvider,
};

export default SearchContext;
