import gql from 'graphql-tag';

export const MESSAGE_SUBSCRIPTION = gql`
  subscription OnSendMessage($postID: ID!) {
    onCreateMessage(postID: $postID) {
      id
      content
    }
  }
`;

export const GET_MESSAGES = gql`
  query GetMessages($input: QueryMessagesInput!) {
    messages(input: $input) {
      prevToken
      nextToken
      items {
        id
        roomId
        type
        content {
          text
        }
        owner {
          id
          avatarUrl
          role
          username
          roomRole
        }
        status
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ROOM_BY_LISTING_ID = gql`
  query GetRoomByListingId($itemIds: String) {
    roomsByItemIds(itemIds: $itemIds) {
      items {
        itemId
        roomId
        totalRoom
      }
    }
  }
`;

export const GET_LISTING_ROOMS = gql`
  query GetListingRooms($itemId: String!, $type: RoomType, $roomStatus: RoomStatus) {
    listingRooms(type: $type, status: $roomStatus, itemId: $itemId) {
      items {
        id
        name
        updatedAt
        createdAt
        roomStatus
        roomType
        groupRoomId
        unreadMessages
        lastMessage
        users {
          id
          email
          avatarUrl
          role
          username
          roomRole
        }
        lastMessage
        item {
          id
          minPrice
          maxPrice
          currency {
            symbol
          }
          imageUrl
          title
          itemOwnerId
          status
          displayMinPrice
          displayMaxPrice
        }
      }
    }
  }
`;

export const GET_DIRECT_ROOM = gql`
  query GetDirectRoom($userId: String!) {
    directRoom(userId: $userId) {
      id
      updatedAt
      createdAt
      users {
        id
        avatarUrl
        role

        username
      }
      lastMessage
    }
  }
`;

export const GET_UNREAD_MESSAGE = gql`
  query GetUnreadMessage {
    getUnreadMessagesCount {
      generalListing {
        total
        roomsCount {
          roomId
          unreadMessages
        }
      }
      myListing {
        total
        roomsCount {
          roomId
          unreadMessages
        }
      }
      direct {
        total
        roomsCount {
          roomId
          unreadMessages
        }
      }
      rooms {
        total
        roomsCount {
          roomId
          unreadMessages
        }
      }
    }
  }
`;

export const SEARCH_CHATS = gql`
  query SearchChats($type: RoomType, $text: String) {
    searchContentInChats(type: $type, text: $text) {
      items {
        id
        updatedAt
        createdAt
        roomStatus
        lastMessage
        users {
          id
          avatarUrl
          role
          username
        }
        item {
          id
          minPrice
          maxPrice
          currency {
            symbol
          }
          imageUrl
          title
          itemOwnerId
          status
          displayMinPrice
          displayMaxPrice
        }
        unreadMessages
        roomType
      }
    }
  }
`;

export const GET_ROOMS = gql`
  query GetRooms($type: RoomType!, $status: RoomStatus, $limit: Int, $nextToken: String) {
    rooms(type: $type, status: $status, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        updatedAt
        createdAt
        roomStatus
        lastMessage
        name
        users {
          id
          avatarUrl
          role
          email
          invitationStatus
          roomRole
          invitedBy
          username
        }
        item {
          id
          displayMinPrice
          displayMaxPrice
          minPrice
          maxPrice
          currency {
            symbol
          }
          imageUrl
          title
          itemOwnerId
          status
        }
        unreadMessages
        roomType
        groupRoomId
      }
    }
  }
`;

export const CHECK_HAS_ANY_ROOM = gql`
  query checkHasAnyRoom {
    existsRoom {
      exists
    }
  }
`;

export const GET_ROOM_BY_ID = gql`
  query GetRoomById($id: String) {
    room(id: $id) {
      id
      updatedAt
      createdAt
      roomStatus
      lastMessage
      name
      users {
        id
        avatarUrl
        role
        roomRole
        invitedBy
        username
        email
      }
      item {
        id
        minPrice
        maxPrice
        currency {
          symbol
        }
        imageUrl
        title
        itemOwnerId
        status
        displayMinPrice
        displayMaxPrice
      }
      unreadMessages
      roomType
      groupRoomId
    }
  }
`;

export const GET_PARTICIPANTS_BY_ROOM_ID = gql`
  query GetParticipants($roomId: String!) {
    participants(roomId: $roomId) {
      id
      avatarUrl
      role
      username
      roomRole
      invitedBy
      email
      invitationStatus
    }
  }
`;

export const READ_MESSAGE = gql`
  mutation ReadMessage($messageId: String) {
    readMessage(messageId: $messageId) {
      id
    }
  }
`;

export const SEND_INVITATION_TO_ROOM = gql`
  mutation SendInvitationToRoom($input: InviteToRoomInput) {
    inviteToRoom(input: $input) {
      id
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitation($input: JoinRoomInput!) {
    acceptRoomInvitation(input: $input) {
      roomId
      status
      userId
      groupRoomId
    }
  }
`;

export const REJECT_INVITATION = gql`
  mutation RejectInvitation($input: RejectRoomInvitationInput!) {
    rejectRoomInvitation(input: $input) {
      roomId
      status
      userId
      groupRoomId
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation SendMessage($input: MessageInput!) {
    sendMessage(input: $input) {
      id
      createdAt
      updatedAt
      roomId
      ownerId
      type
      status
      content {
        text
      }
      owner {
        id
        avatarUrl
        role
        username
      }
    }
  }
`;

export const ROOM_SUBSCRIPTION = gql`
  subscription OnRoomSubscription {
    roomUpdated {
      id
      isNew
      updatedAt
      createdAt
      roomStatus
      lastMessage
      itemId
      userIds
      unreadMessages
      roomType
      name
      # groupRoomId
    }
  }
`;

export const CHECK_INVITATION = gql`
  query CheckInvitation($token: String) {
    invitation(token: $token) {
      status
    }
  }
`;

export const INVITATION_SUBSCRIPTION = gql`
  subscription OnInvitationUpdateSubscription($roomId: String!) {
    invitationUpdated(roomId: $roomId) {
      roomId
      messageId
      status
      userId
      groupRoomId
    }
  }
`;

export const ROOM_INVITATION_SUBSCRIPTION = gql`
  subscription OnInviteeUpdated {
    inviteeUpdated {
      id
      updatedAt
      createdAt
      userId
      userIds
      type
      users {
        id
        avatarUrl
        role
        username
        roomRole
        email
        invitationStatus
        invitedBy
      }
    }
  }
`;

export const CREATE_MESSAGE_SUBSCRIPTION = gql`
  subscription OnMessageCreated($roomId: String) {
    messageCreated(roomId: $roomId) {
      id
      createdAt
      updatedAt
      roomId
      type
      status
      ownerId
      groupRoomId
      content {
        text
      }
      owner {
        id
        avatarUrl
        role
        username
      }
    }
  }
`;

export const UPDATE_ROOM_NAME = gql`
  mutation UpdateRoomName($input: UpdateRoomInfoInput!) {
    updateRoomInfo(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_ROOM_STATUS = gql`
  mutation UpdateRoomStatus($input: UpdateRoomStatusInput!) {
    updateRoomStatus(input: $input) {
      id
      name
    }
  }
`;

export const LEAVE_ROOM = gql`
  mutation LeaveRoom($input: LeaveRoomInput!) {
    leaveRoom(input: $input) {
      id
      avatarUrl
      role
      username
      roomRole
      invitationStatus
    }
  }
`;

export const SEARCH_CONTENT_IN_ROOM = gql`
  query SearchContentInRoom($roomId: String, $text: String) {
    searchContentInRoom(roomId: $roomId, text: $text) {
      messages {
        id
        createdAt
        updatedAt
        roomId
        ownerId
        content {
          text
        }
        owner {
          id
          avatarUrl
          role
          username
        }
        type
        status
        groupRoomId
      }
      users {
        id
        avatarUrl
        username
        email
      }
      files {
        id
        fileName
        fileUrl
        fileExt
        fileSize
        createdAt
        user {
          username
          id
        }
      }
    }
  }
`;

export const ADD_PARTICIPANT = gql`
  mutation AddParticipant($input: AddParticipantInput!) {
    addParticipant(input: $input) {
      id
      avatarUrl
      role
      username
    }
  }
`;

export const REMOVE_PARTICIPANT = gql`
  mutation RemoveParticipant($input: RemoveParticipantInput!) {
    removeParticipant(input: $input) {
      id
      avatarUrl
      role
      username
    }
  }
`;

export const UPDATE_INVITATION = gql`
  mutation UpdateInvitation($input: ParticipantUpdateInvitationInput!) {
    updateInvitation(input: $input) {
      id
      updatedAt
      createdAt
      roomStatus
      lastMessage
      name
      users {
        id
        avatarUrl
        role
        username
      }
      item {
        id
        minPrice
        maxPrice
        currency {
          symbol
        }
        imageUrl
        title
        itemOwnerId
      }
      unreadMessages
      roomType
      groupRoomId
    }
  }
`;
export const GET_UPLOAD_LINK = gql`
  query UploadLink($input: GetUploadLinkInput!) {
    uploadLink(input: $input) {
      files {
        key
        method
        url
        signedHeader
      }
    }
  }
`;

export const GET_FILES_IN_ROOM = gql`
  query UploadedFiles($roomId: String, $limit: Int, $nextToken: String) {
    uploadedFiles(roomId: $roomId, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        fileId
        fileName
        fileUrl
        fileExt
        fileSize
        createdAt
        user {
          username
          id
        }
      }
    }
  }
`;

export const NOTIFY_FILE_ADDED = gql`
  mutation NotifyRoomFileAdded($input: NotifyRoomFileAddedInput) {
    notifyRoomFileAdded(input: $input) {
      roomId
      files {
        id
        fileId
        fileName
        fileUrl
        fileExt
        fileSize
        createdAt
        user {
          username
          id
        }
      }
    }
  }
`;

export const FILE_UPDATED_SUBSCRIPTION = gql`
  subscription FileUpdated($roomId: String) {
    fileUpdated(roomId: $roomId) {
      roomId
      type
      files {
        id
        fileId
        fileName
        fileUrl
        fileExt
        fileSize
        createdAt
        user {
          username
          id
        }
      }
    }
  }
`;

export const REMOVE_FILE = gql`
  mutation RemoveRoomFile($input: RemoveFileInput!) {
    removeRoomFile(input: $input) {
      roomId
      type
      files {
        id
        fileId
        fileName
        fileUrl
        fileExt
        fileSize
        createdAt
        user {
          username
          id
        }
      }
    }
  }
`;

export const EXPORT_ROOM = gql`
  mutation ExportRoomConversation($input: ExportRoomConversationInput!) {
    exportRoomConversation(input: $input) {
      url
    }
  }
`;

export const SEARCH_MESSAGES_IN_ROOMS = gql`
  query SearchMessages($text: String, $limit: Int, $nextToken: String) {
    searchMessages(text: $text, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        createdAt
        updatedAt
        iconUrl
        roomRole
        roomId
        roomName
        roomStatus
        unreadMessages
      }
    }
  }
`;

export const SEARCH_FILES_IN_ROOMS = gql`
  query SearchFiles($text: String, $limit: Int, $nextToken: String) {
    searchFiles(text: $text, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        createdAt
        updatedAt
        iconUrl
        fileUrl
        roomId
        roomRole
        roomName
        roomStatus
        unreadMessages
      }
    }
  }
`;

export const SEARCH_USERS_IN_ROOMS = gql`
  query SearchUsers($text: String, $limit: Int, $nextToken: String) {
    searchUsers(text: $text, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        createdAt
        updatedAt
        iconUrl
        roomId
        roomRole
        roomName
        roomStatus
        unreadMessages
      }
    }
  }
`;

export const SEARCH_ROOMS_IN_ROOMS = gql`
  query SearchRooms($text: String, $limit: Int, $nextToken: String) {
    searchRooms(text: $text, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        createdAt
        updatedAt
        iconUrl
        roomId
        roomName
        roomStatus
        unreadMessages
      }
    }
  }
`;

export const SEARCH_ALL_IN_ROOMS = gql`
  query GlobalRoomsSearch($text: String, $limit: Int, $nextToken: String) {
    searchRooms(text: $text, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        title
        content
        createdAt
        updatedAt
        iconUrl
        roomName
        roomId
        roomStatus
        unreadMessages
        id
      }
    }
    searchUsers(text: $text, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        title
        content
        createdAt
        updatedAt
        iconUrl
        roomRole
        roomId
        roomName
        roomStatus
        unreadMessages
        id
      }
    }
    searchMessages(text: $text, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        title
        content
        createdAt
        updatedAt
        iconUrl
        roomId
        roomName
        roomStatus
        unreadMessages
        id
      }
    }
    searchFiles(text: $text, limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        title
        content
        createdAt
        updatedAt
        iconUrl
        fileUrl
        roomId
        roomName
        roomStatus
        unreadMessages
        id
      }
    }
  }
`;
