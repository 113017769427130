import { Link, Tab, Tabs } from '@mui/material';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from 'constants/colors';
import { useBreakpoints } from 'themes';
import { Item } from 'utils/types';

type Data = {
  value: string;
  title: string;
  href: string;
}[];

type SjTabProps = {
  tab: Item<Data>;
  value: Item<Data>['value'];
  trailing?: string | number;
};

function SjTab(props: SjTabProps) {
  const { tab, value, trailing } = props;
  const isUpLg = useBreakpoints((breakpoints) => breakpoints.up('lg'));

  return (
    <Link href={tab.href} {...(isUpLg ? { py: '24px' } : { py: 1.5, px: 2 })}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          variant="body1"
          sx={{
            textTransform: 'none',
            ...(isUpLg
              ? {
                  fontWeight: 500,
                  color: value === tab.value ? colors.Header : colors.BaseSecondaryText,
                  '&:hover': { textDecoration: 'underline' },
                }
              : {
                  fontWeight: 700,
                  color: value === tab.value ? colors.Secondary : colors.BaseSecondaryText,
                }),
          }}
        >
          {tab.title}
        </Typography>
        {!!trailing && (
          <Typography
            variant="body1"
            sx={{
              ml: '4px',
              ...(isUpLg
                ? {
                    fontWeight: 500,
                    color: value === tab.value ? colors.Header : colors.BaseSecondaryText,
                    '&:hover': { textDecoration: 'underline' },
                  }
                : {
                    fontWeight: 700,
                    color: value === tab.value ? colors.Secondary : colors.BaseSecondaryText,
                  }),
            }}
          >
            {trailing}
          </Typography>
        )}
      </Stack>
    </Link>
  );
}

type SjTabsProps = {
  data: Data;
  value: Item<Data>['value'];
  trailing?: (item: Item<Data>) => string | number;
};

function SjTabs(props: SjTabsProps) {
  const { data, value } = props;
  const isUpLg = useBreakpoints((breakpoints) => breakpoints.up('lg'));
  return isUpLg ? (
    <Stack>
      {data.map((tab, tabIndex) => (
        <>
          {!!tabIndex && <Divider />}
          <SjTab tab={tab} value={value} trailing={props.trailing?.(tab)} />
        </>
      ))}
    </Stack>
  ) : (
    <Tabs
      variant="scrollable"
      scrollButtons={'auto' as any}
      allowScrollButtonsMobile
      value={value}
      sx={{
        '& .MuiTab-root': {
          fontWeight: 500,
          textTransform: 'capitalize',
          fontSize: '16px',
          color: colors.BaseSecondaryText,
        },
        '& .MuiTab-root.Mui-selected': {
          color: colors.Secondary,
          fontWeight: 700,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: colors.Secondary,
        },
      }}
    >
      {data.map((tab) => (
        <Tab
          href={tab.href}
          key={`tab-${tab.value}`}
          value={tab.value}
          sx={{ p: 0 }}
          label={<SjTab tab={tab} value={value} trailing={props.trailing?.(tab)} />}
        />
      ))}
    </Tabs>
  );
}

type CompoundSjTabs = typeof SjTabs & {
  Item: Item<Data>;
  Data: Data;
};

export default SjTabs as CompoundSjTabs;
