import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import Box from '@mui/material/Box';
import { convertChatBoxItemApi } from 'utils/convertApiChatToWebChat';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { GET_ROOM_BY_ID } from 'apis/graphql';

import { Avatar, Button, Divider, Link, Stack, Typography } from '@mui/material';

import { getUserProfileLink } from 'apis/user';

import { IExternalSearch, IRoomsSearchItem } from 'interface/room';
import { renderDateByFormat } from 'utils/dateFormat';
import { useRoomSearchContext } from 'context/RoomSearchContext';
import colors from 'constants/colors';
import ChatListItem from '../ChatListItem';
import { CHAT_LIST_VIEW_MODE } from '../ChatList';

enum ISearchObjects {
  searchRooms = 'Rooms',
  searchUsers = 'Users',
  searchMessages = 'Messages',
  searchFiles = 'Files',
}

enum ISearchAllObjects {
  ALL = 'all',
}

function SearchMultiRoomsResults({ data, objectName }: { data: IExternalSearch; objectName: string }) {
  const { onLoadMore, onViewMore, activeTab, setSelectedMsg, search } = useRoomSearchContext();
  const { setActiveChat, setShowLeftSidebar } = useRoomSearchContext();
  const [getRoomById, { data: room }] = useLazyQuery(GET_ROOM_BY_ID);
  const [isSelected, setIsSelected] = useState(false);
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUserId = auth.user?.id?.toString() ?? '';

  useEffect(() => {
    if (isSelected && room) {
      setActiveChat(
        convertChatBoxItemApi(
          {
            ...room.room,
            type: 'LISTING_GROUP',
          },
          currentUserId,
        ),
      );
      setShowLeftSidebar(false);
      setTimeout(() => {
        setIsSelected(false);
      }, 1000);
    }
  }, [isSelected, room]);

  const renderItem = (type: string, r: IRoomsSearchItem) => {
    switch (type) {
      case ISearchObjects.searchRooms:
        return (
          <ChatListItem
            chatName={r.title}
            chatStatus={r.roomStatus}
            updatedAt={r.updatedAt}
            viewMode={CHAT_LIST_VIEW_MODE.ROOM}
            lastMessage={r.content}
            unreadMessages={r.unreadMessages}
          />
        );
      case ISearchObjects.searchFiles:
        return (
          <Link href={`//${r.fileUrl}`} target="_blank">
            <Typography color={colors.Header} fontWeight={500} fontSize={16} sx={{ wordBreak: 'break-word' }}>
              {r.title}
            </Typography>
            <Typography color={colors.Header} fontWeight={500} fontSize={16}>
              {r.content}
            </Typography>
            <Typography color="#84743f" fontWeight={500} fontSize={14}>
              {`${renderDateByFormat(r.createdAt)} • ${r.roomName}`}
            </Typography>
          </Link>
        );
      default:
        return (
          <Stack direction="row">
            {type === ISearchObjects.searchUsers ? (
              <Link href={getUserProfileLink(r.id, currentUserId)} target="_blank">
                <Avatar src={r.iconUrl} alt="avatar" sx={{ width: 48, height: 48, mr: 1 }} />
              </Link>
            ) : (
              <Avatar src={r.iconUrl} alt="avatar" sx={{ width: 48, height: 48, mr: 1 }} />
            )}
            <Box sx={{ wordBreak: 'break-word' }}>
              {type === ISearchObjects.searchUsers ? (
                <Link href={getUserProfileLink(r.id, currentUserId)} target="_blank">
                  <Typography color={colors.Header} fontWeight={700} fontSize={16}>
                    {r.title}
                  </Typography>
                </Link>
              ) : (
                <Typography color={colors.Header} fontWeight={700} fontSize={16}>
                  {r.title}
                </Typography>
              )}
              <Box
                sx={{
                  fontWeight: 500,
                  color: '#4A4A68',
                  fontSize: '16px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {typeof r.content === 'string' ? r.content : ''}
              </Box>
              <Box
                sx={{
                  fontWeight: 500,
                  color: '#84743f',
                  fontSize: '14px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                }}
              >{`${renderDateByFormat(r.updatedAt)} • ${r.roomName}`}</Box>
            </Box>
          </Stack>
        );
    }
  };

  if (data && data[objectName as keyof typeof data]) {
    const results = data[objectName as keyof typeof data];
    const { nextToken } = data[objectName as keyof typeof data];
    const items = activeTab !== ISearchAllObjects.ALL ? results.items : results.items.slice(0, 3);

    if (items.length > 0) {
      return (
        <Box mt={3}>
          {activeTab === ISearchAllObjects.ALL && (
            <>
              <Stack direction="row" justifyContent="space-between" px={3}>
                <Typography fontSize="14px" fontWeight={700} color="#a4955d" pb={2} textTransform="uppercase">
                  {ISearchObjects[objectName as keyof typeof ISearchObjects]}
                </Typography>
                {results.items.length > 3 && (
                  <Typography fontSize="13px" fontWeight={500} color="#8c7f4f" sx={{ cursor: 'pointer' }} onClick={() => onViewMore(objectName)}>
                    View more
                  </Typography>
                )}
              </Stack>
              <Divider color="#fff" />
            </>
          )}
          <Box>
            {items.map((r) => {
              return (
                <Box key={r.id}>
                  <Box px={3} py={2}>
                    <Stack
                      key={r.id}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        // if (r.id !== activeChat?.id) {
                        setIsSelected(true);
                        getRoomById({
                          variables: {
                            id: r.roomId,
                          },
                        });
                        // }

                        if (objectName === 'searchMessages') {
                          setSelectedMsg({
                            messageId: r.id,
                            keyword: search,
                          });
                        }
                      }}
                    >
                      {renderItem(ISearchObjects[objectName as keyof typeof ISearchObjects], r)}
                    </Stack>
                  </Box>
                  <Divider color="#fff" />
                </Box>
              );
            })}
            {nextToken && activeTab !== ISearchAllObjects.ALL && (
              <Box textAlign="center" mt={2} onClick={() => onLoadMore(objectName, nextToken)}>
                <Button variant="text">Load more</Button>
              </Box>
            )}
          </Box>
        </Box>
      );
    }
    if (activeTab !== ISearchAllObjects.ALL) {
      return (
        <Box textAlign="center" py={5}>
          No results found
        </Box>
      );
    }
  }

  return <Box />;
}

export default SearchMultiRoomsResults;
