import { Box, Stack, Typography, Container, Button } from '@mui/material';
import IDeal from 'interface/deal';
import colors from 'constants/colors';
import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { CHECK_INVITATION, UPDATE_INVITATION } from 'apis/graphql';
import { IInvitationStatus } from 'interface/chat';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showToast } from 'redux/toast/action';

export default function DealInvitationAcceptBox({
  deal,
  onUpdated,
  setUpdatedRoomInvitation,
  token,
}: {
  deal: IDeal;
  onUpdated: any;
  setUpdatedRoomInvitation: any;
  token: string;
}) {
  const [checkInvitation] = useLazyQuery(CHECK_INVITATION);
  const [updateInvitation] = useMutation(UPDATE_INVITATION);
  const [acceptStatus, setAcceptStatus] = useState<IInvitationStatus>(IInvitationStatus.NORMAL);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showError = (error: string) => {
    dispatch(
      showToast({
        type: 'error',
        title: 'Invalid Invitation.',
        description: error,
      }),
    );
  };

  useEffect(() => {
    if (token) {
      checkInvitation({
        variables: {
          token,
        },
        fetchPolicy: 'network-only',
      })
        .then((response) => {
          const invitationStatus = response.data?.invitation?.status;
          if (invitationStatus) {
            setAcceptStatus(invitationStatus);
          } else {
            setAcceptStatus(IInvitationStatus.INVALID);
            showError('The Invitation is invalid.');
          }
        })
        .catch((error) => {
          setAcceptStatus(IInvitationStatus.INVALID);
          showError(error.message || 'The Invitation is invalid.');
        });
    }
  }, [token]);

  useEffect(() => {
    setIsLoading(false);
    if (acceptStatus === 'ACCEPTED' || acceptStatus === 'REJECTED') {
      onUpdated({ ...deal, invitee_accepted: true });
    }
  }, [acceptStatus]);

  const onUpdateInvitation = (status: IInvitationStatus) => {
    setIsLoading(true);
    updateInvitation({
      variables: {
        input: {
          status,
          token,
        },
      },
    }).then((response) => {
      if (!response.errors) {
        setAcceptStatus(status);
        if (status === IInvitationStatus.ACCEPTED && response.data?.updateInvitation) {
          const updatedInvitation = response.data.updateInvitation;
          setUpdatedRoomInvitation(updatedInvitation);
        }
      } else {
        showError(response.errors.toString());
      }
    });
  };

  return acceptStatus === IInvitationStatus.PENDING ? (
    <Box bgcolor={colors.White} position="fixed" bottom={0} left={0} right={0} boxShadow="0px 4px 21px rgba(105, 88, 26, 0.15)" zIndex={1}>
      <Container sx={{ py: 2 }}>
        <Stack direction={['column', 'column', 'row']} justifyContent="space-between" alignItems="center" height={['unset', 'unset', '132px']}>
          <Stack direction="column" alignItems="start" justifyContent="left" spacing="16px">
            <Typography variant="h3">You have been invited to join a Room for this listing.</Typography>
            <Typography>
              Rooms are a dedicated one stop workspace for you and other users to discuss your business transactions - from chats to uploading of documents, you
              can do it all here.
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="16px" height="52px">
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              sx={{ width: '176px', height: '52px', m: '0' }}
              disabled={isLoading}
              onClick={() => {
                onUpdateInvitation(IInvitationStatus.REJECTED);
              }}
            >
              REJECT
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              sx={{ width: '176px', height: '52px', m: '0' }}
              disabled={isLoading}
              onClick={() => {
                onUpdateInvitation(IInvitationStatus.ACCEPTED);
              }}
            >
              ACCEPT
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  ) : (
    <Box hidden />
  );
}
