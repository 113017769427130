import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useNavigate } from 'react-router-dom';
import colors from 'constants/colors';
import { IChat, IChatTabType } from 'interface/chat';
import { useContext } from 'react';
import { MainContext } from 'context/MainContext';

function ChatTabs({ tabs, activeTab, setActiveChat }: { tabs: IChatTabType[]; activeTab: number; setActiveChat: (chat: IChat | null) => void }) {
  const { unreadMessageCount: unreadData } = useContext(MainContext);
  const navigate = useNavigate();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(`/chats?tab=${newValue}`);
    setActiveChat(null);
  };

  const renderTab = (item: IChatTabType) => {
    const d = item.unreadField;
    const unreadNumber = unreadData[d].total > 0 ? `(${unreadData[d].total})` : '';
    return <Tab key={item.value} label={`${item.name} ${unreadNumber}`} />;
  };

  return (
    <Tabs
      value={activeTab}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons={'auto' as any}
      allowScrollButtonsMobile
      sx={{
        width: '100%',
        '& .MuiTabScrollButton-root.Mui-disabled': {
          display: 'none',
        },
        '& .MuiTab-root': {
          fontWeight: 500,
          textTransform: 'capitalize',
          fontSize: '16px',
          color: colors.BaseSecondaryText,
        },
        '& .MuiTab-root.Mui-selected': {
          color: colors.Secondary,
          fontWeight: 700,
        },
        '& .MuiTabs-indicator': {
          backgroundColor: colors.Secondary,
        },
      }}
    >
      {tabs.map((item) => renderTab(item))}
    </Tabs>
  );
}

export default ChatTabs;
