import { Box, Breadcrumbs, Container, Link, Typography } from '@mui/material';
import getAvailableRoutes from 'app/routes';
import colors from 'constants/colors';
import { IReduxState } from 'interface/redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useQuery from 'utils/useQuery';

function SJBreadcrumbs({ title }: { title: string | { name: string; link?: string }[] }) {
  const query = useQuery();
  const location = useLocation();
  const { auth } = useSelector((state: IReduxState) => state);
  if (location.pathname === '/') {
    return <Box />;
  }

  const prevRouter = query.get('location');
  const routers = getAvailableRoutes(auth.user?.role);

  const [items, setItems] = useState([
    {
      name: 'Home',
      path: '/',
    },
  ]);

  useEffect(() => {
    if (prevRouter && prevRouter !== '/') {
      const prevRouterName = routers.find((item) => item.path === prevRouter)?.breadcrumbs;
      if (prevRouterName) {
        setItems([
          ...items,
          {
            name: prevRouterName,
            path: prevRouter,
          },
        ]);
      }
    }
  }, [prevRouter]);

  const renderTitle = () => {
    if (typeof title === 'string') {
      return <Typography>{title}</Typography>;
    }
    return title.map((item) =>
      item.link ? (
        <Link href={item.link} key={item.name} sx={{ fontWeight: 500, color: colors.BaseText }}>
          {item.name}
        </Link>
      ) : (
        <Typography key={item.name}>{item.name}</Typography>
      ),
    );
  };

  return (
    <Breadcrumbs sx={{ mt: 2, mb: 2 }} separator="›">
      {items.map((item) => (
        <Link href={item.path} key={item.name} sx={{ fontWeight: 500, color: colors.BaseText }}>
          {item.name}
        </Link>
      ))}
      {renderTitle()}
    </Breadcrumbs>
  );
}

export default SJBreadcrumbs;
