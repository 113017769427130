import { Avatar } from '@mui/material';
import { User } from 'react-feather';

function SjAvatar({ user }: { user: any }) {
  if (user.avatar) {
    return <Avatar src={user.avatar} alt="avatar" sx={{ width: 45, height: 45, mr: 2 }} />;
  }
  return (
    <Avatar sx={{ width: 45, height: 45, mr: 2 }}>
      <User />
    </Avatar>
  );
}

export default SjAvatar;
