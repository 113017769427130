/* eslint-disable no-console */
import { IMessage } from 'interface/chat';
import { createContext, useContext } from 'react';

export type ChatMessageContextType = {
  isTyping: boolean;
  setIsTyping: (t: boolean) => void;
  search: string;
  setSearch: (k: string) => void;
  messages: IMessage[];
  prevToken: string | null;
  isSearchMode: boolean;
  setIsSearchMode: (isSearch: boolean) => void;
  getMessages: (variable: any, direction?: string) => void;
  setPage: (page: number) => void;
  page: number;
  nextToken: string | null;
  setSearchMsgId: (m: string) => void;
  searchMsgId: string | null;
};

export const ChatMessageContext = createContext<ChatMessageContextType>({
  isTyping: false,
  setIsTyping: (t) => console.warn(t),
  search: '',
  setSearch: (k) => console.warn(''),
  messages: [],
  prevToken: null,
  isSearchMode: false,
  setIsSearchMode: () => console.warn(''),
  getMessages: (variable: any, direction) => console.warn(''),
  setPage: (page: number) => console.warn(''),
  page: 0,
  nextToken: '',
  searchMsgId: null,
  setSearchMsgId: (s: string) => console.warn(''),
});

export const useChatMessageContext = (): ChatMessageContextType => useContext(ChatMessageContext);
