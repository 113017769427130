import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import colors from 'constants/colors';
import { ChatContainerContext } from 'context/ChatContainerContext';
import { IReduxState } from 'interface/redux';
import { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { CHAT_STATUSES, IMessage, MESSAGE_TYPES } from 'interface/chat';
import { Avatar, Link, Stack } from '@mui/material';
import { ChatMessageContext } from 'context/ChatMessageContext';
import { getUserProfileLink } from 'apis/user';
import { isLink } from 'utils/regex';
import { renderDateByFormat } from 'utils/dateFormat';

export const InvitedToRoom = 'Invited To Room';
export const InvitationAccepted = 'Invitation Accepted';
export const InvitationRejected = 'Invitation Rejected';

export const SpecialMessage = [InvitedToRoom, InvitationAccepted, InvitationRejected];

function ChatMessage({
  message,
  acceptRoom,
  rejectRoom,
  index,
  prevMsgOwner,
}: {
  message: IMessage;
  acceptRoom: any;
  rejectRoom: any;
  index: string;
  prevMsgOwner?: string;
}) {
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUser = auth.user?.id.toString() ?? '';
  const { id, owner, content, type, createdAt, status } = message;
  const { activeChat } = useContext(ChatContainerContext);
  const { search, isSearchMode } = useContext(ChatMessageContext);

  const renderWord = (word: string, shouldMark: boolean) => {
    const fmWord = word.replaceAll('&nbsp', ' ');
    return fmWord.toLowerCase() === search.toLowerCase() || shouldMark ? <mark>{fmWord}</mark> : <span>{fmWord}</span>;
  };

  const renderLink = (link: string, word: string, shouldMark: boolean) => {
    return (
      <>
        {word.indexOf('&nbsp') > -1 ? ' ' : ''}
        <Link
          href={`${link}`}
          key={`${link}-${word}`}
          target="_blank"
          sx={{
            fontWeight: 500,
            color: '#4A4A68',
            textDecoration: 'underline',
          }}
        >
          {renderWord(word, shouldMark)}
        </Link>
      </>
    );
  };

  const addMarkup = (word: string) => {
    if (isLink(word)) {
      return renderLink(word, word, false);
    }
    return `${word} `;
  };

  const renderContent = useCallback(
    (text: string) => {
      if (search) {
        const result: any[] = [];
        const re = new RegExp(search.toLowerCase(), 'gi');

        const linkList: any = text.split(' ').filter((i) => isLink(i) || i.startsWith('www'));

        while (re.exec(text.toLowerCase())) {
          result.push({
            index: re.lastIndex - search.length,
            type: 'highlight',
          });
          result.push({
            index: re.lastIndex,
            type: 'highlight',
          });
        }

        const newText: any[] = [];
        let start = 0;

        result.forEach((item, i: number) => {
          if (item.type === 'highlight') {
            newText.push(text.slice(start, item.index));
            start = item.index;
          }
        });

        if (result.length === 0) {
          return text.split(' ').map((item, i) => <>{addMarkup(item)} </>);
        }

        if (start < text.length) {
          newText.push(text.slice(start));
        }

        let isCurrentLink = false;
        let currentLinkIndex = -1;

        const html: any = [];
        newText.forEach((t) => {
          const shouldMark = t.toLowerCase() === search.toLowerCase();
          const words = t.replaceAll(' ', ' &nbsp').split(' ');

          const formatWords = words.map((w: string, i: number) => {
            if (isLink(w) || `${w}`.replaceAll('&nbsp', '').startsWith('www.') || `${w}`.replaceAll('&nbsp', '').startsWith('http')) {
              isCurrentLink = true;
              if (w.includes('&nbsp') || i === 0) {
                currentLinkIndex += 1;
              }
              return renderLink(linkList[currentLinkIndex], w, shouldMark);
            }
            if (isCurrentLink) {
              if (!w.includes('&nbsp')) return renderLink(linkList[currentLinkIndex], w, shouldMark);
              isCurrentLink = false;
            }
            return renderWord(w, shouldMark);
          });
          html.push(...formatWords);
        });

        return html.map((t: any) => <span key={index}>{t}</span>);
      }
      return text.split(' ').map((item, i) => <>{addMarkup(item)} </>);
    },
    [isSearchMode],
  );

  const messageStyles = {
    mt: '5px',
    borderRadius: '8px',
    maxWidth: message.type === MESSAGE_TYPES.INVITATION ? '250px' : '60%',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    color: colors.BaseText,
    textAlign: 'left',
  };

  if (type === MESSAGE_TYPES.ROOM_UPDATED || type === MESSAGE_TYPES.FILE_UPDATED) {
    return (
      <Box
        textAlign="center"
        color={colors.White}
        fontSize={12}
        id={index}
        sx={{
          wordBreak: 'break-word',
          background: '#8c8c8c',
          borderRadius: '16px',
          mt: 0.5,
          mb: 0.5,
          py: 0.2,
          px: 1,
          width: 'fit-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {content.text}
      </Box>
    );
  }

  if (type === MESSAGE_TYPES.INVITATION) {
    return (
      <Box sx={{ ...messageStyles, float: owner.id === currentUser ? 'right' : 'left' }} id={index}>
        <Box
          sx={{
            bgcolor: currentUser === owner.id?.toString() ? colors.Primary500 : colors.Primary100,
            borderRadius: '8px',
            p: '12px 12px 10px',
            mb: 1,
          }}
        >
          <Typography variant="h4">{content.text}</Typography>
          <Typography fontSize="13px" textAlign="right">
            {renderDateByFormat(createdAt, true)}
          </Typography>
        </Box>
        {currentUser !== activeChat.item.ownerId?.toString() &&
          content.text === InvitedToRoom &&
          activeChat.status !== CHAT_STATUSES.ARCHIVED &&
          status !== 'COMPLETED' && (
            <Box>
              <Button
                variant="outlined"
                sx={{ py: '3px', mb: 0, mt: '1px', mr: 1, fontSize: '14px' }}
                onClick={() => {
                  rejectRoom(id);
                }}
              >
                reject
              </Button>
              <Button variant="contained" sx={{ py: '4px', fontSize: '14px' }} onClick={() => acceptRoom(id)}>
                accept
              </Button>
            </Box>
          )}
      </Box>
    );
  }

  return (
    <Stack direction="row" justifyContent={owner.id === currentUser ? 'flex-end' : 'flex-start'}>
      {owner.id !== currentUser && activeChat.type === 'LISTING_GROUP' && (
        <Box mt={1} height={40} width={40} mr={1}>
          {(!prevMsgOwner || +prevMsgOwner !== +owner.id) && (
            <Link href={getUserProfileLink(owner.id, auth.user?.id.toString())}>
              <Avatar src={owner.avatarUrl} alt="avatar" sx={{ width: 40, height: 40 }} />
            </Link>
          )}
        </Box>
      )}

      <Box
        sx={{
          ...messageStyles,
          mb: 0.5,
        }}
        id={index}
      >
        {owner.id !== currentUser && activeChat.type === 'LISTING_GROUP' && (!prevMsgOwner || +prevMsgOwner !== +owner.id) && (
          <Box fontSize={12}>{`${owner.username}, ${renderDateByFormat(createdAt, true)}`}</Box>
        )}
        <Box sx={{ p: '8px 10px 8px', borderRadius: '8px', backgroundColor: currentUser === owner.id?.toString() ? colors.Primary500 : colors.Primary100 }}>
          <Typography sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line', fontWeight: 400 }}>{renderContent(content.text)}</Typography>
          {activeChat.type !== 'LISTING_GROUP' && (
            <Typography fontSize="12px" textAlign="right" sx={{ opacity: 0.6 }}>
              {renderDateByFormat(createdAt, true)}
            </Typography>
          )}
        </Box>
      </Box>
    </Stack>
  );
}

export default ChatMessage;
