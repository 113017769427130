import { Box, Stack, Typography, Container, Button } from '@mui/material';
import IDeal, { IInvitationResponse } from 'interface/deal';
import colors from 'constants/colors';
import { useEffect, useState } from 'react';
import { acceptInvitation } from 'apis/deal';
import { useDispatch } from 'react-redux';

import { showToast } from 'redux/toast/action';

export default function ListingItemAcceptBox({ deal, actions, onUpdated }: { deal: IDeal; actions?: string[]; onUpdated: any }) {
  const dispatch = useDispatch();
  const currentStatus = deal.invitee_accepted ? IInvitationResponse.ACCEPT : IInvitationResponse.UNANSWERED;

  const [isLoading, setIsLoading] = useState(false);
  const [acceptStatus, setAcceptStatus] = useState(currentStatus);

  const submitResponse = (response: IInvitationResponse) => {
    setIsLoading(true);
    acceptInvitation({ id: deal.id, invitationId: deal.invitation_id || '', invitationResponse: response })
      .then((newDeal) => {
        setAcceptStatus(newDeal.invitee_accepted ? IInvitationResponse.ACCEPT : IInvitationResponse.REJECT);
      })
      .catch((error) => {
        dispatch(
          showToast({
            type: 'error',
            title: error.message,
          }),
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (acceptStatus === 'ACCEPTED' || acceptStatus === 'REJECTED') {
      onUpdated({ ...deal, invitee_accepted: true });
    }
  }, [acceptStatus]);
  return acceptStatus === IInvitationResponse.UNANSWERED ? (
    <Box bgcolor={colors.White} position="fixed" bottom={0} left={0} right={0} boxShadow="0px 4px 21px rgba(105, 88, 26, 0.15)" zIndex={1}>
      <Container sx={{ py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" height="132px">
          <Typography variant="h3">You have been invited to join this listing.</Typography>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing="16px" height="52px">
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              sx={{ width: '176px', height: '52px', m: '0' }}
              disabled={isLoading}
              onClick={() => {
                submitResponse(IInvitationResponse.REJECT);
              }}
            >
              REJECT
            </Button>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              sx={{ width: '176px', height: '52px', m: '0' }}
              disabled={isLoading}
              onClick={() => {
                submitResponse(IInvitationResponse.ACCEPT);
              }}
            >
              JOIN LISTING
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  ) : (
    <Box hidden />
  );
}
