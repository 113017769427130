import LogoutIcon from '@mui/icons-material/Logout';
import { Divider, Typography, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import colors from 'constants/colors';
import { MainContext } from 'context/MainContext';
import { IReduxState } from 'interface/redux';
import { UserType } from 'interface/user';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { userLogout } from 'redux/auth/action';
import { setDisplayDesktopOnly, useBreakpoints } from 'themes';
import Listener, { EVENT_NAME } from 'utils/listener';
import { version } from 'utils/version';
import SjDesktopMenu from './DesktopMenu';
import SjMobileMenu, { IMenuItemProps, SjMobileMenuItem } from './MobileMenu';
import SjGlobalCurrency from './SjGlobalCurrency';
import SjSearchField from './SjSearchField';
import SjUserMenu from './SjUserMenu';

const MARGIN_BOTTOM = 7;

function LogOutButton() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const ref = useRef<HTMLButtonElement | null>(null);
  const [height, setHeight] = useState(0);

  const handleLogout = () => {
    dispatch(userLogout());
    navigate('/');
    Listener.emit(EVENT_NAME.CLOSE_MOBILE_MENU);
  };

  useEffect(() => {
    const nextHeight = ref.current?.clientHeight ?? 0;
    if (nextHeight === height || !nextHeight) {
      return;
    }
    setHeight(nextHeight);
  }, [height]);

  return (
    <>
      <Box sx={{ height: height + theme.spacing(MARGIN_BOTTOM * 2) }} />

      <Button
        onClick={handleLogout}
        sx={{
          position: 'absolute',
          bottom: theme.spacing(MARGIN_BOTTOM),
          left: 0,
          alignItems: 'center',
          '.MuiTypography-root': theme.typography.h4,
          textTransform: 'none',
        }}
      >
        <LogoutIcon sx={{ mr: 1, color: colors.Header }} />
        <Typography>Log out</Typography>
      </Button>
    </>
  );
}

const MOBILE_UNAUTH_BUGER_MENU_LIST: SjMobileMenuItem[] = [
  {
    label: 'Log in',
    link: '/login',
    totalUnread: 0,
  },
  {
    label: 'Sign up',
    link: '/sign-up',
    totalUnread: 0,
  },
  { renderComp: () => <Divider /> },
  { renderComp: () => <SjGlobalCurrency type="full" /> },
];
const DESKTOP_MENU_LIST = [
  {
    label: 'Chats',
    link: '/chats?tab=0',
    type: 'chat',
    totalUnread: 0,
  },
  {
    label: 'Rooms',
    type: 'room',
    link: '/rooms',
  },
  {
    label: 'Likes',
    type: 'like',
    link: '/likes',
  },
];
const MOBILE_AUTH_BUGER_MENU_LIST: SjMobileMenuItem[] = (DESKTOP_MENU_LIST as SjMobileMenuItem[]).concat([
  { renderComp: () => <Divider /> },
  {
    label: 'My Listings',
    link: '/my-listings',
    totalUnread: 0,
  },
  {
    label: 'My Profile',
    link: '/my-profile',
    totalUnread: 0,
  },
  { renderComp: () => <Divider /> },
  { renderComp: () => <SjGlobalCurrency type="full" /> },
  { renderComp: () => <Typography>Version: {version}</Typography> },
  { renderComp: () => <LogOutButton /> },
]);

function SjNavBar({ onLayout }: { onLayout?: (event: { height: number }) => any }) {
  const dispatch = useDispatch();
  const isDownSm = useBreakpoints((breakpoints) => breakpoints.down('sm'));
  const isDownMd = useBreakpoints((breakpoints) => breakpoints.down('md'));
  const auth = useSelector((state: IReduxState) => state.auth);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);

  const { unreadMessageCount: unReadCount } = useContext(MainContext);
  const totalChatUnread = Object.entries(unReadCount)
    .map(([key, value]) => ({
      ...value,
      key,
    }))
    .filter((item) => item.key !== 'rooms')
    .reduce((partialSum, a) => {
      if (typeof a.total === 'number') return partialSum + a.total;
      return partialSum;
    }, 0);

  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const menus = (() => {
    const mobileMenu = cloneDeep(MOBILE_AUTH_BUGER_MENU_LIST) as IMenuItemProps[];
    const desktopMenu = cloneDeep(DESKTOP_MENU_LIST) as IMenuItemProps[];

    if (auth.user) {
      mobileMenu[0].totalUnread = totalChatUnread;
      desktopMenu[0].totalUnread = totalChatUnread;

      mobileMenu[1].totalUnread = unReadCount.rooms.total;
      desktopMenu[1].totalUnread = unReadCount.rooms.total;

      return { mobile: mobileMenu, desktop: desktopMenu };
    }
    return { mobile: cloneDeep(MOBILE_UNAUTH_BUGER_MENU_LIST), desktop: desktopMenu };
  })();

  const handleLogout = () => {
    dispatch(userLogout());
    navigate('/');
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    const nextWrapperHeight = wrapperRef.current?.clientHeight ?? 0;
    onLayout?.({ height: nextWrapperHeight });
    setWrapperHeight(nextWrapperHeight);
    return () => setWrapperHeight(0);
  });

  const renderButtonCreate = (buttonCreateProps: { text: string }) => (
    <Button
      type="button"
      variant="contained"
      onClick={() => navigate('/create/deal')}
      sx={{ fontSize: 14, py: 1, ml: '3px', ...(isDownSm && { px: '0px !important' }) }}
    >
      {buttonCreateProps.text}
    </Button>
  );

  const isCreateDealPage = location.pathname !== '/create/deal';
  const isHomePage = location.pathname === '/';

  return (
    <>
      <Box height={wrapperHeight} />
      <AppBar component="nav" sx={{ backgroundColor: scroll || location.pathname !== '/' ? colors.Primary : 'transparent' }}>
        <Box ref={wrapperRef} flex={1}>
          <Box sx={{ bgcolor: colors.Primary500 }}>
            <Container>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={2}>
                  <SjMobileMenu menuItems={menus.mobile} />

                  <Link href="/" sx={{ '&:hover': { color: 'inherit' }, marginLeft: '0 !important' }}>
                    <img src={`${process.env.PUBLIC_URL}/sj-logo.png`} alt="List & Deal" height={isDownSm ? 46 : 55} />
                  </Link>
                  {auth.user?.role !== UserType.Superadmin && <SjDesktopMenu menuItems={menus.desktop as any} />}
                </Stack>

                <Stack direction="row" alignItems="center" spacing={2}>
                  <Stack sx={{ flexDirection: 'row' }} alignItems="center" justifyContent="space-between" gap={[1, 1, 1, 2, 2]}>
                    {!isHomePage && auth.user?.role !== UserType.Superadmin && <SjSearchField type={isDownMd ? 'icon' : 'text-field'} />}
                    {!isDownMd && auth.user && auth.user?.role !== UserType.Superadmin && isCreateDealPage && renderButtonCreate({ text: 'Create Listing' })}
                    {!auth.user && location.pathname !== '/login' && (
                      <Button type="button" variant="text" onClick={() => navigate('/login')} sx={{ ml: -1, display: setDisplayDesktopOnly('block') }}>
                        Log in
                      </Button>
                    )}
                    {!auth.user && location.pathname !== '/sign-up' && (
                      <Button variant="contained" type="button" sx={{ padding: [1, 1, '8px 25px', '8px 25px'] }} onClick={() => navigate('/sign-up')}>
                        Sign up
                      </Button>
                    )}
                    {auth.user && isDownMd && renderButtonCreate({ text: '+ Listing' })}
                    {auth.user && !isDownMd && <SjUserMenu user={auth.user} handleLogout={handleLogout} />}
                  </Stack>
                </Stack>
              </Stack>
            </Container>
          </Box>
        </Box>
      </AppBar>
    </>
  );
}

export default SjNavBar;
