/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Checkbox, Divider, List, ListItem, Typography, FormControlLabel, Link, InputAdornment } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { isAlphaNumericUnderscoreHyphenDot, isEmail, isPasswordStrength } from 'utils/regex';
import SjInput from 'components/Form/SjInput';
import { IReduxState } from 'interface/redux';
import Grid from '@mui/material/Unstable_Grid2';
import { UserType } from 'interface/user';

import { getCategories, getCountries } from 'apis/public';
import { showToast } from 'redux/toast/action';
import authProvider from 'apis/auth';
import SjRemote from 'components/Form/SjRemote';
import colors from 'constants/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { usernameHelpText, maxUsernameCharacter, minUsernameCharacter } from 'constants/index';

export const initSignupForm = {
  email: '',
  username: '',
  password: '',
  confirm_password: '',
  role: UserType.Personal,
  first_name: '',
  last_name: '',
  company_name: '',
  country_id: null,
  business_reg_no: '',
  business_categories: [],
  interests: [],
};

export type ISignupForm = {
  email: string;
  username: string;
  password: string;
  confirm_password: string;
  business_categories: string[];
  role: UserType;
  first_name: string;
  last_name: string;
  company_name: string;
  country_id: any;
  business_reg_no: string;
  interests: any[];
};

function SignupForm({ formType, setEmail }: { formType: UserType; setEmail: (email: string) => void }) {
  const navigate = useNavigate();

  const [isAgree, setAgree] = useState(false);
  const { auth } = useSelector((state: IReduxState) => state);
  const [isUniqueUsername, setIsUniqueUsername] = useState<any>();
  const signupFormRef = useRef<FormikProps<ISignupForm>>(null);
  const dispatch = useDispatch();

  const submit = (values: ISignupForm) => {
    if (!isUniqueUsername) return;
    authProvider
      .signup({
        ...values,
        country_id: values.country_id.id,
        business_categories: values.business_categories.map((item: any) => item.id),
        interests: values.interests.map((item: any) => item.id),
      })
      .then(() => {
        setEmail(values.email);
      })
      .catch((err) => {
        dispatch(
          showToast({
            type: 'error',
            title: err.response.data.error.message ?? 'Oops! Something went wrong!.',
          }),
        );
        signupFormRef.current?.setSubmitting(false);
      });
  };

  const validateBussinessSignup = ({ company_name, business_reg_no, business_categories }: ISignupForm) => {
    const errors: FormikErrors<ISignupForm> = {};
    if (!company_name) {
      errors.company_name = 'Please enter your company.';
    }
    if (!business_reg_no) {
      errors.business_reg_no = 'Please enter your Business Reg. No.';
    }
    return errors;
  };

  const validatePersonalSignup = ({ first_name, last_name, country_id, interests }: ISignupForm) => {
    const errors: FormikErrors<ISignupForm> = {};
    if (!first_name) {
      errors.first_name = 'Please enter your first name.';
    }
    if (!last_name) {
      errors.last_name = 'Please enter your last name.';
    }
    if (!country_id?.id) {
      errors.country_id = 'Please choose your country';
    }

    return errors;
  };

  const validate = (values: ISignupForm) => {
    const { email, password, confirm_password, country_id, username, interests } = values;
    const errors: FormikErrors<ISignupForm> = {};
    let businessError = {};
    let personalError = {};
    if (!country_id) {
      errors.country_id = 'Please select your country.';
    }
    if (!username) {
      errors.username = 'Please enter username.';
    } else if (username.length < minUsernameCharacter || !isAlphaNumericUnderscoreHyphenDot(username) || username.length > maxUsernameCharacter) {
      errors.username = usernameHelpText;
    } else if (typeof isUniqueUsername === 'boolean' && !isUniqueUsername) {
      errors.username = 'This username is already taken.';
    }
    if (interests.length === 0) {
      errors.interests = 'Select up to 5 interests — This will help us recommend relevant listings to you.';
    }
    if (formType === UserType.Business) {
      businessError = validateBussinessSignup(values);
    } else {
      personalError = validatePersonalSignup(values);
    }
    if (!isPasswordStrength(password)) {
      const message = 'Please ensure you follow the password rules.';
      errors.password = message;
    }
    if (confirm_password !== password) {
      errors.confirm_password = 'Please ensure the Password and Confirm Password fields are the same.';
    }
    if (!email) {
      errors.email = 'Please enter email.';
    } else if (!isEmail(email)) {
      errors.email = 'Invalid email address.';
    }
    return {
      ...errors,
      ...personalError,
      ...businessError,
    };
  };

  useEffect(() => {
    if (auth.user) {
      navigate('/');
    }
  }, [auth.user]);

  const onUsernameCheck = async (text: string) => {
    if (text.length < 3 || !isAlphaNumericUnderscoreHyphenDot(text) || text.length > 30) return;
    const resp = await authProvider.checkUniqueUsername(text);
    setIsUniqueUsername(!resp.exists);
    if (resp.exists) {
      signupFormRef.current?.setFieldError('username', 'This username is already taken.');
    } else {
      signupFormRef.current?.setFieldError('username', undefined);
    }
  };

  return (
    <Formik
      initialValues={{
        ...initSignupForm,
        role: formType,
      }}
      onSubmit={submit}
      validate={validate}
      innerRef={signupFormRef}
      enableReinitialize
    >
      {({ handleSubmit, isSubmitting, isValid, values, errors, touched, setErrors, setFieldTouched }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} mb={2} direction={['column', 'column', 'row']}>
              <Grid xs={12} md={6}>
                <SjInput name="email" label="Email*" placeholder="Enter email" mb={2} />
              </Grid>
              <Grid xs={12} md={6}>
                <SjInput
                  name="username"
                  label="Username*"
                  placeholder="Enter username"
                  mb={2}
                  endAdornment={
                    typeof isUniqueUsername === 'boolean' &&
                    isUniqueUsername && (
                      <InputAdornment position="end">
                        <CheckCircleIcon color="success" />
                      </InputAdornment>
                    )
                  }
                  onBlur={onUsernameCheck}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <SjInput
                  name="password"
                  label="Password*"
                  placeholder="Enter password"
                  type="password"
                  mb={2}
                  tooltip={
                    <Box padding={2}>
                      <Typography fontWeight={700} fontSize={12} mb={1}>
                        PASSWORD MUST CONTAIN AT LEAST:
                      </Typography>
                      <List>
                        <ListItem sx={{ py: 0.5 }}>• 8 characters</ListItem>
                        <ListItem sx={{ py: 0.5 }}>• 1 number</ListItem>
                        <ListItem sx={{ py: 0.5 }}>• 1 special character ($ % &, etc)</ListItem>
                        <ListItem sx={{ py: 0.5 }}>• 1 uppercase letter</ListItem>
                      </List>
                    </Box>
                  }
                />
              </Grid>
              <Grid xs={12} md={6}>
                <SjInput name="confirm_password" label="Confirm Password*" placeholder="Confirm Password" type="password" mb={2} />
              </Grid>
            </Grid>
            <Divider />
            {formType === UserType.Personal && (
              <Grid container spacing={2} mt={2} direction={['column', 'column', 'row']}>
                <Grid xs={12} md={6}>
                  <SjInput name="first_name" label="First Name*" placeholder="Enter First Name" mb={2} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SjInput name="last_name" label="Last Name*" placeholder="Enter Last Name" mb={2} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SjRemote name="country_id" label="Country*" getData={getCountries} mb={2} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SjRemote
                    name="interests"
                    label="Interests*"
                    getData={getCategories}
                    isMultiple
                    mb={3}
                    max={5}
                    helpText="Select up to 5 interests — This will help us recommend relevant listings to you."
                  />
                </Grid>
              </Grid>
            )}
            {formType === UserType.Business && (
              <Grid container spacing={2} mt={2} direction={['column', 'column', 'row']}>
                <Grid xs={12} md={6}>
                  <SjInput name="company_name" label="Company Name*" placeholder="Enter Company Name" />
                </Grid>
                <Grid xs={12} md={6}>
                  <SjRemote name="country_id" label="Country*" getData={getCountries} />
                </Grid>
                <Grid xs={12} md={6}>
                  <SjInput name="business_reg_no" label="Business Reg. No.*" placeholder="Enter registration no." />
                </Grid>
                <Grid xs={12} md={6}>
                  <SjRemote
                    name="interests"
                    label="Business Category*"
                    getData={getCategories}
                    isMultiple
                    mb={3}
                    max={5}
                    helpText="Select up to 5 business category — This will help us recommend relevant listings to you."
                  />
                </Grid>
              </Grid>
            )}

            <Box>
              <FormControlLabel
                style={{ alignItems: 'flex-start' }}
                control={
                  <Checkbox
                    sx={{ top: '-10px' }}
                    onChange={(e) => {
                      setAgree(e.target.checked);
                    }}
                  />
                }
                label={
                  <Box>
                    I certify that I am at least 18 years old and of lawful age, understand and agree to
                    <Link sx={{ color: colors.Secondary }} href="/privacy-policy" target="_blank">
                      {` Privacy Policy `}
                    </Link>
                    and
                    <Link sx={{ color: colors.Secondary }} href="/term-and-condition" target="_blank">
                      {` Terms and Condition`}
                    </Link>
                  </Box>
                }
              />
            </Box>

            <Stack direction={['column', 'column', 'row']} justifyContent="space-between" alignItems="center" alignContent="center" mt={2}>
              <Box>
                Already have an account?{' '}
                <Link href="/login" sx={{ color: colors.Secondary }}>
                  Log in now
                </Link>
              </Box>
              <Box width={['100%', '100%', 'auto']} mt={['16px', '16px', 'unset']}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  sx={{ px: 8, mt: ['16px', '16px', 0], float: ['unset', 'unset', 'right'], width: ['100%', '100%', 'auto'] }}
                  disabled={!isAgree || isSubmitting || !isValid}
                >
                  Next
                </Button>
              </Box>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
}

export default SignupForm;
