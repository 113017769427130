import { Button, Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { IRoute, getAllRoutes } from 'app/routes';
import { useMainContext } from 'context/MainContext';
import { IReduxState } from 'interface/redux';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

function ErrorScreen() {
  const MainContext = useMainContext();
  const navigate = useNavigate();
  const routes = getAllRoutes();

  const location = useLocation();

  const isAuthenticated = useSelector((state: IReduxState) => !!state.auth?.user?.id);
  const findPathNameInRoutes = routes.findIndex((item: IRoute) => item.path === location.pathname);

  useEffect(() => {
    MainContext.setPageType('error');
    return () => MainContext.setPageType('normal');
  }, []);

  const render = () => {
    switch (true) {
      case findPathNameInRoutes < 0:
        return (
          <Typography mt={2} fontWeight={500}>
            The page is not existed
          </Typography>
        );

      case !!isAuthenticated:
        return (
          <Typography mt={2} fontWeight={500}>
            Something thing went wrong!
          </Typography>
        );

      case !isAuthenticated:
      default:
        return (
          <>
            <Typography mt={2} fontWeight={500}>
              You do not have access to this area, please log in to start using it.
            </Typography>
            <Box mt={5}>
              <Button variant="contained" onClick={() => navigate('/login')}>
                LOG IN
              </Button>
              <Typography mt={2}>
                Don't have an account?{' '}
                <Button type="button" variant="text" onClick={() => navigate('/sign-up')}>
                  SIGN UP
                </Button>
              </Typography>
            </Box>
          </>
        );
    }
  };

  return (
    <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      <Typography variant="h2">Oops...</Typography>
      {render()}
    </Container>
  );
}

export default ErrorScreen;
