import { Box, Button, Divider, InputLabel, Typography, Link, Stack, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { createDeal, editDeal, getDealById } from 'apis/deal';
import { getCategories, getCities, getCountries, getCurrencies, getRegions } from 'apis/public';

import SjDatePicker from 'components/Form/SjDatePicker';
import SjFile from 'components/Form/SjMultiFile';
import SjInput from 'components/Form/SjInput';
import SjRadio from 'components/Form/SjRadio';
import SjRemote from 'components/Form/SjRemote';
import dayjs from 'dayjs';

import { Formik, FormikProps } from 'formik';
import { DEALTYPE } from 'interface/deal';

import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { showToast } from 'redux/toast/action';
import { getChangedValues } from 'utils/getChanges';
import { formatMoney } from 'utils/formatMoney';
import SjMultiEmail from 'components/Form/SjMultiEmail';
import SjTabsScreen from 'components/Profile/SjTabsScreen';
import { useBreakpoints } from 'themes';
import colors from 'constants/colors';
import SjBlockUnsavedFormModal from 'components/Form/SjBlockUnsavedFormModal';
import SjHelmet from 'components/Layout/SjHelmet';

const errorDescription = [
  {
    field: 'category_ids',
    description: 'Please select at least one business category.',
  },
  {
    field: 'region_ids',
    description: 'Please select at least one region.',
  },
  {
    field: 'country_ids',
    description: 'Please select at least one country.',
  },
];

const validate = (values: any) => {
  const errors: any = {};

  if (!values.name) {
    errors.name = 'Listing Title is required.';
  }
  if (!values.description) {
    errors.description = 'Description is required.';
  }
  errorDescription.forEach(({ field, description }) => {
    if (!values[field] || values[field].length === 0) {
      errors[field] = description;
    }
  });
  if (!values.currency_id) {
    errors.currency_id = 'Please select at least one currency.';
  }

  const minPrice = +values.min_price.toString().split(',').join('');
  const maxPrice = +values.max_price.toString().split(',').join('');

  if (minPrice === 0) {
    errors.min_price = 'Min price is required.';
  }
  if (!values.max_price) {
    errors.max_price = 'Max price is required.';
  }
  if (minPrice > maxPrice) {
    errors.min_price = 'Min price should be lower than max price';
  }
  if (values.photos?.length === 0) {
    errors.photos = 'Please add at least one photo for this listing';
  }
  if (values.invitees) {
    const isInvalidInvitees = values.invitees.find((item: any) => item.isInvalid);
    if (isInvalidInvitees) {
      errors.invitees = 'Please enter valid email.';
    }
  }
  if (values.available_until) {
    if (dayjs(values.available_until).format('DD-MM-YYYY') === 'Invalid Date') {
      errors.available_until = 'Please enter a valid date.';
    } else if (dayjs(values.available_until).isBefore(dayjs())) {
      errors.available_until = 'Please enter a future date.';
    }
  }

  if (values.invitees.length > 0) {
    const isDuplicated = values.invitees.find((item: any) => item.isDuplicated);
    if (isDuplicated) {
      errors.invitees = 'Duplicate email.';
    }
    const isAlreadyInvited = values.invitees.find((item: any) => item.isAlreadyInvited);
    if (isAlreadyInvited) {
      errors.invitees = 'User has already joined this room.';
    }

    const isInvalid = values.invitees.find((item: any) => item.isInvalid);
    if (isInvalid) {
      errors.invitees = 'Invalid user';
    }
  }

  return errors;
};

export interface IDealForm {
  category_ids: number[];
  region_ids: any;
  country_ids: number[];
  city_ids: number[];
  description: string;
  currency_id: string;
  max_price: number;
  min_price: number;
  name: string;
  photos: string[];
  private: boolean;
  type: string;
  invitees?: string;
  available_until?: string;
}

const initDeal = {
  category_ids: [],
  region_ids: [],
  country_ids: [],
  city_ids: [],
  description: '',
  currency_id: '',
  max_price: '',
  min_price: '',
  name: '',
  photos: [],
  private: false,
  type: '',
  invitees: '',
  available_until: '',
};

function DealForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const isDownLg = useBreakpoints((breakpoints) => breakpoints.down('lg'));

  const dealFormRef = useRef<FormikProps<IDealForm>>(null);
  const [deal, setDeal] = useState<any>(initDeal);

  useEffect(() => {
    if (id) {
      dealFormRef.current?.setStatus(false);
      getDealById(id, true).then((resp) => {
        setDeal({
          name: resp.name,
          type: resp.type,
          max_price: formatMoney(resp.max_price),
          min_price: formatMoney(resp.min_price),
          description: resp.description,
          private: resp.private,
          photos: resp.photos,
          invitees:
            resp.invitees?.map((item) => ({
              email: item.user_email,
            })) ?? [],
          category_ids: resp.categories,
          city_ids: resp.cities,
          region_ids: resp.regions,
          country_ids: resp.countries,
          currency_id: {
            ...resp.currency,
            name: `${resp.currency.symbol} (${resp.currency.code})`,
          },
          available_until: resp.available_until,
        });
        dealFormRef.current?.setStatus(true);
      });
    } else {
      setDeal(initDeal);
    }
  }, [id]);

  const doSubmit = (func: any, data: any, setSubmitting: any) => {
    const action = data.id ? 'updated' : 'created';
    func(data)
      .then((resp: any) => {
        dispatch(
          showToast({
            type: 'success',
            title: `Deal has been ${action} successfully`,
          }),
        );
        navigate(`/deal/view/${resp.id}`);
      })
      .catch((error: any) => {
        setSubmitting(false);
        dispatch(
          showToast({
            type: 'error',
            title: `Can not ${action} deal.`,
            description: error.response?.data.error.message ?? 'Please contact with administrator for more detail.',
          }),
        );
      });
  };

  const submit = (values: IDealForm, { setSubmitting }: any) => {
    const changedProperty = getChangedValues(
      {
        ...values,
        min_price: +values.min_price.toString().split(',').join(''),
        max_price: +values.max_price.toString().split(',').join(''),
        id,
      },
      deal,
    );
    const postData: any = {
      ...changedProperty,
    };
    if (changedProperty.available_until) {
      postData.available_until = dayjs(postData.available_until).add(1, 'day').toISOString();
    }
    if (!values.private || !values.invitees) {
      delete postData.invitees;
    }
    if (changedProperty.currency_id) {
      postData.currency_id = changedProperty.currency_id.id;
    }
    if (changedProperty.invitees) {
      postData.invitees = changedProperty.invitees.map((item: any) => item.email);
    }
    if (changedProperty.photos) {
      postData.photos = changedProperty.photos.map((item: any) => (typeof item === 'string' ? item : item.url));
    }
    const fields = ['region_ids', 'country_ids', 'city_ids', 'category_ids'];
    fields.forEach((f) => {
      if (changedProperty[f]) {
        postData[f] = changedProperty[f].map((item: any) => item.id);
      }
    });

    doSubmit(id ? editDeal : createDeal, postData, setSubmitting);
  };

  const renderMainForm = ({ form }: { form: any }) => {
    return (
      <>
        <SjInput name="name" placeholder="Name your listing" label="Listing Title*" />
        <SjRadio name="type" label="Listing Type*" options={Object.values(DEALTYPE)} />
        <SjRemote
          getData={getCategories}
          valueExp="id"
          displayExp="name"
          name="category_ids"
          label="Business Category*"
          isMultiple
          placeholder="Select Category"
          helpText="This will help us recommend relevant listings to you."
        />

        <Grid container spacing={2} px={0} pt={0}>
          <Grid xs={12} lg={4} py={0}>
            <SjRemote isMultiple getData={getRegions} valueExp="id" displayExp="name" name="region_ids" label="Region*" placeholder="Select Region" />
          </Grid>
          <Grid xs={12} lg={4} py={0}>
            <SjRemote
              getData={getCountries}
              externalFilter={[
                {
                  field: 'region_ids',
                  name: 'region_id',
                  value: form.values.region_ids.map((item: any) => item.id),
                },
              ]}
              valueExp="id"
              displayExp="name"
              name="country_ids"
              label="Country*"
              isMultiple
              disabled={!form.values.region_ids}
              placeholder="Select Country"
            />
          </Grid>
          <Grid xs={12} lg={4} py={0}>
            <SjRemote
              getData={getCities}
              externalFilter={[
                {
                  field: 'country_ids',
                  name: 'country_id',
                  value: form.values.country_ids.map((item: any) => item.id),
                },
              ]}
              valueExp="id"
              displayExp="name"
              name="city_ids"
              label="City"
              isMultiple
              disabled={form.values.country_ids.length === 0}
              placeholder="Select City"
            />
          </Grid>
        </Grid>
        <Box mb="24px">
          <InputLabel shrink>Price*</InputLabel>
          <Stack flexDirection="row" mb="8px">
            <Stack flex={[1.5, 1.5, 2]} mr="16px">
              <SjRemote getData={getCurrencies} valueExp="id" displayExp="name" name="currency_id" mb={0} />
            </Stack>
            <Stack flex={[1, 1, 3]}>
              <SjInput name="min_price" placeholder="Min" mode="currency" mb={0} />
            </Stack>
            <Divider sx={{ background: colors.BaseSecondaryText, mt: '24px', mx: '16px', width: '14px', height: '1px' }} />
            <Stack flex={[1, 1, 3]}>
              <SjInput name="max_price" placeholder="Max" mode="currency" mb={0} />
            </Stack>
          </Stack>
          <Typography fontSize={16} color={colors.BaseSecondaryText}>
            Don’t have an exact price? Put an estimated price so customers will know what to expect.
          </Typography>
        </Box>
        <SjInput name="description" type="textarea" placeholder="Describe what you are selling or looking for!" label="Listing Description*" />
        <SjDatePicker name="available_until" label="Available Until" placeholder="Select Date" minDate={!id ? dayjs().toString() : undefined} />
        <Divider />
        <Box my={4}>
          <SjInput type="checkbox" label="Make this a private listing." name="private" mb="6px" />
          <Typography fontSize={16} ml="32px">
            Only the invited participants will be able to see your listing. You may manage your invitees on your Listing Details page.
          </Typography>
        </Box>
        {form.values.private && (
          <SjMultiEmail
            name="invitees"
            placeholder="Enter email"
            label="List of invitees"
            helpText="Separate email addresses by comma."
            // valueExp="user_email"
            // displayExp="user_email"
          />
        )}
        <Box height="29px" />
      </>
    );
  };

  const warningText = (
    <>
      Unlawful or illicit activity is strictly prohibited on List & Deal. You may view a list of some prohibited content{' '}
      <Link href="/term-and-condition" color={colors.Secondary} sx={{ textDecoration: 'underline', fontWeight: 500 }}>
        here
      </Link>
      .
    </>
  );

  return (
    <>
      <SjHelmet title={id ? 'Edit listing' : 'Create a listing'} />
      <Formik initialValues={deal} onSubmit={submit} innerRef={dealFormRef} enableReinitialize validate={validate}>
        {({ handleSubmit, isSubmitting, values, status }) => (
          <form onSubmit={handleSubmit}>
            <SjTabsScreen
              leftGridProps={{ lg: 4 }}
              rightGridProps={{ lg: 8 }}
              title={id ? [{ name: deal.name, link: `/deal/view/${id}` }, { name: 'Edit' }] : [{ name: 'Create a deal' }]}
              left={
                <>
                  <Typography variant="h2" marginBottom={[4, 4, 4, 6]}>
                    {id ? 'Edit' : 'Create'} Listing
                  </Typography>

                  <SjFile
                    isMulti
                    name="photos"
                    accept=""
                    maxFile={10}
                    deal={id}
                    type={isDownLg ? 'carousel' : 'grid'}
                    carouselStackProps={{ ml: '-24px', my: '29px' }}
                  />
                  {isDownLg && renderMainForm({ form: { handleSubmit, isSubmitting, values, status } })}
                </>
              }
              right={
                !isDownLg && (
                  <>
                    {renderMainForm({ form: { handleSubmit, isSubmitting, values, status } })}
                    <Button
                      type="submit"
                      fullWidth={false}
                      variant="contained"
                      sx={{ mt: '40px', px: '64px', py: '16px', float: 'right' }}
                      disabled={isSubmitting}
                    >
                      Publish
                    </Button>
                  </>
                )
              }
            />

            {isDownLg ? (
              <Box
                sx={{
                  boxShadow: '0px 4px 21px rgba(105, 88, 26, 0.15);',
                  position: 'fixed',
                  bottom: 0,
                  right: 0,
                  left: 0,
                  bgcolor: colors.White,
                  p: '16px',
                }}
              >
                <Button type="submit" fullWidth variant="contained" disabled={isSubmitting}>
                  Publish
                </Button>

                <Typography mt="12px" textAlign="center" variant="body2">
                  {warningText}
                </Typography>
              </Box>
            ) : (
              <Container>
                <Grid container spacing={6} m={0}>
                  <Grid xs={12} lg={4} />
                  <Grid xs={12} lg={8} p={0} m={0} pl="calc(var(--Grid-rowSpacing) / 2)">
                    <Typography variant="body2" sx={{ overflow: 'hidden', whiteSpace: 'pre-wrap', textAlign: 'left' }}>
                      {warningText}
                    </Typography>
                  </Grid>
                </Grid>
              </Container>
            )}

            <SjBlockUnsavedFormModal />
          </form>
        )}
      </Formik>
    </>
  );
}

export default DealForm;
