import { Box, Button, Typography } from '@mui/material';
import authProvider from 'apis/auth';
import { getMyDeals } from 'apis/deal';
import SjHelmet from 'components/Layout/SjHelmet';
import ListingStatusPicker from 'components/Listing/ListingStatusPicker';
import ListingsComponent from 'components/Listing/Listings';
import ProfileInfo from 'components/Profile/ProfileInfo';
import SjTabsScreen from 'components/Profile/SjTabsScreen';
import IDeal from 'interface/deal';
import { IFilter } from 'interface/filter';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBreakpoints } from 'themes';
import { Item } from 'utils/types';
import useQuery from 'utils/useQuery';

const LISTING_FILTER = [
  {
    title: 'All',
    value: '',
    tooltip: 'All listings.',
  },
  {
    title: 'Open',
    value: 'OPEN',
    tooltip: 'The listings which are open.',
  },
  {
    title: 'Ongoing',
    value: 'ON_GOING',
    tooltip: 'The open listings which are in active deals.',
  },
  {
    title: 'Closed',
    value: 'CLOSED',
    tooltip: 'Include the Closed listings.',
  },
  {
    title: 'Expired',
    value: 'EXPIRED',
    tooltip: 'Include the Expired listings.',
  },
];
function MyProfile() {
  const navigate = useNavigate();
  const query = useQuery();
  const isUpLg = useBreakpoints((breakpoints) => breakpoints.up('lg'));

  const type = query.get('type');
  const title = type === 'SHARED' ? 'Shared with me' : 'My Listings';

  const [deals, setDeals] = useState<IDeal[] | []>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOpenStatusDrawer, setOpenStatusDrawer] = useState(false);
  const [filter, setFilter] = useState<IFilter>({
    p: 1,
    l: 24,
    s: 'created_at',
    type: 'MINE',
  });
  const [status, setStatus] = useState(LISTING_FILTER[0]);

  const [profile, setProfile] = useState<any>({});

  const onOpenDrawer = (nextOpenStatus: boolean) => () => setOpenStatusDrawer(nextOpenStatus);
  const onClickStatus = (nextStatus: Item<typeof LISTING_FILTER>) => () => {
    setStatus(nextStatus);
    if (isUpLg) return;
    setOpenStatusDrawer(false);
  };

  useEffect(() => {
    if (type === 'SHARED') {
      setFilter({
        ...filter,
        type,
      });
    } else {
      setFilter({
        ...filter,
        type: 'MINE',
      });
    }
  }, [type]);

  useEffect(() => {
    authProvider.userMe().then((resp) => {
      setProfile(resp);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    const updateFilter = {
      ...filter,
    };
    if (status.value) {
      updateFilter.status = status.value;
    }
    getMyDeals(updateFilter).then((resp) => {
      setLoading(false);
      setDeals(resp.data);
      setTotal(resp.total_count);
    });
  }, [filter, status]);

  const updateFilter = (t: string, v: string | number) => {
    setFilter({
      ...filter,
      [t]: v,
    });
  };

  return (
    <>
      <SjHelmet title="My Listing" />
      <SjTabsScreen
        title={title}
        left={
          <ProfileInfo
            profile={{
              ...profile,
              fullname: `${profile?.first_name} ${profile.last_name}`,
            }}
            isMyProfile={true}
          />
        }
        right={
          <>
            {isUpLg && (
              <Typography variant="h2" mb={2}>
                {title}
              </Typography>
            )}

            <ListingStatusPicker
              target={type as (typeof ListingStatusPicker)['Target']}
              isOpenDrawer={isOpenStatusDrawer}
              data={LISTING_FILTER}
              value={status}
              onOpenDrawer={onOpenDrawer}
              onClickStatus={onClickStatus}
            />

            <Box mt={2}>
              <ListingsComponent
                type={isUpLg ? 'multiple-columns' : 'list'}
                deals={deals}
                total={total}
                page={filter.p}
                size={filter.l}
                columns={4}
                hideShadow
                actions={['view_chat', 'view_chat']}
                loading={loading}
                hideOwner
                noResult={
                  type === 'SHARED' || status.value === 'OPEN_ON_GOING' || status.value === 'CLOSED' ? (
                    <Box textAlign="center" mt={10}>
                      <Typography variant="h4" mb={2}>
                        No listing found
                      </Typography>
                    </Box>
                  ) : (
                    <Box textAlign="center" mt={10}>
                      <Typography variant="body1">You have not created any listings yet.</Typography>
                      <Button variant="contained" onClick={() => navigate('/create/deal')} sx={{ px: '32px', py: '16px', mt: '24px' }}>
                        Create Listing
                      </Button>
                    </Box>
                  )
                }
                onPaginationChange={(event: React.ChangeEvent<unknown>, page: number) => updateFilter('p', page)}
                itemProps={{ StackSx: { p: 0 } }}
              />
            </Box>
          </>
        }
      />
    </>
  );
}

export default MyProfile;
