import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from 'react';
import IDeal from 'interface/deal';
import ListingsComponent from 'components/Listing/Listings';
import { useDispatch } from 'react-redux';

import { getDeals } from 'apis/deal';
import { IFilter } from 'interface/filter';
import { showToast } from 'redux/toast/action';
import { Box, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import { useMainContext } from 'context/MainContext';
import SjHelmet from 'components/Layout/SjHelmet';

const initFilter = { p: 1, l: 24, sort_by: 'newest', liked: true };

function LikesPage() {
  const MainContext = useMainContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [listing, setListing] = useState<[] | IDeal[]>([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState<IFilter>(initFilter);

  const [loading, setLoading] = useState(false);

  const loadDeals = () => {
    getDeals(filter)
      .then((resp) => {
        setListing(resp.data);
        setTotal(resp.total_count);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          showToast({
            type: 'error',
            title: 'Failed to fetch data',
          }),
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    loadDeals();
  }, [filter]);

  const paginationNumber = () => {
    if (total < filter.l) {
      return 1;
    }
    if (!Number.isInteger(total / filter.l)) {
      return Math.round(total / filter.l) + 1;
    }
    return total / filter.l;
  };

  const handleUpdatesList = (id: string, changedValues: any) => {
    const index = listing.findIndex((item: any) => item.id === id);
    if (index > -1) {
      const uRows = [...listing];
      changedValues.forEach((item: any) => {
        uRows[index] = {
          ...uRows[index],
          [item.field]: item.value,
        };
      });
      const likedRows = uRows.filter((item) => item.liked);
      setListing(likedRows);
    }
  };

  useEffect(() => {
    if (listing?.length || loading) {
      MainContext.setPageType('normal');
      return;
    }
    MainContext.setPageType('no-result');
    return () => MainContext.setPageType('normal');
  }, [listing?.length, loading]);

  return (
    <Container sx={{ mt: 3, ...(!listing?.length && !loading && { display: 'flex', flexDirection: 'column' }) }}>
      <SjHelmet title="Likes" />
      <SJBreadcrumbs title="Likes" />
      <Typography variant="h2" mb={5}>
        Likes
      </Typography>
      <Box mb={2}>
        <ListingsComponent
          deals={listing}
          loading={loading}
          actions={['like', 'share']}
          onUpdated={handleUpdatesList}
          noResult={
            <Stack textAlign="center" flex={1} justifyContent="center" alignItems="center">
              <Typography variant="h3" my={2}>
                No liked listing yet.
              </Typography>
              <Button variant="contained" onClick={() => navigate('/search')}>
                Browser Listings
              </Button>
            </Stack>
          }
        />
        {filter.p > 1 && (
          <Pagination
            count={paginationNumber()}
            page={filter.p}
            sx={{ flex: 1, mt: 2 }}
            onChange={(event: React.ChangeEvent<unknown>, page: number) => {
              setFilter({
                ...filter,
                p: page,
              });
            }}
          />
        )}
      </Box>
    </Container>
  );
}

export default LikesPage;
