/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-param-reassign */
import produce, { Draft } from 'immer';
import { IReduxAuthState, IReduxBaseAction } from '../../interface/redux';
import {
  USER_CHECK,
  USER_CHECK_ERROR,
  USER_CHECK_SUCCESS,
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_VERIFY_OTP_ERROR,
  USER_LOGIN_VERIFY_OTP_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_LOGOUT_SUCCESS,
  USER_UPDATE_ME,
  USER_UPDATE_ME_ERROR,
  USER_UPDATE_ME_SUCCESS,
  USER_CLEAR_ACTION_STATUS,
  USER_TOKEN_REFRESH,
  USER_TOKEN_REFRESH_SUCCESS,
  USER_TOKEN_REFRESH_FAILURE,
} from './types';

export const initialState = {
  error: null,
  pending: false,
  user: null,
  isChecked: false,
  isUpdated: false,
  isRefreshed: false,
};

const authReducer = (state: IReduxAuthState = initialState, action: IReduxBaseAction) =>
  produce(state, (draft: Draft<IReduxAuthState>) => {
    switch (action?.type) {
      case USER_CHECK:
        draft.pending = true;
        draft.error = null;
        draft.user = null;
        break;
      case USER_CHECK_ERROR:
        draft.pending = false;
        draft.user = null;
        draft.isChecked = true;
        break;
      case USER_CHECK_SUCCESS:
        draft.pending = false;
        draft.error = false;
        draft.isChecked = true;
        draft.user = action.payload;
        break;
      case USER_LOGIN:
        draft.pending = true;
        draft.error = null;
        draft.user = null;
        draft.isChecked = false;
        break;
      case USER_LOGIN_SUCCESS:
        draft.user = action.payload;
        draft.pending = false;
        draft.error = null;
        break;
      case USER_LOGIN_ERROR:
        draft.pending = false;
        draft.error = action.error;
        draft.user = null;
        break;
      case USER_LOGOUT_SUCCESS:
      case USER_LOGOUT_FAILURE:
        draft.pending = false;
        draft.error = null;
        draft.user = null;
        draft.isChecked = true;
        break;
      case USER_LOGIN_VERIFY_OTP_SUCCESS:
        draft.pending = false;
        draft.error = false;
        draft.user = action.payload.user;
        break;
      case USER_LOGIN_VERIFY_OTP_ERROR:
      case USER_UPDATE_ME_ERROR:
        draft.pending = false;
        draft.error = true;
        break;
      case USER_UPDATE_ME:
        draft.pending = true;
        draft.error = null;
        break;
      case USER_UPDATE_ME_SUCCESS:
        draft.pending = false;
        draft.isUpdated = true;
        draft.user = action.payload;
        break;
      case USER_CLEAR_ACTION_STATUS:
        draft.pending = false;
        draft.isUpdated = false;
        break;
      case USER_TOKEN_REFRESH:
        draft.pending = true;
        draft.user = null;
        break;
      case USER_TOKEN_REFRESH_SUCCESS:
        draft.pending = false;
        draft.user = action.payload;
        break;
      case USER_TOKEN_REFRESH_FAILURE:
        draft.pending = false;
        draft.user = null;
        break;
      default:
    }
  });

export default authReducer;
