import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import colors from 'constants/colors';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useLocation, useNavigate } from 'react-router-dom';
import { IUser, UserType } from 'interface/user';
import { useEffect, useState } from 'react';
import { countListingTotal } from 'apis/deal';
import useQuery from 'utils/useQuery';
import { useBreakpoints } from 'themes';
import ProfileName from './ProfileName';
import SjTabs from './SjTabs';

const MY_PROFILE_TABS = [
  {
    value: 'MY_LISTINGS',
    title: 'My Listings',
    href: '/my-listings',
  },
  {
    value: 'SHARED',
    title: 'Shared With Me',
    href: '/my-listings?type=SHARED',
  },
];

function ProfileInfo({ profile, isMyProfile }: { profile: IUser; isMyProfile?: boolean }) {
  const query = useQuery();
  const type = query.get('type');

  const navigate = useNavigate();
  const location = useLocation();
  const isUpMd = useBreakpoints((breakpoints) => breakpoints.up('sm'));
  const isUpLg = useBreakpoints((breakpoints) => breakpoints.up('lg'));
  const [mineListingNumber, setMineListingNumber] = useState(0);
  const [shareListingNumber, setShareListingNumber] = useState(0);
  const [isExpandedBio, setExpandedBio] = useState(false);
  const isDisabledExpandable = (() => {
    const validFieldList = [profile?.bio, profile?.business_reg_no, profile.interests?.length, (profile.interests?.length as number) > 2].filter(
      (fieldValue) => !!fieldValue,
    );
    const hasOnlyOneField = validFieldList.filter((fieldValue) => !!fieldValue)?.length === 1;
    return hasOnlyOneField;
  })();

  const onToggleExpandBio = (nextExpandedBio: boolean) => () => {
    setExpandedBio(nextExpandedBio);
  };

  const onNavigate = (target: string) => () => navigate(target);

  useEffect(() => {
    countListingTotal().then((resp) => {
      setMineListingNumber(resp.mine);
      setShareListingNumber(resp.shared);
    });
  }, []);

  const renderListingNumber = (value: number) => {
    const upLgNumber = value ?? '';
    const downLgNumber = value ? `(${value})` : '';

    return isUpLg ? upLgNumber : downLgNumber;
  };

  const renderChild = (childProps: {
    type: 'avatar' | 'interests' | 'profile-name' | 'username' | 'country' | 'business-no' | 'bio' | 'button-contact';
    avatarSize?: number | string;
  }) => {
    const avatarSize = childProps?.avatarSize ?? 150;

    switch (childProps.type) {
      case 'avatar':
        return (
          <Box position="relative">
            <Avatar src={profile?.avatar} sx={{ width: avatarSize, height: avatarSize, margin: '0 auto' }} />
            {profile?.role === UserType.Business && (
              <Box
                borderRadius="100px"
                position="absolute"
                color={colors.White}
                bgcolor={colors.Primary700}
                fontSize="12px"
                fontWeight={700}
                display="flex"
                bottom="-10px"
                left={0}
                right={0}
                width="80px"
                justifyContent="center"
                margin="0 auto"
              >
                BUSINESS
              </Box>
            )}
          </Box>
        );

      case 'interests':
        return (
          <Typography variant="body1">
            {profile?.interests?.map((item: any, index) => (
              <Typography key={item.id} display="inline" color={colors.BaseSecondaryText}>
                {!!index && <Typography sx={{ display: 'inline', marginRight: '5px', marginLeft: '10px', color: colors.BaseSecondaryText }}>•</Typography>}
                {item.name}
              </Typography>
            ))}
          </Typography>
        );

      case 'profile-name':
        return <ProfileName profile={profile} variant="h4" sx={{ wordBreak: 'break-word', mb: 1 }} />;

      case 'username':
        return (
          <Box color="#023047" fontWeight={600}>
            @{profile.username}
          </Box>
        );

      case 'country':
        return (
          <Stack direction="row" gap={0.2}>
            <FmdGoodIcon sx={{ fill: colors.White, stroke: colors.BaseSecondaryText, mr: '2px', strokeWidth: 2 }} />
            <Typography color={colors.BaseText} fontWeight={500}>
              {profile?.country?.name}
            </Typography>
          </Stack>
        );

      case 'business-no':
        return (
          <>
            <Box color={colors.BaseSecondaryText} textTransform="uppercase" fontWeight={700} fontSize="12px">
              Business Reg. No
            </Box>
            <Box color={colors.Header}>{profile?.business_reg_no}</Box>
          </>
        );

      case 'bio':
        return <Box color={colors.Header}>{profile?.bio}</Box>;

      case 'button-contact':
        return isMyProfile ? (
          <Button variant="outlined" sx={{ width: '100%' }} onClick={onNavigate(`/my-profile?location=${location.pathname}`)}>
            My Profile
          </Button>
        ) : (
          <Button variant="outlined" sx={{ width: '100%' }} onClick={onNavigate(`/chats?tab=2&user=${profile.id}`)}>
            Send Direct Chat
          </Button>
        );

      default:
        return null;
    }
  };

  return (
    <Box mt={[2, 2, 0, 0, 0]}>
      {isUpMd ? (
        <Box textAlign="center">
          <Box mb={4}>{renderChild({ type: 'avatar' })}</Box>
          {!!profile.id && renderChild({ type: 'profile-name' })}
          {renderChild({ type: 'username' })}
          <Box color={colors.BaseSecondaryText} mt={5}>
            {profile?.interests && renderChild({ type: 'interests' })}
            <Stack my={2} alignItems="center" justifyContent="center">
              {renderChild({ type: 'country' })}
            </Stack>
            {profile?.business_reg_no && <Box my={2}>{renderChild({ type: 'business-no' })}</Box>}
            {!!profile?.bio && <Box my={2}>{renderChild({ type: 'bio' })}</Box>}
            {isMyProfile ? (
              <Button variant="outlined" sx={{ mt: 2 }} onClick={onNavigate(`/my-profile?location=${location.pathname}`)}>
                My Profile
              </Button>
            ) : (
              <Button variant="outlined" sx={{ mt: 2 }} onClick={onNavigate(`/chats?tab=2&user=${profile.id}`)}>
                Send Direct Chat
              </Button>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          <Stack flexDirection="row" justifyContent="space-between">
            <Box>{renderChild({ type: 'avatar', avatarSize: '80px' })}</Box>
            <Box flex={1} justifyContent="center" alignItems="center" ml="24px">
              {!!profile.id && renderChild({ type: 'profile-name' })}
              {renderChild({ type: 'username' })}
              <Box mt="12px">{renderChild({ type: 'country' })}</Box>
            </Box>
          </Stack>

          {!isExpandedBio && !isDisabledExpandable ? (
            <Button
              variant="text"
              sx={{
                mt: '24px',
                width: '100%',
                flexDirection: 'column',
                textTransform: 'none',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                overflow: 'hidden',
                whiteSpace: 'pre-wrap',
                textAlign: 'left',
                maxHeight: '50px',
                fontWeight: 500,
                p: 0,
              }}
              onClick={onToggleExpandBio(true)}
            >
              {renderChild({ type: 'bio' })}
              {renderChild({ type: 'interests' })}
              <Typography sx={{ position: 'absolute', bottom: '2px', right: 0, background: colors.BackGround }}>
                {profile?.bio || profile?.interests?.length ? ' ... ' : ''}
                <Typography display="inline" color={colors.Secondary}>
                  Read More
                </Typography>
              </Typography>
            </Button>
          ) : (
            <>
              {!!profile?.bio && <Box mt="24px">{renderChild({ type: 'bio' })}</Box>}
              {profile?.interests && <Box mt="24px"> {renderChild({ type: 'interests' })}</Box>}
              {profile?.business_reg_no && <Box mt="24px">{renderChild({ type: 'business-no' })}</Box>}

              {!isDisabledExpandable && (
                <Button
                  variant="text"
                  sx={{ mt: '24px', width: '100%', textTransform: 'none', justifyContent: 'flex-start', p: 0 }}
                  onClick={onToggleExpandBio(false)}
                >
                  <Typography color={colors.Secondary}>Read Less</Typography>
                </Button>
              )}
            </>
          )}
          <Box sx={{ my: '24px' }}>{renderChild({ type: 'button-contact' })}</Box>
        </Box>
      )}

      {isMyProfile && (
        <Box {...(isUpLg ? { mt: 5 } : {})}>
          <SjTabs
            data={MY_PROFILE_TABS}
            value={type ?? 'MY_LISTINGS'}
            trailing={(tab) => {
              if (tab.value === 'MY_LISTINGS') {
                return renderListingNumber(mineListingNumber);
              }
              return renderListingNumber(shareListingNumber);
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default ProfileInfo;
