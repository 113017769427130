import 'react-gallery-carousel/dist/index.css';
import Box from '@mui/material/Box';
import { DealContext } from 'context/DealContext';
import { useContext, useState } from 'react';
import { Button, IconButton, Modal, TextField, Typography } from '@mui/material';
import { Share2 } from 'react-feather';
import { modalStyle } from 'themes/modal';
import CloseIcon from '@mui/icons-material/Close';
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from 'react-share';
import { getShareContent } from 'utils/shareDeal';
import { useBreakpoints } from 'themes';

function DealShareAction() {
  const { deal } = useContext(DealContext);
  const isDownMd = useBreakpoints((breakpoints) => breakpoints.down('md'));
  const iconSize = isDownMd ? 48 : 56;
  const [isShowingShare, setIsShowingShare] = useState(false);

  const [buttonTitle, setButtonTitle] = useState('COPY LINK');

  const host = window.location.origin;
  const listingUrl = `${host}/deal/view/${deal.id}`;

  const { shareContent, emailSubject, emailContent } = getShareContent(deal.name, listingUrl);

  const showSuccess = () => {
    setButtonTitle('Copied!');
    setTimeout(() => {
      setButtonTitle('COPY LINK');
    }, 2500);
  };

  return (
    <>
      <IconButton
        sx={{
          color: 'white',
          height: '40px',
          backgroundColor: '#00000050',
          borderRadius: '6px',
          '&:hover': { backgroundColor: '#00000060' },
        }}
        type="button"
        onClick={() => setIsShowingShare(true)}
      >
        <Share2 size={22} />

        <Box sx={{ fontSize: '14px', marginLeft: 0.5, color: '#fff' }}>Share</Box>
      </IconButton>
      <Modal open={isShowingShare} onClose={() => setIsShowingShare(false)}>
        <Box sx={{ ...modalStyle, width: 475, maxWidth: '80%' }} textAlign="center">
          <IconButton
            aria-label="close"
            onClick={() => setIsShowingShare(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography mt={3} variant="h3">
            Share this listing
          </Typography>

          <Box gap={['8px', '16px']} sx={{ paddingY: '32px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <WhatsappShareButton url={shareContent}>
              <WhatsappIcon size={iconSize} borderRadius={iconSize} />
            </WhatsappShareButton>
            <FacebookShareButton url={listingUrl} quote={shareContent}>
              <FacebookIcon size={iconSize} borderRadius={iconSize} />
            </FacebookShareButton>
            <TwitterShareButton url={shareContent}>
              <TwitterIcon size={iconSize} borderRadius={iconSize} />
            </TwitterShareButton>
            <LinkedinShareButton url={listingUrl} summary={shareContent} title={emailSubject} source={listingUrl}>
              <LinkedinIcon size={iconSize} borderRadius={iconSize} />
            </LinkedinShareButton>
            <EmailShareButton url={emailContent} subject={emailSubject}>
              <EmailIcon size={iconSize} borderRadius={iconSize} />
            </EmailShareButton>
          </Box>

          <Box mb="2rem" marginX="1.5rem" display="block" alignItems="center" justifyContent="space-between" position="relative" sx={{ height: '52px' }}>
            <TextField
              // id="name"
              rows={4}
              value={`${(listingUrl || '').slice(0, 34)}...`}
              disabled={true}
              multiline={false}
              // readOnly={true}
              inputProps={{
                style: {
                  height: '52px',
                  padding: '0 130px 0 8px',
                  color: '#979797',
                },
              }}
            />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              sx={{ width: 108, top: 10, bottom: 10, right: 16, m: '0', position: 'absolute', fontSize: 14 }}
              onClick={() => {
                navigator.clipboard.writeText(listingUrl).then(() => {
                  showSuccess();
                });
              }}
            >
              {buttonTitle}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default DealShareAction;
