import SjNavBar from 'components/Layout/SjNavBar';
import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import { ReactElement, useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';

import { CHAT_TABS } from 'interface/chat';
import colors from 'constants/colors';
import { GET_UNREAD_MESSAGE, ROOM_SUBSCRIPTION } from 'apis/graphql';
import { MainContext } from 'context/MainContext';
import { useLocation } from 'react-router-dom';
import SJFooter from 'components/Layout/SjFooter';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import useWindowSize from 'utils/useWindowSize';
import SearchContext from 'context/SearchDealContext';

function MainTemplate({ children }: { children: ReactElement | ReactElement[] }) {
  const location = useLocation();
  const { data: chatSubscription } = useSubscription(ROOM_SUBSCRIPTION);

  const user = useSelector((state: IReduxState) => state?.auth?.user);

  const childrenHeights = useRef({
    header: 0,
    footer: 0,
    others: 4,
  });
  const [, windowHeight] = useWindowSize();

  const [childrenWrapperHeight, setChildrenWrapperHeight] = useState<string | number>('100%');
  const [getUnreadMessagesCount, { data: unReadMessageCount }] = useLazyQuery(GET_UNREAD_MESSAGE);
  const [pageType, setPageType] = useState<'normal' | 'error' | 'no-result' | 'chat'>('normal');
  const [unReadCount, setUnreadCount] = useState<any>({
    generalListing: {
      total: 0,
    },
    myListing: {
      total: 0,
    },
    direct: {
      total: 0,
    },
    rooms: {
      total: 0,
    },
  });

  const updateUnreadMessageCount = (chatId: string, chatType: string, count: number) => {
    const currentTab = CHAT_TABS.findIndex((item) => item.value === chatType);

    const unReadCountType = currentTab > -1 ? CHAT_TABS[currentTab].unreadField : 'rooms';
    const currentUnread = unReadCount[unReadCountType];
    const isExistedUnread = currentUnread.roomsCount && currentUnread.roomsCount?.find((item: any) => item.roomId === chatId);

    if (!isExistedUnread || isExistedUnread.unreadMessages === 0 || count === 0) {
      const roomsCount = currentUnread.roomsCount ? currentUnread.roomsCount.filter((item: any) => item.roomId !== chatId) : [];

      const room = {
        roomId: chatId,
        unreadMessages: count,
      };

      const total = count ? currentUnread.total + 1 : currentUnread.total - 1;
      setUnreadCount({
        ...unReadCount,
        [unReadCountType]: {
          total: total > 0 ? total : 0,
          roomsCount: [...roomsCount, room],
        },
      });
    }
  };

  const onLayoutChange = (type: 'header' | 'footer' | 'screen-size') => (event?: { height: number }) => {
    const targetHeight = event?.height ?? 0;
    let nextChildrenWrapperHeight = windowHeight - childrenHeights.current.others;

    switch (type) {
      case 'footer':
        childrenHeights.current.footer = targetHeight;
        nextChildrenWrapperHeight -= targetHeight;
        nextChildrenWrapperHeight -= childrenHeights.current.header;
        break;

      case 'header':
        childrenHeights.current.header = targetHeight;
        nextChildrenWrapperHeight -= targetHeight;
        nextChildrenWrapperHeight -= childrenHeights.current.footer;
        break;

      case 'screen-size':
      default:
        nextChildrenWrapperHeight -= childrenHeights.current.header;
        nextChildrenWrapperHeight -= childrenHeights.current.footer;
    }
    setChildrenWrapperHeight(nextChildrenWrapperHeight);
  };

  useEffect(() => {
    if (user)
      setTimeout(() => {
        getUnreadMessagesCount();
      }, 1000);
  }, [user]);

  useEffect(() => {
    const isNotRoomOrChatScreen = !location.pathname.includes('room') && !location.pathname.includes('chat');
    if (chatSubscription && isNotRoomOrChatScreen) {
      const room = chatSubscription.roomUpdated;
      updateUnreadMessageCount(room.id, room.roomType, 1);
    }
  }, [chatSubscription]);

  useEffect(() => {
    if (unReadMessageCount?.getUnreadMessagesCount) {
      setUnreadCount(unReadMessageCount?.getUnreadMessagesCount);
    }
  }, [unReadMessageCount]);

  useEffect(() => {
    onLayoutChange('screen-size')();
  }, [windowHeight]);

  return (
    <MainContext.Provider
      value={{
        unreadMessageCount: unReadCount,
        updateUnreadMessageCount,
        setPageType,
      }}
    >
      <Box
        bgcolor={colors.BackGround}
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', ...(pageType === 'chat' && { minHeight: 'unset', flex: 1 }) }}
      >
        <SjNavBar onLayout={onLayoutChange('header')} />
        <Box
          sx={{
            flex: 1,
            ...(pageType === 'chat' && { height: `${childrenWrapperHeight}px`, '-webkit-flex': 'unset', pb: 0, flex: 'unset', display: 'flex' }),
            ...(pageType === 'no-result' && { display: 'flex' }),
            ...(pageType === 'error' && { display: 'flex', justifyContent: 'center', alignItems: 'center' }),
          }}
        >
          {children}
        </Box>

        <SJFooter onLayout={onLayoutChange('footer')} />
      </Box>
    </MainContext.Provider>
  );
}

export default SearchContext.withProvider(MainTemplate);
