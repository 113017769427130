import { IFormattedCurrency } from 'interface/public';
import {
  FETCH_CURRENCY,
  FETCH_CURRENCY_FAILURE,
  FETCH_CURRENCY_SUCCESS,
  SET_CURRENCY,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_FAILURE,
  UPDATE_CURRENCY_SUCCESS,
} from './types';

export const setCurrency = (payload: IFormattedCurrency | null) => ({
  type: SET_CURRENCY,
  payload,
});

export const updateCurrency = (payload: IFormattedCurrency | null) => ({
  type: UPDATE_CURRENCY,
  payload,
  meta: {
    success: UPDATE_CURRENCY_SUCCESS,
    failure: UPDATE_CURRENCY_FAILURE,
  },
});

export const fetchCurrency = (opts?: { resolve?: (value: any) => any; reject?: (reason: any) => void }) => ({
  type: FETCH_CURRENCY,
  payload: null,
  meta: {
    success: FETCH_CURRENCY_SUCCESS,
    failure: FETCH_CURRENCY_FAILURE,
    resolve: opts?.resolve,
    reject: opts?.reject,
  },
});
