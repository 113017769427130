import { Box } from '@mui/material';
import colors from 'constants/colors';

function SjImage({
  alt,
  url,
  width,
  height,
  objectFit,
  borderRadius,
}: {
  alt?: string;
  url?: string;
  width: number | string;
  height: number | string;
  objectFit: 'cover' | 'contain';
  borderRadius?: number | string;
}) {
  return url ? (
    <img src={url} alt={alt} width={width} height={height} style={{ borderRadius: borderRadius || '8px', objectFit }} />
  ) : (
    <Box width={width} height={typeof height === 'string' ? height : `${height}px`} bgcolor={colors.Primary} sx={{ borderRadius: borderRadius || '8px' }} />
  );
}

export default SjImage;
