const scope = process.env.REACT_APP_NAMESPACE;
export const TOAST_SHOW = `${scope}/TOAST_SHOW`;
export const TOAST_CLEAR = `${scope}/TOAST_CLEAR`;

export const showToast = (payload: { type: 'success' | 'error'; title: string; description?: string }) => ({
  type: TOAST_SHOW,
  payload,
});

export const clearToast = () => ({
  type: TOAST_CLEAR,
});
