import { IUser } from 'interface/user';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import { getStorage, handleStorage, tokenKey, tokenRefresh, userKey } from 'utils/localStorageUtil';
import Listener, { EVENT_NAME } from 'utils/listener';
import { setCurrency } from 'redux/currency/action';
import * as LocalStorage from 'utils/localStorageUtil';
import UserCurrency from 'utils/userCurrency';
import { IFormattedCurrency } from 'interface/public';
import authProvider, { IResponseLoginPayload } from '../../apis/auth';
import { IReduxBaseAction } from '../../interface/redux';
import { USER_CHECK, USER_LOGIN, USER_LOGOUT, USER_TOKEN_REFRESH, USER_UPDATE_ME } from './types';

export function* userCheck(action: IReduxBaseAction) {
  const { meta } = action;
  try {
    const resp: IUser = yield call(authProvider.getUser);
    if (meta?.success) yield put({ meta, type: meta?.success, payload: resp });
  } catch (error: any) {
    if (meta?.failure) yield put({ meta, type: meta?.failure, error: error?.message });
  }
}

export function* userLogin(action: IReduxBaseAction): Generator<StrictEffect, void, any> {
  const { meta, payload } = action;
  try {
    const response: IResponseLoginPayload = yield call(authProvider.login, payload);
    const me: IUser = yield call(authProvider.userMe, response.access_token);
    yield call(handleStorage, tokenKey, response.access_token);
    yield call(handleStorage, tokenRefresh, response.refresh_token);
    const { role } = me;
    yield call(handleStorage, userKey, {
      ...me,
      role,
    });
    const authenCurrency = me.currency ? UserCurrency.format(me.currency as any) : null;
    yield put(setCurrency(authenCurrency));
    if (meta?.success) yield put({ meta, type: meta?.success, payload: { ...me, role } });
  } catch (error: any) {
    if (meta?.failure) yield put({ meta, type: meta?.failure, error: error?.message });
  }
}

export function* userLogout(action: IReduxBaseAction) {
  const { meta } = action;
  yield call(Listener.emit, EVENT_NAME.USER_LOGOUT);
  yield call(authProvider.logout);

  const localCurrency: IFormattedCurrency | undefined = yield call(LocalStorage.getStorage, LocalStorage.currencyKey);
  yield put(setCurrency(localCurrency ?? null));
  if (meta?.success) yield put({ meta, type: meta?.success });
}

export function* userUpdateMe(action: IReduxBaseAction) {
  const { meta, payload } = action;
  try {
    const response: IResponseLoginPayload = yield call(authProvider.updateMe, payload.id, payload.data);
    yield call(handleStorage, userKey, response);
    if (meta?.success) yield put({ meta, type: meta?.success, payload: response });
  } catch (error: any) {
    if (meta?.failure) yield put({ meta, type: meta?.failure, error: error?.message });
  }
}

export function* sagaUserRefreshToken(action: IReduxBaseAction) {
  const { meta } = action;

  try {
    const user: any = getStorage(userKey);
    const response: IResponseLoginPayload = yield call(authProvider.refreshToken);
    yield call(handleStorage, tokenKey, response.access_token);
    yield call(handleStorage, tokenRefresh, response.refresh_token);
    if (meta?.success) yield put({ meta, type: meta?.success, payload: user });
  } catch (error: any) {
    if (meta?.failure) yield put({ meta, type: meta?.failure, error: error?.message });
  }
}

function* authSaga() {
  yield takeLatest(USER_LOGIN, userLogin);
  yield takeLatest(USER_CHECK, userCheck);
  yield takeLatest(USER_LOGOUT, userLogout);
  yield takeLatest(USER_UPDATE_ME, userUpdateMe);
  yield takeLatest(USER_TOKEN_REFRESH, sagaUserRefreshToken);
}

export default authSaga;
