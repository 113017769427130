import { Avatar, Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import colors from 'constants/colors';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { version } from 'utils/version';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { UserType } from 'interface/user';

function SjUserMenu({ user, handleLogout }: { user: any; handleLogout: () => void }) {
  const isNormalUser = useSelector((state: IReduxState) => state?.auth?.user?.role !== UserType.Superadmin);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box ml={1}>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ padding: 0 }}
      >
        <Avatar alt={user.first_name} src={user.avatar} sx={{ mr: 1, height: '35px', width: '35px' }} />
        <Typography fontWeight={700} sx={{ color: colors.Header, mr: 1, textTransform: 'capitalize' }}>
          Me
        </Typography>
        {open ? <ExpandLessIcon color="secondary" /> : <ExpandMoreIcon color="secondary" />}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '16px',
          },
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          sx: {
            paddingBottom: 0,
            '& .MuiButtonBase-root.MuiMenuItem-root': {
              paddingTop: 2,
              paddingBottom: 2,
              paddingLeft: 4,
              width: 230,
              fontWeight: 500,
              color: colors.BaseText,
              '&:hover': {
                background: colors.Secondary300,
                color: colors.Secondary,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isNormalUser && (
          <>
            <MenuItem
              onClick={() => {
                navigate('/my-listings');
                handleClose();
              }}
            >
              My Listings
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(`/my-profile`);
                handleClose();
              }}
            >
              My Profile
            </MenuItem>
          </>
        )}
        <MenuItem onClick={handleLogout}>
          <LogoutIcon sx={{ mr: 1, color: colors.BaseSecondaryText }} />
          Log out
        </MenuItem>
        <MenuItem>Version: {version}</MenuItem>
      </Menu>
    </Box>
  );
}

export default SjUserMenu;
