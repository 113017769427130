import { IChatParticipant, ROOM_ROLE } from './chat';
import { ICity, IBusinessCategory, ICurrency, ICountry, IRegion, IPhoto } from './public';
import { initUser, IUser } from './user';

export enum ParticipantRole {
  OWNER = 'Owner',
  PARTNER = 'Partner',
  PARTICIPANT = '',
}

export interface IInvitee {
  id: string;
  name?: string;
  username?: string;
  role?: ParticipantRole;
  avatar_url?: string;
  is_accept: boolean;
  user_email: string;
  invitation_status?: string;
  invitationStatus?: string;
  invited_by?: IChatParticipant;
  roomRole?: string;
}

export enum IInvitationResponse {
  ACCEPT = 'ACCEPTED',
  REJECT = 'REJECTED',
  UNANSWERED = '',
}

export default interface IDeal {
  id: string;
  name: string;
  type: DEALTYPE;
  description: string;
  countries: ICountry[];
  owner: IUser;
  total_likes: number;
  currency: ICurrency;
  display_min_price: number;
  display_max_price: number;
  min_price: number;
  max_price: number;
  photos: IPhoto[];
  created_at: string;
  updated_at: string;
  picture?: string;
  price?: number;
  liked?: boolean;
  totalLike?: number;
  isLiked?: boolean;
  status?: DEALSTATUS;
  categories?: [IBusinessCategory];
  invitees?: IInvitee[];
  invitation_id?: string;
  private?: boolean;
  invitee_accepted?: boolean;
  regions?: IRegion[];
  cities?: [ICity];
  available_until?: string;
  reported?: boolean;
}

export enum DEALTYPE {
  BUYING = 'BUYING',
  SELLING = 'SELLING',
}

export enum DEALSTATUS {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN',
  BLOCKED = 'BLOCKED',
  EXPIRED = 'EXPIRED',
}

export const initDeal = {
  id: '',
  name: '',
  type: DEALTYPE.SELLING,
  created_at: '',
  updated_at: '',
  countries: [],
  description: '',
  owner: initUser,
  display_min_price: 0,
  display_max_price: 0,
  min_price: 0,
  max_price: 0,
  total_likes: 0,
  photos: [
    {
      url: '',
      type: 'image',
    },
  ],
  currency: {
    code: '',
    id: 0,
    name: '',
    symbol: '',
  },
};
