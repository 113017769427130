import { useLazyQuery } from '@apollo/react-hooks';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Card, CardContent, Container, IconButton, Link, Stack, Typography } from '@mui/material';
import { getDealById } from 'apis/deal';
import { GET_LISTING_ROOMS } from 'apis/graphql';
import ChatItem from 'components/Chat/ChatItem';
import ChatList, { CHAT_LIST_VIEW_MODE } from 'components/Chat/ChatList';
import DealPrice from 'components/Deal/DealPrice';
import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import SJFooter from 'components/Layout/SjFooter';
import SjHelmet from 'components/Layout/SjHelmet';
import SjImage from 'components/Layout/SjImage';
import colors from 'constants/colors';
import { useMainContext } from 'context/MainContext';
import { CHAT_TYPES, IChat, IChatGraphqlResponse } from 'interface/chat';
import { IReduxState } from 'interface/redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBreakpoints } from 'themes';
import { convertChatBoxItemApi } from 'utils/convertApiChatToWebChat';
import UserCurrency from 'utils/userCurrency';

function ChatByItemPage() {
  const params = useParams();
  const MainContext = useMainContext();
  const isUpMd = useBreakpoints((breakpoints) => breakpoints.up('md'));

  const { auth } = useSelector((state: IReduxState) => state);
  const currentUserId = auth.user?.id?.toString() ?? '';
  const [chats, setChats] = useState<IChat[]>([]);
  const [deal, setDeal] = useState<any>();
  const [activeChat, setActiveChat] = useState<IChat | null>(null);
  const [getListingRooms, { data: listingRooms, loading }] = useLazyQuery(GET_LISTING_ROOMS);
  const [page, setPage] = useState(0);
  const [display, setDisplay] = useState<'chat-list' | 'message'>('chat-list');

  const onToggleDisplay = (isDisplayChatlist: boolean) => {
    setDisplay(isDisplayChatlist ? 'chat-list' : 'message');
  };
  const onClickViewChatList = () => {
    setDisplay('chat-list');
  };

  const refresh = (opts?: { currencyId?: number; skipMountPhase?: boolean }) => {
    if (!params.dealId) {
      return;
    }
    getListingRooms({ variables: { itemId: params.dealId, type: CHAT_TYPES.LISTING_CHAT_MINE }, fetchPolicy: 'network-only' });
    getDealById(params.dealId, undefined, { convert_currency_id: opts?.currencyId }).then((res) => {
      setDeal(res);
    });
  };

  useEffect(refresh, [page, params.dealId]);

  useEffect(() => {
    if (listingRooms?.listingRooms?.items?.length > 0) {
      const chatData = listingRooms.listingRooms?.items.map((item: IChatGraphqlResponse) => convertChatBoxItemApi(item, currentUserId));
      setChats(page === 0 ? chatData : [...chatData, ...chats]);
      if (!activeChat) setActiveChat(chatData[0]);
    }
  }, [listingRooms]);

  useEffect(() => {
    MainContext.setPageType('chat');
    return () => MainContext.setPageType('normal');
  }, []);

  useEffect(() => {
    if (isUpMd) {
      return;
    }
    setDisplay(!activeChat ? 'chat-list' : 'message');
    SJFooter.current.setVisible(false);
    return () => SJFooter.current.setVisible(true);
  }, [isUpMd]);

  UserCurrency.useOnChangeEffect((currency) => refresh({ currencyId: currency?.value, skipMountPhase: true }));

  return (
    <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', mb: 3 }}>
      {deal && (
        <>
          <SjHelmet title={`Chat - ${deal.name}`} />
          <SJBreadcrumbs
            title={[
              {
                name: deal.name,
                link: `/deal/view/${params.dealId}`,
              },
              {
                name: 'Chat',
              },
            ]}
          />
          <Stack direction="row">
            <Link href={`/deal/${deal.id}`}>
              <SjImage
                url={deal.photos[0].content_type.includes('image') ? deal.photos[0].url : deal.photos[0].thumbnail_url}
                width={64}
                height={64}
                objectFit="cover"
                alt={deal.name}
              />
            </Link>
            <Box ml={2} textAlign="left">
              <Link href={`/deal/${deal.id}`}>
                <Typography fontWeight={600} color={colors.BaseText}>
                  {deal.name}
                </Typography>
                <DealPrice
                  currencySymbol={deal.currency.symbol}
                  minPrice={deal.display_min_price}
                  maxPrice={deal.display_max_price}
                  variant="inherit"
                  style={{ fontWeight: 600 }}
                />
              </Link>
            </Box>
          </Stack>
        </>
      )}

      <Card sx={{ flex: 1, margin: '24px 0 0 0', display: 'flex', borderRadius: '16px', m: 0, mt: 3 }}>
        <CardContent sx={{ display: 'flex', flex: 1, flexDirection: 'row', height: '100%', textAlign: 'center', padding: 0 }}>
          <Stack
            sx={{
              overflow: 'hidden',
              borderRight: `1px solid ${colors.BaseGrey}`,
              ...(loading && { alignItems: 'center' }),
              ...(isUpMd && { width: '300px' }),
              ...(!isUpMd && display === 'chat-list' && { flex: 1 }),
              ...(!isUpMd && display === 'message' && { display: 'none' }),
            }}
          >
            <ChatList
              chats={chats}
              setActiveChat={setActiveChat}
              activeChat={activeChat}
              viewMode={CHAT_LIST_VIEW_MODE.CHAT}
              page={page}
              setPage={setPage}
              setShowLeftSidebar={onToggleDisplay}
              noFirstItemMargin
              loading={loading}
            />
          </Stack>

          <Stack {...(isUpMd || display === 'message' ? { flex: 1, position: 'relative' } : { display: 'none' })}>
            {activeChat && (
              <ChatItem
                tab={CHAT_TYPES.LISTING_CHAT_MINE}
                chats={chats}
                setChats={setChats}
                activeChat={activeChat}
                setActiveChat={setActiveChat}
                hideInfo={true}
              />
            )}
            {!isUpMd && (
              <IconButton
                sx={{
                  position: 'absolute',
                  left: 5,
                  top: 0,
                }}
                onClick={onClickViewChatList}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
}

export default ChatByItemPage;
