import { IBusinessCategory, ICountry } from './public';

export enum UserType {
  Personal = 'personal',
  Business = 'business',
  Admin = 'admin',
  Superadmin = 'superadmin',
}

export const initUser = {
  id: 0,
  role: UserType.Personal,
  email: '',
};

export interface IUser {
  id: number;
  role: string;
  username?: string;
  email: string;
  avatar?: string;
  bio?: string;
  blocked?: boolean;
  business_categories?: IBusinessCategory[];
  interests?: IBusinessCategory[];
  business_reg_no?: string;
  business_type?: string;
  company_name?: string;
  country?: ICountry;
  user_email?: string;
  email_verified_at?: string;
  last_login?: string;
  last_name?: string;
  first_name?: string;
  fullname?: string;
  phone?: string;
  currency?: {
    code: string;
    id: string;
  };
}

export type IUserKey = keyof IUser;
