import { CircularProgress, Stack, Box, Card, CardContent } from '@mui/material';

import { CONVERSATION_TYPE } from 'interface/chat';
import { ReactElement } from 'react';
import ChatEmpty from './ChatEmpty';

function ChatTabContainer({
  loading,
  totalChats,
  isSearch,
  children,
  isActiveChat,
}: {
  loading: boolean;
  totalChats: number;
  isSearch: boolean;
  children: ReactElement | ReactElement[];
  isActiveChat: boolean;
}) {
  const render = () => {
    if (loading) {
      return (
        <Box textAlign="center" flex={1} alignSelf="center">
          <CircularProgress />
        </Box>
      );
    }
    if (!isActiveChat && totalChats === 0 && !isSearch) {
      return (
        <Box textAlign="center" flex={1} alignSelf="center" justifyContent="center" alignItems="center">
          <ChatEmpty type={CONVERSATION_TYPE.CHAT} />
        </Box>
      );
    }
    return children;
  };

  return (
    <Card sx={{ borderRadius: '16px', width: '100%', display: 'flex', flex: 1 }}>
      <CardContent sx={{ textAlign: 'center', p: '0 !important', display: 'flex', flex: 1 }}>
        <Stack direction="row" flex={1} position="relative">
          {render()}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default ChatTabContainer;
