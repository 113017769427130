import { useLazyQuery } from '@apollo/react-hooks';
import { Avatar, Stack, Box, Typography, Divider, CircularProgress, ListItem, List, Link } from '@mui/material';
import { SEARCH_CONTENT_IN_ROOM } from 'apis/graphql';
import colors from 'constants/colors';
import { ChatContainerContext } from 'context/ChatContainerContext';
import { ChatMessageContext } from 'context/ChatMessageContext';
import { renderDateByFormat } from 'utils/dateFormat';
import { useCallback, useContext, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { renderFileIcon } from 'utils/fileIcon';
import { convertBytes } from 'utils/convertBytes';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { getUserProfileLink } from 'apis/user';
import { LIMIT } from '../ChatItemDetail';

function ChatItemSearchDetail() {
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUser = auth.user?.id.toString();
  const [searchContentInRooms, { data: searchData }] = useLazyQuery(SEARCH_CONTENT_IN_ROOM);
  const { search, getMessages, setIsSearchMode, setPage, setSearchMsgId } = useContext(ChatMessageContext);
  const { activeChat } = useContext(ChatContainerContext);
  const [data, setData] = useState<any>({
    users: [],
    messages: [],
    files: [],
  });
  const [loading, setLoading] = useState(false);

  const onSearchContent = useCallback(
    debounce((text: string) => {
      searchContentInRooms({
        variables: {
          roomId: activeChat.id,
          text,
        },
      }).then((res) => {
        setLoading(false);
      });
    }, 1000),
    [],
  );

  useEffect(() => {
    if (search) {
      setLoading(true);
      onSearchContent(search);
    }
  }, [search]);

  useEffect(() => {
    if (searchData?.searchContentInRoom) {
      setData(searchData.searchContentInRoom);
    }
  }, [searchData]);

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" flex={1}>
        <CircularProgress />
      </Stack>
    );
  }

  if (data.users.length === 0 && data?.messages?.length === 0 && data?.files?.length === 0) {
    return (
      <Stack alignItems="center" justifyContent="center" flex={1}>
        {`No results for '${search}'`}
      </Stack>
    );
  }

  return (
    <Box height="100%" sx={{ overflowY: 'auto' }}>
      {data?.files?.length > 0 && (
        <Box mt={3}>
          <Typography variant="h6" px={2} my={2} color="#979797" textTransform="uppercase">{`Files (${data?.files?.length})`}</Typography>
          <Divider />
          <List disablePadding>
            {data?.files.map((item: any) => (
              <ListItem divider key={item.fileId} sx={{ px: 2 }}>
                <Box width={30}>
                  <Link target="_blank" href={`//${item.fileUrl}`}>
                    {renderFileIcon(item.fileExt)}
                  </Link>
                </Box>
                <Box ml={2}>
                  <Link target="_blank" href={`//${item.fileUrl}`}>
                    <Typography fontWeight={500} sx={{ wordBreak: 'break-all' }}>
                      {item.fileName}
                    </Typography>
                  </Link>
                  <Stack direction="row" fontSize={14} color={colors.BaseSecondaryText} flexWrap="wrap">
                    <Box mr={1}>{convertBytes(item.fileSize)}</Box>
                    <Box mr={1}>{renderDateByFormat(item.createdAt, false, false, true)}</Box>
                    <Box mr={1}>{item.user.id === currentUser ? 'Me' : item.user.username}</Box>
                    {item.isNew && <Box color="red">New</Box>}
                  </Stack>
                </Box>
                <Divider />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      {data?.users?.length > 0 && (
        <Box mt={3}>
          <Typography variant="h6" px={2} my={2} color="#979797" textTransform="uppercase">{`Users (${data?.users?.length})`}</Typography>
          <Divider />
          {data?.users.map((item: any, index: number) => (
            <Box key={item.id}>
              <Stack direction="row" py="10px" px={2} gap={2}>
                <Avatar src={item.avatarUrl} alt="avatar" sx={{ width: 40, height: 40 }} />
                <Box>
                  <Link href={getUserProfileLink(item.id, auth.user?.id.toString())} color={colors.Header} fontWeight={600}>
                    @{item.username}
                  </Link>
                  <Typography>{item.email}</Typography>
                </Box>
              </Stack>
              {index !== data.users.length - 1 && <Divider />}
            </Box>
          ))}
          <Divider />
        </Box>
      )}
      {data?.messages?.length > 0 && (
        <Box mt={3}>
          <Typography variant="h6" px={2} my={2} color="#979797" textTransform="uppercase">{`Chat messages (${data?.messages?.length})`}</Typography>
          <Divider />
          {data.messages.map((item: any) => (
            <Stack
              gap={2}
              key={item.id}
              direction="row"
              py="10px"
              px={2}
              borderBottom="1px solid #dddcdb"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setPage(0);
                setSearchMsgId(item.id);
                getMessages(
                  {
                    variables: {
                      input: {
                        roomId: activeChat.id,
                        messageId: item.id,
                        limit: LIMIT,
                      },
                    },
                  },
                  'all',
                );
                setIsSearchMode(false);
              }}
            >
              <Avatar src={item.owner.avatarUrl} alt="avatar" sx={{ width: 40, height: 40 }} />
              <Stack ml={1} direction="row" justifyContent="space-between" flex={1}>
                <Box>
                  <Typography color={colors.Header} fontWeight={600}>
                    @{item.owner.username}
                  </Typography>
                  <Typography>{item.content.text}</Typography>
                </Box>
                <Box>
                  <Typography variant="h6" color={colors.BaseSecondaryText} textAlign="right">
                    {renderDateByFormat(item.createdAt)}
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default ChatItemSearchDetail;
