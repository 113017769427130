import { Box, Container, Stack, Typography, createTheme } from '@mui/material';
import { fetchDashboard } from 'apis/dashboard';
import CategoryList from 'category/CategoryList';
import HowToUseFab from 'components/Home/HowToUseFab';
import SjHelmet from 'components/Layout/SjHelmet';
import SjSearchField from 'components/Layout/SjSearchField';
import ListingsComponent from 'components/Listing/Listings';
import HomeMakingBussiness from 'components/Static/HomeMakingBusiness';
import colors from 'constants/colors';
import IDeal from 'interface/deal';
import { IBusinessCategory, IFormattedCurrency } from 'interface/public';
import { IReduxState } from 'interface/redux';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from 'redux/toast/action';
import Listener, { EVENT_NAME } from 'utils/listener';
import UserCurrency from 'utils/userCurrency';

function HomePage() {
  const { auth } = useSelector((state: IReduxState) => state);
  const theme = createTheme();
  const dispatch = useDispatch();

  const [categories, setCategories] = useState<[] | IBusinessCategory[]>([]);
  const [mostPopular, setMostPopular] = useState<[] | IDeal[]>([]);
  const [newest, setNewest] = useState<[] | IDeal[]>([]);
  const [recommended, setRecommended] = useState<[] | IDeal[]>([]);
  const [loading, setLoading] = useState(true);

  const handleUpdatesList = (list: IDeal[], changedValues: any, setList: any, id: string) => {
    if (!list || list.length === 0) {
      return;
    }
    const index = list.findIndex((item: any) => item.id === id);
    if (index > -1) {
      const uRows = [...list];
      changedValues.forEach((item: any) => {
        uRows[index] = {
          ...uRows[index],
          [item.field]: item.value,
        };
      });
      setList(uRows);
    }
  };

  const handleUpdateItemInList = (id: string, changedValues: any) => {
    handleUpdatesList(newest, changedValues, setNewest, id);
    handleUpdatesList(mostPopular, changedValues, setMostPopular, id);
    handleUpdatesList(recommended, changedValues, setRecommended, id);
  };

  const getDashboard = (nextCurrency: IFormattedCurrency | null) => {
    setLoading(true);
    fetchDashboard({ currency_id: nextCurrency?.value })
      .then((data) => {
        setCategories(data.category);
        setMostPopular(data.most_popular);
        setNewest(data.newest);
        setRecommended(data.recommended);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        dispatch(
          showToast({
            type: 'error',
            title: 'Failed to fetch homepage data',
          }),
        );
      });
  };

  useEffect(() => {
    const removeUserLogOutListener = Listener.addListener(EVENT_NAME.USER_LOGOUT, () => getDashboard(null));
    return removeUserLogOutListener;
  }, []);

  UserCurrency.useOnChangeEffect(getDashboard);

  return (
    <Box mt={-20} flex={1}>
      <SjHelmet title="Home" />
      <Box sx={{ background: `url("/images/circle-loop.png") no-repeat ${colors.Primary} top`, pb: 20, borderBottomRightRadius: 250 }}>
        <Box pt={22} pb={10} textAlign="center">
          <Container>
            <Box mb={5}>
              <Typography variant="h1" mt={10} lineHeight={1}>
                <span className="italic">Connecting opportunities, </span>
              </Typography>
              <Typography variant="h1" mt={2}>
                connecting people.
              </Typography>
            </Box>
            <Box maxWidth={600} margin="0 auto">
              <SjSearchField type="text-field" />
            </Box>
          </Container>
        </Box>
      </Box>
      <Stack sx={{ marginTop: '-140px' }}>
        <Container sx={{ px: '0px !important' }}>
          {newest?.length > 0 && (
            <Box mb={8}>
              <ListingsComponent type="carousel" title="New" deals={newest} actions={['like', 'share']} loading={loading} onUpdated={handleUpdateItemInList} />
            </Box>
          )}
          {recommended.length > 0 && (
            <Box mb={8}>
              <ListingsComponent
                type="carousel"
                title="Recommended"
                deals={recommended}
                actions={['like', 'share']}
                loading={loading}
                onUpdated={handleUpdateItemInList}
              />
            </Box>
          )}
          {mostPopular.length > 0 && (
            <Box>
              <ListingsComponent
                type="carousel"
                title="Popular"
                deals={mostPopular}
                actions={['like', 'share']}
                loading={loading}
                onUpdated={handleUpdateItemInList}
              />
            </Box>
          )}
          {!auth?.user && <HomeMakingBussiness />}
          <Box position="relative" py={10} overflow="hidden">
            <Container style={{ paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) }}>
              <Box
                position="absolute"
                sx={{
                  background: colors.Third,
                  top: 0,
                  left: '20%',
                  right: 0,
                  bottom: theme.spacing(4),
                  zIndex: 0,
                  width: '100%',
                  borderBottomLeftRadius: '200px',
                }}
              />
              <CategoryList
                title={{
                  text: 'Category',
                }}
                data={categories}
                isViewAll
              />
            </Container>
          </Box>
        </Container>
      </Stack>

      <HowToUseFab />
    </Box>
  );
}

export default HomePage;
