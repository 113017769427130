import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Close from '@mui/icons-material/Close';
import { Button, Drawer, IconButton, Modal, Stack, StackProps, Typography, styled } from '@mui/material';
import { User } from 'react-feather';
import colors from 'constants/colors';
import { modalStyle } from 'themes/modal';
import { setDisplayMobileOnly, useBreakpoints } from 'themes';
import { debounce } from 'lodash';
import SearchContext from 'context/SearchDealContext';

const Popover = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 5px;
`;

type SjSearchFieldProps = {
  type: 'icon' | 'text-field';
};

function SjSearchField(props: SjSearchFieldProps) {
  const navigate = useNavigate();
  const searchContext = SearchContext.useContext();
  const isMediumScreenDown = useBreakpoints((breakpoints) => breakpoints.down('md'));
  const isSmallScreenDown = useBreakpoints((breakpoints) => breakpoints.down('sm'));

  const textFieldRef = useRef<HTMLInputElement>();
  const drawerSearchFieldRef = useRef<HTMLInputElement>();
  const modalSearchFieldRef = useRef<HTMLInputElement>();

  const [search, setSearch] = useState('');
  const [searchParams] = useSearchParams();
  const [cursor, setCursor] = useState<null | number>(null);

  const [isEnableSearchUser, setEnableSearchUser] = useState(false);
  const [openSearchType, setOpenSearchType] = useState<'none' | 'modal' | 'drawer'>('none');
  const openSearchUserType = (() => {
    switch (true) {
      case !search.length || (props.type === 'icon' && openSearchType === 'none') || !isEnableSearchUser:
        return 'none';

      case openSearchType === 'modal':
        return 'inside-modal';

      case props.type === 'icon' && openSearchType === 'drawer':
        return 'inside-drawer';

      default:
        return 'below-search-field';
    }
  })();

  const UNSAFE_debounce_toPreventTriggerClickedSearchIconOnSubmit = (openSearch: () => void) => debounce(openSearch, 50);

  const onOpenSearch = (nextOpen: boolean) =>
    UNSAFE_debounce_toPreventTriggerClickedSearchIconOnSubmit(() => {
      switch (true) {
        case !nextOpen || props.type === 'text-field':
          setOpenSearchType('none');
          return;

        case props.type === 'icon' && !isMediumScreenDown:
          setOpenSearchType('modal');
          return;

        default:
          setOpenSearchType('drawer');
      }
    });

  const onSubmit =
    (searchType: 'user' | 'deal' = 'deal') =>
    () => {
      setEnableSearchUser(false);
      onOpenSearch(false)();
      if (searchType === 'user' || cursor) {
        navigate(`/search-users?search=${search}&p=1`);
      } else {
        searchContext.setFilterAndLoadDeals((nextFilter) => ({
          ...nextFilter,
          search,
          p: 1,
        }));
        navigate(`/search?search=${search}&p=1`);
      }
    };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEnableSearchUser(true);
    setSearch(e.target.value);
  };

  const onKeyDownSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit('deal')();
    }
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      setCursor(null);
    }
    if (e.key === 'ArrowDown') {
      setCursor(1);
    }
  };

  const onRemoveSearch = () => {
    searchParams.delete('search');
    setSearch('');
    searchContext.setFilterAndLoadDeals((nextFilter) => ({
      ...nextFilter,
      search: '',
      p: 1,
    }));
  };

  useEffect(() => {
    switch (true) {
      case openSearchType === 'modal':
        modalSearchFieldRef.current?.focus();
        return;

      case openSearchType === 'drawer':
        drawerSearchFieldRef.current?.focus?.();
        // eslint-disable-next-line no-useless-return
        return;

      default:
    }
  }, [openSearchType]);

  useEffect(() => {
    searchContext.addRefs({ setSearch });
    return () => {
      searchContext.addRefs({ setSearch: () => console.warn('SjNavBar has unmount & setSearch is not available!') });
    };
  }, []);

  const renderTextField = (textFieldProps: { border?: boolean; hiddenButton?: boolean }, targetRef: React.MutableRefObject<HTMLInputElement | undefined>) => (
    <Stack direction="row">
      <TextField
        autoComplete="off"
        inputRef={targetRef}
        placeholder="Search listings, usernames"
        color="secondary"
        sx={{
          overflow: 'clip',
          input: {
            minWidth: 250,
            ':-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset',
              backgroundColor: 'transparent !important',
            },
          },
          ...(textFieldProps.border && {
            '.MuiOutlinedInput-root': {
              border: `2px solid ${colors.BaseLightGrey}`,
              ':hover': {
                borderColor: colors.Primary500,
              },
            },
          }),
          '.MuiInputBase-input.MuiOutlinedInput-input': {
            paddingLeft: 0,
            paddingY: '10px',
          },
          '.MuiInputBase-input': {
            flex: 1,
            minWidth: isSmallScreenDown ? '50px' : '150px',
          },
        }}
        value={search}
        onFocus={onOpenSearch(targetRef !== textFieldRef)}
        onChange={onChangeSearch}
        onKeyDown={onKeyDownSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <Stack direction="row" justifyContent="flex-end" alignItems="center" mr={-1.75}>
              {!!search && (
                <InputAdornment position="start" sx={{ cursor: 'pointer' }} onClick={onRemoveSearch}>
                  <Close />
                </InputAdornment>
              )}

              {isSmallScreenDown && !textFieldProps?.hiddenButton && (
                <Button
                  variant="contained"
                  sx={{ display: setDisplayMobileOnly('block'), height: 48, p: 0.5, m: 0, fontSize: '12px', borderRadius: '0 10px 10px 0' }}
                  onClick={onSubmit('deal')}
                >
                  Search
                </Button>
              )}
            </Stack>
          ),
        }}
      />

      {!isSmallScreenDown && !textFieldProps?.hiddenButton && (
        <Button
          variant="contained"
          sx={{ ml: 2, display: setDisplayMobileOnly('block'), height: 48, paddingTop: '5px', paddingBottom: '5px' }}
          onClick={onSubmit('deal')}
        >
          Search
        </Button>
      )}
    </Stack>
  );

  const renderSearchUser = (seachUserProps: { maxLength: number; stackProps: StackProps }) => (
    <Popover sx={{ justifyItems: 'center' }}>
      <Stack
        {...seachUserProps?.stackProps}
        direction="row"
        padding={1.5}
        gap={2}
        sx={{
          backgroundColor: cursor ? colors.Secondary300 : colors.White,
          borderRadius: '10px',
          cursor: 'pointer',
          '&:hover': { backgroundColor: colors.Secondary300 },
          ...(seachUserProps?.stackProps?.sx ?? {}),
        }}
        onClick={onSubmit('user')}
      >
        <User />
        <Typography>{`Search '${
          search.length > seachUserProps.maxLength ? `${search.slice(0, seachUserProps.maxLength)}...` : search
        }' user instead`}</Typography>
      </Stack>
    </Popover>
  );

  return (
    <Box flex={1} textAlign="center" position="relative">
      {props.type === 'icon' && (
        <IconButton onClick={onOpenSearch(true)}>
          <SearchIcon />
        </IconButton>
      )}

      <Modal open={openSearchType === 'modal'} onClose={onOpenSearch(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box
          sx={{
            ...modalStyle,
            top: 200,
            width: '80%',
          }}
        >
          {renderTextField({ border: true }, modalSearchFieldRef)}
          {openSearchUserType === 'inside-modal' &&
            renderSearchUser({ maxLength: 30, stackProps: { width: 'calc(100% - 72px)', sx: { mx: 3, boxShadow: '0px 4px 21px rgba(105, 88, 26, 0.15);' } } })}
          <Stack mt={2} gap={1}>
            <Typography color={colors.BaseSecondaryText} fontWeight={700}>
              Popular Searches
            </Typography>
            <Box>Service</Box>
            <Box>Travel</Box>
            <Box>Animal</Box>
          </Stack>
        </Box>
      </Modal>

      {props.type === 'text-field' && <Box>{renderTextField({}, textFieldRef)}</Box>}
      {openSearchUserType === 'below-search-field' && renderSearchUser({ maxLength: 30, stackProps: { width: 'calc(100% - 25px)' } })}

      <Drawer anchor="top" open={openSearchType === 'drawer'} onClose={onOpenSearch(false)} PaperProps={{ sx: { height: '100%' } }}>
        <Box sx={{ p: '24px', bgcolor: colors.Primary, height: '100%' }}>
          <Stack justifyContent="space-between" alignItems="center" direction="row" mb="24px">
            <Typography variant="h3">Search</Typography>
            <IconButton onClick={onOpenSearch(false)}>
              <Close />
            </IconButton>
          </Stack>

          {renderTextField({ border: true, hiddenButton: true }, drawerSearchFieldRef)}
          {openSearchUserType === 'inside-drawer' && renderSearchUser({ maxLength: 10, stackProps: { width: 'calc(100% - 72px)', mx: '24px' } })}
        </Box>
      </Drawer>
    </Box>
  );
}
export default SjSearchField;
