import colors from 'constants/colors';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { formatMoney } from 'utils/formatMoney';

function ListingItemPrice({ symbol, minPrice, maxPrice }: { symbol: string; minPrice: number; maxPrice: number }) {
  return (
    <Stack flexDirection="row" fontWeight={700} color={colors.Black} flexWrap="wrap">
      <Typography fontWeight={700} color={colors.Black}>{`${symbol}${formatMoney(minPrice)}`}</Typography>
      <Typography sx={{ mx: 1 }} fontWeight={700} color={colors.Black}>
        -
      </Typography>
      <Typography fontWeight={700} color={colors.Black}>{`${symbol}${formatMoney(maxPrice)}`}</Typography>
    </Stack>
  );
}

export default ListingItemPrice;
