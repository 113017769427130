/* eslint-disable no-useless-return */
/* eslint-disable @typescript-eslint/default-param-last */
/* eslint-disable no-param-reassign */
import produce, { Draft } from 'immer';
import { IFormattedCurrency } from 'interface/public';
import {
  FETCH_CURRENCY,
  FETCH_CURRENCY_FAILURE,
  FETCH_CURRENCY_SUCCESS,
  SET_CURRENCY,
  UPDATE_CURRENCY,
  UPDATE_CURRENCY_FAILURE,
  UPDATE_CURRENCY_SUCCESS,
} from './types';

export const initialState: {
  status: 'new' | 'loading' | 'success' | 'error';
  data: IFormattedCurrency | null;
} = {
  status: 'new',
  data: null,
};

const currencyReducer = (state = initialState, action: any) =>
  produce(state, (draft: Draft<any>) => {
    switch (action?.type) {
      case FETCH_CURRENCY:
      case UPDATE_CURRENCY:
        draft.status = 'loading';
        return;

      case SET_CURRENCY:
      case FETCH_CURRENCY_SUCCESS:
      case UPDATE_CURRENCY_SUCCESS:
        draft.data = action.payload as IFormattedCurrency | null;
        draft.status = 'success';
        return;

      case FETCH_CURRENCY_FAILURE:
      case UPDATE_CURRENCY_FAILURE:
        draft.status = 'error';
        return;

      default:
    }
  });

export default currencyReducer;
