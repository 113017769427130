import { ReactElement, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import colors from 'constants/colors';
import { ADD_PARTICIPANT, REMOVE_PARTICIPANT, GET_PARTICIPANTS_BY_ROOM_ID } from 'apis/graphql';
import { useChatContainerContext } from 'context/ChatContainerContext';
import { CHAT_STATUSES, IChatParticipant, IInvitationStatus, ROOM_ROLE } from 'interface/chat';

import { IUser } from 'interface/user';
import { ManageInvitees, ManageInvitessViewType } from 'components/Deal/ManageParticipant';
import { IInvitee, ParticipantRole } from 'interface/deal';
import { convertChatUserAPI } from 'utils/convertApiChatToWebChat';
import { uniqBy } from 'lodash';
import { Box } from '@mui/material';

export const modalContent: {
  [x: string]: {
    header: string;
    content: string;
    submitBtn: string;
    bgColor: string;
    color: string;
  };
} = {
  [CHAT_STATUSES.CANCELLED]: {
    header: 'Are you sure you want to cancel this Room?',
    content: 'You and all participants will still be able to access this room, but chat and uploading of documents will be disabled.',
    submitBtn: 'yes, cancel room',
    bgColor: colors.Error300,
    color: colors.Error500,
  },
  [CHAT_STATUSES.COMPLETED]: {
    header: 'Are you sure you want to mark this room as Complete?',
    content: 'You and all participants will still be able to access this room, but chat and uploading of documents will be disabled.',
    submitBtn: 'yes, mark as complete',
    bgColor: colors.Success300,
    color: colors.Success500,
  },
  [CHAT_STATUSES.ARCHIVED]: {
    header: 'Are you sure you want to archive this Room?',
    content: 'This room will be permanently moved to your “Archived” tab.',
    submitBtn: 'yes, archive room',
    bgColor: '#e2e2df',
    color: colors.Secondary500,
  },
  LEAVE: {
    header: 'Are you sure you want to leave this Room??',
    content: 'You will no longer be able to access it.',
    submitBtn: 'leave room',
    bgColor: colors.Error500,
    color: colors.Error500,
  },
};

export type IRoomMenu = {
  color: string;
  onClick: any;
  label: string;
  icon: ReactElement;
};

function RoomInvitees() {
  const { auth } = useSelector((state: IReduxState) => state);

  const [getParticipants, { data: participantsData }] = useLazyQuery(GET_PARTICIPANTS_BY_ROOM_ID);
  const [addParticipant, addParticipantResult] = useMutation(ADD_PARTICIPANT);
  const [removeParticipant, removeParticipantResult] = useMutation(REMOVE_PARTICIPANT);

  const [participants, setParticipants] = useState<IChatParticipant[]>([]);
  const [acceptedParticipants, setAcceptedParticipants] = useState<IChatParticipant[]>([]);
  const [currentParticipant, setCurrentParticipant] = useState<IChatParticipant>({
    id: '',
    fullname: '',
    email: '',
    username: '',
  });
  const [isManagingInvitees, setIsManagingInvitees] = useState(false);

  const { activeChat: chat, setActiveChat } = useChatContainerContext();

  useEffect(() => {
    setCurrentParticipant({
      id: `${auth.user?.id || ''}`,
      fullname: `${auth.user?.first_name} ${auth.user?.last_name}`,
      email: auth.user?.email || '',
    });
  }, [auth]);

  useEffect(() => {
    getParticipants({
      variables: {
        roomId: chat.id,
      },
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'standby',
    });
    setAcceptedParticipants(chat.participants);
    setCurrentParticipant(chat.currentParticipant);
  }, [chat]);

  useEffect(() => {
    if (participantsData?.participants) {
      setParticipants(
        participantsData.participants.map((u: any) => {
          return { ...convertChatUserAPI(u), invitation_status: u.invitationStatus };
        }),
      );
    }
  }, [participantsData]);

  const addParticipants = async (invitees: IInvitee[]) => {
    if (invitees.length > 0) {
      addParticipant({
        variables: {
          input: {
            emails: invitees.map((item: any) => item.email).toString(),
            roomId: chat.id,
          },
        },
      })
        .then(async (response) => {
          if (response.data) {
            setParticipants((preState: IChatParticipant[]): IChatParticipant[] => {
              return [
                ...preState,
                ...invitees.map((invitee: IInvitee): IChatParticipant => {
                  return {
                    email: invitee.user_email,
                    roomRole: ParticipantRole.PARTICIPANT,
                    id: invitee.id || '',
                    fullname: invitee.name || '',
                    avatar: invitee.avatar_url,
                    invitationStatus: IInvitationStatus.PENDING,
                  };
                }),
              ];
            });
          }
        })
        .catch((err) => {
          // setInviteError(true);
          // dispatch(
          //   showToast({
          //     type: 'error',
          //     title: 'User is not existed.',
          //   }),
          // );
        });
    }
  };

  const removeParticipants = async (invitees: IInvitee[]) => {
    if (invitees.length > 0) {
      const invitee = invitees[0];
      removeParticipant({
        variables: {
          input: {
            email: invitee.user_email,
            roomId: chat.id,
          },
        },
      }).then((response) => {
        if (response.data) {
          const newChat = { ...chat };
          newChat.participants = chat.participants.filter((p: any) => p.email !== invitee.user_email);
          setActiveChat(newChat);
          setParticipants((preState) => {
            const newParticipantList = [...preState].filter((p) => {
              return p.email !== invitee.user_email;
            });
            return [...newParticipantList];
          });
          setAcceptedParticipants((preState) => {
            const newParticipantList = [...preState].filter((p) => {
              return p.email !== invitee.user_email;
            });
            return [...newParticipantList];
          });
        }
      });
    }
  };

  const viewType = ManageInvitessViewType.MANAGE_PARTICIPANT;
  const viewerRole = ParticipantRole[(currentParticipant.roomRole || '') as keyof typeof ParticipantRole] || ParticipantRole.PARTICIPANT;

  const getInviteeList = (joinedParticipants: IChatParticipant[], invitedParticipants: IChatParticipant[]): IInvitee[] => {
    const originalList = [currentParticipant, ...joinedParticipants, ...invitedParticipants] || [];

    return uniqBy(originalList, (i) => i.email).map((participant) => {
      const participantRole = ParticipantRole[(participant.roomRole || '') as keyof typeof ParticipantRole] || ParticipantRole.PARTICIPANT;
      const invitedBy = originalList.filter((p: IChatParticipant) => p.id === participant.invitedBy);
      const invitationStatus = IInvitationStatus[(participant.invitationStatus || '') as keyof typeof IInvitationStatus] || IInvitationStatus.COMPLETED;

      return {
        ...participant,
        username: participant.fullname,
        id: participant.id.toString(),
        avatar_url: participant.avatar,
        user_email: participant.email || participant.fullname,
        name: participant.fullname,
        viewerRole,
        role: participantRole,
        is_accept: [IInvitationStatus.ACCEPTED, IInvitationStatus.COMPLETED].includes(invitationStatus),
        invitation_status: invitationStatus,
        invited_by: invitedBy.length > 0 ? invitedBy[0] : undefined,
      };
    });
  };

  const invitees = getInviteeList(acceptedParticipants, participants);

  const currentUserRoomRole = invitees.find((item) => auth.user && +item.id === auth.user?.id)?.roomRole;

  const isEditableRoomRole = currentUserRoomRole === ROOM_ROLE.OWNER || currentUserRoomRole === ROOM_ROLE.PARTNER;

  return (
    <>
      <Modal
        open={isManagingInvitees}
        onClose={() => {
          setIsManagingInvitees(false);
        }}
      >
        <Box>
          <ManageInvitees
            manageInvitees={{
              viewTitle: isEditableRoomRole ? 'Manage Participants' : 'Participants',
              viewType,
              viewerRole,
              invitees: getInviteeList(acceptedParticipants, participants),
              isLoading: removeParticipantResult.loading || addParticipantResult.loading,
              addInvitees: (it) => {
                addParticipants(it);
              },
              removeInvitee: (it) => {
                removeParticipants([it]);
              },
              onClose: () => {
                setIsManagingInvitees(false);
              },
            }}
          />
        </Box>
      </Modal>
      <Stack direction="row" mb={2} alignItems="center">
        {[...chat.participants, auth.user]
          ?.filter((p) => {
            return p.invitationStatus !== IInvitationStatus.PENDING;
          })
          .map((item: IUser) => (
            <Avatar key={item.id} src={item.avatar} alt="avatar" sx={{ width: 35, height: 35, mr: -2, border: `1px solid ${colors.White}` }} />
          ))}
        {chat.status === CHAT_STATUSES.OPEN && (
          <Button
            variant="outlined"
            sx={{ ml: 4, background: colors.Secondary300, borderColor: colors.Secondary300, py: '2px', px: 1, mb: 0, fontSize: '14px' }}
            onClick={() => {
              setIsManagingInvitees(true);
            }}
          >
            {isEditableRoomRole ? 'Manage invitees' : 'Invitees'}
          </Button>
        )}
      </Stack>
    </>
  );
}

export default RoomInvitees;
