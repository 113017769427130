export default {
  Primary100: '#FBFAF2',
  Primary300: '#f6eecd',
  Primary: '#EED478',
  Primary500: '#E4C65C',
  Primary700: '#CAA937',
  Primary900: '#84743f',

  Secondary: '#3E42B6',
  Secondary300: '#DADBFA',
  Secondary500: '#1b1e76',
  Secondary700: '#1D1D44',

  Third: '#E79671',
  Success300: '#E9FCF7',
  Success: '#1DC29B',
  Success500: '#0DAB85',
  Error500: '#D65050',
  Error400: '#D32F2F',
  Error300: '#FFE4E4',
  Header: '#023047',

  BaseText: '#4A4A68',
  BaseSecondaryText: '#979797',
  BaseLightGrey: '#D4D3D1',
  BaseGrey: '#D5D3D1',
  Base600: '#7e7d79',
  Base500: '#F0EEDE',
  Base400: '#FBFAF2',
  Base300: '#FEFDFA',
  White: '#fff',
  Black: '#000',

  BackGround: '#fbfaf3',
  Shadow: '#69581a26',
};
