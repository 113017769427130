import { DEALSTATUS } from './deal';

export type IChatParticipant = {
  username?: string;
  id: string;
  fullname: string;
  avatar?: string;
  roomRole?: string;
  invitedBy?: string;
  email: string;
  invitationStatus?: string;
};

export interface IRoomInviteeUpdated {
  id: string;
  updatedAt: string;
  createdAt: string;
  lastMessage: string;
  userId: string;
  userIds: string[];
  type: ROOM_INVITEE_UPDATED_TYPE;
  users: {
    id: string;
    avatarUrl: string;
    username: string;
    roomRole: string;
    invitedBy: string;
    email: string;
    invitationStatus?: string;
  }[];
}

export enum IInvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  NORMAL = 'NORMAL',
  INVALID = 'INVALID',
}
export interface IChatGraphqlResponse {
  id: string;
  name?: string;
  updatedAt: string;
  createdAt: string;
  lastMessage: string;
  roomStatus: CHAT_STATUSES;
  roomType: CHAT_TYPES;
  unreadMessages: number;
  ownerId: string;
  users: {
    id: string;
    avatarUrl: string;
    username: string;
    roomRole: string;
    invitedBy: string;
    email: string;
    invitationStatus?: string;
  }[];
  itemId?: string;
  item?: {
    imageUrl: string;
    id: string;
    title: string;
    currency: {
      symbol: string;
    };
    minPrice: number;
    maxPrice: number;
    displayMaxPrice: number;
    displayMinPrice: number;
    itemOwnerId: string;
    status: DEALSTATUS;
  };
  groupRoomId: string;
}

export interface IChatListing {
  id: string;
  photo: string;
  name: string;
  symbol: string;
  minPrice: number;
  maxPrice: number;
  ownerId: string;
  status?: DEALSTATUS;
}

export interface IChat {
  id: string;
  name?: string;
  updatedAt: string;
  createdAt: string;
  unreadMessages: number;
  onwerId?: string;
  participants: IChatParticipant[];
  currentParticipant?: IChatParticipant;
  status: CHAT_STATUSES;
  type: CHAT_TYPES;
  lastMessage?: string;
  item?: IChatListing;
  groupRoomId?: string;
}
export interface IChatsWithLoading {
  data: IChat[] | [];
  loading: boolean;
}

export enum ROOM_ROLE {
  OWNER = 'OWNER',
  PARTNER = 'PARTNER',
}

export interface IChatByListing {
  data: IChat | null;
  loading: boolean;
}

export enum MESSAGE_TYPES {
  ROOM_UPDATED = 'ROOM_UPDATED',
  INVITATION = 'INVITATION',
  NORMAL = 'NORMAL',
  FILE_UPDATED = 'FILE_UPDATED',
}

export enum MESSAGE_STATUSES {
  ACCEPTED = 'ACCEPTED',
  COMPLETED = 'COMPLETED',
  NORMAL = 'NORMAL',
  PENDING = 'PENDING',
}

export enum CHAT_STATUSES {
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
  ARCHIVED = 'ARCHIVED',
  LEAVE = 'LEAVE',
}

export enum CONVERSATION_TYPE {
  ROOM = 'room',
  CHAT = 'chat',
}

export enum CHAT_TYPES {
  LISTING_CHAT_MINE = 'LISTING_CHAT_MINE',
  LISTING_CHAT_GENERAL = 'LISTING_CHAT_GENERAL',
  DIRECT = 'DIRECT',
}

export enum ROOM_INVITEE_UPDATED_TYPE {
  INVITED = 'INVITED',
  REMOVED = 'REMOVED',
  ADDED = 'ADDED',
  REJECTED = 'REJECTED',
}

export enum UNREAD_MESSAGE_TYPES {
  MY_LISTING = 'myListing',
  GENERAL_LISTING = 'generalListing',
  DIRECT = 'direct',
}

export type IUnreadDataEachTabType = {
  total: number;
  roomsCount?: {
    roomId: string;
    unreadMessages: number;
  }[];
};

export type IUnreadDataType = {
  generalListing: IUnreadDataEachTabType;
  myListing: IUnreadDataEachTabType;
  direct: IUnreadDataEachTabType;
  rooms: IUnreadDataEachTabType;
};

export type IChatTabType = {
  value: CHAT_TYPES;
  name: string;
  unreadField: keyof IUnreadDataType;
};

export const CHAT_TABS: IChatTabType[] = [
  {
    value: CHAT_TYPES.LISTING_CHAT_MINE,
    name: 'My Listings',
    unreadField: UNREAD_MESSAGE_TYPES.MY_LISTING,
  },
  {
    value: CHAT_TYPES.LISTING_CHAT_GENERAL,
    name: 'General Listings',
    unreadField: UNREAD_MESSAGE_TYPES.GENERAL_LISTING,
  },
  {
    value: CHAT_TYPES.DIRECT,
    name: 'Direct Chats',
    unreadField: UNREAD_MESSAGE_TYPES.DIRECT,
  },
];

export interface IMessage {
  createdAt: string;
  type: MESSAGE_TYPES;
  status: MESSAGE_STATUSES;
  id: string;
  content: {
    text: string;
  };
  owner: {
    id: string;
    avatarUrl: string;
    username: string;
  };
}

export interface IRoomFile {
  id: string;
  isNew?: boolean;
  fileId: string;
  fileSize: string;
  fileName: string;
  fileExt: string;
  fileUrl: string;
  createdAt: string;
  user: {
    username: string;
    id: string;
  };
}
