import { useLazyQuery } from '@apollo/react-hooks';
import { Link, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Modal from '@mui/material/Modal';
import { editInvitees } from 'apis/deal';
import { GET_LISTING_ROOMS } from 'apis/graphql';
import SJFooter from 'components/Layout/SjFooter';
import colors from 'constants/colors';
import { DealContext } from 'context/DealContext';
import dayjs from 'dayjs';
import { CHAT_TYPES } from 'interface/chat';
import { DEALSTATUS, IInvitee, ParticipantRole } from 'interface/deal';
import { IReduxState } from 'interface/redux';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDisplayName } from 'utils/getDisplayName';
import { ManageInvitees, ManageInvitessViewType } from '../ManageParticipant';

enum ChatButtonType {
  'VIEW' = 'View',
  'START' = 'Start',
  'OWNER_VIEW' = 'Owner View',
}
const buttonStyle = { px: 3, py: 1.8, lineHeight: 'unset' };

function DealChatAction({ rooms, type }: { rooms: any; type?: 'footer' | 'on-page' }) {
  const navigate = useNavigate();
  const currentUserId = useSelector((state: IReduxState) => state.auth.user?.id?.toString() ?? '');
  const { deal, onUpdated } = useContext(DealContext);
  const [totalChat, setTotalChat] = useState(0);
  const [button, setButton] = useState('');

  const [getListingRooms, { data: chats, loading }] = useLazyQuery(GET_LISTING_ROOMS);

  const dealOwnerId = deal.owner.id?.toString();

  const [isManagingInvitees, setIsManagingInvitees] = useState(false);
  const [invitees, setInvitees] = useState<IInvitee[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [viewerRole, setViewerRole] = useState(ParticipantRole.PARTICIPANT);

  const viewType = ManageInvitessViewType.MANAGE_INVITEE;

  useEffect(() => {
    if (dealOwnerId === currentUserId) {
      setButton(ChatButtonType.OWNER_VIEW);
      setViewerRole(ParticipantRole.OWNER);
      setInvitees(deal.invitees || []);
    }
  }, [deal]);

  useEffect(() => {
    if (!deal.id || !currentUserId) {
      return;
    }
    getListingRooms({
      variables: { itemId: deal.id, type: currentUserId === dealOwnerId ? CHAT_TYPES.LISTING_CHAT_MINE : CHAT_TYPES.LISTING_CHAT_GENERAL },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'standby',
    });
  }, [currentUserId, deal]);

  useEffect(() => {
    const isOpenDeal = deal.status === DEALSTATUS.OPEN && (!deal.available_until || dayjs(deal.available_until).isAfter(dayjs()));

    if (chats?.listingRooms) {
      setTotalChat(chats?.listingRooms?.items?.length ?? 0);
      if (dealOwnerId === currentUserId) {
        setButton(ChatButtonType.OWNER_VIEW);
      } else if (chats?.listingRooms?.items && chats?.listingRooms?.items.length > 0) {
        setButton(ChatButtonType.VIEW);
      } else if (isOpenDeal) {
        setButton(ChatButtonType.START);
      }
    }
  }, [chats]);

  const renderButtonChat = () => {
    if (!chats && loading) {
      return <Button type="button">Loading...</Button>;
    }
    switch (button) {
      case ChatButtonType.OWNER_VIEW:
        return (
          <Button type="button" variant="contained" fullWidth sx={buttonStyle} onClick={() => navigate(`/deal/chats/${deal.id}`)} disabled={totalChat === 0}>
            {`View ${totalChat > 0 ? totalChat : ''} ${totalChat > 1 ? 'chats' : 'chat'}`}
          </Button>
        );
      case ChatButtonType.VIEW:
        return (
          <Button type="button" variant="contained" sx={buttonStyle} fullWidth onClick={() => navigate(`/chats?tab=1&deal=${deal.id}`)}>
            View Chat
          </Button>
        );
      case ChatButtonType.START:
        return (
          <Button type="button" variant="contained" sx={buttonStyle} fullWidth onClick={() => navigate(`/chats?tab=1&deal=${deal.id}`)}>
            Start Chat
          </Button>
        );
      default:
        return '';
    }
  };

  const addInvitees: any = (newAddedInvitees: string[]) => {
    setIsLoading(true);

    editInvitees({
      id: deal.id,
      invitees: [...invitees, ...newAddedInvitees].map((i: any) => {
        return i.user_email ?? i.email;
      }),
    })
      .then((updatedDeal) => {
        onUpdated({
          ...deal,
          invitees: updatedDeal.invitees || [],
        });
        setIsLoading(false);
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  };

  const removeInvitee = (newRemovedInvites: IInvitee) => {
    setIsLoading(true);
    const newInviteeList = invitees.filter((i) => i.user_email !== newRemovedInvites.user_email);
    editInvitees({
      id: deal.id,
      invitees: [...newInviteeList].map((i) => {
        return i.user_email;
      }),
    })
      .then((updatedDeal) => {
        onUpdated({
          ...deal,
          invitees: updatedDeal.invitees || [],
        });
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (type !== 'footer') {
      return;
    }
    SJFooter.current.setVisible?.(false);
    return () => SJFooter.current.setVisible?.(true);
  }, []);

  const manageInviteesButton = () => {
    return (
      <>
        <Modal
          open={isManagingInvitees}
          onClose={() => {
            setIsManagingInvitees(false);
          }}
        >
          <ManageInvitees
            manageInvitees={{
              viewType,
              viewerRole,
              invitees,
              isLoading,
              addInvitees,
              removeInvitee,
              onClose: () => {
                setIsManagingInvitees(false);
              },
            }}
          />
        </Modal>
        {dealOwnerId === currentUserId && deal.private && (
          <Button
            type="button"
            variant="outlined"
            fullWidth
            sx={{ px: 3, py: 1.2, mb: 0 }}
            onClick={() => {
              setIsManagingInvitees(true);
            }}
            disabled={deal.status === DEALSTATUS.CLOSED}
          >
            MANAGE INVITEES
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      {type === 'on-page' && (
        <Box textAlign="center">
          {(button.length > 0 || (rooms && rooms.totalRoom > 0)) && currentUserId !== dealOwnerId && <Divider />}
          {!!button && <Box mt={2}>{renderButtonChat()}</Box>}
          {!!rooms && rooms.totalRoom > 0 && (
            <Box my={2}>
              <Button
                type="button"
                variant="contained"
                fullWidth
                sx={buttonStyle}
                onClick={() => {
                  if (currentUserId === deal.owner.id.toString()) {
                    navigate(`/deal/rooms/${deal.id}`);
                  } else {
                    navigate(`/rooms?id=${rooms.roomId}`);
                  }
                }}
              >
                {currentUserId === deal.owner.id.toString() ? `View ${rooms.totalRoom} Room` : 'View Room'}
              </Button>
            </Box>
          )}

          {!!button && <Box mt={2}>{manageInviteesButton()}</Box>}
        </Box>
      )}

      {type === 'footer' &&
        ([ChatButtonType.VIEW, ChatButtonType.OWNER_VIEW, ChatButtonType.START].includes(button as ChatButtonType) ||
          rooms?.totalRoom > 0 ||
          (!!button && dealOwnerId === currentUserId && deal.private)) && (
          <SJFooter.Trailing>
            {currentUserId ? (
              <Box p={2}>
                <Stack direction="row" gap={2}>
                  {!!button && <Stack flex={1}>{renderButtonChat()}</Stack>}
                  {!!rooms && rooms.totalRoom > 0 && (
                    <Stack flex={1}>
                      <Button
                        type="button"
                        variant="contained"
                        fullWidth
                        sx={{ px: 3, py: 2 }}
                        onClick={() => {
                          if (currentUserId === deal.owner.id.toString()) {
                            navigate(`/deal/rooms/${deal.id}`);
                          } else {
                            navigate(`/rooms?dealId=${rooms.roomId}`);
                          }
                        }}
                      >
                        {currentUserId === deal.owner.id.toString() ? `View ${rooms.totalRoom} Room` : 'View Room'}
                      </Button>
                    </Stack>
                  )}
                </Stack>
                {!!button && dealOwnerId === currentUserId && deal.private && <Box mt={2}>{manageInviteesButton()}</Box>}
              </Box>
            ) : (
              <Box p={2} textAlign="center">
                Interested in this listing? Log in now and get a chat going with {getDisplayName(deal.owner)}!
                <Stack mt={3} alignItems="center">
                  <Button type="button" variant="contained" fullWidth sx={{ py: '10px' }} onClick={() => navigate('/login')}>
                    Log in
                  </Button>

                  <Box mt={2}>
                    Don't have an account?{' '}
                    <Link href="/sign-up" sx={{ color: colors.Secondary }}>
                      SIGN UP
                    </Link>
                  </Box>
                </Stack>
              </Box>
            )}
          </SJFooter.Trailing>
        )}
    </>
  );
}

DealChatAction.defaultProps = {
  type: 'on-page',
};

export default DealChatAction;
