import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
import { Button, Drawer, IconButton, Stack, Tooltip } from '@mui/material';
import { countListingTotal } from 'apis/deal';
import colors from 'constants/colors';
import { useEffect, useState } from 'react';
import { useBreakpoints } from 'themes';

type Target = '' | 'SHARED';

function ListingStatusPicker<
  Option extends {
    title: string;
    value: string;
    tooltip: string;
  },
>({
  target,
  isOpenDrawer,
  data,
  value,
  onOpenDrawer,
  onClickStatus,
}: {
  target: Target;
  isOpenDrawer: boolean;
  data: Option[];
  value: Option;
  onOpenDrawer: (nextOpenDrawer: boolean) => () => void;
  onClickStatus: (status: Option) => () => void;
}) {
  const isUpLg = useBreakpoints((breakpoints) => breakpoints.up('lg'));
  const [totals, setTotals] = useState({ mine: 0, shared: 0 });
  const isHidden = (!target && !totals?.mine) || (target === 'SHARED' && !totals.shared);

  useEffect(() => {
    (async () => {
      try {
        const nextTotals = await countListingTotal();
        setTotals(nextTotals);
      } catch (error) {
        console.log('Failed to fetch total listings');
      }
    })();
  }, []);

  const renderFilterList = ({ position }: { position: 'in-drawer' | 'default' }) => (
    <Stack sx={{ flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', gap: 2, ...(position === 'default' && { justifyContent: 'flex-end' }) }}>
      {data.map((item) => (
        <Tooltip key={item.title} disableFocusListener title={item.tooltip} arrow placement="bottom">
          <div>
            <Button variant="outlined" color="info" onClick={onClickStatus(item)} className={value.value === item.value ? 'MuiButton-active' : ''}>
              {item.title}
            </Button>
          </div>
        </Tooltip>
      ))}
    </Stack>
  );

  switch (true) {
    case isHidden:
      return null;

    case !isUpLg:
      return (
        <>
          <Button
            type="button"
            onClick={onOpenDrawer(true)}
            sx={{
              border: `1px solid ${colors.BaseLightGrey}`,
              mt: -2,
              px: 2,
              py: 0.5,
              color: colors.Header,
              fontWeight: 700,
              ':hover': { background: 'transparent', color: colors.Header },
            }}
            variant="outlined"
          >
            <TuneIcon sx={{ transform: 'rotate(90deg)', color: colors.Header, mr: 0.5 }} />
            {value.title}
          </Button>

          <Drawer anchor="top" open={isOpenDrawer} onClose={onOpenDrawer(false)} PaperProps={{ sx: { height: '100%', background: colors.Primary, p: 2 } }}>
            <Stack justifyContent="flex-end" alignItems="center" direction="row" p={2}>
              <IconButton onClick={onOpenDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>

            {renderFilterList({ position: 'in-drawer' })}
          </Drawer>
        </>
      );

    default:
      return renderFilterList({ position: 'default' });
  }
}

type CompoundListingStatusPicker = typeof ListingStatusPicker & {
  Target: Target;
};

export default ListingStatusPicker as CompoundListingStatusPicker;
