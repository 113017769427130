import { getCurrencies } from 'apis/public';
import { ICurrency } from 'interface/public';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { userUpdateMe } from 'redux/auth/action';
import UserCurrency from 'utils/userCurrency';
import * as LocalStorage from 'utils/localStorageUtil';
import { IReduxBaseAction, IReduxState } from '../../interface/redux';
import { FETCH_CURRENCY, UPDATE_CURRENCY } from './types';

export function* fetchCurrency(action: IReduxBaseAction) {
  const { meta } = action ?? {};
  try {
    const nextCurrenciesResponse: { data: ICurrency[] } = yield call(getCurrencies);
    const nextCurrencyList = nextCurrenciesResponse.data?.map?.(UserCurrency.format) ?? [];

    const isAuthenticated: boolean = yield select((state: IReduxState) => !!state.auth?.user?.id);
    const nextCurrencyCode: ICurrency['code'] = yield select((state: IReduxState) => {
      const authenCode = state.auth?.user?.currency?.code;
      const localCode = LocalStorage.getStorage(LocalStorage.currencyKey)?.code ?? 'USD';
      return !isAuthenticated ? localCode : authenCode;
    });

    const nextCurrency = nextCurrencyList.find((c) => c.code === nextCurrencyCode, null);
    if (!nextCurrency) {
      throw new Error('Not found currency in currency list response!');
    }

    if (!isAuthenticated) LocalStorage.handleStorage(LocalStorage.currencyKey, nextCurrency);
    if (meta?.success) yield put({ meta, type: meta?.success, payload: nextCurrency });
    if (meta?.resolve) meta?.resolve(nextCurrencyList);
  } catch (error: any) {
    if (meta?.failure) yield put({ meta, type: meta?.failure, error: error?.message });
    if (meta?.reject) meta?.reject(error);
  }
}

export function* updateCurrency(action: IReduxBaseAction) {
  const { payload: nextCurrency, meta } = action;
  try {
    const userId: number | undefined = yield select((state: IReduxState) => state.auth?.user?.id);
    if (!userId) {
      LocalStorage.handleStorage(LocalStorage.currencyKey, nextCurrency);
      if (meta?.success) yield put({ meta, type: meta?.success, payload: nextCurrency });
      return;
    }

    if (!nextCurrency?.value) {
      throw new Error('Authenticated user try to change currency to null!');
    }

    yield put(userUpdateMe(userId.toString(), { currency_id: nextCurrency.value }));
    if (meta?.success) yield put({ meta, type: meta?.success, payload: nextCurrency });
  } catch (error: any) {
    if (meta?.failure) yield put({ meta, type: meta?.failure, error: error?.message });
  }
}

function* currencySaga() {
  yield takeLatest(FETCH_CURRENCY, fetchCurrency);
  yield takeLatest(UPDATE_CURRENCY, updateCurrency);
}

export default currencySaga;
