import { Stack, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import colors from 'constants/colors';

import { formatMoney } from 'utils/formatMoney';

function DealPrice({
  currencySymbol,
  maxPrice,
  minPrice,
  style,
  variant,
}: {
  currencySymbol: string;
  maxPrice: number;
  minPrice: number;
  style: any;
  variant: Variant | 'inherit';
}) {
  return (
    <Stack flexDirection="row" flex={1} flexWrap="wrap">
      <Typography variant={variant} color={colors.BaseText} sx={style}>{`${currencySymbol}${formatMoney(minPrice)}`}</Typography>
      <Typography sx={{ mx: 1 }}>-</Typography>
      <Typography variant={variant} color={colors.BaseText} sx={style}>{`${currencySymbol}${formatMoney(maxPrice)}`}</Typography>
    </Stack>
  );
}

export default DealPrice;
