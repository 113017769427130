import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import { Box, Fab, Link, Stack, Typography, createTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import colors from 'constants/colors';
import { useState } from 'react';
import { getStorage, handleStorage, howToUseFabKey } from 'utils/localStorageUtil';

const HowToUse = styled(Fab)`
  display: flex;
  flex-direction: row;
  background: ${colors.Secondary500};
  opacity: 0.7;
  &:hover {
    opacity: 1;
    background: ${colors.Secondary500};
  }
`;

const LINK_TO_HOW_TO_USE_DOC = process.env.REACT_APP_LINK_TO_HOW_TO_USE_DOC;

function HowToUseFab() {
  const theme = createTheme();

  const [isVisible, setVisible] = useState(getStorage(howToUseFabKey)?.isVisible ?? true);

  const onPressHide = () => {
    handleStorage(howToUseFabKey, { isVisible: false });
    setVisible(false);
  };

  return (
    isVisible && (
      <Box position="fixed" bottom={115} right={15} zIndex={2}>
        <Stack flexDirection="row" alignItems="center" sx={{ '> a > h6': { opacity: 0 }, '&:hover': { '> a > h6': { opacity: 1 } } }}>
          <Link href={LINK_TO_HOW_TO_USE_DOC} target="_blank">
            <Typography variant="h6" sx={{ mr: 0.5, color: colors.White, bgcolor: colors.Base600, p: 0.5 }}>
              How to Use?
            </Typography>
          </Link>

          <HowToUse>
            <Link href={LINK_TO_HOW_TO_USE_DOC} target="_blank" display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
              <MenuBookRoundedIcon sx={{ color: colors.White }} />
            </Link>

            <IconButton
              disableTouchRipple
              disableRipple
              onClick={onPressHide}
              sx={{
                position: 'absolute',
                right: theme.spacing(-0.5),
                top: theme.spacing(-0.5),
                color: colors.BaseSecondaryText,
                bgcolor: colors.White,
                zIndex: 1,
                p: 0,
                '& :hover': {
                  bgcolor: colors.White,
                  borderRadius: '50%',
                },
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </HowToUse>
        </Stack>
      </Box>
    )
  );
}

export default HowToUseFab;
