import { AxiosRequestConfig } from 'axios';
import { IFilter } from 'interface/filter';
import qs from 'qs';

import { IReportForm } from 'components/Deal/DealActions/DealReportAction';
import IDeal, { IInvitationResponse } from 'interface/deal';
import customAxios from '../utils/request';
import { IDealForm } from '../pages/deal/DealForm';

export type IDealPostData = Omit<IDealForm, 'currency_ids' | 'region_ids' | 'invitees' | 'id'> & {
  currency_ids: number[];
  region_ids: number[];
  invitees?: string[];
  id?: string;
};

export const createDeal = async (data: IDealPostData) => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: '/app/items',
    headers: { accept: '*/*' },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getMyDeals = async (
  filter: IFilter,
): Promise<{
  data: IDeal[];
  total_count: number;
}> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `/app/items/me`,
    headers: { accept: '*/*' },
    params: filter,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getDeals = async (
  filter: IFilter,
): Promise<{
  data: IDeal[];
  total_count: number;
}> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `/app/items`,
    headers: { accept: '*/*' },
    paramsSerializer: {
      serialize: (p) => {
        return qs.stringify(p, { arrayFormat: 'comma' });
      },
    },
    params: {
      ...filter,
      f: JSON.stringify(filter.f),
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getDealById = async (id: string, showOriginPrice?: boolean, opts?: { convert_currency_id?: number }): Promise<IDeal> => {
  const params: AxiosRequestConfig['params'] = {
    show_original_currency: showOriginPrice,
  };

  if (typeof opts?.convert_currency_id === 'number') {
    params.convert_currency_id = opts?.convert_currency_id;
  }

  const config: AxiosRequestConfig = {
    method: 'GET',
    url: `/app/items/${id}`,
    headers: { accept: '*/*' },
    params,
  };
  const response = await customAxios.request({ ...config });
  return response.data;
};

export const editDeal = async (data: IDealPostData) => {
  const params: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/app/items/${data.id}`,
    headers: { accept: '*/*' },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const editInvitees = async ({ id, invitees }: { id: string; invitees: string[] }): Promise<IDeal> => {
  const params: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/app/items/${id}`,
    headers: { accept: '*/*' },
    data: { invitees },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const likeDeal = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: `/app/items/${id}/like`,
    headers: { accept: '*/*' },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const unlikeDeal = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: 'DELETE',
    url: `/app/items/${id}/unlike`,
    headers: { accept: '*/*' },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const acceptInvitation = async ({
  id,
  invitationId,
  invitationResponse,
}: {
  id: string;
  invitationId: string;
  invitationResponse: IInvitationResponse;
}): Promise<IDeal> => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: `app/items/${id}/invitation/${invitationId}/${invitationResponse}`,
    headers: { accept: '*/*' },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const closeDeal = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/app/items/mark-as-closed/${id}`,
    headers: { accept: '*/*' },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const countListingTotal = async () => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `/app/items/me/count`,
    headers: { accept: '*/*' },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const reportDeal = async (id: string, data: IReportForm) => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: `/app/items/${id}/report`,
    headers: { accept: '*/*' },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getReportedDeals = async (filter: any) => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `/admin/items`,
    headers: { accept: '*/*' },
    params: filter,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getReportedDealItems = async (filter: any) => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `/admin/items/reports`,
    headers: { accept: '*/*' },
    params: filter,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const notifyReportedListing = async (id: string, data: any) => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: `/admin/items/${id}/notify`,
    headers: { accept: '*/*' },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const blockReportedListing = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: `/admin/items/${id}/block`,
    headers: { accept: '*/*' },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};
