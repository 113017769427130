import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import 'react-gallery-carousel/dist/index.css';
import { unstable_useBlocker, useBeforeUnload } from 'react-router-dom';
import { useBreakpoints } from 'themes';
import { modalStyle } from 'themes/modal';

function SjBlockUnsavedFormModal() {
  const { dirty, isSubmitting } = useFormikContext();
  const isUpMd = useBreakpoints((breakpoints) => breakpoints.up('md'));
  const routerBlocker = unstable_useBlocker(dirty && !isSubmitting);

  const [isOpenWarning, setOpenWarning] = useState(false);

  const onOpenWarning = (type: 'open' | 'yes' | 'cancel') => () => {
    switch (type) {
      case 'open':
        setOpenWarning(true);
        return;

      case 'yes':
        setOpenWarning(false);
        routerBlocker?.proceed?.();
        return;

      case 'cancel':
      default:
        setOpenWarning(false);
    }
  };

  useBeforeUnload(
    useCallback(
      (event) => {
        if (!dirty) {
          return;
        }
        event.preventDefault();
        const warning = 'Any changes made will not be saved.';
        // eslint-disable-next-line no-param-reassign
        event.returnValue = warning;
        return warning;
      },
      [dirty],
    ),
  );

  useEffect(() => {
    if (!routerBlocker.location) {
      return;
    }
    onOpenWarning('open')();
  }, [routerBlocker.location]);

  return (
    <Modal open={isOpenWarning} onClose={onOpenWarning('cancel')} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Stack
        sx={{
          ...modalStyle,
          px: ['16px', '16px', '40px'],
          pt: ['16px', '16px', '32px'],
          pb: ['24px', '24px', '56px'],
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Stack sx={{ flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
          <IconButton onClick={onOpenWarning('cancel')}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography variant={isUpMd ? 'h3' : 'h4'} mb="32px">
          Are you sure you want to leave this page?
        </Typography>
        <Typography variant="body1" mb="24px">
          Any changes made will not be saved.
        </Typography>

        <Button variant="contained" color="error" sx={{ padding: ['8px 16px', '8px 16px', '16px 32px'] }} onClick={onOpenWarning('yes')}>
          Yes
        </Button>
      </Stack>
    </Modal>
  );
}

export default SjBlockUnsavedFormModal;
