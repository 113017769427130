import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import { UserType } from 'interface/user';
import SignupForm from 'components/Signup/SignupForm';
import authProvider from 'apis/auth';
import { showToast } from 'redux/toast/action';
import { useDispatch } from 'react-redux';
import SjHelmet from 'components/Layout/SjHelmet';

function SignupPage() {
  const [formType, setFormType] = useState(UserType.Personal);
  const [isRegistered, setIsRegisted] = useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (email) {
      setIsRegisted(true);
    }
  }, [email]);

  const resendEmail = () => {
    authProvider
      .resendActiveEmail({
        email,
      })
      .then(() => {
        dispatch(
          showToast({
            type: 'success',
            title: `An email has been sent to ${email}`,
          }),
        );
        // resetSubmitButton();
      })
      .catch((err) => {
        dispatch(
          showToast({
            type: 'error',
            title: err.response.data.error.message ?? 'Oops! Something went wrong!.',
          }),
        );
        // resetSubmitButton();
      });
  };

  // const resendEmail = () => {
  //   navigate('/sign-up/resend-email', { state: { email } });
  // };

  if (isRegistered) {
    return (
      <Stack textAlign="center" alignItems="center" marginTop={10}>
        <Card variant="outlined" sx={{ boxShadow: 'none' }}>
          <CardContent>
            <Typography variant="h2" mb={5}>
              Please verify your email.
            </Typography>
            <Box mb={5}>
              <Typography lineHeight={3}>
                We’ve sent an email to <span style={{ fontWeight: 600 }}>{email}</span>.
              </Typography>
              <Typography lineHeight={3}> Click on the link in the email to complete your sign up. </Typography>
              {/* <Typography lineHeight={3}>Still can’t find the email?</Typography> */}
            </Box>
            <Button variant="contained" type="button" fullWidth sx={{ height: 52 }} onClick={resendEmail}>
              Resend Email
            </Button>
          </CardContent>
        </Card>
      </Stack>
    );
  }

  return (
    <Stack>
      <SjHelmet title="Signup" />
      <Typography variant="h3" textAlign="center">
        I am a ...
      </Typography>
      <Card variant="outlined" sx={{ width: ['100%', '100%', 800], boxShadow: 'none', margin: '0 auto ', border: 'none' }}>
        <CardContent sx={{ pb: '16px !important' }}>
          <Stack direction={['column', 'column', 'row']} sx={{ width: '100%' }} gap={2} mb={2}>
            <Button
              color={formType === UserType.Personal ? 'secondary' : 'info'}
              variant="contained"
              type="button"
              sx={{ height: '50px', flex: 1, borderRadius: '16px', padding: ['24px', '24px', 'auto'] }}
              onClick={() => setFormType(UserType.Personal)}
            >
              Personal User
            </Button>
            <Button
              color={formType === UserType.Business ? 'secondary' : 'info'}
              variant="contained"
              type="button"
              sx={{ height: '50px', flex: 1, borderRadius: '16px', padding: ['24px', '24px', 'auto'] }}
              onClick={() => setFormType(UserType.Business)}
            >
              Business User
            </Button>
          </Stack>
          <SignupForm formType={formType} setEmail={setEmail} />
        </CardContent>
      </Card>
    </Stack>
  );
}

export default SignupPage;
