import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { CONVERSATION_TYPE } from 'interface/chat';

function ChatEmpty({ type }: { type: CONVERSATION_TYPE }) {
  const navigate = useNavigate();
  return (
    <Box p={[4, 4, 10]}>
      <Typography variant="h3">No {type}s yet</Typography>
      <Typography sx={{ mt: 1 }}>Start browsing listings and get a conversation going!</Typography>

      <Button variant="contained" sx={{ px: 4, mt: 5 }} onClick={() => navigate('/search')}>
        browse listings
      </Button>
    </Box>
  );
}

export default ChatEmpty;
