export const formatMoney = (value?: string | number, opts?: { defaultValue?: string }) => {
  const defaultValue = opts?.defaultValue ?? '0.00';
  if (!value) {
    return defaultValue;
  }
  const num = value.toString().replace(/,/gi, '');
  const splitDecima = num.toString().split('.');
  const isNegative = +splitDecima[0] < 0;
  let num2;
  if (splitDecima.includes('-')) {
    num2 = `-${splitDecima[0]
      .replace('-', '')
      .split(/(?=(?:\d{3})+$)/)
      .join(',')}`;
  } else {
    num2 = splitDecima[0]
      .replace('-', '')
      .split(/(?=(?:\d{3})+$)/)
      .join(',');
  }

  const res = splitDecima.length > 1 ? `${num2}.${splitDecima[1]}` : `${num2 || defaultValue}`;
  return isNegative ? `-${res}` : res;
};

export const formatMoneyWithDecimal = (value: any) => {
  const money = value.toString().split('.');
  if (money.length === 1) {
    return `${value}.00`;
  }
  if (money[1].length === 1) {
    return `${money[0]}.${money[1]}0`;
  }
  return value;
};
