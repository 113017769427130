import { Box, AppBar, Link, Container, Button, Stack } from '@mui/material';
import colors from 'constants/colors';
import { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { userLogout } from 'redux/auth/action';

function AdminTemplate({ children }: { children: ReactElement | ReactElement[] }) {
  const dispatch = useDispatch();
  return (
    <Box>
      <AppBar component="nav" sx={{ backgroundColor: colors.Primary500 }}>
        <Container>
          <Stack justifyContent="space-between" direction="row">
            <Link href="/" sx={{ '&:hover': { color: 'inherit' }, marginLeft: '0 !important' }}>
              <img src={`${process.env.PUBLIC_URL}/sj-logo.png`} alt="List & Deal" height={55} />
            </Link>
            <Button onClick={() => dispatch(userLogout())}>Log out</Button>
          </Stack>
        </Container>
      </AppBar>
      <Box mt={10}>
        <Container>{children}</Container>
      </Box>
    </Box>
  );
}

export default AdminTemplate;
