/* eslint-disable no-debugger */
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import EditProfileSidebar from 'components/Profile/EditProfileMenu';

import Grid from '@mui/material/Unstable_Grid2';
import authProvider from 'apis/auth';
import { UserType } from 'interface/user';
import { IBusinessCategory, ICountry } from 'interface/public';
import FormGroup from '@mui/material/FormGroup';
import { InputLabel } from '@mui/material';
import SjTabsScreen from 'components/Profile/SjTabsScreen';
import SjHelmet from 'components/Layout/SjHelmet';

export const initEditProfileForm = {
  email: '',
  first_name: '',
  last_name: '',
  avatar: '',
  bio: '',
  interests: [],
  country_id: '',
  phone: '',
  business_categories: [],
};

export type IEditProfileForm = {
  email: string;
  first_name: string;
  last_name: string;
  avatar?: string;
  country_id: number;
  interests: string[];
  business_categories?: IBusinessCategory[];
  bio: '';
  business_reg_no?: string;
  company_name?: string;
};

function MyProfile() {
  const navigate = useNavigate();
  const [data, setData] = useState<any>(initEditProfileForm);

  const [role, setRole] = useState(UserType.Personal);

  useEffect(() => {
    authProvider.userMe().then((resp: any) => {
      setRole(resp.role);
      setData(resp);
    });
  }, []);

  return (
    <>
      <SjHelmet title="My Profile" />
      <SjTabsScreen
        title="My Profile"
        left={<EditProfileSidebar />}
        right={
          <>
            <Grid container spacing={1} padding={2}>
              <Stack flexDirection="row" alignItems="center" sx={{ mb: '24px' }}>
                <Avatar src={data.avatar} sx={{ width: 80, height: 80, mr: 3 }} />
                <Box>
                  <Typography fontSize={40} variant="h2">
                    {role === UserType.Personal ? `${data.first_name} ${data.last_name}` : data.company_name}
                  </Typography>
                </Box>
              </Stack>
            </Grid>

            <Grid container spacing={1} padding={2}>
              <Grid xs={12} lg={6}>
                <FormGroup>
                  <InputLabel>Email</InputLabel>
                  <Typography>{data.email}</Typography>
                </FormGroup>
              </Grid>
              <Grid xs={12} lg={6}>
                <FormGroup>
                  <InputLabel>Username</InputLabel>
                  <Typography>@{data.username}</Typography>
                </FormGroup>
              </Grid>
            </Grid>

            {role === UserType.Personal ? (
              <Grid container spacing={1} padding={2}>
                <Grid xs={12} lg={6}>
                  <FormGroup>
                    <InputLabel>First Name</InputLabel>
                    <Typography>{data.first_name}</Typography>
                  </FormGroup>
                </Grid>
                <Grid xs={12} lg={6}>
                  <FormGroup>
                    <InputLabel>Last Name</InputLabel>
                    <Typography>{data.last_name}</Typography>
                  </FormGroup>
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={1} padding={2}>
                <Grid xs={12} lg={6}>
                  <FormGroup>
                    <InputLabel>Company Name</InputLabel>
                    <Typography>{data.company_name}</Typography>
                  </FormGroup>
                </Grid>
              </Grid>
            )}
            <Grid container spacing={1} padding={2}>
              <Grid xs={12} lg={6}>
                <FormGroup>
                  <InputLabel>Phone Number</InputLabel>
                  <Typography>{data.phone ?? '-'}</Typography>
                </FormGroup>
              </Grid>
              <Grid xs={12} lg={6}>
                <FormGroup>
                  <InputLabel>Country</InputLabel>
                  <Typography>{data.country?.name ?? '-'}</Typography>
                </FormGroup>
              </Grid>
            </Grid>

            <Grid container spacing={1} padding={2}>
              <Grid xs={12} lg={12}>
                <FormGroup>
                  <InputLabel>{role === UserType.Personal ? 'Interests' : 'Business Categories'}</InputLabel>
                  <Typography>
                    {data.interests.map((item: ICountry) => (
                      <Box key={item.id}>{item.name}</Box>
                    ))}
                  </Typography>
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container spacing={1} padding={2}>
              <Grid xs={12} lg={12}>
                <FormGroup>
                  <InputLabel>Profile Bio</InputLabel>
                  <Typography>{data.bio || '-'}</Typography>
                </FormGroup>
              </Grid>
            </Grid>
            {role === UserType.Business && (
              <Grid container spacing={1} padding={2}>
                <Grid xs={12} lg={6}>
                  <FormGroup>
                    <InputLabel>Business Reg. No.</InputLabel>
                    <Typography>{data.business_reg_no || '-'}</Typography>
                  </FormGroup>
                </Grid>
                <Grid xs={12} lg={6}>
                  <FormGroup>
                    <InputLabel>Registered Business Address</InputLabel>
                    <Typography>{data.business_address || '-'}</Typography>
                  </FormGroup>
                </Grid>
              </Grid>
            )}
            <Stack alignItems="flex-end" mt={4}>
              <Button variant="contained" onClick={() => navigate('/my-profile/edit')} sx={{ px: 4 }}>
                Edit Profile
              </Button>
            </Stack>
          </>
        }
      />
    </>
  );
}

export default MyProfile;
