import Button from '@mui/material/Button';
import { useMutation } from '@apollo/react-hooks';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import colors from 'constants/colors';
import { useContext, useState } from 'react';
import { modalStyle } from 'themes/modal';
import { Formik, FormikErrors } from 'formik';
import SjInput from 'components/Form/SjInput';
import { SEND_INVITATION_TO_ROOM } from 'apis/graphql';
import { ChatContainerContext } from 'context/ChatContainerContext';
import { Stack } from '@mui/material';

function ChatInviteToRoomButton({ onInvited }: { onInvited: () => void }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [inviteToRoom] = useMutation(SEND_INVITATION_TO_ROOM);
  const { activeChat } = useContext(ChatContainerContext);

  const submit = (values: { name: string }) => {
    inviteToRoom({
      variables: {
        input: {
          roomId: activeChat.id,
          name: values.name,
        },
      },
    }).then(() => {
      setOpen(false);
      onInvited();
    });
  };

  return (
    <Stack direction="row" alignItems="center">
      <Button variant="contained" sx={{ fontSize: '14px', py: 1, zIndex: 2 }} onClick={handleOpen}>
        Invite to room
      </Button>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography my={2} variant="h3">
            Invite to Room
          </Typography>
          <Formik
            initialValues={{ name: '' }}
            onSubmit={submit}
            validate={(values) => {
              const errors: FormikErrors<{ name: string }> = {};
              if (!values.name) {
                errors.name = 'Please enter room name.';
              }
              if (values.name.length > 50) {
                errors.name = 'Room name must not be greater than 50 characters long.';
              }
              return errors;
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <SjInput name="name" placeholder="E.g Rebranding Project" label="Room Name*" helpText="You can change your Room Name later." />
                <Button type="submit" variant="contained" sx={{ py: 1, px: 2, mb: 1, float: 'right' }}>
                  Create room
                </Button>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>

      <Tooltip
        componentsProps={{ tooltip: { sx: { opacity: 1, background: colors.Secondary700, padding: 2 } } }}
        title={
          <Box fontSize="14px" lineHeight={1.6}>
            Rooms are a dedicated one stop workspace to discuss your business transactions - from chats to uploading of documents, you can do it all here.
          </Box>
        }
        arrow
        placement="top"
        enterTouchDelay={0}
      >
        <div style={{ lineHeight: 0 }}>
          <InfoOutlinedIcon sx={{ ml: 1, color: colors.BaseSecondaryText }} />
        </div>
      </Tooltip>
    </Stack>
  );
}

export default ChatInviteToRoomButton;
