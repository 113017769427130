import { Box, Stack } from '@mui/material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import Grid from '@mui/material/Unstable_Grid2';
import colors from 'constants/colors';

function ListingItemLoading({ columns, hideOwner }: { columns?: number; hideOwner?: boolean }) {
  return (
    <Grid xs={12} sm={12} lg={columns ?? 3}>
      <Box
        bgcolor={colors.White}
        p={2}
        sx={{
          boxShadow: '0px 4px 21px rgba(105, 88, 26, 0.15)',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: 0,
          borderBottomRightRadius: '20px',
          borderBottomLeftRadius: 0,
        }}
      >
        <Stack justifyContent="space-between">
          <Box>
            {!hideOwner && (
              <Box
                height="32px"
                mb={2}
                sx={{
                  borderRadius: '8px',
                  background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
                }}
              />
            )}
            <Box
              className="image"
              height="130px"
              width="100%"
              sx={{
                borderRadius: '8px',
                background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
                animation: '1.5s shine linear infinite',
                backgroundSize: '200% 100%',
                '@keyframes shine': {
                  to: {
                    backgroundPositionx: '-200%',
                  },
                },
              }}
            />
          </Box>

          <Box flex={1}>
            <Stack direction="row" alignItems="center" gap={0.2} mt={2} mb={1}>
              <FmdGoodIcon sx={{ fill: colors.White, stroke: colors.BaseText, mr: '2px', width: 14, strokeWidth: 2 }} />
              <Box
                sx={{
                  flex: 1,
                  borderRadius: '8px',
                  height: '17px',
                  background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)',
                }}
              />
            </Stack>
            <Box sx={{ borderRadius: '8px', height: '50px', mb: 2, background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)' }} />

            <Box sx={{ borderRadius: '8px', height: '70px', background: 'linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%)' }} />
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
}

export default ListingItemLoading;
