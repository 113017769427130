import { CircularProgress, Link } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
import colors from 'constants/colors';
import { IChat } from 'interface/chat';
import uniqBy from 'lodash/uniqBy';
import { useEffect, useRef, useState } from 'react';
import SjScrollBox from 'components/Layout/SjScrollBox';
import useWindowSize from 'utils/useWindowSize';
import ChatListItem from './ChatListItem';

export enum CHAT_LIST_VIEW_MODE {
  ROOM = 'room',
  CHAT = 'chat',
}

function ChatList({
  page,
  header,
  setPage,
  chats,
  setActiveChat,
  activeChat,
  nextToken,
  viewMode,
  setShowLeftSidebar,
  loading,
  hiddenBody,
  headerProps,
  noFirstItemMargin,
}: {
  page: number;
  header?: React.ReactNode;
  setPage: (p: number) => void;
  chats: IChat[];
  setActiveChat: (c: IChat) => void;
  activeChat: IChat | null;
  nextToken?: string;
  viewMode: CHAT_LIST_VIEW_MODE;
  setShowLeftSidebar?: (s: boolean) => void;
  loading?: boolean;
  hiddenBody?: boolean;
  headerProps?: StackProps;
  noFirstItemMargin?: boolean;
}) {
  const windowSize = useWindowSize();
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [orderedChat, setOrderedChat] = useState<IChat[]>([]);
  const chatListRef = useRef();

  useEffect(() => {
    const uniqueChats = uniqBy(chats, (e) => {
      return e.id;
    });
    setOrderedChat(uniqueChats.filter((chat) => chat.status !== 'LEAVE'));
  }, [chats]);

  useEffect(() => {
    const nextHeaderHeight = headerRef.current?.clientHeight ?? 0;
    setHeaderHeight(nextHeaderHeight);
  }, [windowSize, header]);

  const renderChatItem = (chat: IChat) => {
    return (
      <Stack
        key={chat.id}
        onClick={() => {
          if (chat.id !== activeChat?.id) {
            setActiveChat(chat);
          }
          if (setShowLeftSidebar) {
            setShowLeftSidebar(false);
          }
        }}
        sx={{
          cursor: 'pointer',
          p: 2,
          flexDirection: 'row',
          background: activeChat && activeChat?.id === chat.id ? colors.Primary300 : 'transparent',
          borderBottom: `1px solid ${viewMode === CHAT_LIST_VIEW_MODE.CHAT ? colors.BaseGrey : '#f1dd97'}`,
        }}
      >
        <Link href={`/profile/${chat.participants?.[0]?.id}`}>
          {viewMode === CHAT_LIST_VIEW_MODE.CHAT && <Avatar src={chat.participants?.[0].avatar} alt="avatar" sx={{ width: 48, height: 48, mr: 2 }} />}
        </Link>
        <Box flex={1} alignSelf="center" width="100%">
          <ChatListItem
            deal={chat.item}
            chatName={chat?.name}
            chatStatus={chat.status}
            participants={chat.participants}
            updatedAt={chat.updatedAt}
            viewMode={viewMode}
            lastMessage={chat.lastMessage}
            unreadMessages={chat.unreadMessages}
          />
        </Box>
      </Stack>
    );
  };

  const renderChatContainer = () => {
    if (loading) {
      return (
        <Box mt={5}>
          <CircularProgress size={20} />
        </Box>
      );
    }
    if (orderedChat.length === 0) {
      return (
        <Box textAlign="center" color={colors.BaseSecondaryText} fontWeight={400} fontSize={14} mt={3}>
          You have no {CHAT_LIST_VIEW_MODE.CHAT ? 'Chats' : 'Rooms'} here
        </Box>
      );
    }
    return orderedChat.map((chat: any, index) => (
      <>
        {!index && !noFirstItemMargin && <Box height={16} />}
        {renderChatItem(chat)}
      </>
    ));
  };

  return (
    <>
      <Stack ref={headerRef} {...headerProps}>
        {header}
      </Stack>

      {!hiddenBody && (
        <SjScrollBox
          ref={chatListRef}
          id="chat-list"
          style={{
            flex: 1,
            height: `calc(100% - ${headerHeight}px)`,
            overflowX: 'hidden',
          }}
          trackColor={viewMode === CHAT_LIST_VIEW_MODE.CHAT ? 'transparent' : '#d3bc6b'}
        >
          <>
            {activeChat && activeChat.id === 'created-chat' && renderChatItem(activeChat)}
            {renderChatContainer()}
            {orderedChat.length > 0 && nextToken && (
              <Button fullWidth sx={{ textTransform: 'capitalize' }} onClick={() => setPage(page + 1)}>
                Load more
              </Button>
            )}
          </>
        </SjScrollBox>
      )}
    </>
  );
}

export default ChatList;
