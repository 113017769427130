import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ISignupForm } from 'components/Signup/SignupForm';
import { IUser } from 'interface/user';
import { IEditAccountForm } from 'pages/profile/EditAccount';
import { deleteItemStorage, getStorage, tokenKey, tokenRefresh, userKey } from '../utils/localStorageUtil';
import customAxios from '../utils/request';

const SIGNUP_ENDPOINT = '/auth/register';
const RESEND_EMAIL_ENDPOINT = '/auth/email/resend';
const ACTIVATE_ENDPOINT = 'auth/active-user';
const LOGIN_ENDPONIT = '/auth/login';

const FORGOTPW_ENDPOINT = '/auth/forgot-password';
const RESETPW_ENDPOINT = '/auth/reset-password';

interface ILoginData {
  email: '';
  password: '';
}

export type IResponseLoginPayload = {
  access_token: string;
  refresh_token: string;
  expires_in: number;
};

const login = async (payload: ILoginData): Promise<AxiosResponse<IResponseLoginPayload>> => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: LOGIN_ENDPONIT,
    headers: {
      accept: '*/*',
    },
    data: {
      ...payload,
    },
  };

  const response = await customAxios.request({ ...params });
  return response?.data;
};

const userMe = async (token?: string): Promise<AxiosResponse<IUser>> => {
  const header: {
    accept: string;
    Authorization?: string;
  } = {
    accept: '*/*',
  };
  if (token) {
    header.Authorization = `Bearer ${token}`;
  }
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: '/app/users/me',
    headers: header,
  };

  const response = await customAxios.request({ ...params });
  return response.data;
};

const getUser = async () => {
  const token = getStorage(tokenKey);
  const user = getStorage(userKey);

  if (token && user) {
    return Promise.resolve(user);
  }

  return Promise.reject(false);
};

const signup = async (data: ISignupForm): Promise<AxiosResponse<IUser>> => {
  const postData = Object.fromEntries(Object.entries(data).filter(([_, v]) => v.toString().length > 0));
  const businessCategories = postData.business_categories;
  if (typeof businessCategories === 'object') {
    postData.business_categories = businessCategories.map((item: string) => +item);
  }
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: SIGNUP_ENDPOINT,
    headers: {
      accept: '*/*',
    },
    data: postData,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

const resendActiveEmail = async (data: { email: string }): Promise<AxiosResponse> => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: RESEND_EMAIL_ENDPOINT,
    headers: {
      accept: '*/*',
    },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

const activate = async (token: string): Promise<AxiosResponse> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `${ACTIVATE_ENDPOINT}/${token}`,
    headers: {
      accept: '*/*',
    },
  };
  const response = await customAxios.request({ ...params });
  return response;
};

const forgotPassword = async (data: { email: string }): Promise<AxiosResponse> => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: FORGOTPW_ENDPOINT,
    headers: {
      accept: '*/*',
    },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

const logout = () => {
  deleteItemStorage(tokenKey);
  deleteItemStorage(tokenRefresh);
  deleteItemStorage(userKey);
  // window.location.reload();
};

const updateMe = async (
  id: string,
  data: {
    [x in keyof IUser]: string;
  },
) => {
  const params: AxiosRequestConfig = {
    method: 'PATCH',
    url: `/app/users/${id}`,
    headers: {
      accept: '*/*',
    },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const changePassword = async (data: IEditAccountForm): Promise<AxiosResponse> => {
  const params: AxiosRequestConfig = {
    method: 'PATCH',
    url: '/app/users/me/password',
    headers: {
      accept: '*/*',
    },
    data,
  };

  const response = await customAxios.request({ ...params });

  return response?.data;
};

export const resetPassword = async (data: { token: string; new_password: string; new_password_confirm: string }) => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: `${RESETPW_ENDPOINT}/${data.token}`,
    headers: {
      accept: '*/*',
    },
    data,
  };

  await customAxios.request({ ...params });
};

export const refreshToken = async () => {
  const token = getStorage(tokenRefresh);
  if (token) {
    const params: AxiosRequestConfig = {
      method: 'POST',
      url: `/auth/refresh-token`,
      headers: {
        accept: '*/*',
      },
      data: {
        refresh_token: token,
      },
    };
    const resp = await customAxios.request({ ...params });
    return resp.data;
  }
};

export const checkUniqueUsername = async (username: string) => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: `/app/users/username`,
    headers: {
      accept: '*/*',
    },
    data: {
      username,
    },
  };
  const resp = await customAxios.request({ ...params });
  return resp.data;
};

const authProvider = {
  login,
  userMe,
  getUser,
  signup,
  resendActiveEmail,
  activate,
  forgotPassword,
  logout,
  updateMe,
  changePassword,
  resetPassword,
  refreshToken,
  checkUniqueUsername,
};

export default authProvider;
