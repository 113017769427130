import { AxiosRequestConfig } from 'axios';
import { IBusinessCategory } from 'interface/public';
import IDeal from 'interface/deal';
import customAxios from 'utils/request';

const DASHBOARD = '/app/dashboard';

export type IFetchDashboardData = {
  currency_id?: number;
};

export const fetchDashboard = async (
  data?: IFetchDashboardData,
): Promise<{
  category: IBusinessCategory[];
  most_popular: IDeal[];
  newest: IDeal[];
  recommended: IDeal[];
}> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: DASHBOARD,
    params: data,
    headers: {
      accept: '*/*',
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};
