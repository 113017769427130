import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import SjInput from 'components/Form/SjInput';
import { useLocation } from 'react-router-dom';
import { showToast } from 'redux/toast/action';
import authProvider from '../../apis/auth';

type IResendEmailForm = {
  email: string;
};

function ResendEmail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const [countDown, setCountDown] = useState(0);
  const initResendEmailForm = {
    email: location.state.email,
  };
  const resendEmailFormRef = useRef<FormikProps<IResendEmailForm>>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (countDown >= 0) {
        setCountDown((preState) => preState - 1);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [countDown]);

  const resetSubmitButton = () => {
    const timeOutInSecond = 10;
    setCountDown(timeOutInSecond);
    setTimeout(() => {
      resendEmailFormRef.current?.setSubmitting(false);
    }, timeOutInSecond * 1000);
  };

  const onSubmit = (data: IResendEmailForm) => {
    authProvider
      .resendActiveEmail(data)
      .then(() => {
        dispatch(
          showToast({
            type: 'success',
            title: `An email has been sent to ${data.email}`,
          }),
        );
        resetSubmitButton();
      })
      .catch((err) => {
        dispatch(
          showToast({
            type: 'error',
            title: err.response.data.error.message ?? 'Oops! Something went wrong!.',
          }),
        );
        resetSubmitButton();
      });
  };

  return (
    <Stack>
      <Typography variant="h2" textAlign="center" mb={6}>
        Resend Email
      </Typography>
      <Card variant="outlined" sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Formik
            innerRef={resendEmailFormRef}
            initialValues={initResendEmailForm}
            validate={(values) => {
              const errors: FormikErrors<IResendEmailForm> = {};
              if (!values.email) {
                errors.email = 'Please enter email address.';
              }
              return errors;
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <SjInput name="email" label="Email" placeholder="Enter email" />

                <Box textAlign="center">
                  <Button type="submit" variant="contained" fullWidth sx={{ mb: 4, height: 50 }} disabled={isSubmitting}>
                    {countDown <= 0 ? 'Submit' : `Wait ${countDown}...`}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default ResendEmail;
