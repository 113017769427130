import { useLazyQuery, useSubscription } from '@apollo/react-hooks';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Box, Button, Divider, List, ListItem, Stack, Typography, Link } from '@mui/material';
import { FILE_UPDATED_SUBSCRIPTION, GET_FILES_IN_ROOM } from 'apis/graphql';
import colors from 'constants/colors';
import dayjs from 'dayjs';
import { renderDateByFormat } from 'utils/dateFormat';
import { useEffect, useRef, useState } from 'react';
import { renderFileIcon } from 'utils/fileIcon';
import { CHAT_STATUSES, IRoomFile } from 'interface/chat';
import { convertBytes } from 'utils/convertBytes';
import SjScrollBox from 'components/Layout/SjScrollBox';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import ChatFileAction from './ChatFileAction';

dayjs.extend(relativeTime);

const MUTATION_FILE_TYPE = {
  FILE_DELETED: 'FILE_DELETED',
};

function ChatFiles({ roomId, roomOwner, roomStatus }: { roomId: string; roomOwner?: string; roomStatus: CHAT_STATUSES }) {
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUser = auth.user?.id.toString();
  const [uploadedFiles, { data }] = useLazyQuery(GET_FILES_IN_ROOM);
  const { data: fileSubscription } = useSubscription(FILE_UPDATED_SUBSCRIPTION);
  const [nextToken, setNextToken] = useState();
  const [page, setPage] = useState(0);
  const [files, setFiles] = useState<IRoomFile[]>([]);
  // const [selectedFile, setSelectedFile] = useState<IRoomFile | null>(null);
  const fileListRef = useRef();
  const fetchFiles = (noNextToken?: boolean) => {
    uploadedFiles({
      variables: {
        roomId,
        limit: 5,
        nextToken: noNextToken ? '' : nextToken,
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'standby',
    });
  };

  useEffect(() => {
    const fileUpdated = fileSubscription?.fileUpdated?.files?.[0];

    if (fileUpdated && fileSubscription?.fileUpdated?.roomId === roomId) {
      if (fileSubscription?.fileUpdated.type === MUTATION_FILE_TYPE.FILE_DELETED) {
        const updatedFiles = files.filter((item) => item.fileId !== fileUpdated.fileId);
        setFiles(updatedFiles);
      } else {
        setFiles([
          {
            ...fileUpdated,
            isNew: true,
          },
          ...files,
        ]);
      }
    }
  }, [fileSubscription]);

  useEffect(() => {
    setNextToken(undefined);
    fetchFiles(true);
    setFiles([]);
  }, [roomId]);

  useEffect(() => {
    if (data?.uploadedFiles) {
      if (data.uploadedFiles.items) {
        setFiles(page === 0 ? data.uploadedFiles.items : [...files, ...data.uploadedFiles.items]);
      }
      setNextToken(data.uploadedFiles.nextToken);
    }
  }, [data?.uploadedFiles]);

  return (
    <SjScrollBox style={{ flex: 1, height: 'calc(100% - 130px)' }} ref={fileListRef} id="file-list">
      <>
        <List>
          {files.map((item) => (
            <ListItem divider key={item.fileId} sx={{ display: 'flex' }}>
              <Box width={30} sx={{ cursor: 'pointer' }}>
                <Link target="_blank" href={`//${item.fileUrl}`}>
                  {renderFileIcon(item.fileExt)}
                </Link>
              </Box>
              <Stack ml={2} flex={1} direction="row" justifyContent="space-between">
                <Box>
                  <Link target="_blank" href={`//${item.fileUrl}`}>
                    <Typography color={colors.Secondary} fontWeight={500} sx={{ wordBreak: 'break-all', cursor: 'pointer' }}>
                      {item.fileName}
                    </Typography>
                  </Link>

                  <Stack direction="row" fontSize={14} color={colors.BaseSecondaryText} flexWrap="wrap">
                    <Box mr={1}>{convertBytes(item.fileSize)}</Box>
                    <Box mr={1}>{renderDateByFormat(item.createdAt, false, false, true)}</Box>
                    <Box mr={1}>{item.user.id === currentUser ? 'Me' : item.user.username}</Box>
                    {item.isNew && <Box color="red">New</Box>}
                  </Stack>
                </Box>
                <ChatFileAction roomId={roomId} file={item} roomOwner={roomOwner} roomStatus={roomStatus} />
              </Stack>
              <Divider />
            </ListItem>
          ))}
        </List>
        {nextToken && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                setPage(page + 1);
                fetchFiles();
              }}
            >
              Load more
            </Button>
          </Box>
        )}
      </>
    </SjScrollBox>
  );
}

export default ChatFiles;
