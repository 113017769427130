import { Box, Link, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import IDeal, { DEALSTATUS, DEALTYPE } from 'interface/deal';
import colors from 'constants/colors';
import { Lock } from 'react-feather';
import { useBreakpoints } from 'themes';
import SjMedia from 'components/Layout/SjMedia';
import ListingItemOwner from './ListingItemOwner';
import ListingItemPrice from './ListingItemPrice';
import ListingItemActions from './ListingItemActions';

function ListingContainer({ deal, actions, hideOwner, onUpdated }: { deal: IDeal; actions?: string[]; hideOwner?: boolean; onUpdated: any }) {
  const location = useLocation();
  const isUpXlScreen = useBreakpoints((breakpoints) => breakpoints.up('xl'));
  const isBlocked = deal.status === DEALSTATUS.BLOCKED;
  const opacityStyle = { opacity: isBlocked ? 0.5 : 1 };
  const LinkComp: React.ElementType = isBlocked ? Box : Link;

  return (
    <Box position="relative">
      <Box>
        <Box sx={opacityStyle}>{!hideOwner && <ListingItemOwner owner={deal.owner} createdAt={deal.created_at} />}</Box>
        <LinkComp
          href={`/deal/view/${deal.id}?location=${location.pathname}`}
          sx={{
            display: 'block',
            position: 'relative',
            '&:after': {
              background: colors.White,
              clear: 'both',
              content: '""',
              display: deal.status === 'CLOSED' ? 'block' : 'none',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              opacity: 0.5,
            },
          }}
        >
          <Box sx={opacityStyle}>
            <SjMedia media={deal?.photos?.[0]} width="100%" height={isUpXlScreen ? 180 : 130} objectFit="cover" alt={deal.owner?.first_name} />
          </Box>
          {deal.private && (
            <Box
              sx={{
                position: 'absolute',
                top: 4,
                left: 4,
                background: colors.Primary500,
                borderRadius: '100%',
                width: '25px',
                height: '25px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Lock size={14} color={colors.Secondary500} />
            </Box>
          )}
          {deal.type === DEALTYPE.BUYING && (
            <Box
              sx={{
                position: 'absolute',
                top: 8,
                right: 5,
                background: colors.Primary500,
                borderRadius: 3,
                height: '25px',
                px: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h6" sx={{ color: colors.White }}>
                LOOKING TO BUY
              </Typography>
            </Box>
          )}
          {deal.status !== DEALSTATUS.CLOSED && dayjs(deal.available_until).isBefore(dayjs()) && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 5,
                background: colors.Base400,
                color: colors.BaseSecondaryText,
                p: '3px 6px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '13px',
                zIndex: 10,
              }}
            >
              EXPIRED
            </Box>
          )}
          {deal.status === DEALSTATUS.CLOSED && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 5,
                background: colors.Error300,
                color: colors.Error500,
                p: '3px 6px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '13px',
                zIndex: 10,
              }}
            >
              CLOSED
            </Box>
          )}
          {isBlocked && (
            <Box
              sx={{
                position: 'absolute',
                bottom: 8,
                right: 5,
                background: colors.Error300,
                color: colors.Error500,
                p: '3px 6px',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '13px',
                zIndex: 10,
              }}
            >
              BLOCKED
            </Box>
          )}
        </LinkComp>
      </Box>

      <Box flex={1} sx={opacityStyle}>
        <Stack direction="row" alignItems="center" gap={0.2} mt={2} mb={1}>
          <FmdGoodIcon sx={{ fill: colors.White, stroke: colors.BaseText, mr: '2px', width: 14, strokeWidth: 2 }} />
          <Typography fontSize={14} color={colors.BaseText} fontWeight={500}>
            {deal.countries.slice(0, 3).map((item, index) => (
              <span key={item.id}>{`${item.name}${index < 2 && index < deal.countries.length - 1 ? ', ' : ''} `}</span>
            ))}
            {deal.countries.length > 3 ? '...' : ''}
          </Typography>
        </Stack>
        <LinkComp
          href={`/deal/view/${deal.id}?location=${location.pathname}`}
          sx={{ '&:hover': { color: colors.Secondary }, fontWeight: 600, wordWrap: 'break-word' }}
          variant="h5"
        >
          {deal.name}
        </LinkComp>
        <ListingItemPrice symbol={deal.currency?.symbol} minPrice={deal.display_min_price} maxPrice={deal.display_max_price} />
        <Typography fontWeight={500} fontSize={14} mt={2} color={colors.BaseText} sx={{ wordWrap: 'break-word' }}>
          {deal.description.slice(0, 60) + (deal.description.length > 60 ? '...' : '')}
        </Typography>
      </Box>
      <ListingItemActions deal={deal} actions={actions} onUpdated={onUpdated} />
    </Box>
  );
}

export default ListingContainer;
