export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: ['calc(100% - 64px)', '50%', '400px'],
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  borderRadius: '16px',
};
