import { Breakpoints, ButtonProps, createTheme, Theme, useMediaQuery, useTheme } from '@mui/material';
import colors from 'constants/colors';
import { LinkProps } from '@mui/material/Link';
import LinkBehavior from 'components/Layout/LinkBehavior';
import { setAppBar } from './app-bar';
import { setFormStyles } from './form';

export const setDisplayDesktopOnly = (display: 'block' | 'flex') => ['none', 'none', display, display];
export const setDisplayMobileOnly = (display: 'block' | 'flex') => [display, display, 'none', 'none'];

export const getSelectPropsStyle = (selectedIds: any, isMultiple?: boolean, borderRadius?: string, title?: string) => ({
  '&.MuiInputBase-root': {
    paddingLeft: title ? 0 : '12px',
    borderRadius: borderRadius ?? '100px',
    backgroundColor: selectedIds && selectedIds.length > 0 && selectedIds !== 'all' ? colors.Primary300 : 'inherit',
  },
  '&.MuiInputBase-root .MuiInputBase-inputAdornedStart': {
    marginLeft: isMultiple ? '-8px' : '0',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: borderRadius ?? '100px',
    borderColor: selectedIds && selectedIds.length > 0 && selectedIds !== 'all' ? colors.Primary : colors.BaseGrey,
  },
  '& .MuiSelect-select.MuiOutlinedInput-input': {
    fontWeight: 500,
    color: colors.BaseText,
    paddingLeft: 0,
  },
});

export const getPaperPropsFilterSearchStyle = (showClearAll: boolean, isMultiple?: boolean) => ({
  sx: {
    borderRadius: '16px',
    width: '250px',
    maxHeight: '500px',
    marginTop: isMultiple ? '10px' : 0,
    '& .MuiList-root': {
      marginTop: showClearAll ? '-50px' : 0,
      paddingBottom: showClearAll ? 0 : '5px',
    },
    '& .MuiList-root .MuiMenuItem-root': {
      paddingLeft: 1,
      whiteSpace: 'break-spaces',
      py: 1,
      '&:last-child': {
        paddingBottom: showClearAll ? '80px' : 'inherit',
      },
    },
    '& .MuiList-root .MuiMenuItem-root.Mui-selected': {
      color: colors.BaseSecondaryText,
      background: 'transparent',
    },
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      background: colors.Base300,
      marginY: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: colors.BaseSecondaryText,
    },
  },
});

const breakpointsTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1800,
    },
  },
});

export const useBreakpoints = (selector: (breakpoints: Breakpoints) => string) => {
  const theme = useTheme<typeof breakpointsTheme>();
  const isValid = useMediaQuery(selector(theme.breakpoints));
  return isValid;
};

export const theme: Theme = createTheme({
  palette: {
    mode: 'light',
  },
  breakpoints: breakpointsTheme.breakpoints,
  typography: {
    fontSize: 14,
    fontFamily: 'Work Sans',
    h1: {
      fontSize: '40px',
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      letterSpacing: '-2',
      color: colors.Header,
      '@media (min-width:900px)': {
        fontSize: '80px',
      },
      '@media (max-width:600px)': {
        fontSize: '28px',
        letterSpacing: '-2',
        color: colors.Header,
      },
    },
    h2: {
      fontSize: '40px',
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      letterSpacing: '-2',
      color: colors.Header,
      '@media (max-width: 600px)': {
        fontSize: '21px',
        lineHeight: '26.31px',
      },
    },
    h3: {
      fontSize: '28px',
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      letterSpacing: '-2',
      color: colors.Header,
    },
    h4: {
      fontSize: '21px',
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      color: colors.Header,
    },
    h5: {
      fontSize: '18px',
      fontFamily: 'Source Serif Pro',
      fontWeight: 600,
      color: colors.Black,
    },
    h6: {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '25px',
      color: colors.Base600,
    },
    body1: {
      fontSize: '16px',
      fontWeight: 500,
      color: colors.BaseText,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          paddingLeft: '6px',
          paddingRight: '6px',
        },
        deleteIcon: {
          fontSize: '17px',
          margin: '0 3px 0 -3px',
        },
        root: () => ({
          height: '26px',
          '&.MuiChip-colorSecondary': {
            backgroundColor: colors.Secondary500,
            color: colors.White,
            fontSize: '12px',
            fontWeight: 700,
          },
          '&.MuiChip-colorError': {
            backgroundColor: colors.Error300,
            color: colors.Error500,
            fontSize: '12px',
            fontWeight: 700,
          },
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: () => ({
          minHeight: '50px !important',
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: (props) => ({
          [props.theme.breakpoints.up('xl')]: {
            maxWidth: 1800,
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 21px rgba(105, 88, 26, 0.15)',
          margin: '0 auto',
          border: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: () => ({
          textDecoration: 'none',
          fontWeight: 700,
          color: colors.Header,
          '&:hover': {
            color: colors.Secondary500,
          },
          '&.Mui-focusVisible': {
            border: 'none',
            outline: 0,
            color: colors.Secondary,
          },
        }),
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          paddingBottom: 15,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        ul: {
          justifyContent: 'center',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.MuiMenuItem-active': {
            background: colors.Primary300,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingLeft: '8px',
          paddingRight: '8px',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          paddingTop: 0,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorSecondary: {
          color: colors.Header,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }: { ownerState: ButtonProps }) => ({
          ...(ownerState.variant === 'text' && {
            color: colors.Secondary,
            textTransform: 'uppercase',
            fontWeight: 700,
            '&:hover': {
              color: colors.Secondary500,
            },
          }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'success' && {
              backgroundColor: '#0DAB85',
            }),
          ...(ownerState.variant === 'contained' && {
            marginTop: 1,
            fontSize: '16px',
            fontWeight: 700,
            color: colors.White,
            borderRadius: '100px',
            boxShadow: 'none',
            minWidth: '100px',
            '&:disabled': {
              opacity: 0.5,
            },
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'error' && {
              border: `2px solid ${colors.Error500}`,
              borderRadius: '100px',
              fontWeight: 700,
              color: colors.Error500,
              fontSize: '16px',
              padding: '8px 20px',
              marginBottom: 5,
              '&:hover': {
                border: `2px solid ${colors.Error500}`,
                backgroundColor: colors.Error300,
                color: colors.Error500,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              border: `2px solid ${colors.Secondary}`,
              borderRadius: '100px',
              fontWeight: 700,
              color: colors.Secondary,
              fontSize: '16px',
              padding: '8px 20px',
              marginBottom: 5,
              '&:hover': {
                border: `2px solid ${colors.Secondary}`,
                backgroundColor: colors.Secondary,
                color: '#fff',
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: colors.Secondary,
              paddingTop: '12px',
              paddingBottom: '12px',
              '&:hover': {
                backgroundColor: colors.Secondary500,
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              backgroundColor: colors.Header,
              '&:hover': {
                backgroundColor: colors.Header,
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'info' && {
              backgroundColor: colors.Base500,
              color: colors.Header,
              '&:hover': {
                backgroundColor: colors.Header,
                color: colors.White,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'info' && {
              backgroundColor: colors.White,
              color: colors.BaseText,
              borderColor: colors.BaseGrey,
              borderWidth: 2,
              borderRadius: '100px',
              textTransform: 'capitalize',
              '&.MuiButton-active': { borderWidth: 2, borderColor: colors.Primary, color: colors.BaseText, background: colors.Primary300 },
              '&:hover': { borderWidth: 2, borderColor: colors.Primary, color: colors.BaseText, background: colors.Primary300 },
            }),
        }),
      },
    },
    ...setFormStyles(),
    ...setAppBar(),
  },
});
