export const usernameHelpText =
  'Username should be between 3 to 50 characters, without space, can contain letters (a-z), numbers (0-9), period (.), hyphen (-), and underscore (_).\n Must not contain special characters (e.g: [ ] { } *, @,$, #, etc.)';

export const maxUsernameCharacter = 50;
export const minUsernameCharacter = 3;

const BlockTextMessage = {
  VIOLATE_TERMS_CONDITION: 'The listing was found to be in violation of our Terms & Conditions.',
  ABLE_VIEW_DOWNLOAD: 'You can still view the chat and download files, but all other actions are disabled.',
  CONTINUE_CHAT_IN_ROOM: 'You can continue chatting in Room instead.',
};
export const toBlockText = (blockText: {
  target: 'Room' | 'chat';
  status: 'cancelled' | 'archived' | 'Marked as Complete';
  messageList: (keyof typeof BlockTextMessage)[];
}) => {
  const title = `This ${blockText.target} has been ${blockText.status}.`;
  const message = blockText.messageList.map((part) => BlockTextMessage[part]).join(' ');

  return {
    title,
    message,
  };
};

export const ROOMS_LIMIT_PER_LOAD = 10;

export const DEFAULT_CURRENCY = '$ (USD)';
