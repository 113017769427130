/* eslint-disable no-alert */
import { useLazyQuery } from '@apollo/react-hooks';
import { Edit } from '@mui/icons-material';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { ListItemIcon, MenuItem, MenuList, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getDealById } from 'apis/deal';
import { GET_ROOM_BY_LISTING_ID } from 'apis/graphql';
import DealChatAction from 'components/Deal/DealActions/DealChatAction';
import DealCloseAction from 'components/Deal/DealActions/DealCloseActions';
import DealInfo from 'components/Deal/DealInfo';
import DealInvitationAcceptBox from 'components/Deal/DealInvitationAcceptBox';
import DealPhotos from 'components/Deal/DealPhotos';
import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import SjHelmet from 'components/Layout/SjHelmet';
import ListingItemAcceptBox from 'components/Listing/ListingItemAccept';
import ProfileName from 'components/Profile/ProfileName';
import SjTabsScreen from 'components/Profile/SjTabsScreen';
import colors from 'constants/colors';
import { DealContext } from 'context/DealContext';
import { useMainContext } from 'context/MainContext';
import IDeal, { DEALSTATUS, initDeal } from 'interface/deal';
import { IFormattedCurrency } from 'interface/public';
import { IReduxState } from 'interface/redux';
import { UserType } from 'interface/user';
import { useEffect, useState } from 'react';
import 'react-gallery-carousel/dist/index.css';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useBreakpoints } from 'themes';
import { getDisplayName } from 'utils/getDisplayName';
import useQuery from 'utils/useQuery';
import UserCurrency from 'utils/userCurrency';

function Deal() {
  const MainContext = useMainContext();
  const theme = useTheme();
  const isDownLg = useBreakpoints((breakpoints) => breakpoints.down('lg'));
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUserId = auth.user?.id?.toString() ?? '';
  const [deal, setDeal] = useState<IDeal>(initDeal);
  const [error, setError] = useState(false);
  const query = useQuery();
  const token = query.get('token');
  const invitationType = query.get('type');

  const { id } = useParams();

  const [getRoomByListingId, { data: roomListData }] = useLazyQuery(GET_ROOM_BY_LISTING_ID);
  const navigate = useNavigate();
  const [rooms, setRooms] = useState<any>(null);

  useEffect(() => {
    if (currentUserId && deal.id) {
      getRoomByListingId({ variables: { itemIds: deal.id }, fetchPolicy: 'network-only' });
    }
  }, [currentUserId, deal]);

  useEffect(() => {
    if (roomListData?.roomsByItemIds?.items) {
      setRooms(roomListData.roomsByItemIds.items[0]);
    }
  }, [roomListData]);

  const fetchDeal = (nextCurrency: IFormattedCurrency | null) => {
    if (!id) return;
    if (token && !currentUserId) {
      setError(true);
      return false;
    }
    const opts = { convert_currency_id: nextCurrency?.value };
    getDealById(id, undefined, opts)
      .then((resp) => {
        setDeal({
          ...resp,
          photos: resp.photos.map((item) => ({
            ...item,
            type: item.url.includes('mov') || item.url.includes('mp4') ? 'video' : 'image',
          })),
        });
      })
      .catch((err) => {
        MainContext.setPageType('error');
        setError(true);
      });
  };

  UserCurrency.useOnChangeEffect(fetchDeal);

  const onUpdateDeal = (updatedDeal: IDeal) => {
    setDeal({
      ...updatedDeal,
    });
  };

  const setUpdatedRoomInvitation = (updatedInvitation: any) => {
    setRooms({ itemId: updatedInvitation.item?.id, roomId: updatedInvitation.id, totalRoom: 1 });
  };

  useEffect(
    () => () => {
      if (!error) {
        return;
      }
      MainContext.setPageType('normal');
    },
    [],
  );

  if (error) {
    return (
      <Container sx={{ textAlign: 'center', height: '100%', mt: 6 }}>
        <Typography variant="h2">You do not have access to this listing.</Typography>
        {!auth.user ? (
          <>
            <Typography mt={2} fontWeight={500}>
              If you are invited to this listing, please log in to continue.
            </Typography>
            <Box mt={5}>
              <Button variant="contained" onClick={() => navigate(`/login?back=/deal/view/${id}?token=${token}`)}>
                LOG IN
              </Button>
              <Typography mt={2}>
                Don't have an account?{' '}
                <Button type="button" variant="text" onClick={() => navigate('/sign-up')}>
                  SIGN UP
                </Button>
              </Typography>
            </Box>
          </>
        ) : (
          <Typography mt={2} fontWeight={500}>
            Please check your permission and try again
          </Typography>
        )}
      </Container>
    );
  }

  if (!deal.name) {
    return (
      <Container sx={{ mt: 6, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  const ownerLink = deal.owner?.id.toString() === currentUserId ? '/my-profile' : `/profile/${deal.owner?.id}`;

  const renderDealChatAction = () => {
    if (!auth.user) {
      return (
        <>
          <Divider sx={{ width: 'calc(100% + 80px)', mx: -6 }} />
          <Box mt={3}>
            Interested in this listing? Log in now and get a chat going with {getDisplayName(deal.owner)}!
            <Stack mt={3} alignItems="center">
              <Button type="button" variant="contained" fullWidth sx={{ py: '10px' }} onClick={() => navigate('/login')}>
                Log in
              </Button>

              <Box mt={2}>
                Don't have an account?{' '}
                <Link href="/sign-up" sx={{ color: colors.Secondary }}>
                  SIGN UP
                </Link>
              </Box>
            </Stack>
          </Box>
        </>
      );
    }
    if (auth.user.role !== UserType.Superadmin) {
      return <DealChatAction rooms={rooms} />;
    }
    return '';
  };

  const renderOwner = (ownerProps: { type: 'full' | 'snippet' }) => (
    <>
      <Stack direction="row" gap={0.2} {...(ownerProps.type === 'full' && { mb: 4 })} px={0}>
        <Link href={ownerLink}>
          <Avatar src={deal.owner?.avatar} alt={`${deal.owner?.first_name} ${deal.owner?.last_name}`} sx={{ width: 80, height: 80 }} />
        </Link>

        <Box ml={4}>
          <Link href={ownerLink}>
            <ProfileName profile={deal.owner} variant="h3" />
          </Link>

          <Typography color={colors.BaseSecondaryText} mt={1}>
            {deal.owner?.interests?.reduce((accumulator, currentValue) => `${accumulator}${accumulator.length > 0 ? ',' : ''} ${currentValue.name}`, '')}
          </Typography>

          <Stack direction="row" mt={2}>
            <FmdGoodIcon sx={{ fill: colors.White, stroke: colors.BaseSecondaryText, mr: 0.25, strokeWidth: 2 }} />
            <Typography>{deal.owner?.country?.name}</Typography>
          </Stack>
        </Box>
      </Stack>

      {ownerProps.type === 'full' && (
        <>
          {renderDealChatAction()}

          {auth.user?.id === deal.owner.id && deal.status !== DEALSTATUS.CLOSED && (
            <Box mt={5}>
              <Divider sx={{ width: 'calc(100% + 48px)', mx: -3 }} />

              <Box pt={3} px={0}>
                <MenuList dense>
                  <MenuItem sx={{ mb: 1, px: 0 }} onClick={() => navigate(`/deal/edit/${deal.id}`)}>
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    <Typography color={colors.BaseText}>Edit listing</Typography>
                  </MenuItem>
                  <DealCloseAction roomsLength={rooms?.totalRoom ?? 0} />
                </MenuList>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );

  return (
    <DealContext.Provider
      value={{
        deal,
        onUpdated: onUpdateDeal,
        rooms,
      }}
    >
      <SjHelmet title={deal.name} />
      <Box>
        <SjTabsScreen
          title={deal.name}
          CustomTitle={isDownLg && <Box />}
          leftGridProps={{ xl: 8, lg: 7, ...(isDownLg && { p: 0 }) }}
          rightGridProps={{ xl: 4, lg: 5 }}
          rightCardStyle={{ ...(!isDownLg && { minWidth: 'unset', padding: theme.spacing(1), paddingBottom: 0 }) }}
          rightCardContentStyle={{ paddingBottom: theme.spacing(2) }}
          left={
            isDownLg ? (
              <>
                {deal.photos && <DealPhotos noRadius />}
                <Box sx={{ px: 3 }}>
                  <SJBreadcrumbs title={deal.name} />
                  <Box sx={{ mt: 3 }}>
                    <DealInfo noCountries hasActions />
                  </Box>

                  <Divider sx={{ mt: 3 }} />
                  <Box sx={{ mt: 3 }}>{renderOwner({ type: 'snippet' })}</Box>
                </Box>
              </>
            ) : (
              <>
                {deal.photos && <DealPhotos />}
                <Box sx={{ mt: 4 }}>
                  <DealInfo />
                </Box>
              </>
            )
          }
          right={!isDownLg && renderOwner({ type: 'full' })}
        />

        {isDownLg && <DealChatAction rooms={rooms} type="footer" />}
        {deal.private && (deal.invitation_id ?? '').length > 0 && !deal.invitee_accepted && <ListingItemAcceptBox deal={deal} onUpdated={onUpdateDeal} />}
        {invitationType === 'ROOM_INVITATION' && (
          <DealInvitationAcceptBox deal={deal} onUpdated={onUpdateDeal} token={token || ''} setUpdatedRoomInvitation={setUpdatedRoomInvitation} />
        )}
      </Box>
    </DealContext.Provider>
  );
}

export default Deal;
