import { Box, Container, CircularProgress, IconButton, Stack } from '@mui/material';
import { useLazyQuery } from '@apollo/react-hooks';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useRef, useState } from 'react';
import uniqBy from 'lodash/uniqBy';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { CHAT_TABS, IChatGraphqlResponse, IChat, CHAT_TYPES } from 'interface/chat';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { GET_ROOMS, GET_DIRECT_ROOM, GET_LISTING_ROOMS, SEARCH_CHATS } from 'apis/graphql';
import ChatTabs from 'components/Chat/ChatTabs';
import { convertChatBoxItemApi, generateNewChat, orderRoomsByActiveRoom } from 'utils/convertApiChatToWebChat';
import useQuery from 'utils/useQuery';
import { getDealById } from 'apis/deal';
import { getDisplayName } from 'utils/getDisplayName';
import { getUserInfo } from 'apis/user';
import colors from 'constants/colors';
import ChatSearch from 'components/Chat/Search/ChatSearch';
import ChatList, { CHAT_LIST_VIEW_MODE } from 'components/Chat/ChatList';
import ChatItem from 'components/Chat/ChatItem';
import { ROOMS_LIMIT_PER_LOAD } from 'constants/index';
import ChatTabContainer from 'components/Chat/ChatTab';
import { useMainContext } from 'context/MainContext';
import SJFooter from 'components/Layout/SjFooter';
import { useBreakpoints } from 'themes';
import UserCurrency from 'utils/userCurrency';
import SjHelmet from 'components/Layout/SjHelmet';

function ChatPage() {
  const MainContext = useMainContext();
  const isUpLg = useBreakpoints((breakpoints) => breakpoints.up('lg'));

  const isMountRef = useRef(false);

  const [searchContentInChats, { data: searchRoomData, loading: searchLoading }] = useLazyQuery(SEARCH_CHATS);
  const [getListingRooms, { loading: listingRoomLoading }] = useLazyQuery(GET_LISTING_ROOMS);
  const [getDirectRoom, { loading: redirectRoomLoading }] = useLazyQuery(GET_DIRECT_ROOM);
  const [getRooms, { data: chatData, loading: roomLoading }] = useLazyQuery(GET_ROOMS);

  const params = useParams();
  const navigate = useNavigate();
  const query = useQuery();
  const currentUserId = useSelector((state: IReduxState) => state?.auth.user?.id?.toString() ?? '');
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [chats, setChats] = useState<IChat[]>([]);
  const [nextToken, setNextToken] = useState();
  const [activeChat, setActiveChat] = useState<IChat | null>(null);
  const [page, setPage] = useState(0);
  const [showLeftSidebar, setShowLeftSidebar] = useState<boolean>(false);
  const [isSearch, setIsSearch] = useState(false);

  const tabId = query.get('tab');
  const dealId = query.get('deal');
  const userId = query.get('user');

  const loading = listingRoomLoading || redirectRoomLoading || roomLoading;

  useEffect(() => {
    setActiveTab(tabId ? +tabId : 0);
    setShowLeftSidebar(true);
    setNextToken(undefined);
  }, [tabId]);

  const generateNewListingRoom = async (id: string, type: CHAT_TYPES) => {
    try {
      const listingInfo = await getDealById(id);

      const chat = generateNewChat(type, undefined, listingInfo);

      chat.participants = [
        {
          id: listingInfo.owner.id.toString(),
          avatar: listingInfo.owner.avatar,
          fullname: getDisplayName(listingInfo.owner),
          email: listingInfo.owner.email,
          username: listingInfo.owner.username,
        },
      ];

      return chat;
    } catch (err) {
      // navigate(`/chats?tab=${tabId ?? 0}`);
      return null;
    }
  };

  const generateNewDirectRoom = async (id: string) => {
    try {
      const userInfo = await getUserInfo(id);
      const chat = generateNewChat(CHAT_TYPES.DIRECT, userInfo);
      return chat;
    } catch (err) {
      navigate(`/chats?tab=2`);
      return null;
    }
  };

  const fetchRooms = (type: string) => {
    if (nextToken) {
      getRooms({ variables: { type: type.toUpperCase(), limit: ROOMS_LIMIT_PER_LOAD, nextToken }, fetchPolicy: 'network-only', nextFetchPolicy: 'standby' });
    } else {
      getRooms({ variables: { type: type.toUpperCase(), limit: ROOMS_LIMIT_PER_LOAD }, fetchPolicy: 'network-only', nextFetchPolicy: 'standby' });
    }
  };

  useEffect(() => {
    if (page > 0 && typeof activeTab === 'number') {
      const type = CHAT_TABS[activeTab].value;
      fetchRooms(type);
    }
  }, [page]);

  const getListingRoomsGraphql = async (type: CHAT_TYPES, did: string) => {
    const { data } = await getListingRooms({ variables: { type, itemId: did }, fetchPolicy: 'network-only' });
    const filterByChatOnly = data.listingRooms?.items.filter((item: IChatGraphqlResponse) => item.roomType === type);

    if (filterByChatOnly.length > 0) {
      setActiveChat(convertChatBoxItemApi(filterByChatOnly[0], currentUserId));
    } else {
      const newChat = await generateNewListingRoom(did, type);
      if (newChat) {
        setActiveChat(newChat);
      }
    }
    fetchRooms(type);
  };

  const getDirectRoomGraphql = async (type: CHAT_TYPES, uid: string) => {
    const { data } = await getDirectRoom({ variables: { userId: uid }, fetchPolicy: 'network-only' });
    if (data.directRoom) {
      setActiveChat(convertChatBoxItemApi(data.directRoom, currentUserId));
    } else {
      const newChat = await generateNewDirectRoom(uid);
      setActiveChat(newChat);
    }
    fetchRooms(type);
  };

  useEffect(() => {
    if (typeof activeTab === 'number') {
      const type = CHAT_TABS[activeTab].value;

      if (dealId) {
        getListingRoomsGraphql(type, dealId);
      } else if (userId) {
        getDirectRoomGraphql(type, userId);
      } else {
        fetchRooms(type);
      }
    }
  }, [params.id, activeTab]);

  useEffect(() => {
    if (searchRoomData?.searchContentInChats?.items) {
      setChats(orderRoomsByActiveRoom(searchRoomData?.searchContentInChats?.items, currentUserId, activeChat, true));
      setNextToken(undefined);
    } else {
      setChats([]);
    }
  }, [searchRoomData]);

  useEffect(() => {
    if (chatData?.rooms?.items) {
      const items = orderRoomsByActiveRoom(chatData.rooms.items, currentUserId, activeChat);
      const roomItems = page === 0 ? items : uniqBy([...chats, ...items], 'id');
      setChats(roomItems);
      if (!activeChat && items[0]) {
        setActiveChat(items[0]);
      }
    }
    setNextToken(chatData?.rooms.nextToken);
  }, [chatData]);

  const debounceOnSearchRoom = debounce((value: string) => {
    setIsSearch(true);
    searchContentInChats({ variables: { type: CHAT_TABS[activeTab ?? 0].value, text: value }, fetchPolicy: 'network-only' });
  }, 1200);

  const display = showLeftSidebar ? 'block' : 'none';

  useEffect(() => {
    if (activeChat === null && chats.length === 0 && !isSearch) {
      MainContext.setPageType('no-result');
      return () => MainContext.setPageType('normal');
    }
    MainContext.setPageType('chat');
    return () => MainContext.setPageType('normal');
  }, [activeChat, chats.length, isSearch]);

  useEffect(() => {
    if (isUpLg) {
      return;
    }
    SJFooter.current.setVisible(false);
    return () => SJFooter.current.setVisible(true);
  }, [isUpLg]);

  UserCurrency.useOnChangeEffect(async () => {
    if (!isMountRef.current) {
      isMountRef.current = true;
      return;
    }

    if (typeof activeTab === 'number') {
      setActiveChat(null);
      const type = CHAT_TABS[activeTab].value;
      getRooms({
        variables: { type: type.toUpperCase(), limit: ROOMS_LIMIT_PER_LOAD },
      });
    }
  });

  const renderTitle = () => {
    switch (activeTab) {
      case 1:
        return 'Chat - General Listings';
      case 2:
        return 'Chat - Direct Chats';
      default:
        return 'Chat - My Listings';
    }
  };

  return (
    <>
      <SjHelmet title={renderTitle()} />
      {activeTab === null && <CircularProgress />}
      {activeTab !== null && (
        <Container sx={{ display: 'flex', flex: 1, mb: 3, mt: 2 }}>
          <Stack sx={{ flex: 1, width: '100%' }}>
            <ChatTabs tabs={CHAT_TABS} activeTab={activeTab} setActiveChat={setActiveChat} />
            <ChatTabContainer loading={loading} totalChats={chats.length} isSearch={isSearch} isActiveChat={activeChat !== null}>
              <Box
                width={['auto', 'auto', 'auto', '300px']}
                borderRight={`1px solid ${colors.BaseGrey}`}
                overflow="hidden"
                position={['absolute', 'absolute', 'absolute', 'static']}
                left={0}
                right={0}
                top={0}
                bottom={0}
                display={[display, display, display, 'block']}
                zIndex={5}
                bgcolor="white"
              >
                <ChatList
                  headerProps={{ ...(searchLoading && { sx: { mb: 2 } }) }}
                  header={typeof activeTab === 'number' && <ChatSearch onSearch={debounceOnSearchRoom} />}
                  chats={chats}
                  setActiveChat={setActiveChat}
                  activeChat={activeChat}
                  nextToken={nextToken}
                  page={page}
                  setPage={setPage}
                  viewMode={CHAT_LIST_VIEW_MODE.CHAT}
                  setShowLeftSidebar={setShowLeftSidebar}
                  loading={searchLoading}
                />
              </Box>
              <Box
                flex={1}
                position="relative"
                overflow="hidden"
                sx={{
                  display: [!showLeftSidebar ? 'block' : 'none', !showLeftSidebar ? 'block' : 'none', 'block', 'block'],
                }}
              >
                {activeChat && (
                  <>
                    <IconButton
                      onClick={() => setShowLeftSidebar(true)}
                      sx={{
                        position: 'absolute',
                        left: 0,
                        top: 30,
                        display: ['block', 'block', 'block', 'none'],
                      }}
                    >
                      <ArrowBackIosIcon />
                    </IconButton>
                    <ChatItem tab={CHAT_TABS[activeTab].value} chats={chats} setChats={setChats} activeChat={activeChat} setActiveChat={setActiveChat} />
                  </>
                )}
              </Box>
            </ChatTabContainer>
          </Stack>
        </Container>
      )}
    </>
  );
}

export default ChatPage;
