import Chip from '@mui/material/Chip';
import { CHAT_STATUSES } from 'interface/chat';
import { modalContent } from 'components/Chat/Header/RoomBoxItemHeader';
import { Box } from '@mui/material';

function ChipStatus({ status }: { status: string }) {
  if (status === CHAT_STATUSES.OPEN) {
    return <Box />;
  }
  return <Chip label={status} sx={{ background: modalContent[status].bgColor, color: modalContent[status].color, fontWeight: 700, fontSize: '11px' }} />;
}

export default ChipStatus;
