import ActivatePage from 'pages/auth/Activate';
import ForgotPasswordPage from 'pages/auth/ForgotPassword';
import LoginPage from 'pages/auth/Login';
import SignupPage from 'pages/auth/Signup';
import ChatPage from 'pages/Chats';
import Deal from 'pages/deal/Deal';
import DealEditing from 'pages/deal/DealForm';
import HomePage from 'pages/Home';
import EditAccount from 'pages/profile/EditAccount';
import EditProfile from 'pages/profile/EditProfile';
import MyProfile from 'pages/profile/MyProfile';
import MyListings from 'pages/profile/MyListings';
import UserProfile from 'pages/profile/UserProfile';
import SearchPage from 'pages/search/Search';
import { FC, ReactElement } from 'react';
import MainTemplate from 'themes/MainTemplate';
import SingleCard from 'themes/SingleCard';
import ResendEmail from 'pages/auth/ResendEmail';
import CategoriesPage from 'pages/Categories';
import LikesPage from 'pages/Likes';
import ResetPasswordPage from 'pages/auth/ResetPassword';
import ChatByItemPage from 'pages/deal/DealChats';
import RoomsPage from 'pages/Rooms';
import DealRooms from 'pages/deal/DealRooms';
import PrivacyScreen from 'pages/Privacy';
import SearchUserPage from 'pages/search/SearchUser';
import AboutUsScreen from 'pages/About';
import ContactScreen from 'pages/Contact';
import ReportListingsScreen from 'pages/ReportListings';
import AdminTemplate from 'themes/AdminTemplate';
import { UserType } from 'interface/user';
import TermConditionScreen from 'pages/TermCondition';

export interface IRoute {
  path: string;
  pageComponent: FC;
  template: FC<{ children: ReactElement[] }>;
  breadcrumbs?: string;
}

const generalRoutes: IRoute[] = [
  {
    path: '/',
    pageComponent: HomePage,
    template: MainTemplate,
  },
  {
    path: '/categories',
    pageComponent: CategoriesPage,
    template: MainTemplate,
    breadcrumbs: 'Categories',
  },
  {
    path: '/search',
    pageComponent: SearchPage,
    template: MainTemplate,
    breadcrumbs: 'Search',
  },
  {
    path: '/deal/view/:id',
    pageComponent: Deal,
    template: MainTemplate,
  },
  {
    path: '/deal/:id',
    pageComponent: Deal,
    template: MainTemplate,
  },
  {
    path: '/privacy-policy',
    pageComponent: PrivacyScreen,
    template: MainTemplate,
  },
  {
    path: '/term-and-condition',
    pageComponent: TermConditionScreen,
    template: MainTemplate,
  },
  {
    path: '/contact',
    pageComponent: ContactScreen,
    template: MainTemplate,
  },
  {
    path: '/about',
    pageComponent: AboutUsScreen,
    template: MainTemplate,
  },
];

const anonRoutes: IRoute[] = [
  {
    path: '/forgot-password',
    pageComponent: ForgotPasswordPage,
    template: SingleCard,
  },
  {
    path: '/reset-password',
    pageComponent: ResetPasswordPage,
    template: SingleCard,
  },
  {
    path: '/sign-up',
    pageComponent: SignupPage,
    template: SingleCard,
  },
  {
    path: '/sign-up/resend-email',
    pageComponent: ResendEmail,
    template: SingleCard,
  },
  {
    path: '/login',
    pageComponent: LoginPage,
    template: SingleCard,
  },
  {
    path: 'active-user',
    pageComponent: ActivatePage,
    template: SingleCard,
  },
  {
    path: '/profile/:id',
    pageComponent: UserProfile,
    template: MainTemplate,
  },
  {
    path: '/search-users',
    pageComponent: SearchUserPage,
    template: MainTemplate,
  },
];

const userRoutes = [
  {
    path: '/likes',
    pageComponent: LikesPage,
    template: MainTemplate,
    breadcrumbs: 'Likes',
  },
  {
    path: '/create/deal',
    pageComponent: DealEditing,
    template: MainTemplate,
  },
  {
    path: '/deal/edit/:id',
    pageComponent: DealEditing,
    template: MainTemplate,
  },
  {
    path: '/deal/chats/:dealId',
    pageComponent: ChatByItemPage,
    template: MainTemplate,
  },
  {
    path: '/deal/rooms/:dealId',
    pageComponent: DealRooms,
    template: MainTemplate,
  },
  {
    path: '/my-profile',
    pageComponent: MyProfile,
    template: MainTemplate,
    breadcrumbs: 'My Profile',
  },
  {
    path: '/my-listings',
    pageComponent: MyListings,
    template: MainTemplate,
    breadcrumbs: 'My Listings',
  },
  {
    path: '/my-profile/edit',
    pageComponent: EditProfile,
    template: MainTemplate,
    breadcrumbs: 'My Profile',
  },
  {
    path: '/my-profile/change-password',
    pageComponent: EditAccount,
    template: MainTemplate,
    breadcrumbs: 'My Profile',
  },
  {
    path: '/chats',
    pageComponent: ChatPage,
    template: MainTemplate,
  },
  {
    path: '/chats',
    pageComponent: ChatPage,
    template: MainTemplate,
  },
  {
    path: '/rooms',
    pageComponent: RoomsPage,
    template: MainTemplate,
  },
];

const superAdminRoutes = [
  {
    path: '/',
    pageComponent: ReportListingsScreen,
    template: AdminTemplate,
  },
  {
    path: '/',
    pageComponent: ReportListingsScreen,
    template: AdminTemplate,
  },
];

export const getAllRoutes = () => [...userRoutes, ...anonRoutes, ...generalRoutes];

const getAvailableRoutes = (role?: string): IRoute[] => {
  if (role === UserType.Superadmin) {
    return [...superAdminRoutes, ...anonRoutes, ...generalRoutes];
  }
  if (role) {
    return [...userRoutes, ...anonRoutes, ...generalRoutes];
  }
  return [...anonRoutes, ...generalRoutes];
};

export default getAvailableRoutes;
