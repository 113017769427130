import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import { IBusinessCategory } from 'interface/public';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import colors from 'constants/colors';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import CategoryItem from './CategoryItem';

function CategoryList({
  data,
  title,
  isViewAll,
}: {
  data: IBusinessCategory[];
  title: {
    text: string;
    size?: 'h2' | 'h3';
  };
  isViewAll?: boolean;
}) {
  return (
    <Box zIndex={1} position="relative">
      <Stack mb={4} alignItems="center" justifyContent="space-between" direction="row">
        <Typography variant={title.size ?? 'h2'}>{title.text}</Typography>
        {isViewAll && (
          <Link href="/categories" sx={{ color: colors.Secondary, display: 'flex', alignItems: 'center' }}>
            VIEW ALL <ArrowForwardIcon sx={{ width: 16, height: 16, strokeWidth: 4, ml: 1 }} />
          </Link>
        )}
      </Stack>
      <Grid2 container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} m={0}>
        {data
          .filter((item) => item.name !== 'Others')
          .map((item) => (
            <CategoryItem key={item.id} id={item.id} name={item.name} />
          ))}
      </Grid2>
    </Box>
  );
}

export default CategoryList;
