import { Stack, Typography, Box } from '@mui/material';
import SjHelmet from 'components/Layout/SjHelmet';
import SimplePage from 'themes/SimplePage';

function AboutUsScreen() {
  const data = [
    {
      title: 'Our mission',
      details: [
        'Our mission is to connect people through opportunities (products, services and more…, B2C, C2C, B2B channels) and enable users to meet their everyday needs and develop business potentials.',
      ],
    },
    {
      title: 'Our vision',
      details: [
        'To build a dynamic and convenient marketplace where continuous opportunities are created and promoted through innovation, ideas and greater good values.',
      ],
    },
  ];

  const details = (
    <Stack gap={7}>
      {data.map((item) => (
        <Stack
          key={item.title}
          direction={['column', 'column', 'row', 'row', 'row']}
          gap={[3, 3, 3, 30, 30]}
          alignItems={['flex-start', 'flex-start', 'center', 'center', 'center']}
        >
          <Typography variant="h2" ml={['0px', '0px', '106px']} width={['auto', 'auto', 310, 310]} textAlign="right">
            {item.title}
          </Typography>
          <Box flex={1}>{item.details}</Box>
        </Stack>
      ))}
    </Stack>
  );

  const contactData = {
    title: 'About us',

    details,
  };
  return (
    <>
      <SjHelmet title="About us" />
      <SimplePage data={contactData} />
    </>
  );
}

export default AboutUsScreen;
