import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import colors from 'constants/colors';
import Box from '@mui/material/Box';
import { DealContext } from 'context/DealContext';
import { useContext, useMemo, useState } from 'react';
import { likeDeal, unlikeDeal } from 'apis/deal';
import { useNavigate } from 'react-router-dom';
import { IconButton, Stack } from '@mui/material';
import { Maximize, Heart, Lock } from 'react-feather';
import IDeal from 'interface/deal';
import { IReduxState } from 'interface/redux';
import { useSelector } from 'react-redux';
import { UserType } from 'interface/user';
import DealReportAction from './DealActions/DealReportAction';
import DealShareAction from './DealActions/DealShareAction';

function DealPhotos(props: { noRadius?: boolean }) {
  const auth = useSelector((state: IReduxState) => state.auth);
  const navigation = useNavigate();
  const { deal, onUpdated } = useContext(DealContext);
  const [isMaximize, setMaximize] = useState(false);
  const { photos = [] } = deal;

  const updateDeal = (updatedDeal: IDeal) => {
    onUpdated({ ...deal, liked: updatedDeal.liked, total_likes: updatedDeal.total_likes });
  };

  const onMaximize = () => {
    setMaximize(true);
  };

  const onMinimize = () => {
    setMaximize(false);
  };

  const handleLike = () => {
    if (!auth.user?.id) {
      navigation('/likes');
    }
    if (deal.liked) {
      unlikeDeal(deal.id).then((updatedDeal) => {
        updateDeal(updatedDeal);
      });
    } else {
      likeDeal(deal.id).then((updatedDeal) => {
        updateDeal(updatedDeal);
      });
    }
  };

  return (
    <Box
      {...(!props?.noRadius && { borderRadius: '10px', overflow: 'hidden' })}
      position="relative"
      sx={{
        '> div': { background: 'transparent' },
        '> div > div ': { margin: 0 }, // thumnails wrapper
        '> div > div > ul': { background: '#00000070', margin: 0 }, // thumnails
        '> div > div > div > span': { background: '#00000070', color: colors.BaseLightGrey }, // pager
      }}
    >
      <Carousel
        canAutoPlay
        hasLeftButton={photos.length > 1 ? 'centerLeft' : false}
        hasRightButton={photos.length > 1 ? 'centerRight' : false}
        playIcon={false}
        isMaximized={false}
        hasIndexBoard="bottomCenter"
        hasThumbnails={false}
        hasThumbnailsAtMax={photos.length > 1}
        shouldMinimizeOnClick
        leftIcon={
          <KeyboardArrowLeftIcon
            sx={{
              color: colors.Base300,
              width: 30,
              height: 50,
              padding: 0,
              backgroundColor: '#00000030',
              borderRadius: '6px',
            }}
          />
        }
        rightIcon={
          <KeyboardArrowRightIcon
            sx={{
              color: colors.Base300,
              width: 30,
              height: 50,
              padding: 0,
              backgroundColor: '#00000030',
              borderRadius: '6px',
            }}
          />
        }
        maxIcon={
          <IconButton
            sx={{
              position: 'absolute',
              top: 16,
              right: 18,
              color: 'white',
              width: '40px',
              height: '40px',
              backgroundColor: '#00000070',
              borderRadius: '6px',
              '&:hover': { backgroundColor: '#00000070' },
            }}
            onClick={onMaximize}
          >
            <Maximize size={22} />
          </IconButton>
        }
        minIcon={
          <IconButton
            sx={{
              position: 'absolute',
              top: 16,
              right: 18,
              color: 'white',
              width: '40px',
              height: '40px',
              borderRadius: '6px',
              backgroundColor: '#00000070',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onClick={onMinimize}
          >
            <Maximize size={22} />
          </IconButton>
        }
        style={{ height: '340px', background: colors.White }}
      >
        {photos.map((item) =>
          item.content_type?.includes('video') ? (
            <Box key={item.url} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center" sx={{ background: '#00000070' }}>
              <video src={item.url} controls width="100%" autoPlay={true} style={{ height: '340px' }}>
                <track kind="captions" />
              </video>
            </Box>
          ) : (
            <Box
              key={item.url}
              width="100%"
              height={isMaximize ? 'calc(100% - 32px)' : '100%'}
              justifyContent="center"
              display="flex"
              sx={{ background: '#00000070', ...(isMaximize && { p: 2 }) }}
            >
              <img
                src={item.url}
                alt="galaxy"
                style={{
                  objectFit: 'contain',
                  width: '100%',
                }}
              />
            </Box>
          ),
        )}
      </Carousel>
      {auth.user?.role !== UserType.Superadmin && (
        <Stack spacing="6px" direction="row" alignItems="center" sx={{ zIndex: 100, position: 'absolute', top: 16, right: 64 }}>
          <DealShareAction />
          <IconButton
            type="button"
            onClick={handleLike}
            sx={{
              height: '40px',
              padding: '6px',
              backgroundColor: '#00000050',
              borderRadius: '6px',
              '&:hover': { backgroundColor: '#00000060' },
            }}
          >
            {deal.liked ? <Heart color={colors.Error500} fill={colors.Error500} size={22} /> : <Heart color="white" size={22} />}
            <Box sx={{ fontSize: '14px', marginLeft: 0.5, color: '#fff' }}>{`${deal.total_likes} ${deal.total_likes > 1 ? 'likes' : 'like'}`}</Box>
          </IconButton>
          {auth.user?.id && auth.user?.id !== deal.owner.id && <DealReportAction />}
        </Stack>
      )}

      {deal.private && (
        <Box
          sx={{
            position: 'absolute',
            top: 16,
            left: 24,
            background: colors.Primary500,
            borderRadius: '100%',
            width: 30,
            height: 30,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Lock size={14} color={colors.Secondary500} />
        </Box>
      )}
    </Box>
  );
}

export default DealPhotos;
