import { useSubscription } from '@apollo/react-hooks';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { ROOM_SUBSCRIPTION, ROOM_INVITATION_SUBSCRIPTION } from 'apis/graphql';
import { ChatContainerContext, IChangeField } from 'context/ChatContainerContext';

import { Stack } from '@mui/material';
import { getUserInfo } from 'apis/user';
import { MainContext } from 'context/MainContext';
import { CHAT_TYPES, IChat, IChatGraphqlResponse, IRoomInviteeUpdated, ROOM_INVITEE_UPDATED_TYPE } from 'interface/chat';
import { getDealById } from 'apis/deal';
import { getDisplayName } from 'utils/getDisplayName';
import { IRoomsGlobalSearchSelectedMessage } from 'interface/room';
import ChatItemDetail from './ChatItemDetail';

function ChatItem({
  chats,
  setChats,
  activeChat,
  setActiveChat,
  tab,
  hideInfo,
  selectedMsg,
  moveActiveRoomToTop,
  onRemovedCurrentUserFromActiveRoom,
}: {
  chats: IChat[];
  setChats: (c: IChat[]) => void;
  activeChat: IChat;
  setActiveChat: (c: IChat | null) => void;
  tab: any;
  hideInfo?: boolean;
  selectedMsg?: IRoomsGlobalSearchSelectedMessage;
  moveActiveRoomToTop?: () => void;
  onRemovedCurrentUserFromActiveRoom?: () => void;
}) {
  const { updateUnreadMessageCount } = useContext(MainContext);
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUserId = auth.user?.id?.toString() ?? '';

  const [scrollToBottom, setScrollToBottom] = useState(false);
  const { data: chatSubscription } = useSubscription(ROOM_SUBSCRIPTION);
  const { data: roomInviteesSubscription } = useSubscription(ROOM_INVITATION_SUBSCRIPTION);

  useEffect(() => {
    setScrollToBottom(true);
  }, [activeChat]);

  const updateChat = (chatId: string, changes: IChangeField[]) => {
    const findRoomIndex = chats.findIndex((item) => item.id === chatId);
    const updatedRooms = [...chats];
    if (findRoomIndex > -1) {
      const roomNeedUpdate: any = { ...updatedRooms[findRoomIndex] };
      const isStatusChange = changes.findIndex((item) => item.field === 'status' && roomNeedUpdate.type === 'LISTING_GROUP') > -1;
      const isPositionChange = changes.findIndex((item) => item.field === 'position') > -1;
      if (isStatusChange) {
        const newRooms = updatedRooms.filter((item: any) => item.id !== chatId);
        setChats(newRooms);
        setActiveChat(newRooms[0]);
      } else if (isPositionChange) {
        setChats([updatedRooms[findRoomIndex], ...updatedRooms.filter((item: any) => item.id !== chatId)]);
      } else {
        changes.forEach((item) => {
          roomNeedUpdate[item.field] = item.value;
        });
        updatedRooms[findRoomIndex] = {
          ...roomNeedUpdate,
        };

        setChats(updatedRooms);
        if (activeChat && activeChat.id === chatId) {
          setActiveChat({
            ...activeChat,
            ...roomNeedUpdate,
          });
        }
      }
    }
  };

  const updateUnreadMessage = (chat: any, increased: boolean) => {
    const chatId = chat.id;
    const findRoomIndex = chats.findIndex((item) => item.id === chatId);

    if (findRoomIndex > -1) {
      const updatedChats = [...chats];
      const unreadMessageNumber = increased ? updatedChats[findRoomIndex].unreadMessages + 1 : 0;
      updatedChats[findRoomIndex] = {
        ...updatedChats[findRoomIndex],
        unreadMessages: unreadMessageNumber,
      };

      if (!activeChat || chat.id !== activeChat.id || !increased) {
        updateUnreadMessageCount(chat.id, chat.type, unreadMessageNumber);
      }

      setChats(updatedChats);
    }
  };

  const handleCreatedRoomsSubscription = async (chatUser: string, chatCreated: IChatGraphqlResponse) => {
    const userInfo = await getUserInfo(chatUser);
    if (chatCreated.roomType === tab) {
      const chat: IChat = {
        id: chatCreated.id,
        createdAt: chatCreated.createdAt,
        updatedAt: chatCreated.updatedAt,
        unreadMessages: 1,
        status: chatCreated.roomStatus,
        type: chatCreated.roomType,
        lastMessage: chatCreated.lastMessage,
        participants: [
          {
            id: userInfo.id,
            avatar: userInfo.avatar,
            fullname: getDisplayName(userInfo),
            email: userInfo.email,
            username: userInfo.username,
          },
        ],
      };
      if (chatCreated.roomType !== CHAT_TYPES.DIRECT && chatCreated.itemId) {
        const listingInfo = await getDealById(chatCreated.itemId);
        chat.item = {
          id: listingInfo.id,
          photo: listingInfo.photos[0].url,
          name: listingInfo.name,
          symbol: listingInfo.currency.symbol,
          minPrice: listingInfo.min_price,
          maxPrice: listingInfo.max_price,
          ownerId: listingInfo.owner.id.toString(),
          status: listingInfo.status,
        };
      }

      const updatedRoom = chats.filter((item: IChat) => item.id !== 'created-chat');

      setChats([chat, ...updatedRoom]);

      if (activeChat && activeChat.id === 'created-chat') {
        setActiveChat(chat);
      }
    }
  };

  const handleUpdateRoomsSubscription = (chat: IChatGraphqlResponse) => {
    const findIndex = chats.findIndex((item) => item.id === chat.id);
    const updatedRooms = [...chats];

    if (findIndex > -1) {
      const updatedRoom = {
        ...updatedRooms[findIndex],
        status: chat.roomStatus,
        lastMessage: chat.lastMessage,
        name: chat.name,
        updatedAt: chat.updatedAt,
      };

      if (!activeChat || activeChat.id !== chat.id) {
        updatedRoom.unreadMessages = chat.unreadMessages;
        updatedRoom.groupRoomId = chat.groupRoomId;
      } else {
        setActiveChat({
          ...activeChat,
          status: chat.roomStatus,
          name: chat.name,
          updatedAt: chat.updatedAt,
        });
      }
      updatedRooms[findIndex] = updatedRoom;

      setChats(updatedRooms);
    }
    if (chat.roomType && (!activeChat || chat.id !== activeChat.id)) {
      updateUnreadMessageCount(chat.id, chat.roomType, chat.unreadMessages);
    }
  };

  const handleRoomInviteeUpdatedSubscription = (response: IRoomInviteeUpdated) => {
    const findIndex = chats.findIndex((item) => item.id === response.id);

    if (findIndex > -1) {
      const updatedRooms = [...chats];
      const updatedRoom = {
        ...updatedRooms[findIndex],
        updatedAt: response.updatedAt,
      };

      const participants = [
        ...response.users
          .filter((p) => p.id !== `${auth.user?.id || ''}`)
          .map((p) => {
            return { ...p, fullname: p.username, avatar: p.avatarUrl };
          }),
      ];

      if (!activeChat || activeChat.id !== response.id) {
        updatedRoom.participants = participants;
      }
      updatedRooms[findIndex] = updatedRoom;
      // Remove Room in case user is removed
      if (response.type === ROOM_INVITEE_UPDATED_TYPE.REMOVED && !response.userIds.includes(currentUserId)) {
        setChats(
          updatedRooms.filter((room) => {
            return room.id !== response.id;
          }),
        );
        setActiveChat(null);
        onRemovedCurrentUserFromActiveRoom?.();
      } else {
        const updatedActive = {
          ...activeChat,
          participants,
        };
        setActiveChat(updatedActive);
        setChats(updatedRooms);
      }
    }
  };

  useEffect(() => {
    if (chatSubscription) {
      if (chatSubscription.roomUpdated.isNew) {
        const chatUser = chatSubscription.roomUpdated.userIds.find((item: string) => item.toString() !== currentUserId);
        if (chatUser) {
          handleCreatedRoomsSubscription(chatUser, chatSubscription.roomUpdated);
        }
      } else {
        handleUpdateRoomsSubscription(chatSubscription.roomUpdated);
      }
    }
  }, [chatSubscription]);

  useEffect(() => {
    if (roomInviteesSubscription) {
      handleRoomInviteeUpdatedSubscription(roomInviteesSubscription.inviteeUpdated);
    }
  }, [roomInviteesSubscription]);

  if (!activeChat) {
    return (
      <Stack direction="column" height="100%" alignItems="center" justifyContent="center">
        No chats
      </Stack>
    );
  }

  return (
    <ChatContainerContext.Provider
      value={{
        activeChat,
        setActiveChat,
        updateUnreadMessage,
        updateChat,
        scrollToBottom,
        setScrollToBottom,
        searchRoom: () => {},
        hideInfo,
        selectedMsg,
        moveActiveRoomToTop,
      }}
    >
      <ChatItemDetail />
    </ChatContainerContext.Provider>
  );
}

export default ChatItem;
