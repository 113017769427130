import { IUser } from 'interface/user';
import { ILoginForm } from 'pages/auth/Login';
import { IReduxBaseAction } from '../../interface/redux';
import {
  USER_CHECK,
  USER_CHECK_ERROR,
  USER_CHECK_SUCCESS,
  USER_CLEAR_ACTION_STATUS,
  USER_LOGIN,
  USER_LOGIN_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_VERIFY_OTP_ERROR,
  USER_LOGOUT,
  USER_LOGOUT_FAILURE,
  USER_LOGOUT_SUCCESS,
  USER_TOKEN_REFRESH,
  USER_TOKEN_REFRESH_SUCCESS,
  USER_UPDATE_ME,
  USER_UPDATE_ME_ERROR,
  USER_UPDATE_ME_SUCCESS,
} from './types';

export const userLogin = (payload: ILoginForm): IReduxBaseAction => ({
  type: USER_LOGIN,
  payload,
  meta: {
    success: USER_LOGIN_SUCCESS,
    failure: USER_LOGIN_ERROR,
  },
});

// export const userLoginSuccess = (user: IUser): IReduxBaseAction => ({
//   type: USER_LOGIN_SUCCESS,
//   meta: {},
//   payload: user,
// });

export const userLogout = (): IReduxBaseAction => ({
  type: USER_LOGOUT,
  meta: {
    success: USER_LOGOUT_SUCCESS,
    failure: USER_LOGOUT_FAILURE,
  },
});

export const userCheck = (): IReduxBaseAction => ({
  type: USER_CHECK,
  meta: {
    success: USER_CHECK_SUCCESS,
    failure: USER_CHECK_ERROR,
  },
});

export const userRefreshToken = (): IReduxBaseAction => {
  return {
    type: USER_TOKEN_REFRESH,
    meta: {
      success: USER_TOKEN_REFRESH_SUCCESS,
      failure: USER_LOGIN_VERIFY_OTP_ERROR,
    },
  };
};

export const userUpdateMe = (id: string, data: any): IReduxBaseAction => ({
  type: USER_UPDATE_ME,
  payload: {
    id,
    data,
  },
  meta: {
    success: USER_UPDATE_ME_SUCCESS,
    failure: USER_UPDATE_ME_ERROR,
  },
});

export const userClearActionStatus = (): IReduxBaseAction => ({
  type: USER_CLEAR_ACTION_STATUS,
  meta: {},
});
