import { Helmet } from 'react-helmet';

function SjHelmet({ title, description }: { title?: string; description?: string }) {
  return (
    <Helmet>
      <title>{`${title} - ${process.env.REACT_APP_NAME}`}</title>
      {description && <meta name="description" content="Helmet application" />}
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
}

export default SjHelmet;
