/* eslint-disable no-param-reassign */
import axios, { InternalAxiosRequestConfig } from 'axios';
import { store } from 'redux/configureStore';
import { userLogout, userRefreshToken } from 'redux/auth/action';
import { getStorage, tokenKey, tokenRefresh } from './localStorageUtil';

export const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
export const basePath = process.env.REACT_APP_API_PREFIX || '';
export const apiUrl = baseUrl + basePath;

let isRefreshing = false;
interface Error {
  code: number;
  message: string;
  type: string;
}
export interface ErrorResponse {
  error: Error;
}

const axiosClient = axios.create({
  timeout: 150000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

axiosClient.interceptors.request.use(async (req: InternalAxiosRequestConfig) => {
  if (!req.url?.startsWith('http') && !req.url?.startsWith('//')) {
    if (req.url?.startsWith('/')) {
      req.url = baseUrl + req.url;
    } else {
      req.url = `${apiUrl}/${req.url}`;
    }
  }

  const token = await getStorage(tokenKey);
  const isOurAPI = req.url?.startsWith(baseUrl);
  if (token && isOurAPI) {
    req.headers.Authorization = `Bearer ${token}`;
  }
  return req;
});

// TODO: handle refresh token flow
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const { dispatch } = store;

    const refereshToken = localStorage.getItem(tokenRefresh);
    if (!err.response.config.url.includes('login')) {
      if (err.response.status === 401 && !err.response.config.url.includes('/auth/refresh-token') && refereshToken && !isRefreshing) {
        dispatch(userRefreshToken());
        isRefreshing = true;
        setTimeout(() => {
          isRefreshing = false;
        }, 10000);
      } else if (err.response.status === 401) {
        dispatch(userLogout());
      }
    }
    return Promise.reject(err);
  },
);

export default axiosClient;
