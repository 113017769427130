import { useEffect } from 'react';

const listeners: { name: string; cb: (...args: any[]) => any }[] = [];

export enum EVENT_NAME {
  USER_LOGOUT = 'USER_LOGOUT',
  CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU',
}

const addListener = (eventName: string, cb: (...args: any[]) => any) => {
  const newListener = { name: eventName, cb };
  listeners.push(newListener);

  const removeListener = () => {
    const foundIndex = listeners.findIndex((l) => l === newListener);
    if (foundIndex < 0) {
      console.warn('Unabled to find listener by event name', eventName);
      return;
    }
    listeners.splice(foundIndex, 1);
  };
  return removeListener;
};

const emit = (eventName: string, ...args: any[]) => {
  listeners.forEach((l) => {
    if (l?.name !== eventName) {
      return;
    }
    l.cb(...args);
  });
};

const useEvent = (eventName: EVENT_NAME, cb: (...args: any[]) => any) => {
  useEffect(() => {
    const removeListener = addListener(eventName, cb);
    return removeListener;
  }, []);
};

const Listener = {
  addListener,
  emit,
  useEvent,
};

export default Listener;
