import { Button, Card, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import authProvider from 'apis/auth';
import SjHelmet from 'components/Layout/SjHelmet';

import SjInput from 'components/Form/SjInput';
import { Formik, FormikErrors } from 'formik';
import { isEmail } from 'utils/regex';
import { useNavigate } from 'react-router-dom';

function ForgotPasswordPage() {
  const [isSent, setIsSent] = useState(false);

  const navigate = useNavigate();
  const submit = (values: { email: string }) => {
    authProvider
      .forgotPassword(values)
      .then(() => {
        setIsSent(true);
      })
      .catch((err) => {
        setIsSent(true);
      });
  };

  const render = () => {
    if (isSent) {
      return (
        <>
          <Typography textAlign="center" mb={6}>
            If your email is registered, we will be sending instructions to your registered email on how to reset your password.
          </Typography>
          <Stack gap={2}>
            <Button variant="contained" type="button" fullWidth sx={{ height: 52 }} onClick={() => navigate('/login')}>
              Go to login
            </Button>
            <Button variant="contained" type="button" fullWidth sx={{ height: 52 }} onClick={() => navigate('/')}>
              Go to homepage
            </Button>
          </Stack>
        </>
      );
    }
    return (
      <>
        <Typography textAlign="center" mb={6}>
          Enter the email you associated with your account, we’ll send you a link to reset your password.{' '}
        </Typography>
        <Formik
          initialValues={{ email: '' }}
          onSubmit={submit}
          validate={(values) => {
            const errors: FormikErrors<any> = {};
            if (!values.email) {
              errors.email = 'Please enter email address.';
            } else if (!isEmail(values.email)) {
              errors.email = 'Invalid email address';
            }
            return errors;
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <SjInput name="email" label="Email" placeholder="Enter email" />
              <Button variant="contained" type="submit" fullWidth sx={{ height: 52 }} disabled={isSubmitting}>
                send email
              </Button>
            </form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <Stack alignItems="center" marginTop={10}>
      <SjHelmet title="Forgot Password" />
      <Card variant="outlined" sx={{ boxShadow: 'none' }}>
        <Typography variant="h2" textAlign="center" mb={6}>
          Forgot password?
        </Typography>
        {render()}
      </Card>
    </Stack>
  );
}

export default ForgotPasswordPage;
