import { Close } from '@mui/icons-material';
import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import colors from 'constants/colors';
import { ChatMessageContext } from 'context/ChatMessageContext';
import { useContext, useEffect } from 'react';

function RoomSearchContent() {
  const { setIsSearchMode, search, setSearch } = useContext(ChatMessageContext);

  useEffect(() => {
    setSearch('');
  }, []);

  const onSearchContent = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (e.target.value.length === 0) {
      setIsSearchMode(false);
    }
  };

  return (
    <Box sx={{ position: 'sticky', top: 0, background: colors.White, zIndex: 1, py: 2 }}>
      <OutlinedInput
        fullWidth
        placeholder="Search chats, usernames"
        onChange={onSearchContent}
        value={search}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            setIsSearchMode(true);
          }
        }}
        endAdornment={
          search.length > 0 && (
            <InputAdornment position="end">
              <Close
                onClick={() => {
                  setIsSearchMode(false);
                  setSearch('');
                }}
                sx={{ cursor: 'pointer' }}
              />
            </InputAdornment>
          )
        }
      />
    </Box>
  );
}

export default RoomSearchContent;
