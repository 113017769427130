import { DEALSTATUS } from 'interface/deal';
import { useState } from 'react';
import 'react-gallery-carousel/dist/index.css';
import colors from 'constants/colors';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ListItemIcon from '@mui/material/ListItemIcon';
import { MinusSquare } from 'react-feather';
import IconButton from '@mui/material/IconButton';
import { closeDeal } from 'apis/deal';
import { useDispatch } from 'react-redux';
import { showToast } from 'redux/toast/action';
import { AxiosError } from 'axios';
import { useDealContext } from 'context/DealContext';
import { modalStyle } from 'themes/modal';

function DealCloseAction({ roomsLength }: { roomsLength: number }) {
  const { deal } = useDealContext();
  const [markAsClosedModal, setMarkAsClosedModal] = useState(false);
  const [cannotCloseModal, setCannotCloseModal] = useState(false);
  const { onUpdated } = useDealContext();
  const dispatch = useDispatch();

  const onCloseDeal = async () => {
    closeDeal(deal.id)
      .then((res) => {
        onUpdated({
          ...deal,
          status: DEALSTATUS.CLOSED,
        });
        dispatch(
          showToast({
            type: 'success',
            title: 'Deal has been closed',
          }),
        );
        setMarkAsClosedModal(false);
      })
      .catch((error: AxiosError<any>) => {
        setMarkAsClosedModal(false);
        dispatch(
          showToast({
            type: 'error',
            title: error.response?.data?.error.message ?? 'Can not close deal.',
          }),
        );
      });
  };

  const onCloseListing = () => {
    if (roomsLength === 0) {
      setMarkAsClosedModal(true);
    } else {
      setCannotCloseModal(true);
    }
  };

  return (
    <>
      <Modal open={markAsClosedModal} onClose={() => setMarkAsClosedModal(false)}>
        <Box
          sx={{
            ...modalStyle,
            textAlign: 'center',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={() => setMarkAsClosedModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography mt={3} variant="h3">
            Are you sure you want to close this listing?
          </Typography>
          <Typography sx={{ my: 4 }} color={colors.Header}>
            Your chats and chats will still be accessible, but your listing will be removed from public viewing and will no longer be editable. This cannot be
            undone.
          </Typography>
          <Button variant="contained" color="error" sx={{ padding: '12px 32px', mb: 3 }} onClick={onCloseDeal}>
            Close Listing
          </Button>
        </Box>
      </Modal>

      <Modal open={cannotCloseModal} onClose={() => setCannotCloseModal(false)}>
        <Box
          sx={{
            ...modalStyle,
            textAlign: 'center',
          }}
          textAlign="center"
        >
          <IconButton
            aria-label="close"
            onClick={() => setCannotCloseModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography mt={3} variant="h3">
            Can not close this listing
          </Typography>
          <Typography sx={{ my: 4 }} color={colors.Header}>
            There is at least 1 active room or chat for the listing. Please cancel/archive them before closing the listing.
          </Typography>
          <Button variant="outlined" color="primary" sx={{ padding: '12px 32px', mb: 3 }} onClick={() => setCannotCloseModal(false)}>
            Back
          </Button>
        </Box>
      </Modal>

      <MenuItem onClick={onCloseListing} sx={{ px: 0 }}>
        <ListItemIcon>
          <MinusSquare />
        </ListItemIcon>
        <Typography color={colors.BaseText}>Mark as closed</Typography>
      </MenuItem>
    </>
  );
}

export default DealCloseAction;
