/* eslint-disable no-debugger */
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import EditProfileSidebar from 'components/Profile/EditProfileMenu';

import Grid from '@mui/material/Unstable_Grid2';
import { Formik, FormikErrors } from 'formik';
import SjInput from 'components/Form/SjInput';
import SjRemote from 'components/Form/SjRemote';
import { getCategories, getCountries } from 'apis/public';
import authProvider from 'apis/auth';
import { UserType } from 'interface/user';
import { IBusinessCategory } from 'interface/public';
import { showToast } from 'redux/toast/action';
import SjFile from 'components/Form/SjFile';
import { userClearActionStatus, userUpdateMe } from 'redux/auth/action';
import { IReduxState } from 'interface/redux';
import { InputAdornment } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { usernameHelpText, maxUsernameCharacter, minUsernameCharacter } from 'constants/index';
import { isAlphaNumericUnderscoreHyphenDot } from 'utils/regex';
import SjTabsScreen from 'components/Profile/SjTabsScreen';
import SjHelmet from 'components/Layout/SjHelmet';

export const initEditProfileForm = {
  email: '',
  username: '',
  first_name: '',
  last_name: '',
  avatar: '',
  bio: '',
  interests: [],
  country_id: '',
  phone: '',
  business_categories: [],
};

export type IEditProfileForm = {
  email: string;
  username: string;
  first_name: string;
  last_name: string;
  avatar?: string;
  country_id: number;
  interests: string[];
  business_categories?: IBusinessCategory[];
  bio: '';
  business_reg_no?: string;
  company_name?: string;
};

function EditProfile() {
  const formRef = useRef<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state: IReduxState) => state);
  const [data, setData] = useState<any>(initEditProfileForm);
  const [id, setId] = useState('');
  const [role, setRole] = useState(UserType.Personal);
  const [isUniqueUsername, setIsUniqueUsername] = useState<any>();
  const submit = (values: IEditProfileForm) => {
    const changedProperty: any = { ...values };
    if (changedProperty.business_categories) {
      changedProperty.business_categories = changedProperty.business_categories.map((item: IBusinessCategory) => item.id);
    }
    if (changedProperty.interests) {
      changedProperty.interests = changedProperty.interests.map((item: IBusinessCategory) => item.id);
    }
    if (changedProperty.country_id) {
      changedProperty.country_id = changedProperty.country_id.id;
    }

    dispatch(userUpdateMe(id, changedProperty));
  };

  useEffect(() => {
    if (auth.isUpdated) {
      dispatch(userClearActionStatus());
      dispatch(
        showToast({
          type: 'success',
          title: 'Your profile has been updated successfully',
        }),
      );
      navigate(`/my-profile`);
    }
    if (auth.error) {
      dispatch(
        showToast({
          type: 'error',
          title: 'Can not update profile.',
        }),
      );
    }
  }, [auth]);

  useEffect(() => {
    authProvider.userMe().then((resp: any) => {
      setId(resp.id);
      setRole(resp.role);
      setData({
        username: resp.username,
        email: resp.email,
        first_name: resp.first_name,
        last_name: resp.last_name,
        avatar: resp.avatar,
        bio: resp.bio,
        interests: resp.interests,
        country_id: resp.country,
        phone: resp.phone,
        business_categories: resp.business_categories,
        company_name: resp.company_name,
        business_reg_no: resp.business_reg_no,
      });
    });
  }, []);

  const validate = (values: IEditProfileForm) => {
    const errors: FormikErrors<IEditProfileForm> = {};
    if (role === UserType.Personal) {
      if (!values.first_name) errors.first_name = 'First name is required.';
      if (!values.last_name) errors.first_name = 'Last name is required.';
    }
    if (role === UserType.Business) {
      if (!values.company_name) errors.company_name = 'First name is required.';
      if (!values.business_reg_no) errors.business_reg_no = 'Last name is required.';
      // if (!values.business_categories || values.business_categories.length === 0) errors.interests = 'Interests field is required.';
    }

    if (!values.username) {
      errors.username = 'Please enter username.';
    } else if (
      values.username.length < minUsernameCharacter ||
      !isAlphaNumericUnderscoreHyphenDot(values.username) ||
      values.username.length > maxUsernameCharacter
    ) {
      errors.username = usernameHelpText;
    } else if (typeof isUniqueUsername === 'boolean' && !isUniqueUsername) {
      errors.username = 'This username is already taken.';
    }
    if (values.interests.length === 0) errors.interests = `${role === UserType.Business ? 'Business Categories' : 'Interests'} field is required.`;
    return errors;
  };

  const onChangeAvatar = () => {};

  const onUsernameCheck = async (text: string) => {
    if (text.length < 3 || !isAlphaNumericUnderscoreHyphenDot(text) || text.length > 30) return;
    const resp = await authProvider.checkUniqueUsername(text);
    setIsUniqueUsername(!resp.exists);
    if (resp.exists) {
      formRef.current?.setFieldError('username', 'This username is already taken.');
    } else {
      formRef.current?.setFieldError('username', undefined);
    }
  };

  return (
    <>
      <SjHelmet title="Edit Profile" />
      <SjTabsScreen
        title="Edit Profile"
        left={<EditProfileSidebar />}
        right={
          <>
            <Typography display={['none', 'none', 'block']} variant="h2" mb={5}>
              Edit Profile
            </Typography>
            <Formik initialValues={data} onSubmit={submit} enableReinitialize validate={validate} innerRef={formRef}>
              {({ handleSubmit, values, errors, touched }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid xs={12} p="16px">
                        <Box>
                          <Stack
                            direction={['column', 'column', 'row']}
                            justifyContent={['center', 'center', 'flex-start']}
                            alignItems="center"
                            sx={{ mb: ['24px', '24px', 5] }}
                          >
                            <Avatar src={values.avatar} sx={{ width: 150, height: 150, mr: 3, mb: ['24px', '24px', 0] }} />
                            <Box>
                              <Typography mb={3} textAlign={['center', 'center', 'left']}>
                                Clear photos are a good way for users to recognise each other.
                              </Typography>
                              <SjFile
                                name="avatar"
                                onChange={onChangeAvatar}
                                sx={{ display: ['block', 'block', 'flex'] }}
                                uploadPhoto={{
                                  wrapper: { sx: { pr: [0, 0, '24px'] } },
                                  text: { sx: { width: ['100%', '100%', '200px'] } },
                                }}
                                removePhoto={{ button: { sx: { width: ['100%', '100%', '200px'] } } }}
                              />
                            </Box>
                          </Stack>
                        </Box>

                        <Stack mt={['24px', '24px', 0]} direction={['column', 'column', 'row']} gap={[0, 0, 3]}>
                          <Box flex={1}>
                            <SjInput name="email" label="Email" disabled />
                          </Box>
                          <Box flex={1}>
                            <SjInput
                              name="username"
                              label="Username"
                              onBlur={onUsernameCheck}
                              endAdornment={
                                Boolean(errors.username) === false &&
                                touched.username && (
                                  <InputAdornment position="end">
                                    <CheckCircle color="success" />
                                  </InputAdornment>
                                )
                              }
                            />
                          </Box>
                        </Stack>

                        {role === UserType.Personal && (
                          <Stack direction={['column', 'column', 'row']} gap={[0, 0, 3]}>
                            <Box flex={1}>
                              <SjInput name="first_name" label="First Name*" />
                            </Box>
                            <Box flex={1}>
                              <SjInput name="last_name" label="Last Name*" />
                            </Box>
                          </Stack>
                        )}

                        {role === UserType.Business && (
                          <Stack direction={['column', 'column', 'row']} gap={[0, 0, 3]}>
                            <Box flex={1}>
                              <SjInput name="company_name" label="Company Name*" />
                            </Box>
                            <Box flex={1}>
                              <SjInput name="business_reg_no" label="Business Reg. No.*" />
                            </Box>
                          </Stack>
                        )}

                        <Stack direction={['column', 'column', 'row']} gap={[0, 0, 3]}>
                          <Box flex={1}>
                            <SjInput name="phone" label="Phone Number" />
                          </Box>
                          <Box flex={1}>
                            <SjRemote name="country_id" label="Country" getData={getCountries} valueExp="id" displayExp="name" />
                          </Box>
                        </Stack>
                        <SjRemote
                          name="interests"
                          getData={getCategories}
                          valueExp="id"
                          displayExp="name"
                          label={role === UserType.Personal ? 'Interests*' : 'Business Categories*'}
                          isMultiple
                          helpText={`Select up to 5 ${
                            role === UserType.Personal ? 'interests' : 'business categories'
                          } — This will help us recommend relevant listings to you.`}
                          max={5}
                        />
                        {role === UserType.Business && <SjInput name="business_address" label="Registered Business Address" />}
                        <SjInput
                          name="bio"
                          label="Profile Bio"
                          type="textarea"
                          maxChars={255}
                          placeholder="Describe your business, let users know what makes you stand out from the rest!"
                        />

                        <Button type="submit" variant="contained" sx={{ px: 4, width: '100%' }}>
                          Save Changes
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </>
        }
      />
    </>
  );
}

export default EditProfile;
