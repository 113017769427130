/* eslint-disable max-len */
export const isNumber = (value: string) => {
  const reg = /^[\d]+$/;
  return reg.test(value);
};

export const isAlphaNumeric = (value: string) => {
  const reg = /^[A-Za-z0-9]*$/;
  return reg.test(value);
};

export const isEmail = (value: string) => {
  const reg = /^[a-zA-Z\d.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,4}$/;
  return reg.test(value);
};

export const isPasswordStrength = (value: string) => {
  const reg = /(?=.*[a-zA-Z])(?=.*\d)(?=.*[^A-Za-z0-9])(?=.{8,})/;
  return reg.test(value) && value.length >= 8;
};

export const isDecimal = (value: string) => {
  const reg = /^\d*\.?\d*$/;
  return reg.test(value);
};

export const isAlphaNumericUnderscoreHyphenDot = (value: string) => {
  const reg = /^[A-Za-z0-9._-]+$/;
  return reg.test(value);
};

export const urlPattern =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const urlPattern2 =
  /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;

export const isLink = (value: string) => {
  return urlPattern.test(value) || urlPattern2.test(value);
};
