import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import colors from 'constants/colors';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { isEmail } from 'utils/regex';
import { userLogin } from 'redux/auth/action';
import SjInput from 'components/Form/SjInput';
import { IReduxState } from 'interface/redux';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import useQuery from 'utils/useQuery';
import SjHelmet from 'components/Layout/SjHelmet';

const initLoginForm = {
  username: '',
  password: '',
};

export type ILoginForm = {
  username: string;
  password: string;
};

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector((state: IReduxState) => state);
  const [showError, setShowError] = useState(false);
  const loginFormRef = useRef<FormikProps<ILoginForm>>(null);
  const query = useQuery();
  const backUrl = query.get('back');
  const onSubmit = (values: ILoginForm) => {
    dispatch(userLogin(values));
  };

  useEffect(() => {
    if (auth.error) {
      setShowError(true);
      loginFormRef.current?.setSubmitting(false);
    }
  }, [auth.error]);

  useEffect(() => {
    if (auth.user) {
      navigate(backUrl || '/');
    }
  }, [auth.user]);

  return (
    <Stack>
      <SjHelmet title="Login" />
      <Typography variant="h2" textAlign="center" mb={6}>
        Log in
      </Typography>
      <Card variant="outlined" sx={{ minWidth: ['100%', '100%', 400, 500, 600], margin: '0 auto', border: 'none', boxShadow: 'none' }}>
        <CardContent>
          {showError && (
            <Stack direction="row" bgcolor={colors.Error300} color={colors.Error500} p={2} mb={4} borderRadius="8px" gap={2}>
              <WarningAmberIcon />
              Invalid username or password
            </Stack>
          )}
          <Formik
            innerRef={loginFormRef}
            initialValues={initLoginForm}
            validate={(values) => {
              const errors: FormikErrors<ILoginForm> = {};
              if (!values.username) {
                errors.username = 'Please enter username or email.';
              }
              if (!values.password) {
                errors.password = 'Please enter password.';
              }
              return errors;
            }}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <SjInput
                  name="username"
                  label="Username/Email*"
                  placeholder="Enter username or email"
                  onFocus={() => {
                    setShowError(false);
                  }}
                />
                <SjInput
                  name="password"
                  label="Password*"
                  placeholder="Enter password"
                  type="password"
                  onFocus={() => {
                    setShowError(false);
                  }}
                />
                <Box textAlign="center" mb={4}>
                  <Link href="/forgot-password">Forgot password?</Link>
                </Box>
                <Box textAlign="center">
                  <Button type="submit" variant="contained" fullWidth sx={{ mb: 4, height: 50 }} disabled={auth.pending || isSubmitting}>
                    LOG IN
                  </Button>
                  <Typography fontWeight={500}>
                    Don't have an account?{' '}
                    <Link href="/sign-up" color={colors.Secondary} fontWeight={700}>
                      Sign up
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default LoginPage;
