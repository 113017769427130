import { AxiosRequestConfig } from 'axios';
import { IFileUpload } from 'interface/public';
import customAxios from '../utils/request';

export const upload = async (url: string, file: ArrayBuffer | string, header: string) => {
  const params: AxiosRequestConfig = {
    method: 'PUT',
    url,
    headers: {
      accept: '*/*',
      ...JSON.parse(header),
    },
    data: file,
  };
  const response = await customAxios.request({ ...params });
  return response;
};

export const getSignedUrl = async (files: IFileUpload[], dealId?: string) => {
  const p: {
    files: IFileUpload[];
    itemId?: string;
  } = {
    files,
  };

  if (dealId) {
    p.itemId = dealId;
  }

  const params: AxiosRequestConfig = {
    method: 'POST',
    url: '/app/uploads/signed',
    headers: {
      accept: '*/*',
    },
    data: p,
  };
  const response = await customAxios.request({ ...params });
  return response.data.Files;
};

export const getBinaryFromFile = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => resolve(reader.result));
    reader.addEventListener('error', (err) => reject(err));

    reader.readAsArrayBuffer(file);
  });
};
