import { Box, Link, Stack, Typography, IconButton } from '@mui/material';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SjImage from 'components/Layout/SjImage';
import colors from 'constants/colors';
import { IChat, CHAT_STATUSES } from 'interface/chat';
import { formatMoney } from 'utils/formatMoney';
import { Close } from '@mui/icons-material';
import ChatUpload from 'components/Chat/Files/ChatUpload';
import ChatFiles from 'components/Chat/Files/ChatFiles';
import { DEALSTATUS } from 'interface/deal';
import React from 'react';
import DealPrice from 'components/Deal/DealPrice';

function RoomRightSideBar({ activeRoom, setShow, isShow }: { activeRoom: IChat; setShow: any; isShow: any }) {
  const display = isShow ? 'none' : 'block';
  const isBlocked = activeRoom?.item?.status === DEALSTATUS.BLOCKED;
  const DealWrapper: React.ElementType = isBlocked ? Box : Link;

  return (
    <>
      <IconButton
        sx={{
          display: [display, display, 'block', 'block'],
          position: 'absolute',
          right: 0,
          top: 12,
          background: '#F0EEDE',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          width: 30,
          height: 38,
          zIndex: 2,
        }}
        onClick={() => setShow(!isShow)}
      >
        <DoubleArrowIcon sx={{ width: 15 }} />
      </IconButton>
      {isShow && (
        <Box
          bgcolor={colors.White}
          sx={{
            width: ['auto', 'auto', '250px', '250px'],
            borderLeft: `1px solid ${colors.BaseGrey}`,
            position: ['absolute', 'absolute', 'static', 'static'],
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              display: ['block', 'block', 'none', 'none'],
              right: 8,
              top: 0,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              width: 30,
              height: 30,
              zIndex: 2,
            }}
            onClick={() => setShow(false)}
          >
            <Close sx={{ width: 30 }} />
          </IconButton>
          {activeRoom?.item?.id && (
            <DealWrapper href={`/deal/view/${activeRoom.item.id}`} style={{ opacity: isBlocked ? 0.5 : 1 }}>
              <Stack direction="row" pt={2} px={2} pb={2} sx={{ justifyContent: 'space-between', alignItems: 'center', mr: 2 }}>
                <Stack direction="row">
                  <SjImage url={activeRoom.item.photo} width={64} height={64} objectFit="cover" alt={activeRoom.item.name} />
                  <Box ml={2} textAlign="left">
                    <Typography fontWeight={600} color={colors.Black}>
                      {activeRoom.item.name}
                    </Typography>
                    <DealPrice
                      currencySymbol={activeRoom.item.symbol}
                      minPrice={activeRoom.item.minPrice}
                      maxPrice={activeRoom.item.maxPrice}
                      variant="inherit"
                      style={{ fontWeight: 700 }}
                    />
                  </Box>
                </Stack>
              </Stack>
            </DealWrapper>
          )}
          {activeRoom.status === CHAT_STATUSES.OPEN && <ChatUpload roomId={activeRoom.id} />}
          <ChatFiles roomId={activeRoom.id} roomOwner={activeRoom.onwerId} roomStatus={activeRoom.status} />
        </Box>
      )}
    </>
  );
}

export default RoomRightSideBar;
