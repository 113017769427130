import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IFilter } from 'interface/filter';
import { showToast } from 'redux/toast/action';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Pagination } from '@mui/material';

import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import { getUserProfileLink, getUsers } from 'apis/user';

import colors from 'constants/colors';
import SjAvatar from 'components/Layout/SjAvatar';
import { IUser, UserType } from 'interface/user';
import Grid from '@mui/material/Unstable_Grid2';
import { paginationNumber } from 'utils/common';
import { IReduxState } from 'interface/redux';
import SjHelmet from 'components/Layout/SjHelmet';

function SearchUserPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state: IReduxState) => state.auth);

  const initFilter = {
    p: 1,
    l: 20,
    search: '',
  };

  const [searchParams] = useSearchParams();
  const [users, setUsers] = useState<[] | IUser[]>([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState<IFilter>(initFilter);

  const [loading, setLoading] = useState(false);

  const loadUsers = (f: IFilter) => {
    setLoading(true);
    getUsers(f)
      .then((resp: any) => {
        setUsers(resp.data.data);
        setTotal(resp.data.total_count);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          showToast({
            type: 'error',
            title: 'Failed to fetch data',
          }),
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    const params: any = Object.fromEntries(searchParams);
    const newFilter: any = { ...initFilter };

    if (params.p) {
      newFilter.p = +params.p;
    }
    if (params.search) {
      newFilter.search = params.search;
    }

    setFilter(newFilter);
    loadUsers(newFilter);
  }, [searchParams]);

  const onPaginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    navigate(
      `/search-users?${new URLSearchParams({
        ...filter,
        p: page.toString(),
        l: filter.l.toString(),
      }).toString()}`,
    );
  };

  const onClickUser = (user: IUser) => () => {
    const currentUserId = auth?.user?.id.toString();
    const targetUserId = `${user.id}`;
    navigate(getUserProfileLink(targetUserId, currentUserId));
  };

  const renderShowingText = () => {
    const from = (filter.p - 1) * filter.l + 1;
    const to = (filter.p - 1) * filter.l + 1 + (filter.l - 1);
    return `Showing ${from} - ${to > total ? total : to} of ${total}`;
  };

  const renderUserName = ({ position, user }: { position: 'stick-avatar' | 'stick-view-profile'; user: IUser }) => (
    <Box
      flex={1}
      mr={2}
      overflow="hidden"
      {...(position === 'stick-view-profile' && { display: ['block', 'none'] })}
      {...(position === 'stick-avatar' && { display: ['none', 'block'] })}
    >
      <Typography fontWeight={600} fontSize={17} noWrap>{`@${user.username}`}</Typography>
      <Typography fontSize={16} noWrap>
        {user.role === UserType.Personal ? ` ${user.first_name} ${user.last_name}` : user.company_name}
      </Typography>
    </Box>
  );
  return (
    <Container sx={{ minWidth: '320px' }}>
      <SJBreadcrumbs title={`User search results '${filter.search}'`} />

      <SjHelmet title="Search user" />
      {loading ? (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <Stack direction={['column', 'column', 'row']} justifyContent="space-between">
            <Typography variant="h2">{`${total} user results ${filter.search ? `for '${filter.search}'` : ''}`}</Typography>
            <Typography color={colors.BaseSecondaryText}>{renderShowingText()}</Typography>
          </Stack>
          <Stack gap={5} my={3}>
            <Grid container spacing={3} padding={0}>
              {users.map((user) => (
                <Grid xs={12} sm={12} lg={6} md={6} key={user.id} minWidth="320px">
                  <Stack direction="row" bgcolor={colors.White} p={2} alignItems={['flex-start', 'center']} justifyContent={['start', 'space-between']}>
                    <Stack direction="row" flex={['none', 1]} overflow="hidden">
                      <SjAvatar user={user} />
                      {renderUserName({ position: 'stick-avatar', user })}
                    </Stack>

                    <Stack direction={['column', 'row']} overflow="hidden" justifyContent={['flex-start', 'flex-end']} flex={[1, 'none']}>
                      {renderUserName({ position: 'stick-view-profile', user })}
                      <Button sx={{ backgroundColor: colors.Secondary300, borderRadius: 20, mt: [2, 0], px: 2 }} onClick={onClickUser(user)}>
                        View Profile
                      </Button>
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            {!!total && filter.p && filter.l && total > filter.l && (
              <Pagination count={paginationNumber(total, filter.l)} page={filter.p} sx={{ flex: 1 }} onChange={onPaginationChange} />
            )}
          </Stack>
        </>
      )}
    </Container>
  );
}

export default SearchUserPage;
