import { Box } from '@mui/material';
import colors from 'constants/colors';
import { ReactElement } from 'react';

function RoomLeftSidebar({ isShow, children }: { isShow: boolean; children: ReactElement | ReactElement[] }) {
  const display = isShow ? 'block' : 'none';
  return (
    <Box
      sx={{
        bgcolor: colors.Primary,
        width: ['auto', 'auto', '250px', '250px'],
        overflow: 'hidden',
        position: ['absolute', 'absolute', 'static', 'static'],
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 2,
      }}
      display={[display, display, 'block', 'block']}
    >
      {children}
    </Box>
  );
}

export default RoomLeftSidebar;
