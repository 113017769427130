/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Typography from '@mui/material/Typography';
import PlayCircleOutlineTwoToneIcon from '@mui/icons-material/PlayCircleOutlineTwoTone';
import colors from 'constants/colors';
import { FormikValues, useFormikContext } from 'formik';
import { DragEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showToast } from 'redux/toast/action';

import CloseIcon from '@mui/icons-material/Close';
import { getBinaryFromFile, getSignedUrl, upload } from 'apis/upload';
import { Stack, StackProps } from '@mui/material';

export enum IFileKind {
  MEDIA = 'MEDIA',
  CHART = 'CHART',
  ANALYSE = 'analyse',
  FAQ = 'faq',
}

export const MAXIMUM_FILE = 3000000;

const generateVideoThumbnail = (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const video = document.createElement('video');

    video.autoplay = true;
    video.muted = true;
    video.src = URL.createObjectURL(file);

    video.onloadeddata = () => {
      const ctx: any = canvas.getContext('2d');

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
      video.pause();
      return resolve(canvas.toDataURL('image/png'));
    };
  });
};

function SjFile({
  deal,
  name,
  type = 'grid',
  carouselStackProps,
  accept = 'image/png,image/jpg',
  isOptional,
  isMulti,
  label,
  helpText,
  maxFile,
  kind,
}: {
  deal?: string;
  name: string;
  type?: 'grid' | 'carousel';
  carouselStackProps?: StackProps;
  accept: string;
  isOptional?: boolean;
  isMulti?: boolean;
  label?: string;
  helpText?: string | ReactElement;
  maxFile?: number;
  kind?: IFileKind;
}) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { values, setFieldValue, errors, touched } = useFormikContext<FormikValues>();

  const [uploading, setUploading] = useState(false);
  const dispatch = useDispatch();
  const [media, setMedia] = useState<any>([]);

  useEffect(() => {
    if (values.photos) {
      setMedia(
        values.photos.map((item: any) => ({
          ...item,
          thumbnail_url: item.thumbnail_url.length > 0 ? item.thumbnail_url : item.url,
        })),
      );
    }
  }, [values.photos]);

  const handleChange = async (targetFiles: File[]) => {
    setUploading(true);
    const uploadFiles: File[] = [];
    targetFiles.forEach((item: File) => {
      let isError = false;
      if (item.type.includes('video') && item.size / 1000000 > 250) {
        isError = true;
        dispatch(
          showToast({
            type: 'error',
            title: 'Exceed allowed limit of size.',
            description: `${item.name}: You can upload a video with max file size 250MB.`,
          }),
        );
      }

      if (item.size / 1000000 > 25) {
        isError = true;
        dispatch(
          showToast({
            type: 'error',
            title: 'Exceed allowed limit of size.',
            description: `${item.name}: You can upload a photo with max file size 25MB.`,
          }),
        );
      }

      if (!isError) {
        uploadFiles.push(item);
      }
    });

    if (uploadFiles.length === 0) {
      setUploading(false);
      return;
    }

    const mediaVideoFiles = media.filter((item: any) => item.content_type.includes('video'));

    const videoFiles = uploadFiles.filter((item) => item.type.includes('video'));

    if (videoFiles.length + mediaVideoFiles.length > 1) {
      setUploading(false);
      dispatch(
        showToast({
          type: 'error',
          title: 'Exceed allowed limit of file.',
          description: 'You can only upload 1 video.',
        }),
      );
      return;
    }

    if (uploadFiles.length + media.length - mediaVideoFiles.length - videoFiles.length > 10) {
      setUploading(false);
      dispatch(
        showToast({
          type: 'error',
          title: 'Exceed allowed limit of file.',
          description: 'You can only upload 10 photos.',
        }),
      );
      return;
    }

    let exceedFileSize = false;

    uploadFiles.forEach((item) => {
      if (item.size / 1000000 > 100 && !exceedFileSize) {
        exceedFileSize = true;
      }
    });

    if (exceedFileSize) {
      setUploading(false);
      dispatch(
        showToast({
          type: 'error',
          title: 'Exceed allowed file size.',
          description: 'Max file size is 100MB.',
        }),
      );
      return;
    }
    try {
      const fileKeys = values[name] ? [...values[name]] : [];
      const mediaFiles = [...media];
      await Promise.all(
        uploadFiles.map(async (file: any, index: number) => {
          const url = URL.createObjectURL(file);
          const thumb = file.type.includes('video') ? await generateVideoThumbnail(file) : url;
          mediaFiles.push({
            url,
            content_type: file.type,
            thumbnail_url: thumb,
          });

          const fileExt = file.name.split('.').pop();
          const signedResp = await getSignedUrl(
            [
              {
                fileSize: file.size,
                fileExt: `.${fileExt}`,
                fileName: file.name,
              },
            ],
            deal,
          );
          const binaryFile = await getBinaryFromFile(file);
          const signInfo = signedResp[0];
          if (binaryFile) {
            await upload(signInfo.URL, binaryFile, JSON.stringify(signInfo.SignedHeader));
            fileKeys.push({
              url: signInfo.Key,
              content_type: file.type,
              thumbnail_url: thumb,
            });
          }
          setUploading(false);
        }),
      );
      setFieldValue(name, fileKeys);
    } catch (error: any) {
      dispatch(
        showToast({
          type: 'error',
          title: 'Can not upload the file',
          description: error?.response?.data?.error?.message ?? 'Please try again later',
        }),
      );
      setUploading(false);
    }
  };

  const handleUpload = (e: FileList) => {
    handleChange(Array.from(e));
  };

  const handleDrag = (e: DragEvent<HTMLLabelElement>) => {
    if (!uploading) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    if (!uploading) {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const fileList: File[] = Array.from(e.dataTransfer.files);
        handleChange(fileList);
      }
    }
  };

  const renderImageList = () =>
    media.map((item: any, index: number) => (
      <ImageListItem key={index} sx={{ position: 'relative', ...(type === 'carousel' ? { mr: '16px' } : {}) }}>
        {item.content_type.includes('video') && (
          <PlayCircleOutlineTwoToneIcon sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '50px' }} />
        )}
        <img
          src={item.thumbnail_url}
          key={item.url}
          style={{ borderRadius: '8px', height: '100px', ...(type === 'carousel' ? { width: '100px' } : {}) }}
          alt="galery"
        />
        {item.url}
        <IconButton
          sx={{
            position: 'absolute',
            right: 2,
            top: '-3px',
            zIndex: 1,
            padding: 0,

            '&:before': {
              content: '""',
              background: colors.Header,
              opacity: 0.5,
              width: '20px',
              height: '20px',
              position: 'absolute',
              zIndex: 0,
              top: 3,

              borderRadius: '50%',
            },
          }}
          onClick={() => {
            const updatedFileValue = [...values[name]];
            const updatedMedia = [...media];
            updatedFileValue.splice(index, 1);
            updatedMedia.splice(index, 1);
            setMedia(updatedMedia);
            setFieldValue(name, updatedFileValue);
          }}
        >
          <CloseIcon sx={{ width: '16px', color: colors.White, zIndex: 2 }} />
        </IconButton>

        <Box
          sx={{
            position: 'absolute',
            top: '5px',
            left: '5px',
            width: '18px',
            height: '17px',
            borderRadius: '8px',
            opacity: 0.5,
            background: colors.Black,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ color: colors.White, lineHeight: '14px', fontSize: '13px' }}>{index + 1}</Typography>
        </Box>
      </ImageListItem>
    ));

  return (
    <Box>
      <label htmlFor="input-file-upload" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}>
        <Box
          textAlign="center"
          p={4}
          border={`3px dashed ${touched[name] && errors[name] ? colors.Error500 : colors.BaseGrey}`}
          borderRadius="24px"
          sx={{ backgroundColor: colors.White }}
        >
          {values[name].length < 10 && (
            <Button variant="outlined" component="label" disabled={uploading} color={touched[name] && Boolean(errors[name]) ? 'error' : 'primary'}>
              {!uploading ? 'UPLOAD FILES' : 'Uploading'}
              <input
                disabled={uploading}
                accept="image/*,video/mp4,video/quicktime"
                multiple
                type="file"
                hidden
                id="input-file-upload"
                onChange={(e) => {
                  if (e.target.files) {
                    handleUpload(e.target.files);
                  }
                  e.target.value = '';
                }}
                ref={inputRef}
              />
            </Button>
          )}
          <Typography fontWeight={500} color={colors.BaseText} mt={2}>
            Up to 10 files include photos and 1 video
          </Typography>
        </Box>
      </label>
      {touched[name] && Boolean(errors[name]) && (
        <Typography
          sx={{
            color: colors.BaseSecondaryText,
            fontSize: '14px',
            fontWeight: 500,
            mt: 2,
          }}
        >
          {errors[name]?.toString()}
        </Typography>
      )}
      <Typography color={colors.BaseSecondaryText} fontSize={14} mt={1}>
        Only JPG, JPEG or PNG for Photo (max file size: 25MB) and MOV, MP4 for Video (max file size: 250MB)
      </Typography>

      <Box mt={2}>
        {!!media?.length &&
          (type === 'carousel' ? (
            <Stack
              alignItems="center"
              direction="row"
              width="100%"
              px={3}
              {...(carouselStackProps ?? {})}
              sx={{
                overflowX: 'auto',
                py: 1,
                '&::-webkit-scrollbar': {
                  height: '3px',
                },
                '&::-webkit-scrollbar-track': {
                  background: 'transparent',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: colors.Primary,
                },
                ...(carouselStackProps?.sx ?? {}),
              }}
            >
              {renderImageList()}
            </Stack>
          ) : (
            <ImageList cols={3} rowHeight={100} gap={12} sx={{ overflowY: 'visible' }}>
              {renderImageList()}
            </ImageList>
          ))}
      </Box>
    </Box>
  );
}

export default SjFile;
