import colors from 'constants/colors';

export const setAppBar = () => ({
  MuiAppBar: {
    styleOverrides: {
      root: () => ({
        background: colors.Primary,
        color: colors.Header,
        fontWeight: 700,
        boxShadow: 'none',
        borderBottomRightRadius: '100px',
      }),
    },
  },
});
