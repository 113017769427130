import { Button, Card, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useEffect, useState } from 'react';
import authProvider from 'apis/auth';

import useQuery from 'utils/useQuery';
import { useNavigate } from 'react-router-dom';

function ActivatePage() {
  const query = useQuery();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isActivate, setIsActive] = useState(true);

  const activate = (token: string) => {
    authProvider
      .activate(token)
      .then(() => {
        setLoading(false);
        setIsActive(true);
      })
      .catch((error) => {
        setLoading(false);
        setIsActive(false);
      });
  };

  useEffect(() => {
    const token = query.get('token');
    if (token && token?.length > 0) {
      activate(token);
    }
  }, [query]);

  if (loading) {
    return (
      <Stack textAlign="center" alignItems="center" marginTop={10}>
        <Typography>Loading</Typography>
      </Stack>
    );
  }

  return isActivate ? (
    <Stack textAlign="center" alignItems="center" marginTop={10}>
      <Card sx={{ boxShadow: 'none', maxWidth: '50%' }} variant="outlined">
        <Typography variant="h2" textAlign="center" mb={6}>
          Your account has been successfully activated!
        </Typography>
        <Typography textAlign="center" mb={6}>
          You can log in to your account now and start enjoying the site
        </Typography>
        <Button variant="contained" type="button" fullWidth sx={{ height: 52 }} onClick={() => navigate('/login')}>
          Log in
        </Button>
      </Card>
    </Stack>
  ) : (
    <Stack textAlign="center" alignItems="center" marginTop={10}>
      <Card sx={{ boxShadow: 'none', maxWidth: '50%' }} variant="outlined">
        <Typography variant="h2" textAlign="center" mb={6}>
          Activation failed!
        </Typography>

        <Typography textAlign="center" mb={6}>
          Please contact <a href="mailto:admin@listndeal.com">our administrator</a> for more details.
        </Typography>
      </Card>
    </Stack>
  );
}

export default ActivatePage;
