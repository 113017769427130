import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import React from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { FileText } from 'react-feather';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

export const renderFileIcon = (fileExt: string) => {
  const ext = fileExt.replace('.', '');
  switch (ext) {
    case 'pdf':
      return <PictureAsPdfIcon />;
    case 'docx':
      return <FileText />;
    case 'mp4':
    case 'mov':
      return <VideoLibraryIcon />;
    case 'mp3':
      return <AudioFileIcon />;
    case 'png':
    case 'jpg':
    case 'jpeg':
      return <InsertPhotoIcon />;
    case 'xls':
    case 'xlsx':
      return <img src={`${process.env.PUBLIC_URL}/images/xls.svg`} alt="xls" />;
    default:
      return <InsertDriveFileIcon />;
  }
};
