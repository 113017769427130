/* eslint-disable import/no-extraneous-dependencies */
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Link, Stack, SxProps, Theme, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Unstable_Grid2';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IDeal from 'interface/deal';
import colors from 'constants/colors';
import { useMainContext } from 'context/MainContext';
import { IReduxState } from 'interface/redux';
import ListingItem from './ListingItem';
import ListingItemLoading from './ListingItemLoading';

function ListingsComponent({
  deals,
  total,
  page,
  title,
  size,
  columns,
  hideShadow,
  actions,
  loading,
  hideOwner,
  onUpdated,
  noResult,
  onPaginationChange,
  type,
  itemProps,
}: {
  deals: IDeal[];
  total?: number;
  page?: number;
  size?: number;
  title?: string;
  columns?: number;
  hideShadow?: boolean;
  actions?: string[];
  loading?: boolean;
  hideOwner?: boolean;
  onUpdated?: any;
  noResult?: ReactElement;
  onPaginationChange?: any;
  type?: 'carousel' | 'list' | 'multiple-columns';
  itemProps?: { StackSx?: SxProps<Theme> };
}) {
  const MainContext = useMainContext();
  const [query, setQuery] = useState('');
  const { auth } = useSelector((state: IReduxState) => state);

  useEffect(() => {
    switch (title) {
      case 'New':
        setQuery('sort_by=newest');
        break;
      case 'Recommended':
        setQuery(`excludedOwnListing=true&category_ids=${auth?.user?.interests?.map((item) => item.id)?.toString()}`);
        break;
      case 'Popular':
        setQuery('includeLikeListing=true&sort_by=mostpopular');
        break;
      default:
    }
  }, [title]);

  const paginationNumber = (t: number, s: number) => {
    if (t < s || s <= 0) {
      return 1;
    }
    return Math.floor(t / s) + (t % s > 0 ? 1 : 0);
  };

  useEffect(() => {
    if (!!loading || !!deals.length) {
      MainContext.setPageType('normal');
      return;
    }
    MainContext.setPageType('no-result');
    return () => MainContext.setPageType('normal');
  }, [loading, deals.length]);

  const renderWithWrapper = (wrapperProps: { children: React.ReactNode }) => (
    <Box sx={{ ...(type === 'carousel' && { mb: 10 }) }}>
      {title && (
        <Stack mb={4} alignItems="center" justifyContent="space-between" direction="row" px={type === 'carousel' ? '16px' : undefined}>
          <Typography variant="h2">{title}</Typography>
          <Link href={`/search?${query}`} sx={{ color: colors.Secondary, display: 'flex', alignItems: 'center' }}>
            VIEW ALL <ArrowForwardIcon sx={{ width: 16, height: 16, strokeWidth: 4, ml: 1 }} />
          </Link>
        </Stack>
      )}
      <Grid
        container
        spacing={3}
        padding={0}
        {...(type === 'carousel' && {
          wrap: 'nowrap',
          sx: {
            display: 'flex',
            overflow: ['scroll', 'scroll', 'scroll', 'clip'],
            px: '16px',
            '&::-webkit-scrollbar': {
              height: '3px',
            },
            '&::-webkit-scrollbar-track': {
              background: colors.BackGround,
              marginY: '20px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: colors.BackGround,
            },
          },
        })}
        {...(type === 'multiple-columns' && { mb: 2 })}
      >
        {wrapperProps.children}
      </Grid>
    </Box>
  );

  if (loading) {
    return renderWithWrapper({ children: <ListingItemLoading columns={columns} hideOwner={hideOwner} /> });
  }
  if (!deals.length) {
    return noResult ?? <Box textAlign="center">No Results</Box>;
  }
  return (
    <>
      {renderWithWrapper({
        children: deals.map((deal) => (
          <Grid xs={12} sm={6} lg={columns ?? 3} md={columns ?? 3} key={deal.id} {...(type === 'carousel' && { minWidth: 300 })}>
            <Stack
              bgcolor={colors.White}
              p={2}
              sx={{
                boxShadow: !hideShadow ? '0px 4px 21px rgba(105, 88, 26, 0.15)' : 'none',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: '20px',
                borderBottomLeftRadius: 0,
                height: 'calc(100% - 36px)',
                ...(itemProps?.StackSx ?? {}),
              }}
            >
              <ListingItem deal={deal} actions={actions} hideOwner={hideOwner} onUpdated={onUpdated} />
            </Stack>
          </Grid>
        )),
      })}

      {!!total && page && size && total > size && (
        <Pagination count={paginationNumber(total!, size!)} page={page} sx={{ flex: 1, mb: 2 }} onChange={onPaginationChange} />
      )}
    </>
  );
}

ListingsComponent.defaultProps = {
  type: 'multiple-columns',
};

export default ListingsComponent;
