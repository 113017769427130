import { PlayCircleFilledTwoTone } from '@mui/icons-material';
import { Box } from '@mui/material';

import colors from 'constants/colors';
import { IPhoto } from 'interface/public';

function SjMedia({
  alt,
  media,
  width,
  height,
  objectFit,
  borderRadius,
}: {
  alt?: string;
  media?: IPhoto;
  width: number | string;
  height: number | string;
  objectFit: 'cover' | 'contain';
  borderRadius?: number | string;
}) {
  if (!media) {
    <Box width={width} height={typeof height === 'string' ? height : `${height}px`} bgcolor={colors.Primary} sx={{ borderRadius: borderRadius || '8px' }} />;
  }

  const url = media?.thumbnail_url ? media.thumbnail_url : media?.url;
  const type = media?.content_type?.includes('video') ? 'video' : 'image';

  return (
    <Box
      sx={{
        borderRadius: borderRadius || '8px',
        width,
        height: typeof height === 'string' ? height : `${height}px`,
        bgcolor: colors.Primary,
        position: 'relative',
      }}
    >
      <img src={url} alt={alt} width={width} height={height} style={{ borderRadius: borderRadius || '8px', objectFit }} />
      {type === 'video' && (
        <PlayCircleFilledTwoTone
          sx={{ color: colors.White, fontSize: 60, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
        />
      )}
    </Box>
  );
}

export default SjMedia;
