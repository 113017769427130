/* eslint-disable no-console */
import IDeal, { initDeal } from 'interface/deal';
import { createContext, useContext } from 'react';

export type DealContextType = {
  deal: IDeal;
  onUpdated: (deal: IDeal) => void;
  rooms: any;
};

export const DealContext = createContext<DealContextType>({
  deal: initDeal,
  onUpdated: (deal: IDeal) => console.warn(''),
  rooms: null,
});

export const useDealContext = (): DealContextType => useContext(DealContext);
