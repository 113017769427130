/* eslint-disable no-console */
import { IUnreadDataType } from 'interface/chat';
import { createContext, useContext } from 'react';

export type MainContextType = {
  unreadMessageCount: IUnreadDataType;
  updateUnreadMessageCount: (chatId: string, chatType: string, count: number) => void;
  setPageType: React.Dispatch<React.SetStateAction<'normal' | 'error' | 'no-result' | 'chat'>>;
};

export const MainContext = createContext<MainContextType>({
  unreadMessageCount: {
    generalListing: {
      total: 0,
    },
    myListing: {
      total: 0,
    },
    direct: {
      total: 0,
    },
    rooms: {
      total: 0,
    },
  },
  updateUnreadMessageCount: (chatId: string, chatType: string, count: number) => console.warn(''),
  setPageType: (pageType: React.SetStateAction<'normal' | 'error' | 'no-result' | 'chat'>) => console.warn('Need implementation!'),
});

export const useMainContext = (): MainContextType => useContext(MainContext);
