/* eslint-disable no-underscore-dangle */
import { useState, useEffect, ClassAttributes, Key, LiHTMLAttributes, useCallback } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { FormikValues, useFormikContext } from 'formik';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import colors from 'constants/colors';
import { IFilter } from 'interface/filter';
import { debounce, uniqBy } from 'lodash';
import { showToast } from 'redux/toast/action';
import { useDispatch } from 'react-redux';

export type IOption = {
  label: string;
  value: string | number;
  display?: string;
};

interface ISjRemoteProps {
  valueExp?: string;
  displayOptionText?: string[];
  displayExp?: string;
  label?: string;
  placeholder?: string;
  name: string;
  getData: any;
  debounceLoadDataTime?: number;
  isMultiple?: boolean;
  shouldShowAllChips?: boolean;
  maxInputHeight?: number;
  shouldKeepFilter?: boolean;
  disabled?: boolean;
  onChange?: (e: any) => void;
  helpText?: string;
  externalFilter?: any[];
  freeSolo?: boolean;
  mb?: number;
  isCommaSeparatable?: boolean;
  customFilterForSeparator?: (x: string) => boolean; //
  max?: number;
  selectDefaultValue?: (data: any) => any;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const initFilter = {
  l: 30,
  p: 1,
  search: '',
};

export default function SjRemote({
  label,
  name,
  getData,
  isMultiple,
  disabled,
  onChange,
  valueExp = 'id',
  displayExp = 'name',
  helpText,
  externalFilter,
  mb = 4,
  max,
  placeholder,
}: ISjRemoteProps) {
  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<IFilter>(initFilter);

  const [total, setTotal] = useState(0);
  const [selected, setSelected] = useState<any>(isMultiple ? [] : '');
  const [dependencies, setDependencies] = useState<any>();

  const getOptions = (active: boolean) => {
    const _filter = { ...filter };
    if (externalFilter) {
      externalFilter.forEach((item: { field: string; value: string }) => {
        if (item.value) {
          _filter[item.field] = item.value;
        }
      });
    }
    getData(_filter).then((resp: { data: any[]; total_count: number }) => {
      if (!active) {
        return;
      }

      let _options = filter.p > 1 ? [...options, ...resp.data] : resp.data;

      if (isMultiple && values[name]) {
        _options = uniqBy([..._options], valueExp);

        _options = [..._options].filter((x) => {
          return !values[name].find((c: any) => c[valueExp] === x[valueExp]);
        });
      }

      setOptions([..._options]);
      setTotal(resp.total_count);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    });
  };

  useEffect(() => {
    if (values[name]) {
      setSelected(isMultiple ? [...values[name].filter((item: any) => item)] : values[name]);
    }
  }, [values[name]]);

  useEffect(() => {
    if (selected && dependencies?.length > 0) {
      const fieldName = dependencies[0].name;
      const fieldValue = dependencies[0].value;
      const selectedOptions = selected.filter((item: any) => fieldValue.includes(item[fieldName]));
      setFieldValue(name, selectedOptions);
    }
  }, [dependencies]);

  useEffect(() => {
    if (externalFilter && JSON.stringify(dependencies) !== JSON.stringify(externalFilter)) {
      setDependencies(externalFilter);
    }
  }, [externalFilter]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
      setFilter(initFilter);
    } else {
      setLoading(true);
      let active = true;
      getOptions(active);
      return () => {
        active = false;
      };
    }
  }, [open, filter, externalFilter]);

  const handleChange = (e: any, value: any) => {
    if (max && value.length > max && value.length > values[name]?.length) {
      dispatch(
        showToast({
          type: 'error',
          title: `Exceed allowed limit of selection.`,
          description: `You can only select up to ${max}.`,
        }),
      );
      return;
    }
    setFieldValue(name, value);
    if (value.length < values[name]?.length) {
      const removedItem = values[name].filter((obj: any) => value.indexOf(obj) === -1);
      setOptions(uniqBy([...removedItem, ...options], valueExp));
    }
    if (typeof onChange === 'function') {
      onChange(value);
    }
  };

  const renderHelpText = () => {
    if (helpText) {
      return helpText;
    }
    if (touched[name] && errors[name]) {
      return errors[name]?.toString();
    }
    return '';
  };

  const debounceOnLoadData = useCallback(
    debounce((search) => {
      setFilter({
        ...filter,
        p: 1,
        search,
      });
    }, 500),
    [],
  );

  const renderOption = (
    props: JSX.IntrinsicAttributes & ClassAttributes<HTMLLIElement> & LiHTMLAttributes<HTMLLIElement>,
    option: { [x: string]: any; id: Key | null | undefined },
    metadata: { index: number },
  ) => {
    const listItem = [];

    let currentOptionsLength;
    if (isMultiple) {
      currentOptionsLength = values[name] ? values[name].length + options.length : options.length;
    } else {
      currentOptionsLength = options.length;
    }

    listItem.push(
      <li {...props} key={option[valueExp]}>
        {isMultiple && (
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={!!values[name]?.find((item: any) => item[valueExp] === option[valueExp])}
          />
        )}
        <Typography fontWeight={500}>{option[displayExp]}</Typography>
      </li>,
    );

    if (metadata.index === options.length - 1 && currentOptionsLength < total) {
      listItem.push(
        <li key={`load-more-${filter.p}`}>
          <Button
            variant="text"
            fullWidth
            sx={{
              fontWeight: 500,
              fontSize: '14px',
              justifyContent: 'flex-start',
              pl: '25px',
              pb: 3,
              textTransform: 'capitalize',
              '&:hover': {
                background: 'inherit',
                textDecoration: 'underline',
              },
            }}
            onClick={() => {
              setFilter({
                ...filter,
                p: filter.p + 1,
              });
            }}
          >
            Show more
          </Button>
        </li>,
      );
    }
    return listItem;
  };

  return (
    <Box flex={1} mb={mb}>
      {label && (
        <InputLabel shrink error={touched[name] && Boolean(errors[name])}>
          {label}
        </InputLabel>
      )}

      <Autocomplete
        multiple={isMultiple}
        open={open}
        value={selected}
        disabled={disabled}
        disableClearable
        disableCloseOnSelect={isMultiple}
        onChange={handleChange}
        sx={{
          '.MuiAutocomplete-tag': {
            height: '23px',
          },
          '.MuiFormControl-root .MuiInputBase-root': {
            minHeight: '47px',
            paddingX: '16px',
            paddingY: '14px',
          },
          '.MuiInputBase-root .MuiAutocomplete-input': {
            padding: '0',
            margin: '3px',
          },
        }}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={(o) => o}
        isOptionEqualToValue={(option, value) => option[valueExp] === value[valueExp]}
        getOptionLabel={(option) => (option ? option[displayExp] : '')}
        options={options}
        loading={loading}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            error={touched[name] && Boolean(errors[name])}
            {...params}
            onBlur={() => {
              setFieldTouched(name, true, true);
            }}
            onChange={(e) => {
              setLoading(true);
              debounceOnLoadData(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: params.InputProps.endAdornment,
            }}
            placeholder={!isMultiple || !selected?.length ? placeholder : ''}
          />
        )}
        // ListboxProps={{
        //   role: 'list-box',
        // }}
      />
      <Typography fontSize="14px" color={colors.BaseSecondaryText} fontWeight={500}>
        {renderHelpText()}
      </Typography>
    </Box>
  );
}
