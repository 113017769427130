import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import { useDeferredValue, useEffect, useMemo, useState } from 'react';
import IDeal from 'interface/deal';
import ListingsComponent from 'components/Listing/Listings';
import { useDispatch } from 'react-redux';
import { getDeals } from 'apis/deal';
import { IFilter } from 'interface/filter';
import { showToast } from 'redux/toast/action';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SortField, { ISortOption } from 'components/Search/SortItem';
import SearchFilterGroup from 'components/Search/FilterGroup';

import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import UserCurrency from 'utils/userCurrency';
import useQuery from 'utils/useQuery';
import { useBreakpoints } from 'themes';
import colors from 'constants/colors';
import cloneDeep from 'lodash/cloneDeep';
import SearchContext from 'context/SearchDealContext';
import SjHelmet from 'components/Layout/SjHelmet';

const SORT_OPTIONS: ISortOption[] = [
  {
    name: 'Newest',
    id: 'newest',
    order: 'DESC',
  },
  {
    name: 'Most popular',
    id: 'mostpopular',
    order: 'DESC',
  },
  {
    name: 'Price - Low to high',
    id: '+price',
    order: 'DESC',
  },
  {
    name: 'Price - High to low',
    id: '-price',
    order: 'ASC',
  },
];

function SearchPage() {
  const searchContext = SearchContext.useContext();
  const query = useQuery();
  const isUpMd = useBreakpoints((breakpoints) => breakpoints.up('md'));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Currency = UserCurrency.useCurrency();

  const [searchParams] = useSearchParams();

  const emptySearchFilter = useMemo(
    () => ({
      p: 1,
      l: 24,
      sort_by: 'newest',
      search: query.get('search') ?? '',
      category_ids: [],
      country_ids: [],
      convert_currency_id: Currency.data?.value,
      type: 'all',
      status: 'all',
    }),
    [Currency.data],
  );

  const [sortOptions, setSortOptions] = useState(SORT_OPTIONS);
  const [listing, setListing] = useState<[] | IDeal[]>([]);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState<IFilter>(emptySearchFilter);
  const [isOpenDrawerFilter, setOpenDrawerFilter] = useState(false);

  const [loading, setLoading] = useState(false);

  const deferredLoading = useDeferredValue(loading);

  const numOfOptions = (() => {
    const { category_ids, country_ids, type, status, min_price, max_price } = filter;
    const validOptionList = [category_ids?.length > 0, country_ids?.length > 0, !!min_price && !!max_price, type !== 'all', status !== 'all'];
    const result = validOptionList.filter((isValid) => isValid).length;
    return result;
  })();

  const onOpenDrawerFilter = (nextOpen: boolean) => () => {
    setOpenDrawerFilter(nextOpen);
  };

  const loadDeals = (f: IFilter) => {
    const filterWithoutAll = { ...f };
    Object.keys(f).forEach((key: string) => {
      switch (true) {
        case key === 'min_price' && f.min_price !== undefined:
        case key === 'max_price' && f.max_price !== undefined:
          return;

        case f[key] === 'all':
        case !f[key]:
          delete filterWithoutAll[key];
          // eslint-disable-next-line no-useless-return
          return;

        default:
      }
    });
    setLoading(true);
    getDeals(filterWithoutAll)
      .then((resp) => {
        setListing(resp.data);
        setTotal(resp.total_count);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(
          showToast({
            type: 'error',
            title: 'Failed to fetch data',
          }),
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    if (Currency.status !== 'success') {
      return;
    }

    const searchParamKeys: any = Object.fromEntries(query);

    const fieldMultiple = ['country_ids', 'category_ids'];
    Object.keys(searchParamKeys).forEach((item) => {
      if (searchParamKeys[item] === `${undefined}` || searchParamKeys[item]?.length === 0) {
        searchParamKeys[item] = fieldMultiple.includes(item) ? [] : '';
        return;
      }
      if (fieldMultiple.includes(item)) {
        searchParamKeys[item] = searchParamKeys[item].split(',').map((v: string) => +v);
      }
    });

    searchContext.setPriceRange((prevPriceRange: { min: string; max: string }) => ({
      ...prevPriceRange,
      min: searchParamKeys.min_price,
      max: searchParamKeys.max_price,
    }));

    loadDeals({
      ...searchParamKeys,
      convert_currency_id: Currency.data?.value,
    });

    setFilter({
      ...emptySearchFilter,
      ...searchParamKeys,
    });
  }, [query, Currency.data?.value, Currency.status]);

  useEffect(() => {
    if (filter.currency_id) {
      const updatedSortOptions = [...sortOptions];
      updatedSortOptions[2] = {
        ...updatedSortOptions[2],
        disabled: false,
      };
      updatedSortOptions[3] = {
        ...updatedSortOptions[3],
        disabled: false,
      };
      setSortOptions(updatedSortOptions);
    } else {
      setSortOptions(SORT_OPTIONS);
    }
  }, [filter]);

  const updateFilter = (field: string, v: string, isReload?: boolean) => {
    const newFilter = cloneDeep(filter);

    switch (true) {
      case field === 'max_price' && v === 'clear':
      case field === 'min_price' && v === 'clear':
        delete newFilter[field];
        setFilter(newFilter);
        return;

      case field === 'price_group' && v === 'clear':
        delete newFilter.min_price;
        delete newFilter.max_price;
        delete newFilter.currency_id;

        setFilter(newFilter);
        return;

      default:
        newFilter.p = 1;
        newFilter[field] = v;

        if (field === 'max_price' && !!v && newFilter.min_price === undefined) {
          newFilter.min_price = 0;
          searchContext.setPriceRange((prevPriceRange: { min: string; max: string }) => ({ ...prevPriceRange, min: '0' }));
        }

        setFilter(newFilter);
        if (isReload) {
          const nextParams = new URLSearchParams(newFilter).toString();
          navigate(`/search?${nextParams}`);
        }
    }
  };

  const onClearAll = () => {
    if (!isUpMd) {
      setOpenDrawerFilter(false);
    }
    searchContext.setPriceRange({ min: '', max: '' });
    const nextSearchParams = new URLSearchParams({
      search: '',
      p: '1',
    });
    const nextFilter = cloneDeep(emptySearchFilter);
    nextFilter.search = '';
    searchContext.setSearch('');
    setFilter(nextFilter);
    navigate(`/search?${nextSearchParams.toString()}`);
  };

  const onApply = () => {
    if (!isUpMd) {
      setOpenDrawerFilter(false);
    }

    const nextFilter: IFilter = { ...filter, p: 1 };
    if (!!nextFilter.max_price && nextFilter.min_price === undefined) {
      nextFilter.min_price = 0;
      searchContext.setPriceRange((prevPriceRange: { min: string; max: string }) => ({ ...prevPriceRange, min: '0' }));
    }

    const nextParams = new URLSearchParams(nextFilter).toString();
    const isSameFilter = nextParams === query.toString();

    if (isSameFilter) {
      navigate(0);
      return;
    }
    navigate(`/search?${nextParams}`);
  };

  const setFilterAndLoadDeals = (onNextFilter: (nextFilter: IFilter) => IFilter) => {
    const nextFilter = onNextFilter(cloneDeep(filter));
    setFilter(nextFilter);
    loadDeals(nextFilter);
    const nextParams = new URLSearchParams(nextFilter).toString();
    navigate(`/search?${nextParams}`);
  };

  const renderHeader = () => {
    const categoryName = searchParams.get('category_name');
    if (categoryName) {
      return categoryName;
    }
    const searchString = filter.search ? `for "${filter.search}"` : '';
    return `${total} results ${searchString}`;
  };

  const handleUpdatesList = (id: string, changedValues: any) => {
    const index = listing.findIndex((item: any) => item.id === id);
    if (index > -1) {
      const uRows = [...listing];
      changedValues.forEach((item: any) => {
        uRows[index] = {
          ...uRows[index],
          [item.field]: item.value,
        };
      });
      setListing(uRows);
    }
  };

  useEffect(() => {
    searchContext.addRefs({ setFilterAndLoadDeals });
    return () => {
      searchContext.addRefs({ setFilterAndLoadDeals: () => console.warn('Search page has unmounted!') });
    };
  }, []);

  const renderBreadcrumbTitle = () => {
    if (filter.excludedOwnListing) {
      return 'Recommended';
    }
    const searchString = filter.search ? `for "${filter.search}"` : '';
    return `Search results ${searchString}`;
  };

  const renderMetaTitle = () => {
    if (filter.search) {
      return 'Search result';
    }
    if (searchParams.get('excludedOwnListing')) {
      return 'All recommended listings';
    }
    if (searchParams.get('sort_by') === 'mostpopular') {
      return 'All popular listings';
    }
    if (searchParams.get('sort_by') === 'newest') {
      return 'All new listings';
    }
    return 'Search';
  };

  return (
    <Container sx={{ mt: 3, flex: 1, ...(!deferredLoading && listing.length === 0 && { display: 'flex', flexDirection: 'column' }) }}>
      <SjHelmet title={renderMetaTitle()} />
      <SJBreadcrumbs title={renderBreadcrumbTitle()} />
      <Typography variant="h2" mb={[0, 0, 0, 5]}>
        {renderHeader()}
      </Typography>
      <SearchFilterGroup
        position={isUpMd ? 'on-page' : 'drawer'}
        drawer={{ isOpen: isOpenDrawerFilter, onClose: onOpenDrawerFilter(false), onClearAll, onApply }}
        filter={filter}
        updateFilter={updateFilter}
      />
      <Stack direction="row-reverse" gap={4} justifyContent="space-between" mt={4}>
        {isUpMd ? (
          <Stack direction="row" gap={3} alignSelf="flex-end">
            <Button type="button" variant="text" onClick={onClearAll} sx={{ py: 1 }}>
              Clear All
            </Button>
            <Button type="button" variant="contained" onClick={onApply} sx={{ py: 0, width: '130px', fontSize: '14px' }} disabled={searchContext.hasError}>
              Apply
            </Button>
          </Stack>
        ) : (
          <Button
            type="button"
            variant="text"
            onClick={onOpenDrawerFilter(true)}
            sx={{ mr: -2, borderRadius: '50%', p: 0, minWidth: '48px' }}
            {...(numOfOptions > 0 && {
              variant: 'outlined',
              sx: { border: `1px solid ${colors.BaseLightGrey}`, px: 2, py: 0.75, ':hover': { background: 'transparent' } },
            })}
          >
            {numOfOptions > 0 && (
              <Typography
                sx={{
                  width: '20px',
                  height: '20px',
                  background: colors.Secondary,
                  borderRadius: '50%',
                  color: colors.White,
                  fontSize: '12px !important',
                  fontWeight: 700,
                  mr: 1,
                }}
              >
                {numOfOptions}
              </Typography>
            )}
            <TuneIcon sx={{ transform: 'rotate(90deg)', color: colors.Header }} />
          </Button>
        )}
        <SortField
          options={sortOptions}
          value={filter?.sort_by}
          onChange={(v: string) => {
            updateFilter('sort_by', v, true);
          }}
        />
      </Stack>
      <Stack mt={isUpMd ? 3 : 2} {...(!deferredLoading && listing.length === 0 && { flex: 1 })}>
        <ListingsComponent
          deals={listing}
          total={total}
          page={filter.p}
          size={filter.l}
          loading={deferredLoading}
          actions={['like', 'share']}
          onUpdated={handleUpdatesList}
          onPaginationChange={(_: React.ChangeEvent<unknown>, page: number) => {
            const nextFilter = {
              ...filter,
              p: page,
              l: filter.l,
            };
            navigate(
              `/search?${new URLSearchParams({
                ...filter,
                p: page.toString(),
                l: filter.l.toString(),
              }).toString()}`,
            );
            setFilter(nextFilter);
            loadDeals(nextFilter);
          }}
          noResult={
            <Stack textAlign="center" flex={1} justifyContent="center" alignItems="center">
              <img src={`${process.env.PUBLIC_URL}/images/search.png`} alt="search" />
              <Typography variant="h3" mt={6}>
                There are no listings that match your search terms
              </Typography>
              <Typography mt={2} fontWeight={500}>
                Try another search, check if all words are spelled correctly, or try more general keywords
              </Typography>
            </Stack>
          }
        />
      </Stack>
    </Container>
  );
}

export default SearchPage;
