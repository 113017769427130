import { Close } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import SjMultiEmail from 'components/Form/SjMultiEmail';
import colors from 'constants/colors';
import { Formik, FormikErrors } from 'formik';
import { IInvitee, ParticipantRole } from 'interface/deal';
import { IFilter } from 'interface/filter';
import { useEffect, useRef, useState } from 'react';
import { useBreakpoints } from 'themes';
import SjScrollBox from 'components/Layout/SjScrollBox';
import { modalStyle } from 'themes/modal';
import Invitee from './Invitee';

export enum ManageInvitessViewType {
  MANAGE_INVITEE = 'Manage Invitees',
  MANAGE_PARTICIPANT = 'Manage Participants',
}

export interface IManageInvitee {
  viewType: ManageInvitessViewType;
  viewerRole: ParticipantRole;
  invitees: IInvitee[];
  isLoading: boolean;
  loadUsers?: (filter: IFilter) => void;
  addInvitees?: (invitees: IInvitee[]) => void;
  removeInvitee?: (invitee: IInvitee) => void;
  viewTitle?: string;
  onClose?: () => void;
}

export function ManageInvitees({ manageInvitees }: { manageInvitees: IManageInvitee }) {
  const isDownMd = useBreakpoints((breakpoints) => breakpoints.down('md'));
  const { viewTitle, viewType, isLoading, viewerRole, addInvitees, removeInvitee, onClose } = manageInvitees;
  const inviteesRef = useRef();
  const [invitees, setInvitees] = useState(manageInvitees.invitees);

  const owner = invitees.find((i) => {
    return ParticipantRole[(i.role || '').toUpperCase() as keyof typeof ParticipantRole] === ParticipantRole.OWNER;
  });
  const partner = invitees.find((i) => {
    return ParticipantRole[(i.role || '').toUpperCase() as keyof typeof ParticipantRole] === ParticipantRole.PARTNER;
  });

  const participants = invitees.filter((i) => {
    return [ParticipantRole.PARTICIPANT.toString(), undefined].includes(i.role || '');
  });

  const allParticipants = [owner, partner, ...participants].filter((item) => item);

  useEffect(() => {
    if (manageInvitees.invitees) {
      setInvitees(manageInvitees.invitees);
    }
  }, [manageInvitees]);

  return (
    <Stack
      sx={{
        ...modalStyle,
        p: 0,
        width: ['98%', '78%', '78%', '50%'],
        maxHeight: 'calc(100% - 32px)',
        flexDirection: 'row',
      }}
      textAlign="center"
    >
      <Stack
        sx={{
          padding: isDownMd ? 2 : 3,
          flexDirection: 'column',
          flex: 1,
          maxHeight: '100%',
          maxWidth: `calc(100% - ${isDownMd ? 32 : 48}px)`,
        }}
      >
        <Box sx={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row-reverse' }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              onClose?.();
            }}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Box>

        <Typography
          variant="h3"
          sx={{ display: 'flex', justifyContent: 'space-between', lineHeight: '1', height: '50px', ...(isDownMd && { mb: 3, height: 'unset' }) }}
        >
          {viewTitle ?? viewType}
        </Typography>

        {[ParticipantRole.OWNER, ParticipantRole.PARTNER].includes(viewerRole) && (
          <Formik
            initialValues={{ invitees: [] }}
            onSubmit={(values, { resetForm }) => {
              if (addInvitees) {
                addInvitees?.(values.invitees);
                resetForm();
              }
            }}
            validate={(values) => {
              const errors: FormikErrors<{ invitees: string }> = {};
              if (values.invitees.length > 0) {
                const isDuplicated = values.invitees.find((item: any) => item.isDuplicated);
                if (isDuplicated) {
                  errors.invitees = 'Duplicate email.';
                }
                const isAlreadyInvited = values.invitees.find((item: any) => item.isAlreadyInvited);
                if (isAlreadyInvited) {
                  errors.invitees = 'User has already joined this room.';
                }

                const isInvalid = values.invitees.find((item: any) => item.isInvalid);
                if (isInvalid) {
                  errors.invitees = 'Invalid user';
                }
              }
              return errors;
            }}
          >
            {({ handleSubmit, values, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: isDownMd ? 2 : 3,
                    justifyContent: 'stretch',
                    alignItems: 'top',
                    textAlign: 'left',
                    flexDirection: isDownMd ? 'column' : 'row',
                  }}
                >
                  <SjMultiEmail
                    name="invitees"
                    helpText="Separate email addresses by comma"
                    disabled={isSubmitting}
                    excludeItems={[...allParticipants]}
                    mb={0}
                  />
                  <Button type="submit" variant="contained" disabled={isLoading || values.invitees.length === 0} sx={{ py: 1, px: 2, mb: 'auto' }}>
                    invite
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        )}
        {allParticipants && allParticipants.length > 0 && (
          <SjScrollBox ref={inviteesRef} style={{ maxHeight: '50vh', maxWidth: '100%' }}>
            <>
              <Stack gap={2} mt={3}>
                {owner && (
                  <Invitee
                    invitee={owner}
                    key={owner.user_email}
                    viewerRole={viewerRole}
                    onRemove={removeInvitee}
                    isLoading={isLoading}
                    ownerId={owner.id || ''}
                  />
                )}
                {partner && (
                  <Invitee
                    invitee={partner}
                    key={partner.user_email}
                    viewerRole={viewerRole}
                    onRemove={removeInvitee}
                    isLoading={isLoading}
                    ownerId={partner.id || ''}
                  />
                )}
                {viewType === ManageInvitessViewType.MANAGE_PARTICIPANT && <Divider />}
                {participants &&
                  participants.length > 0 &&
                  participants.map((invitee) => {
                    return (
                      <Invitee
                        invitee={invitee}
                        key={invitee.user_email}
                        viewerRole={viewerRole}
                        onRemove={removeInvitee}
                        isLoading={isLoading}
                        ownerId={owner ? owner.id || '' : ''}
                        type={viewType === ManageInvitessViewType.MANAGE_INVITEE ? 'invitee' : 'participant'}
                      />
                    );
                  })}
              </Stack>
              {viewType === ManageInvitessViewType.MANAGE_PARTICIPANT && (
                <Typography fontSize="14px" color={colors.BaseSecondaryText} fontWeight={500} sx={{ textAlign: 'left', my: 2 }}>
                  You can only remove participants you’ve added to the Room.
                </Typography>
              )}
            </>
          </SjScrollBox>
        )}
      </Stack>
    </Stack>
  );
}
