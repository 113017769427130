/* eslint-disable no-param-reassign */
export const getChangedValues = (values: any, initValues: any) => {
  return Object.entries(values).reduce((acc: any, [key, value]) => {
    const hasChanged = initValues[key] !== value;
    if (hasChanged) {
      acc[key] = value;
    }
    return acc;
  }, {});
};
