/* eslint-disable @typescript-eslint/no-unused-expressions */
import Button from '@mui/material/Button';
import { useMutation } from '@apollo/react-hooks';
import Box from '@mui/material/Box';

import colors from 'constants/colors';
import { ChatContainerContext } from 'context/ChatContainerContext';
import { createRef, useContext, useDeferredValue, useEffect, useRef, useState } from 'react';
import { ACCEPT_INVITATION, REJECT_INVITATION } from 'apis/graphql';

import { MESSAGE_TYPES } from 'interface/chat';
import { ChatMessageContext } from 'context/ChatMessageContext';
import { Typography } from '@mui/material';
import SjScrollBox from 'components/Layout/SjScrollBox';
import { renderDateByFormat } from 'utils/dateFormat';
import ChatMessage from './ChatItemDetailMessage';
import { LIMIT } from './ChatItemDetail';

export const InvitedToRoom = 'Invited To Room';
export const InvitationAccepted = 'Invitation Accepted';
export const InvitationRejected = 'Invitation Rejected';

export const SpecialMessage = [InvitedToRoom, InvitationAccepted, InvitationRejected];

function ChatMessageList({
  setRoomId,
  updateMessages,
}: {
  setRoomId: (roomId: string) => void;
  updateMessages: (id: string, field: string, value: string, s: string) => void;
}) {
  const { messages, prevToken, getMessages, nextToken, setPage, page, searchMsgId, search, isTyping, setIsTyping } = useContext(ChatMessageContext);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const messageListRef = useRef<HTMLDivElement | null>(null);
  const { activeChat } = useContext(ChatContainerContext);

  const [acceptRoomInvitation] = useMutation(ACCEPT_INVITATION);
  const [rejectRoomInvitation] = useMutation(REJECT_INVITATION);
  const [lastMsgId, setLastMsgId] = useState('');
  const [focusIndex, setFocusIndex] = useState<any>();

  const deferredMsg = useDeferredValue(messages);
  const [elRefs, setElRefs] = useState<any>([]);

  const isLoadOlder = useRef(false);
  const isLoadMore = useRef(false);

  const handleIntersect = (changes: any) => {
    const idSplit = changes[0].target.id.split('-');

    const index = parseInt(idSplit[idSplit.length - 1], 10);
    if (changes[0].isIntersecting && index) {
      setFocusIndex(index);
    }
  };

  const observer = new IntersectionObserver(handleIntersect);

  useEffect(() => {
    if (!isTyping && !isLoadMore.current && (isLoadOlder.current || (search && page === 0))) {
      const msgId = isLoadOlder.current ? lastMsgId : searchMsgId;
      const scrollTo = document.getElementById(`message-${msgId}`)?.getBoundingClientRect().y ?? 0;
      messageListRef.current?.scrollTo({
        top: scrollTo - 500,
        left: 0,
        behavior: 'auto',
      });
    } else if (!isLoadMore.current) {
      const endBoundingRect = messagesEndRef.current?.getBoundingClientRect();
      const endPosition = (endBoundingRect?.top ?? 0) + (endBoundingRect?.height ?? 0);
      messageListRef.current?.scrollTo({ behavior: 'auto', top: endPosition });
      setIsTyping(false);
    }
    isLoadOlder.current = false;
    isLoadMore.current = false;
    if (messages.length > 0) setLastMsgId(messages[0].id);
  }, [deferredMsg]);

  useEffect(() => {
    if (!focusIndex) {
      setFocusIndex(deferredMsg.length - 1);
    }
    setElRefs(deferredMsg.map((_, i) => elRefs[i] || createRef()));
  }, [deferredMsg]);

  useEffect(() => {
    if (elRefs.length > 0) {
      elRefs.forEach((el: any) => observer.observe(el.current));
      elRefs[deferredMsg.length - 1].current.focus();
    }
  }, [elRefs]);

  const acceptRoom = (id: string) => {
    acceptRoomInvitation({
      variables: {
        input: {
          roomId: activeChat.id,
          messageId: id,
        },
      },
    }).then((res: any) => {
      const { data } = res;
      if (data.acceptRoomInvitation) {
        setRoomId(data.acceptRoomInvitation.groupRoomId);
      }
    });
    updateMessages(id, 'status', 'COMPLETED', InvitationAccepted);
  };

  const rejectRoom = (id: string) => {
    rejectRoomInvitation({
      variables: {
        input: {
          roomId: activeChat.id,
          messageId: id,
        },
      },
    });
    updateMessages(id, 'status', 'COMPLETED', InvitationRejected);
  };

  const renderFocusDate = () => {
    if (!deferredMsg[focusIndex]) return;

    const date = deferredMsg[focusIndex]?.createdAt;
    return renderDateByFormat(date, false, true);
  };

  return (
    <>
      {messages.length > 0 && (
        <Typography fontWeight={700} fontSize="13px" sx={{ background: colors.White, mt: 1, mb: 1, textAlign: 'center' }}>
          {renderFocusDate()}
        </Typography>
      )}
      <SjScrollBox
        direction="column"
        id="message-list"
        ref={messageListRef}
        style={{
          px: [2, 2, 2, 3],
          mb: 2,
        }}
      >
        <>
          {messages.length === 0 && (
            <Box textAlign="center" my={2}>
              Type something to start{' '}
            </Box>
          )}
          {prevToken && (
            <Button
              onClick={() => {
                setPage(page - 1);
                isLoadOlder.current = true;
                getMessages(
                  {
                    variables: {
                      input: {
                        roomId: activeChat.id,
                        limit: LIMIT,
                        prevToken,
                      },
                    },
                    fetchPolicy: 'network-only',
                  },
                  'prev',
                );
              }}
            >
              Load older messages
            </Button>
          )}

          {deferredMsg.map((m: any, index: number) => (
            <Box ref={elRefs[index]} key={`${m.id}-index`} id={`message-wrapper-${index}`}>
              <ChatMessage
                message={m}
                acceptRoom={acceptRoom}
                rejectRoom={rejectRoom}
                index={`message-${m.id}`}
                prevMsgOwner={index > 0 && deferredMsg[index - 1].type === MESSAGE_TYPES.NORMAL ? deferredMsg[index - 1].owner.id : undefined}
              />
            </Box>
          ))}
          <Box ref={messagesEndRef} sx={{ visibility: 'hidden' }}>
            End
          </Box>
          {nextToken && (
            <Button
              onClick={() => {
                setPage(page + 1);
                const input: any = {
                  roomId: activeChat.id,
                  limit: LIMIT,
                  nextToken,
                };
                if (searchMsgId) {
                  input.messageId = searchMsgId;
                }
                isLoadMore.current = true;
                getMessages(
                  {
                    variables: {
                      input,
                    },
                    fetchPolicy: 'network-only',
                  },
                  'next',
                );
              }}
            >
              Load more messages
            </Button>
          )}
        </>
      </SjScrollBox>
    </>
  );
}

export default ChatMessageList;
