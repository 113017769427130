import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'redux/configureStore';
import App from './app/App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
