import { Box, Container, Stack, Typography } from '@mui/material';
import colors from 'constants/colors';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import { useBreakpoints } from 'themes';

const MAKING_BUSSINESS_CONTENT = [
  { id: '01', normalText: ' ', italicText: 'Convenient', icon: 'convenient.svg', height: '241px', space: '32px' },
  { id: '02', normalText: ' ', italicText: 'Efficient', icon: 'efficient.svg', height: '241px', space: '32px' },
  { id: '03', normalText: ' ', italicText: 'Secure', icon: 'secure.svg', height: '211px', space: '62px' },
];
function HomeMakingBussiness() {
  const isUpMd = useBreakpoints((breakpoints) => breakpoints.up('md'));

  const renderItem = (item: any) => (
    <Box key={item.id} textAlign="center" mb={5}>
      <img src={`${process.env.PUBLIC_URL}/images/${item.icon}`} alt={item.italicText} height={230} />
      <Typography fontSize={14} fontWeight={700} mb={3} color={colors.BaseSecondaryText}>
        {item.id}
      </Typography>
      <Typography variant="h3">
        <span className="italic">{item.italicText}</span>
      </Typography>
    </Box>
  );

  return (
    <Container sx={{ mb: 10 }}>
      <Typography variant="h2" textAlign="center" mb={8}>
        Doing business made easy.
      </Typography>

      {isUpMd ? (
        <Stack sx={{ flexDirection: 'row' }} justifyContent="space-between" px={6}>
          {MAKING_BUSSINESS_CONTENT.map(renderItem)}
        </Stack>
      ) : (
        <Carousel
          isAutoPlaying
          hasMediaButton={false}
          hasLeftButton={false}
          hasRightButton={false}
          hasSizeButton={false}
          playIcon={false}
          isMaximized={false}
          hasIndexBoard={false}
          hasThumbnails={false}
          hasDotButtons="bottom"
          style={{ background: colors.BackGround }}
          activeIcon={<Box bgcolor={colors.Black} height="8px" width="8px" borderRadius="4px" mr="8px" />}
          passiveIcon={<Box bgcolor={colors.Base600} height="8px" width="8px" borderRadius="4px" mr="8px" />}
        >
          {MAKING_BUSSINESS_CONTENT.map(renderItem)}
        </Carousel>
      )}
    </Container>
  );
}

export default HomeMakingBussiness;
