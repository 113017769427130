import { InputLabelProps } from '@mui/material';
import colors from 'constants/colors';

export const setFormStyles = () => ({
  MuiFormHelperText: {
    styleOverrides: {
      root: () => ({
        fontSize: 14,
        fontWeight: 500,
        color: colors.BaseSecondaryText,
        fontFamily: 'Work Sans',
        marginLeft: 0,
        '&.Mui-error': {
          color: colors.BaseSecondaryText,
        },
      }),
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: () => ({
        borderRadius: '10px',
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: () => ({
        border: `2px solid ${colors.BaseGrey}`,
        borderRadius: 10,
      }),
      root: {
        '&.MuiInputBase-noborder .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${colors.Primary}`,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${colors.Primary}`,
        },
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: () => ({
        width: '100%',
        '.MuiInputBase-colorSecondary': {
          background: colors.White,
          '.MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        },
        '.MuiOutlinedInput-root': {
          borderRadius: 10,
        },
      }),
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: colors.Base300,
        '&.Mui-error': {
          backgroundColor: colors.Error300,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.Base400,
          // '& .MuiOutlinedInput-notchedOutline': {
          //   borderColor: colors.Base300,
          // },
        },
      },
      input: {
        '&.MuiOutlinedInput-input': {
          padding: '15px 16px',
        },
        '&.MuiInputBase-inputMultiline': {
          padding: 0,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: ({ ownerState }: { ownerState: InputLabelProps }) => ({
        ...ownerState,
        color: colors.Header,
        fontSize: 16,
        fontWeight: 600,
        transform: 'none',
        marginBottom: 10,
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: () => ({
        '&.MuiError .MuiOutlinedInput-notchedOutline': {
          borderColor: '#d32f2f',
        },
        '& .MuiInputBase-root': {
          padding: '2px 5px 2.1px',
        },
        '& .MuiAutocomplete-inputRoot .MuiInputBase-input': {
          paddingBottom: '13px',
          paddingTop: '8px',
        },
        '&.MuiInputNoBorder .MuiOutlinedInput-notchedOutline': {
          borderRight: 'none',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        '& .MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-root': {
          paddingRight: 0,
        },
      }),
      popper: () => ({
        '& .MuiAutocomplete-option': {
          fontSize: 16,
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          fill: colors.Secondary,
        },
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        '&.MuiMenuItem-root': {
          paddingTop: 0,
          paddingBottom: 0,
        },
        '& .MuiTypography-root': {
          fontSize: 16,
        },
        '&.MuiIconButton-colorPrimary': {
          color: colors.Secondary,
        },
        '&.MuiButton-outlinedInfo': {
          color: colors.BaseText,
          fontWeight: 700,
          fontSize: 16,
          textTransform: 'uppercase',
          '&:hover': {
            backgroundColor: colors.Secondary300,
            borderColor: 'transparent',
          },
        },
      },
    },
  },
});
