import { useState } from 'react';
import { Box, Button, IconButton, Menu, MenuItem, Modal, Typography, TextField, InputLabel, Stack } from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import { Download, XCircle } from 'react-feather';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CloseIcon from '@mui/icons-material/Close';
import colors from 'constants/colors';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { CHAT_STATUSES, IRoomFile } from 'interface/chat';
import { modalStyle } from 'themes/modal';
import { REMOVE_FILE } from 'apis/graphql';
import { dowloadFromUrl } from 'apis/download';

function ChatFileAction({ roomId, roomOwner, file, roomStatus }: { roomId: string; file: IRoomFile; roomOwner?: string; roomStatus: CHAT_STATUSES }) {
  const { auth } = useSelector((state: IReduxState) => state);

  const currentUserId = auth?.user?.id.toString() ?? '';
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showRemoveFileModal, setShowRemoveFileModal] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [removeRoomFile] = useMutation(REMOVE_FILE);

  const open = Boolean(anchorEl);
  const removeFile = () => {
    removeRoomFile({
      variables: {
        input: {
          roomId,
          id: file.id,
          reason: deleteReason,
        },
      },
    }).then((res) => {
      setShowRemoveFileModal(false);
    });
  };

  const downloadFile = () => {
    dowloadFromUrl(`https://${file.fileUrl}`, file.fileName, file.fileName);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Modal open={showRemoveFileModal} onClose={() => setShowRemoveFileModal(false)}>
        <Box sx={modalStyle} textAlign="center">
          <IconButton
            aria-label="close"
            onClick={() => setShowRemoveFileModal(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography mt={3} variant="h3">
            Are you sure you want to delete this file?
          </Typography>
          <Box mt={3}>
            <InputLabel>Enter a reason for deletion, if applicable.</InputLabel>
            <TextField onChange={(e) => setDeleteReason(e.target.value)} />
          </Box>
          <Stack alignItems="center">
            <Button variant="contained" color="error" sx={{ padding: '12px 32px', mt: 3, width: 200 }} onClick={removeFile}>
              Yes, remove it
            </Button>
            <Button variant="text" sx={{ padding: '12px 32px', mt: 1, fontSize: 16 }} onClick={() => setShowRemoveFileModal(false)}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
      <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
          },
        }}
        MenuListProps={{
          sx: {
            borderRadius: '16px',
            p: 0,
            '& .MuiButtonBase-root.MuiMenuItem-root': {
              p: 2,
              ':not(:last-child)': {
                borderBottom: '1px solid #dddcdb',
              },
            },
          },
        }}
      >
        <MenuItem onClick={downloadFile}>
          <Download color={colors.BaseSecondaryText} />
          <Typography sx={{ ml: 1, color: colors.BaseText, fontWeight: 500 }}>Download</Typography>
        </MenuItem>
        {(currentUserId === roomOwner || file.user.id === currentUserId) && roomStatus === CHAT_STATUSES.OPEN && (
          <MenuItem onClick={() => setShowRemoveFileModal(true)}>
            <XCircle color={colors.Error500} />
            <Typography sx={{ ml: 1, color: colors.Error500, fontWeight: 500 }}>Remove</Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

export default ChatFileAction;
