/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, CircularProgress, TablePagination } from '@mui/material';
import { getReportedDeals } from 'apis/deal';

import Row, { columns } from 'components/Reported/ReportedDetail';

export default function CollapsibleTable() {
  const [filter, setFilter] = React.useState({
    l: 20,
    p: 1,
  });
  const [total, setTotal] = React.useState(0);
  const [data, setData] = React.useState([]);
  const [status, setStatus] = React.useState<'new' | 'loading' | 'success' | 'error'>('new');

  const onGetReportDetails = (f: any) => {
    getReportedDeals(f).then((res) => {
      setTotal(res.total_count);
      setData(res.data);
    });
  };

  const refreshReportDetails = () => {
    onGetReportDetails(filter);
  };

  const onClickRefresh = async () => {
    try {
      setStatus('loading');
      const res = await getReportedDeals(filter);
      setTotal(res.total_count);
      setData(res.data);
      setStatus('success');
    } catch (error) {
      setStatus('error');
    }
  };

  React.useEffect(() => {
    onGetReportDetails(filter);
  }, [filter]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h3" my={5}>
          Report Listings
        </Typography>

        <Button sx={{ flexDirection: 'row' }} onClick={onClickRefresh} disabled={status === 'loading'}>
          REFRESH{' '}
          {status !== 'loading' ? <RefreshIcon sx={{ fontSize: '16px', ml: '5px' }} /> : <CircularProgress color="inherit" size="16px" sx={{ ml: '5px' }} />}
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              {columns.map((column: any) => (
                <TableCell key={column.field} width={column.width ? `${column.width}%` : 'auto'} sx={{ fontSize: '16px' }} variant="head">
                  {column.headerName}
                </TableCell>
              ))}
              <TableCell width="16%" sx={{ fontSize: '16px' }} variant="head">
                Action
              </TableCell>
              <TableCell width="14%" sx={{ fontSize: '16px' }} variant="head">
                Action Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any) => {
              return <Row row={row} key={row.id} onActionSuccess={refreshReportDetails} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={filter.l}
        page={filter.p - 1}
        onPageChange={(event, newPage) => {
          setFilter({
            ...filter,
            p: newPage + 1,
          });
        }}
      />
    </>
  );
}
