export const tokenKey = `${process.env.REACT_APP_NAMESPACE}.token`;
export const tokenRefresh = `${process.env.REACT_APP_NAMESPACE}.tokenRefresh`;
export const userKey = `${process.env.REACT_APP_NAMESPACE}.user`;
export const currencyKey = `${process.env.REACT_APP_NAMESPACE}.currency`;
export const howToUseFabKey = `${process.env.REACT_APP_NAMESPACE}.howToUseFab`;

export const handleStorage = (name: string, value: any): void => {
  localStorage.setItem(name, JSON.stringify(value));
};

export const deleteItemStorage = (name: string): void => {
  localStorage.removeItem(name);
};

export const clearStorage = (): void => {
  localStorage.clear();
};

export const getStorage = (name: string) => {
  const data = localStorage.getItem(name);
  if (data) return JSON.parse(data);
  return undefined;
};
