import { useLazyQuery } from '@apollo/react-hooks';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { IconButton, Link, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { getDealById } from 'apis/deal';
import { GET_LISTING_ROOMS } from 'apis/graphql';
import ChatItem from 'components/Chat/ChatItem';
import ChatList, { CHAT_LIST_VIEW_MODE } from 'components/Chat/ChatList';
import DealPrice from 'components/Deal/DealPrice';
import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import SJFooter from 'components/Layout/SjFooter';
import SjHelmet from 'components/Layout/SjHelmet';
import SjImage from 'components/Layout/SjImage';
import colors from 'constants/colors';
import { useMainContext } from 'context/MainContext';
import { CHAT_STATUSES, IChat, IChatGraphqlResponse } from 'interface/chat';
import { IReduxState } from 'interface/redux';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useBreakpoints } from 'themes';
import { convertChatBoxItemApi } from 'utils/convertApiChatToWebChat';
import UserCurrency from 'utils/userCurrency';

const chatFilters = [
  {
    name: 'All',
    value: '',
  },
  {
    name: 'Active',
    value: CHAT_STATUSES.OPEN,
  },
  {
    name: 'Cancelled',
    value: CHAT_STATUSES.CANCELLED,
  },
  {
    name: 'Completed',
    value: CHAT_STATUSES.COMPLETED,
  },
  {
    name: 'Archived',
    value: CHAT_STATUSES.ARCHIVED,
  },
];

function DealRooms() {
  const params = useParams();
  const MainContext = useMainContext();
  const isUpMd = useBreakpoints((breakpoints) => breakpoints.up('md'));

  const [getListingRooms, { data: listingRooms, loading }] = useLazyQuery(GET_LISTING_ROOMS);

  const [deal, setDeal] = useState<any>();
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUserId = auth.user?.id?.toString() ?? '';
  const [filter, setFilter] = useState<CHAT_STATUSES>(CHAT_STATUSES.OPEN);
  const [nextToken, setNextToken] = useState();
  const [page, setPage] = useState(0);
  const [rooms, setRooms] = useState<IChat[]>([]);
  const [activeRoom, setActiveRoom] = useState<IChat | null>(null);
  const [display, setDisplay] = useState<'chat-list' | 'message'>('chat-list');

  const onToggleDisplay = (isDisplayChatlist: boolean) => {
    setDisplay(isDisplayChatlist ? 'chat-list' : 'message');
  };
  const onClickViewChatList = () => {
    setDisplay('chat-list');
  };

  const refresh = (opts?: { currencyId?: number; skipMountPhase?: boolean }) => {
    if (!params.dealId) {
      return;
    }

    getListingRooms({
      variables: {
        itemId: params.dealId,
        type: 'LISTING_GROUP',
        ...(!!filter && { roomStatus: filter }),
      },
      fetchPolicy: 'network-only',
    });
    getDealById(params.dealId, undefined, { convert_currency_id: opts?.currencyId }).then((res) => {
      setDeal(res);
    });
  };

  useEffect(refresh, [page, params.dealId, filter]);

  useEffect(() => {
    if (rooms.length > 0 && !activeRoom) {
      setActiveRoom(rooms[0]);
    }
  }, [rooms]);

  useEffect(() => {
    const roomData = listingRooms?.listingRooms?.items?.map?.((item: IChatGraphqlResponse) => convertChatBoxItemApi(item, currentUserId)) ?? [];
    setRooms(page === 0 ? roomData : [...roomData, ...rooms]);
    setNextToken(listingRooms?.listingRooms.nextToken);
  }, [listingRooms]);

  useEffect(() => {
    MainContext.setPageType('chat');
    return () => MainContext.setPageType('normal');
  }, []);

  useEffect(() => {
    if (isUpMd) {
      return;
    }
    setDisplay(!activeRoom ? 'chat-list' : 'message');
    SJFooter.current.setVisible(false);
    return () => SJFooter.current.setVisible(true);
  }, [isUpMd]);

  UserCurrency.useOnChangeEffect((currency) => refresh({ currencyId: currency?.value, skipMountPhase: true }));

  return (
    <Container sx={{ display: 'flex', flex: 1, flexDirection: 'column', mb: 3 }}>
      {deal && (
        <>
          <SjHelmet title={`Room - ${deal?.name}`} />
          <SJBreadcrumbs title={[{ name: deal.name, link: `/deal/view/${params.dealId}` }, { name: 'Rooms' }]} />

          <Stack direction="row">
            <Link href={`/deal/${deal.id}`}>
              <SjImage
                url={deal.photos[0].content_type.includes('image') ? deal.photos[0].url : deal.photos[0].thumbnail_url}
                width={64}
                height={64}
                objectFit="cover"
                alt={deal.name}
              />
            </Link>
            <Box ml={2} textAlign="left">
              <Link href={`/deal/${deal.id}`}>
                <Typography fontWeight={600} color={colors.BaseText}>
                  {deal.name}
                </Typography>

                <DealPrice
                  currencySymbol={deal.currency.symbol}
                  minPrice={deal.display_min_price}
                  maxPrice={deal.display_max_price}
                  variant="inherit"
                  style={{ fontWeight: 600 }}
                />
              </Link>
            </Box>
          </Stack>
        </>
      )}

      <Stack direction="row" sx={{ flex: 1, mt: 3, borderRadius: '16px', boxShadow: '0px 4px 21px rgba(105, 88, 26, 0.15)', overflow: 'hidden' }}>
        <Stack
          sx={{
            bgcolor: colors.Primary,
            overflow: 'hidden',
            ...(loading && { alignItems: 'center' }),
            ...(isUpMd && { width: '280px' }),
            ...(!isUpMd && display === 'chat-list' && { flex: 1 }),
            ...(!isUpMd && display === 'message' && { display: 'none' }),
          }}
        >
          <ChatList
            header={
              <Stack p={2} gap={1} direction="row" flexWrap="wrap">
                {chatFilters.map((item: any) => (
                  <Chip
                    label={item.name}
                    key={item.value}
                    onClick={() => {
                      setFilter(item.value);
                      setPage(0);
                      setActiveRoom(null);
                    }}
                    sx={{
                      fontSize: '14px',
                      fontWeight: 500,
                      bgcolor: filter === item.value ? colors.White : 'transparent',
                      border: `1px solid ${colors.White}`,
                      p: '15px 6px',
                    }}
                  />
                ))}
              </Stack>
            }
            chats={rooms}
            setActiveChat={setActiveRoom}
            activeChat={activeRoom}
            viewMode={CHAT_LIST_VIEW_MODE.ROOM}
            page={page}
            setPage={setPage}
            nextToken={nextToken}
            setShowLeftSidebar={onToggleDisplay}
            noFirstItemMargin
            loading={loading}
          />
        </Stack>

        <Stack {...(isUpMd || display === 'message' ? { flex: 1, position: 'relative' } : { display: 'none' })} bgcolor={colors.White}>
          {activeRoom && <ChatItem tab="LISTING_GROUP" chats={rooms} setChats={setRooms} activeChat={activeRoom} setActiveChat={setActiveRoom} />}

          {!isUpMd && (
            <IconButton
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                m: 1.5,
              }}
              onClick={onClickViewChatList}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Container>
  );
}

export default DealRooms;
