import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Modal, Stack, Typography } from '@mui/material';
import { reportDeal } from 'apis/deal';
import SjInput from 'components/Form/SjInput';
import SjRadio from 'components/Form/SjRadio';
import SjScrollBox from 'components/Layout/SjScrollBox';
import colors from 'constants/colors';
import { useDealContext } from 'context/DealContext';
import { Formik, FormikErrors } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { Flag } from 'react-feather';
import { modalStyle } from 'themes/modal';

const reasons = [
  {
    id: 'suspicious_account',
    label: 'Suspicious account',
  },
  {
    id: 'duplicate_post',
    label: 'Duplicate post',
  },
  {
    id: 'listing_wrongly_categorised',
    label: 'Listing wrongly categorised',
  },
  {
    id: 'irrelevant_content',
    label: 'Irrelevant content',
  },
  {
    id: 'offensive_content',
    label: 'Offensive content',
  },
  {
    id: 'illicit_activity',
    label: 'Illicit activity',
  },
  {
    id: 'others',
    label: 'Others',
  },
];

export type IReportForm = {
  type: string;
  detail: string;
};
function DealReportAction() {
  const { deal } = useDealContext();
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isReported, setIsReported] = useState(true);
  const [reportDetail] = useState<IReportForm>({
    type: '',
    detail: '',
  });

  const popupRef = useRef();
  useEffect(() => {
    setIsReported(deal.reported ?? false);
  }, [deal]);

  const submit = (values: IReportForm) => {
    reportDeal(deal.id, values).then((resp) => {
      setIsReported(true);
    });
  };
  return (
    <>
      <IconButton
        sx={{
          color: 'white',
          padding: '6px',
          paddingLeft: '4px',
          backgroundColor: '#00000050',
          borderRadius: '6px',
          '&:hover': { backgroundColor: '#00000060' },
        }}
        type="button"
        onClick={() => setIsShowPopup(true)}
      >
        <Flag size={20} />
        <Box sx={{ fontSize: '14px', marginLeft: 0.5, color: '#fff' }}>Report</Box>
      </IconButton>
      <Modal open={isShowPopup} onClose={() => setIsShowPopup(false)}>
        <Box
          sx={{
            ...modalStyle,
            maxHeight: '75vh',
            overflowY: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isReported ? (
            <>
              <IconButton
                aria-label="close"
                onClick={() => setIsShowPopup(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h3" textAlign="center" mt={1}>
                Thank you for reporting.
              </Typography>
              <Box fontWeight={400} my={2} color="#4A4A68">
                Thank you for your report. We will remove this listing if we find it to violate our Terms & Conditions.
              </Box>
            </>
          ) : (
            <>
              <IconButton
                aria-label="close"
                onClick={() => setIsShowPopup(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h3" textAlign="center" mb={2}>
                Report Listing
              </Typography>

              <SjScrollBox ref={popupRef} style={{ flex: 1, pr: 0.5 }}>
                <Formik
                  initialValues={reportDetail}
                  enableReinitialize
                  onSubmit={submit}
                  validate={(values) => {
                    const errors: FormikErrors<IReportForm> = {};

                    if (values.type.length === 0) {
                      errors.type = 'The field is required';
                    }
                    if (!values.detail || values.detail.length === 0) {
                      errors.detail = 'The field is required';
                    }

                    return errors;
                  }}
                >
                  {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                      <Stack>
                        <SjRadio options={reasons} name="type" isBold direction="column" />
                        <SjInput name="detail" type="textarea" placeholder="Provide any additional details" />
                        <Stack spacing={2}>
                          <Box color="#979797">
                            Flagged listings will be reviewed by List & Deal staff. We will take action to remove the listing and/or user account if necessary.{' '}
                          </Box>
                        </Stack>
                        <Stack direction="row" gap={3} mt={4}>
                          <Button variant="outlined" type="button" sx={{ mb: 0 }} onClick={() => setIsShowPopup(false)} fullWidth>
                            Cancel
                          </Button>
                          <Button variant="contained" type="submit" fullWidth>
                            Submit
                          </Button>
                        </Stack>
                      </Stack>
                    </form>
                  )}
                </Formik>
              </SjScrollBox>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}

export default DealReportAction;
