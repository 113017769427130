const AppSyncConfig: {
  graphqlEndpoint: string;
  region: string;
  authenticationType: 'AWS_LAMBDA';
} = {
  graphqlEndpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT ?? '',
  region: process.env.REACT_APP_APPSYNC_REGION ?? '',
  authenticationType: 'AWS_LAMBDA',
};

export default AppSyncConfig;
