import dayjs from 'dayjs';

export const dowloadFromUrl = (url: string, name: string, dowloadName?: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.download = dowloadName ?? `${name}-${dayjs().format('DDMMYYYYHHMM')}`;
      a.href = blobUrl;
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((e) => console.error(e));
};
