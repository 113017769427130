import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import authReducer from './auth/reducer';
import authSaga from './auth/saga';
import toastReducer from './toast/reducer';
import currencyReducer from './currency/reducer';
import currencySaga from './currency/saga';

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([fork(authSaga), fork(currencySaga)]);
}

const reducers = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  currency: currencyReducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (gDM) => gDM({ serializableCheck: false }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);
