import { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { IFilter } from 'interface/filter';
import { ICity, ICountry, ICurrency, IRegion, IBusinessCategory } from 'interface/public';
import customAxios from '../utils/request';

export const getRegions = async (filter?: IFilter): Promise<IRegion[]> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: '/app/regions',
    headers: {
      accept: '*/*',
    },
    params: filter ?? {},
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getCountries = async (filter: IFilter): Promise<ICountry[]> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: '/app/countries',
    headers: {
      accept: '*/*',
    },
    paramsSerializer: {
      serialize: (p) => {
        return qs.stringify(p, { arrayFormat: 'comma' });
      },
    },
    params: filter
      ? {
          ...filter,
        }
      : {},
  };
  const response = await customAxios.request({ ...params });

  return response.data;
};

export const getCategories = async (
  filter?: IFilter,
): Promise<{
  data: IBusinessCategory[];
}> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: '/app/categories',
    headers: {
      accept: '*/*',
    },
    paramsSerializer: {
      serialize: (p) => {
        return qs.stringify(p, { arrayFormat: 'comma' });
      },
    },
    params: filter ?? {},
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getCities = async (filter?: number[]): Promise<ICity[]> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: '/app/cities',
    headers: {
      accept: '*/*',
    },
    paramsSerializer: {
      serialize: (p) => {
        return qs.stringify(p, { arrayFormat: 'comma' });
      },
    },
    params: filter ?? {},
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getCurrencies = async (
  filter?: IFilter,
): Promise<{
  data: ICurrency[];
}> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: 'app/currencies',
    headers: {
      accept: '*/*',
    },
    params: filter ?? {},
  };
  const response = await customAxios.request({ ...params });
  return {
    ...response.data,
    data: response.data.data.map((item: ICurrency) => ({
      ...item,
      name: `${item.symbol} (${item.code})`,
    })),
  };
};

export const getCurrencyById = async (id?: number): Promise<ICurrency> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `app/currencies/${id}`,
    headers: {
      accept: '*/*',
    },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const uploadImage = async (
  data: FormData,
): Promise<
  {
    img_url: string;
  }[]
> => {
  const params: AxiosRequestConfig = {
    method: 'POST',
    url: 'app/uploads/img',
    headers: {
      accept: '*/*',
      'Content-Type': `multipart/form-data`,
    },
    data,
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getSettings = async (): Promise<{
  contact_email: string;
}> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: 'app/settings',
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};
