import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { InfoOutlined } from '@mui/icons-material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Popover from '@mui/material/Popover';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import colors from 'constants/colors';
import { DEFAULT_CURRENCY } from 'constants/index';
import { useEffect, useState } from 'react';
import { formatMoney } from 'utils/formatMoney';
import { Divider, Tooltip } from '@mui/material';
import UserCurrency from 'utils/userCurrency';
import SearchContext from 'context/SearchDealContext';
import { isNumber } from 'utils/regex';

function FilterPriceRange({
  type,
  onChange,
  data,
}: {
  type?: 'full' | 'popover';
  onChange: any;
  data?: { max_price?: number | string; min_price?: number | string };
}) {
  const searchContext = SearchContext.useContext();

  const Currency = UserCurrency.useCurrency();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [priceRange, setPriceRange] = useState({
    min: data?.min_price === 0 ? '0' : formatMoney(data?.min_price, { defaultValue: '' }),
    max: data?.max_price === 0 ? '0' : formatMoney(data?.max_price, { defaultValue: '' }),
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChangePrice = (field: any, value: string) => {
    const money = value.split(',').join('').split('.').join('');
    if (money !== '' && !isNumber(money)) {
      return;
    }
    const nextValue = formatMoney(value, { defaultValue: '' });

    setPriceRange({
      ...priceRange,
      [field]: nextValue,
    });

    const nextMin = field === 'min' ? nextValue : priceRange.min;
    const nextMax = field === 'max' ? nextValue : priceRange.max;

    const numNextMin = !+nextMin ? 0 : +nextMin;
    const numNextMax = !+nextMax ? 0 : +nextMax;

    const nextError = numNextMax < numNextMin ? 'Min price should be lower than max price.' : '';
    searchContext.setErrors((prevErrors) => ({ ...prevErrors, price: nextError }));

    if (nextValue === '') {
      onChange(`${field}_price`, 'clear');
      return;
    }
    const formatted = +nextValue.split(',').join('');
    onChange(`${field}_price`, formatted);
  };

  const clearPrice = () => {
    onChange('price_group', 'clear');
    setPriceRange({ min: '', max: '' });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    searchContext.addRefs({ setPriceRange });
    return () => {
      searchContext.addRefs({ setPriceRange: () => console.warn('Search page has unmount & setPriceRange is not available!') });
    };
  }, []);

  return (
    <>
      {type === 'full' && (
        <Box>
          <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">Price Range</Typography>
            <Button type="button" variant="text" sx={{ fontWeight: 600 }} onClick={clearPrice}>
              Clear
            </Button>
          </Stack>

          <Grid container spacing={2} mt={1.5}>
            <Grid xs={12} alignSelf="center">
              <Button type="button" onClick={handleClick} variant="outlined" disabled fullWidth sx={{ borderRadius: '10px' }}>
                {Currency.data?.code && Currency.data?.symbol ? <>{`${Currency.data?.symbol} (${Currency.data?.code})`} </> : DEFAULT_CURRENCY}
                <KeyboardArrowDownIcon sx={{ position: 'absolute', right: 3 }} />
              </Button>
              <Typography display="flex" color={colors.BaseSecondaryText} fontWeight={500} alignItems="center" gap={1} fontSize="14px">
                <InfoOutlined />
                Show based on your preferences
              </Typography>
            </Grid>
            <Grid xs>
              <TextField
                value={priceRange.min}
                placeholder="Min"
                onChange={(e) => {
                  handleChangePrice('min', e.target.value);
                }}
                error={!!searchContext.errors.price}
              />
            </Grid>
            <Grid xs={0.5} p={0} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Box bgcolor={colors.BaseSecondaryText} width="15px" margin="0 auto" height="1px" />
            </Grid>
            <Grid xs>
              <TextField
                value={priceRange.max}
                placeholder="Max"
                onChange={(e) => {
                  handleChangePrice('max', e.target.value);
                }}
                error={!!searchContext.errors.price}
              />
            </Grid>
          </Grid>
          <Box color={colors.BaseSecondaryText} fontWeight={500} fontSize={14} mt={2}>
            Search results will show listings posted in the same currency only.
          </Box>
          {!!searchContext.errors.price && (
            <Box color={colors.Error500} fontWeight={500} fontSize={14} mt={2}>
              {searchContext.errors.price}
            </Box>
          )}
        </Box>
      )}

      {type === 'popover' && (
        <>
          <Button
            type="button"
            onClick={handleClick}
            variant="outlined"
            sx={{
              borderColor: open || priceRange.min || priceRange.max ? colors.Primary : colors.BaseGrey,
              bgcolor: priceRange.min || priceRange.max ? colors.Primary300 : 'transparent',
              color: colors.BaseText,
              textTransform: 'capitalize',
              fontWeight: 500,
              position: 'relative',
              paddingRight: 4,
              mb: 0,
              '&:hover': {
                borderColor: colors.Primary,
                bgcolor: 'transparent',
                color: colors.BaseText,
              },
            }}
          >
            Price Range
            {(priceRange.min || priceRange.max) && <Typography>:</Typography>}
            <Stack direction="row" ml="10px" mr="10px">
              {priceRange.min && (
                <Typography fontWeight={500}>{`${priceRange.min && Currency.data?.symbol ? Currency.data?.symbol : ''}${priceRange.min ?? ''}`} </Typography>
              )}
              {priceRange.max && (
                <Typography fontWeight={500} ml="4px">
                  {`- ${priceRange.max && Currency.data?.symbol ? Currency.data?.symbol : ''}${priceRange.max ?? ''}`}{' '}
                </Typography>
              )}
            </Stack>
            <KeyboardArrowDownIcon sx={{ position: 'absolute', right: 10, fontSize: 20 }} />
          </Button>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              sx: {
                borderRadius: '16px',
                mt: 1,
              },
            }}
          >
            <Box sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid xs={2.2} alignSelf="center">
                  <Stack flexDirection="row" gap={1} alignItems="center">
                    <Tooltip title="Show based on your preferences">
                      <InfoOutlined sx={{ fontSize: '18px' }} />
                    </Tooltip>
                    {Currency.data?.symbol && Currency.data?.code ? <>{`${Currency.data?.symbol} (${Currency.data?.code})`} </> : DEFAULT_CURRENCY}
                  </Stack>
                </Grid>
                <Grid xs>
                  <TextField
                    value={priceRange.min}
                    placeholder="Min"
                    onChange={(e) => {
                      handleChangePrice('min', e.target.value);
                    }}
                    error={!!searchContext.errors.price}
                  />
                </Grid>
                <Grid xs={0.5} p={0} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Box bgcolor={colors.BaseSecondaryText} width="15px" margin="0 auto" height="1px" />
                </Grid>
                <Grid xs>
                  <TextField
                    value={priceRange.max}
                    placeholder="Max"
                    onChange={(e) => {
                      handleChangePrice('max', e.target.value);
                    }}
                    error={!!searchContext.errors.price}
                  />
                </Grid>
              </Grid>
              <Box color={colors.BaseSecondaryText} fontWeight={500} fontSize={14} mt={2}>
                Search results will show listings posted in the same currency only.
              </Box>
              {!!searchContext.errors.price && (
                <Box color={colors.Error500} fontWeight={500} fontSize={14} mt={2}>
                  {searchContext.errors.price}
                </Box>
              )}

              <Divider sx={{ pt: 2, mb: 2 }} />
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={clearPrice}>Clear All</Button>
              </Box>
            </Box>
          </Popover>
        </>
      )}
    </>
  );
}

FilterPriceRange.defaultProps = {
  type: 'popover',
};

export default FilterPriceRange;
