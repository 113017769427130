/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import Box from '@mui/material/Box';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import debounce from 'lodash/debounce';
import { SEARCH_ALL_IN_ROOMS, SEARCH_FILES_IN_ROOMS, SEARCH_MESSAGES_IN_ROOMS, SEARCH_ROOMS_IN_ROOMS, SEARCH_USERS_IN_ROOMS } from 'apis/graphql';
import { Close } from '@mui/icons-material';
import { CircularProgress, Stack, Tab, Tabs } from '@mui/material';
import { IChat } from 'interface/chat';
import { RoomSearchContext } from 'context/RoomSearchContext';
import { IExternalSearch, IRoomsGlobalSearchSelectedMessage, IRoomsSearchItem } from 'interface/room';
import colors from 'constants/colors';
import SjScrollBox from 'components/Layout/SjScrollBox';
import { ROOMS_LIMIT_PER_LOAD } from 'constants/index';
import SearchMultiRoomsResults from './SearchMultiRoomsResults';

enum ISearchObjects {
  searchRooms = 'Rooms',
  searchUsers = 'Users',
  searchMessages = 'Messages',
  searchFiles = 'Files',
}

enum ISearchAllObjects {
  ALL = 'all',
}

function RoomsSearch({
  onSearching,
  isSearchMode,
  activeRoom,
  setActiveRoom,
  setSelectedMsg,
  setShowLeftSidebar,
}: {
  onSearching: (isSearching: boolean) => void;
  isSearchMode: boolean;
  activeRoom: IChat | null;
  setActiveRoom: (r: IChat) => void;
  setSelectedMsg: (m: IRoomsGlobalSearchSelectedMessage) => void;
  setShowLeftSidebar: (s: boolean) => void;
}) {
  const [search, setSearch] = useState('');
  const [searchRooms, { data: roomResults }] = useLazyQuery(SEARCH_ROOMS_IN_ROOMS);
  const [searchUsers, { data: userResults }] = useLazyQuery(SEARCH_USERS_IN_ROOMS);
  const [searchFiles, { data: fileResults }] = useLazyQuery(SEARCH_FILES_IN_ROOMS);
  const [searchMessages, { data: messageResults }] = useLazyQuery(SEARCH_MESSAGES_IN_ROOMS);
  const [searchAllInRooms, { data: searchResults }] = useLazyQuery(SEARCH_ALL_IN_ROOMS);

  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string>(ISearchAllObjects.ALL);
  const [data, setData] = useState<IExternalSearch>();
  const [total, setTotal] = useState(0);

  const multiRoomSearchRef = useRef();

  useEffect(() => {
    if (!isSearchMode) {
      setSearch('');
    }
  }, [isSearchMode]);

  const onSearchContent = useCallback(
    debounce((text: string) => {
      searchAllInRooms({
        variables: {
          text,
          limit: ROOMS_LIMIT_PER_LOAD,
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'standby',
      }).then((res) => {
        setLoading(false);
      });
    }, 1000),
    [],
  );

  const onSearch = (text: string) => {
    setSearch(text);
    if (text.length > 0) {
      setLoading(true);
      onSearching(true);
      onSearchContent(text);
    }
  };

  useEffect(() => {
    if (data) {
      setTotal(
        Object.entries(data).reduce((a, [key, value]) => {
          return a + value.items.length;
        }, 0),
      );
    }
  }, [data]);

  useEffect(() => {
    setData(searchResults);
  }, [searchResults]);

  useEffect(() => {
    if (roomResults && roomResults.searchRooms && roomResults.searchRooms.items && data) {
      setData({
        ...data,
        searchRooms: {
          nextToken: roomResults.searchRooms.nextToken,
          items: [...data.searchRooms.items, ...roomResults.searchRooms.items],
        },
      });
    }
  }, [roomResults]);

  useEffect(() => {
    if (userResults?.searchUsers?.items && data) {
      setData({
        ...data,
        searchUsers: {
          nextToken: userResults.searchUsers.nextToken,
          items: [...data.searchUsers.items, ...userResults.searchUsers.items],
        },
      });
    }
  }, [userResults]);

  useEffect(() => {
    if (messageResults?.searchMessages?.items && data) {
      setData({
        ...data,
        searchMessages: {
          nextToken: messageResults.searchMessages.nextToken,
          items: [...data.searchMessages.items, ...messageResults.searchMessages.items.filter((i: IRoomsSearchItem) => i.__typename === 'SearchItemResponse')],
        },
      });
    }
  }, [messageResults]);

  useEffect(() => {
    if (fileResults?.searchFiles?.items && data) {
      setData({
        ...data,
        searchFiles: {
          nextToken: fileResults.searchFiles.nextToken,
          items: [...data.searchFiles.items, ...fileResults.searchFiles.items],
        },
      });
    }
  }, [fileResults]);

  const onViewMore = (item: string) => {
    setSelectedTab(item);
  };

  const onLoadMore = (name: string, nextToken: string) => {
    switch (name) {
      case 'searchUsers':
        searchUsers({
          variables: {
            nextToken,
            text: search,
            limit: ROOMS_LIMIT_PER_LOAD,
          },
          fetchPolicy: 'no-cache',
          nextFetchPolicy: 'standby',
        });
        break;
      case 'searchRooms':
        searchRooms({
          variables: {
            nextToken,
            text: search,
            limit: ROOMS_LIMIT_PER_LOAD,
          },
          fetchPolicy: 'no-cache',
          nextFetchPolicy: 'standby',
        });
        break;
      case 'searchMessages':
        searchMessages({
          variables: {
            nextToken,
            text: search,
            limit: ROOMS_LIMIT_PER_LOAD,
          },
          fetchPolicy: 'no-cache',
          nextFetchPolicy: 'standby',
        });
        break;
      default:
        searchFiles({
          variables: {
            nextToken,
            text: search,
            limit: ROOMS_LIMIT_PER_LOAD,
          },
          fetchPolicy: 'no-cache',
          nextFetchPolicy: 'standby',
        });
    }
  };

  const renderResultGroup = useMemo(() => {
    if (data) {
      if (selectedTab === 'all') return Object.keys(data).map((item) => <SearchMultiRoomsResults key={item} data={data} objectName={item} />);
      return <SearchMultiRoomsResults data={data} objectName={selectedTab} key={selectedTab} />;
    }
    return <Box />;
  }, [data, selectedTab]);

  return (
    <RoomSearchContext.Provider
      value={{
        search,
        activeTab: selectedTab,
        activeChat: activeRoom,
        setActiveChat: setActiveRoom,
        onViewMore,
        onLoadMore,
        setSelectedMsg,
        setShowLeftSidebar,
      }}
    >
      <Box px={2} pb={2}>
        <TextField
          autoComplete="off"
          fullWidth
          placeholder="Search rooms, users or messages"
          onChange={(e) => onSearch(e.target.value)}
          value={search}
          sx={{
            '.MuiInputBase-root.MuiOutlinedInput-root': {
              backgroundColor: '#fff',
              border: 'none',
              paddingLeft: 1,
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            '.MuiInputBase-input.MuiOutlinedInput-input': {
              paddingLeft: 0,
            },
            '.MuiInputAdornment-positionStart': {
              marginRight: '5px',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment:
              search.length > 0 ? (
                <InputAdornment position="end">
                  <Close
                    onClick={() => {
                      setSearch('');
                      onSearching(false);
                    }}
                    sx={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              ) : undefined,
          }}
        />
      </Box>
      {loading && (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress size={20} />
        </Stack>
      )}
      {!loading && total === 0 && isSearchMode && (
        <Stack alignItems="center" my={10}>
          No results
        </Stack>
      )}

      {total > 0 && data && isSearchMode && (
        <>
          <Tabs
            value={selectedTab}
            onChange={() => {}}
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              '.MuiTabs-flexContainer': {
                justifyContent: ['space-around', 'space-around', 'flex-start'],
              },
              '.MuiTabScrollButton-root.MuiTabScrollButton-horizontal': {
                paddingX: 0,
                width: '20px',
              },
              '.MuiButtonBase-root.MuiTab-root': {
                paddingX: 1,
                minWidth: 'unset',
                textTransform: 'capitalize',
              },
              '.Mui-selected.MuiButtonBase-root.MuiTab-root': {
                color: colors.Secondary700,
                fontWeight: 700,
              },
              '.MuiTabs-indicator': {
                backgroundColor: colors.Secondary700,
              },
            }}
          >
            <Tab value="all" label="All" onClick={() => setSelectedTab(ISearchAllObjects.ALL)} />
            {(Object.keys(ISearchObjects) as Array<keyof typeof ISearchObjects>).map((key) => (
              <Tab key={key} value={key} label={ISearchObjects[key]} onClick={() => onViewMore(key)} />
            ))}
          </Tabs>

          <SjScrollBox
            ref={multiRoomSearchRef}
            id="multi-rooms-search-results"
            style={{
              flex: 1,
              height: 'calc(100% - 184px)',
            }}
          >
            {renderResultGroup}
          </SjScrollBox>
        </>
      )}
    </RoomSearchContext.Provider>
  );
}

export default RoomsSearch;
