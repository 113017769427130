/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormikValues, useFormikContext } from 'formik';
import { Avatar, Box, Chip, InputLabel, Stack, Typography } from '@mui/material';
import colors from 'constants/colors';
import { getUsers } from 'apis/user';
import { debounce } from 'lodash';
import { isEmail } from 'utils/regex';
import authProvider from 'apis/auth';

interface ISjMultiEmailInputProps {
  label?: string;
  placeholder?: string;
  name: string;
  disabled?: boolean;
  helpText?: string;
  mb?: number;
  excludeItems?: any[];
  allowUsername?: boolean;
}

const sx = {
  '.MuiFormControl-root .MuiInputBase-root': {
    paddingX: 1,
    paddingTop: '5px',
    paddingBottom: '6px',
    minHeight: '47px',
  },
  '.MuiInputBase-root .MuiAutocomplete-input': {
    py: 0,
    paddingLeft: '8px !important',
  },
};

const initFilter = {
  p: 1,
  l: 5,
  search: '',
};

export default function SjMultiEmail({ label, placeholder, name, helpText, mb = 4, disabled, excludeItems }: ISjMultiEmailInputProps) {
  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext<FormikValues>();
  const textInputRef = useRef<any>();
  const [selected, setSelected] = useState<any>([]);
  const [options, setOptions] = useState<any>([]);
  const [filter, setFilter] = useState(initFilter);
  const [loading, setLoading] = useState(false);
  const endOfSearch = useRef(false);
  const onGetUsers = useCallback(
    debounce(async (f) => {
      const { data } = await getUsers(f);

      let _options = [...data.data];

      if (values[name]?.length > 0) {
        _options = _options.filter((x: any) => !values[name]?.find((c: any) => c.email === x.email || c.username === x.username));
      }

      if (excludeItems) {
        _options = _options.filter((x: any) => !excludeItems?.find((c: any) => c?.email === x?.email || c?.username === x?.username));
      }
      setOptions(_options.map((user) => ({ id: user?.email, email: user?.email, username: user?.username, avatar_url: user?.avatar })));
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, 1000),
    [],
  );

  useEffect(() => {
    if (options.length > 0 && excludeItems && excludeItems.length > 0 && !loading) {
      setOptions(options.filter((x: any) => !excludeItems?.find((c: any) => c?.email === x?.email || c?.username === x?.username)));
    }
  }, [excludeItems, loading]);

  useEffect(() => {
    if (filter.search.length > 0) {
      onGetUsers(filter);
    }
  }, [filter]);

  useEffect(() => {
    if (values[name]) {
      setSelected(values[name]);
    }
  }, [values[name]]);

  const pushToSelected = async (item: string, checkValid?: boolean) => {
    const email = item.trim();

    const isDuplicated = selected.find((i: any) => i.email === email);

    let isInvalid = !isEmail(email);

    if (checkValid && isInvalid) {
      const isExisted = await authProvider.checkUniqueUsername(email);
      isInvalid = !isExisted.exists;
    }
    return {
      email,
      isInvalid,
      isDuplicated: !!isDuplicated,
      isAlreadyInvited: excludeItems && excludeItems.find((i: any) => i.email === email),
    };
  };

  const handleTextChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    const textArray = text.split(',');
    if (textArray.length > 1) {
      const emailList = [...selected];

      textArray.forEach(async (item) => {
        if (item.trim().length > 0) {
          const email = await pushToSelected(item, true);
          emailList.push(email);
          setFilter({
            ...filter,
            search: '',
          });
        }
      });
      setFieldValue(name, emailList);
    } else if (text[text.length - 1] === ' ') {
      const email = await pushToSelected(text, true);
      setFieldValue(name, [...selected, email]);
      setFilter({
        ...filter,
        search: '',
      });
      setOptions([]);
    } else {
      setFilter({
        ...filter,
        search: text,
      });
    }
  };

  const handleSelectOption = (e: any, value: any) => {
    const findIndex = value.findIndex((item: any) => item.username);
    const updatedValue = [...value];
    updatedValue[findIndex] =
      typeof value[findIndex] === 'string'
        ? {
            email: value[findIndex].trim(),
            isInvalid: false,
            isDuplicated: false,
          }
        : {
            email: value[findIndex].email,
            isInvalid: false,
            isDuplicated: value[findIndex].isDuplicated,
          };
    setFieldValue(name, updatedValue);
    setFilter(initFilter);
  };

  const handleDelete = (index: number) => {
    const _selected = [...values[name].filter((v: any, i: number) => i !== index)];

    _selected.forEach((s, i) => {
      if (s.isDuplicated) {
        const isDuplicated = _selected.filter((item) => s.email === item.email).length > 1;
        _selected[i] = {
          ..._selected[i],
          isDuplicated,
        };
      }
    });
    setFieldValue(name, _selected);
  };

  const renderTags = useCallback(
    (value: any, getTagProps: any) => {
      return value.map((item: any, index: number) => (
        <Chip
          label={item.email}
          sx={{
            marginRight: 1,
            border: `1px solid ${item.isDuplicated || item.isAlreadyInvited || item.isInvalid ? colors.Error500 : 'transparent'}`,
            color: item.isDuplicated || item.isAlreadyInvited || item.isInvalid ? colors.Error500 : 'inherit',
          }}
          {...getTagProps({ index })}
          key={`${item.email}-${index}`}
          onDelete={() => handleDelete(index)}
        />
      ));
    },
    [selected],
  );

  const renderInput = (params: any) => (
    <TextField
      {...params}
      placeholder={placeholder}
      onChange={handleTextChange}
      onKeyUp={(e) => {
        if (e.key !== 'Backspace') {
          endOfSearch.current = false;
        } else if (endOfSearch.current) {
          const newValues = values[name];
          newValues.pop();
          setFieldValue(name, newValues);
        } else if (e.key === 'Backspace' && !filter.search) {
          endOfSearch.current = true;
        }
      }}
      inputRef={textInputRef}
      onBlur={async (e) => {
        setFieldTouched(name, true, true);
        if (filter.search) {
          const email = await pushToSelected(filter.search, true);
          setFieldValue(name, [...values[name], email]);
        }
        setFilter(initFilter);
      }}
    />
  );

  return (
    <Box flex={1} mb={mb}>
      {label && (
        <InputLabel shrink error={touched[name] && Boolean(errors[name])}>
          {label}
        </InputLabel>
      )}
      <Autocomplete
        disabled={disabled}
        multiple
        clearOnBlur
        disableClearable
        options={options}
        value={selected}
        getOptionLabel={(option: any) => {
          if (option.email.includes(filter.search)) {
            return option.email;
          }
          return `@${option.username}` ?? '';
        }}
        loading={loading}
        sx={sx}
        freeSolo
        onChange={handleSelectOption}
        renderTags={renderTags}
        renderInput={renderInput}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <Avatar src={`${option.avatar_url}`} />
            <Typography ml={1}>
              @{option.username} ({option.email})
            </Typography>
          </Box>
        )}
      />
      <Stack>
        <Typography fontSize="14px" color={colors.BaseSecondaryText} fontWeight={500} sx={{ color: colors.Error500, mr: 0.5 }}>
          {errors[name]?.toString()}
        </Typography>
        <Typography fontSize="14px" color={colors.BaseSecondaryText} fontWeight={500}>
          {helpText}
        </Typography>
      </Stack>
    </Box>
  );
}
