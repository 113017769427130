import { Box, BoxProps, Container, Link, Stack, Typography } from '@mui/material';
import colors from 'constants/colors';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import useWindowSize from 'utils/useWindowSize';

const current: {
  setTrailingHeight: React.Dispatch<React.SetStateAction<number>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
} = { setTrailingHeight: () => {}, setVisible: () => {} };

function SJFooter({ onLayout }: { onLayout?: (event: { height: number }) => any }) {
  const [, windowHeight] = useWindowSize();
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const [trailingHeight, setTrailingHeight] = useState(0);
  const [isVisible, setVisible] = useState(true);

  const menuItems = [
    {
      title: 'Contact us',
      link: '/contact',
    },
    {
      title: 'Term & Conditions',
      link: '/term-and-condition',
    },
    {
      title: 'Privacy Policy',
      link: '/privacy-policy',
    },
    {
      title: 'About Us',
      link: '/about',
    },
    {
      title: 'How to Use',
      link: process.env.REACT_APP_LINK_TO_HOW_TO_USE_DOC,
      target: '_blank',
    },
  ];

  useEffect(() => {
    current.setTrailingHeight = setTrailingHeight;
    current.setVisible = setVisible;
  }, []);

  useEffect(() => {
    onLayout?.({ height: wrapperRef.current?.clientHeight ?? 0 });
  }, [trailingHeight, windowHeight, isVisible]);

  return (
    <Container ref={wrapperRef}>
      {isVisible && (
        <Stack
          alignItems="center"
          sx={{ borderTop: '1px solid #D5D3D1', flexDirection: ['column', 'column', 'row', 'row'], justifyContent: 'space-between', py: 1 }}
        >
          <Stack direction="row" alignItems="center">
            <Link href="/" sx={{ '&:hover': { color: 'inherit' }, marginLeft: '0 !important', mr: 4 }}>
              <img src={`${process.env.PUBLIC_URL}/sj-logo.png`} alt="List & Deal" height={40} />
            </Link>
            <Typography>© {dayjs().format('YYYY')} List & Deal</Typography>
          </Stack>
          <Typography textAlign="center" mt={1}>
            {menuItems.map((item, index) => (
              <Link href={item.link} key={item.title} sx={{ fontWeight: 500, color: colors.BaseText }} target={item?.target}>
                {`${index ? '  •  ' : ''}${item.title}`}
              </Link>
            ))}
          </Typography>
        </Stack>
      )}
      <Box height={trailingHeight} />
    </Container>
  );
}

function Trailing(props: React.PropsWithChildren<BoxProps>) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const footerTralingHeight = ref.current?.clientHeight ?? 0;

    current.setTrailingHeight?.(footerTralingHeight);

    return () => {
      current.setTrailingHeight?.(0);
    };
  });

  return (
    <Box ref={ref} bgcolor={colors.White} position="fixed" bottom={0} left={0} right={0} boxShadow="0px 4px 21px rgba(105, 88, 26, 0.15)" zIndex={1} {...props}>
      {props.children}
    </Box>
  );
}

type CompoundSJFooter = typeof SJFooter & {
  Trailing: typeof Trailing;
  current: typeof current;
};

(SJFooter as CompoundSJFooter).Trailing = Trailing;
(SJFooter as CompoundSJFooter).current = current;

export default SJFooter as CompoundSJFooter;
