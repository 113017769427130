import { Box, Button, Modal, Stack, Typography, IconButton } from '@mui/material';
import SjInput from 'components/Form/SjInput';
import { Formik, FormikErrors } from 'formik';
import { useState } from 'react';
import { modalStyle } from 'themes/modal';
import CloseIcon from '@mui/icons-material/Close';
import { notifyReportedListing } from 'apis/deal';
import { showToast } from 'redux/toast/action';
import { useDispatch } from 'react-redux';
import colors from 'constants/colors';

function NotifyReportedListing({ id, isNotified, onSuccess }: { id: string; isNotified: boolean; onSuccess: () => void }) {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const dispatch = useDispatch();

  const submit = async (values: { detail: '' }) => {
    try {
      await notifyReportedListing(id, values);
      setIsShowPopup(false);
      dispatch(
        showToast({
          type: 'success',
          title: 'You have notified user.',
        }),
      );
      onSuccess();
    } catch (err) {
      console.log('err', err);
    }
  };
  return (
    <>
      <Button onClick={() => setIsShowPopup(true)} sx={{ background: isNotified ? colors.Secondary300 : undefined }}>
        {isNotified ? 'Notified' : 'Notify'}
      </Button>
      <Modal open={isShowPopup} onClose={() => setIsShowPopup(false)}>
        <Box sx={modalStyle}>
          <IconButton
            aria-label="close"
            onClick={() => setIsShowPopup(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h3" textAlign="center" mb={4}>
            Notify User
          </Typography>
          <Formik
            initialValues={{ detail: '' }}
            enableReinitialize
            onSubmit={submit}
            validate={(values) => {
              const errors: FormikErrors<{ detail: string }> = {};

              if (values.detail.length === 0) {
                errors.detail = 'The field is required';
              }

              return errors;
            }}
          >
            {({ handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit}>
                <SjInput name="detail" type="textarea" placeholder="Provide any additional details" label="Message" />
                <Stack direction="row" gap={3}>
                  <Button variant="outlined" type="button" color="info" onClick={() => setIsShowPopup(false)} fullWidth>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" fullWidth>
                    Submit
                  </Button>
                </Stack>
              </form>
            )}
          </Formik>
        </Box>
      </Modal>
    </>
  );
}

export default NotifyReportedListing;
