/* eslint-disable no-param-reassign */
import { Alert, Box, Divider, Fade, LinearProgress, ListItem, Snackbar, Stack, Typography } from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_UPLOAD_LINK, NOTIFY_FILE_ADDED } from 'apis/graphql';
import colors from 'constants/colors';
import { useEffect, useRef, useState } from 'react';
import { getBinaryFromFile, upload } from 'apis/upload';
import { FileText } from 'react-feather';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import List from '@mui/material/List';

const FILE_TYPE_ACCEPTED = 'image/*, .xls, .xlsx, .ppt, .pptx, .pdf, .csv , .jpeg, .doc, .docx, .mp4, .mov, .rar, .zip,';

type IFile = {
  size: number;
  name: string;
  type: string;
};

function ChatUpload({ roomId }: { roomId: string }) {
  const [uploadError, setUploadError] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState<IFile | null>(null);
  const [uploadLink] = useLazyQuery(GET_UPLOAD_LINK);
  const [notifyRoomFileAdded] = useMutation(NOTIFY_FILE_ADDED);

  const uploadFile = async (e: any) => {
    if (!e.target.files?.[0]) {
      return false;
    }
    try {
      const fileUpload = e.target.files?.[0];
      const { size, name } = fileUpload;
      const type = name.split('.').pop();
      setFile({
        size,
        name,
        type,
      });
      const { data, error } = await uploadLink({
        variables: {
          input: {
            roomId,
            files: [
              {
                fileSize: size,
                fileExt: `.${type}`,
                fileName: name,
              },
            ],
          },
        },
      });
      setUploadError(error?.message);
      if (data) {
        const { key, url, signedHeader } = data.uploadLink.files[0];
        const binaryFile = await getBinaryFromFile(fileUpload);
        if (binaryFile) {
          setProgress(30);
          const resp = await upload(url, binaryFile, signedHeader);
          setProgress(60);
          if (resp.status === 200) {
            await notifyRoomFileAdded({
              variables: {
                input: { roomId, fileKeys: [key] },
              },
            });
            setProgress(100);
          }
        }
      }
      if (error) {
        setProgress(0);
        setFile(null);
      }
    } catch (err) {
      console.log(err);
    }
    e.target.value = '';
  };

  useEffect(() => {
    if (progress === 100) {
      setFile(null);
      setProgress(0);
    }
  }, [progress]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setUploadError(undefined);
  };

  return (
    <>
      <Snackbar
        open={!!uploadError}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message={uploadError}
        sx={{ marginTop: 10 }}
        TransitionComponent={Fade}
      >
        <Alert severity="error" sx={{ alignItems: 'center' }}>
          <Typography fontWeight={500}>Can not upload the file</Typography>
          <Box>{uploadError}</Box>
        </Alert>
      </Snackbar>
      <Stack mx={3} direction="row">
        <Box flex={1}>
          <label htmlFor="input-file-upload">
            <input type="file" hidden id="input-file-upload" accept={FILE_TYPE_ACCEPTED} onChange={uploadFile} ref={inputRef} />
            <Typography
              sx={{
                border: `2px solid ${colors.Secondary}`,
                borderRadius: '100px',
                height: '45px',
                textTransform: 'uppercase',
                fontWeight: 700,
                color: colors.Secondary,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              + Upload file
            </Typography>
          </label>
        </Box>
        <Tooltip
          componentsProps={{ tooltip: { sx: { opacity: 1, background: colors.Secondary700, padding: 2 } } }}
          title={
            <Box fontSize="14px" lineHeight={1.6}>
              Allow the file extensions:
              <List>
                <ListItem>- Photos: .JPG, .JPEG, .PNG</ListItem>
                <ListItem>- Videos: .MOV, .MP4</ListItem>
                <ListItem>- Documents: .DOC, .DOCX, .XLS, .XLSX, .PPT, .PPTX, .PDF, .CSV</ListItem>
                <ListItem>- WinRAR and Zip files</ListItem>
              </List>
              Max file size is 100MB
            </Box>
          }
          arrow
          placement="top"
          enterTouchDelay={0}
        >
          <div style={{ lineHeight: 0 }}>
            <InfoOutlinedIcon sx={{ color: colors.BaseSecondaryText }} />
          </div>
        </Tooltip>
      </Stack>

      {file && (
        <Stack direction="row" alignItems="center" mt={3} px={1}>
          <Box>
            <FileText />
          </Box>
          <Box ml={2} flex={1}>
            <Typography color={colors.Secondary} fontWeight={500} mb={1} sx={{ wordBreak: 'break-all' }}>
              {file.name}
            </Typography>
            <LinearProgress variant="buffer" value={progress} sx={{ '& .MuiLinearProgress-bar1Buffer': { backgroundColor: colors.Secondary } }} />
          </Box>
        </Stack>
      )}
      <Divider sx={{ pb: 3 }} />
    </>
  );
}

export default ChatUpload;
