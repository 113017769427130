/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Link, Stack, TablePagination } from '@mui/material';
import { getReportedDealItems } from 'apis/deal';

import dayjs from 'dayjs';
import NotifyReportedListing from 'components/Reported/NotifyAction';
import BlockReportedListing from 'components/Reported/BlockAction';
import colors from 'constants/colors';
import { renderDateByFormat } from 'utils/dateFormat';

enum REPORTED_STATUS {
  BLOCKED = 'BLOCKED',
  OPEN = 'OPEN',
}

export const columns = [
  {
    field: 'name',
    headerName: 'Listing name',
    width: 20,
    formatter: (p: any) => (
      <Link href={`/deal/view/${p.id}`} color={colors.Secondary}>
        {p.name}
      </Link>
    ),
  },
  {
    field: 'owner',
    headerName: 'Owner',
    width: 20,
    formatter: (p: any) => <Box>@{p?.owner?.username}</Box>,
  },
  {
    field: 'reported_count',
    headerName: 'Number of reports',
    width: 10,
  },
  {
    field: 'lastest_reported_at',
    headerName: 'Latest report',
    width: 14,
    formatter: (p: any) => <Box>{renderDateByFormat(p.lastest_reported_at, false, false, false, 'DD/MM/YYYY HH:mm')}</Box>,
  },
];

function Row(props: { row: any; onActionSuccess: () => void }) {
  const { row, onActionSuccess } = props;
  const [open, setOpen] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [data, setData] = React.useState<any>({});
  const [filter, setFilter] = React.useState({
    l: 20,
    p: 1,
  });

  const onGetReportItems = async () => {
    const d = await getReportedDealItems({
      ...filter,
      item_id: row.id,
    });
    setItems(filter.p === 1 ? d.data : [...items, ...d.data]);
  };
  const onClick = async () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    setData(row);
  }, [row]);

  React.useEffect(() => {
    if (open) {
      onGetReportItems();
    }
  }, [filter.p, open]);

  const onBlockedSuccess = () => {
    setData({
      ...data,
      status: REPORTED_STATUS.BLOCKED,
    });
    onActionSuccess();
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' }, backgroundColor: open ? 'aliceblue' : 'transparent' }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={onClick}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const value = data[column.field];
          return <TableCell key={column.field}>{column.formatter ? column.formatter(data) : value}</TableCell>;
        })}
        <TableCell>
          <Stack direction="row" alignItems="center">
            {data.status === REPORTED_STATUS.BLOCKED ? (
              <Typography color="red" fontSize={14} padding="6px 8px">
                BLOCKED
              </Typography>
            ) : (
              <>
                <NotifyReportedListing id={data.id} isNotified={!!data.lastest_admin_action_at} onSuccess={onActionSuccess} />
                <BlockReportedListing id={data.id} onBlockedSuccess={onBlockedSuccess} />
              </>
            )}
          </Stack>
        </TableCell>
        <TableCell key="lastest_admin_action_at">
          {data.lastest_admin_action_at ? (
            <Box>{renderDateByFormat(data.lastest_admin_action_at, false, false, false, 'DD/MM/YYYY HH:mm')}</Box>
          ) : (
            data.lastest_admin_action_at
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases" sx={{ '& td': { border: 0 } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Reported Date</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Detail</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item: any, index: number) => (
                    <TableRow key={`${item.date}-${index}`}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{renderDateByFormat(item.created_at, false, false, false, 'DD/MM/YYYY HH:mm')}</TableCell>
                      <TableCell>{item.reason.replaceAll('_', ' ')}</TableCell>
                      <TableCell>{item.detail}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={data.reported_count}
                rowsPerPage={filter.l}
                page={filter.p - 1}
                backIconButtonProps={{ style: { display: 'none' } }}
                onPageChange={(event, newPage) => {
                  setFilter({
                    ...filter,
                    p: newPage + 1,
                  });
                }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default Row;
