import { Autocomplete, TextField } from '@mui/material';
import { IReduxState } from 'interface/redux';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import colors from 'constants/colors';
import { userClearActionStatus } from 'redux/auth/action';
import UserCurrency from 'utils/userCurrency';

function SjGlobalCurrency(props: { type?: 'full' | 'snippet' }) {
  const isUpdated = useSelector((state: IReduxState) => state?.auth?.isUpdated);
  const isAuthenticated = useSelector((state: IReduxState) => !!state?.auth?.user?.id);
  const dispatch = useDispatch();
  const Currency = UserCurrency.useCurrency();
  const CurrencyList = UserCurrency.useList();

  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (isUpdated && isUpdating) {
      dispatch(userClearActionStatus());
      setIsUpdating(true);
    }
  }, [isUpdated]);

  return (
    <Autocomplete
      options={CurrencyList.data}
      clearIcon={false}
      sx={{
        overflow: 'hidden',
        ml: 0,
        ...(props?.type === 'full'
          ? {
              width: '100%',
              '.MuiTextField-root': {
                border: `2px ${colors.BaseLightGrey} !important`,
                borderRadius: '10px',
              },
              '.MuiInputBase-root.MuiOutlinedInput-root': {
                bgcolor: colors.White,
              },
            }
          : {
              width: '100px',
              '.MuiInputBase-root.MuiOutlinedInput-root': {
                bgcolor: colors.Primary500,
              },
            }),
      }}
      value={Currency.data}
      getOptionLabel={(option) => option?.label || ''}
      onChange={(e, value) => {
        if (isAuthenticated) {
          setIsUpdating(true);
        }
        Currency.update(value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '.MuiAutocomplete-endAdornment .MuiButtonBase-root': {
              padding: 0,
            },

            ...(props?.type === 'full'
              ? {
                  '.MuiAutocomplete-endAdornment': {
                    right: '16px !important',
                  },
                  '.MuiInputBase-root': {
                    py: 0,
                  },
                  '.MuiAutocomplete-input.MuiInputBase-input': {
                    fontWeight: 500,
                    color: colors.BaseText,
                    fontSize: '16px',
                    fontFamily: 'Work Sans',
                    py: '14px !important',
                    pl: '16px !important',
                  },
                }
              : {
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                  '.MuiAutocomplete-inputRoot.MuiInputBase-root': {
                    paddingRight: '8px !important',
                  },
                  '.MuiAutocomplete-endAdornment': {
                    right: '4px !important',
                  },
                  '.MuiInputBase-root': {
                    py: 0,
                    pl: '5px',
                  },
                  '.MuiAutocomplete-input.MuiInputBase-input': {
                    color: colors.Header,
                    fontFamily: 'Work Sans',
                    fontSize: '16px',
                    fontWeight: 700,
                    py: '12px !important',
                  },
                }),
          }}
        />
      )}
    />
  );
}

SjGlobalCurrency.defaultProps = {
  type: 'snippet',
};

export default SjGlobalCurrency;
