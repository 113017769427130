import { useEffect, useMemo, useState } from 'react';
import unionBy from 'lodash/unionBy';
import dayjs from 'dayjs';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GET_MESSAGES, CREATE_MESSAGE_SUBSCRIPTION, SEND_MESSAGE, READ_MESSAGE } from 'apis/graphql';
import colors from 'constants/colors';
import { useSelector } from 'react-redux';
import { IReduxState } from 'interface/redux';
import { useChatContainerContext } from 'context/ChatContainerContext';
import { CHAT_STATUSES, CHAT_TYPES, IMessage, MESSAGE_STATUSES, MESSAGE_TYPES } from 'interface/chat';
import { DEALSTATUS } from 'interface/deal';
import { ChatMessageContext } from 'context/ChatMessageContext';
import { toBlockText } from 'constants/index';
import ChatMessageList, { InvitationAccepted, InvitationRejected, InvitedToRoom } from './ChatItemDetailMessages';
import InputMessage from './ChatInput';

import ChatBoxItemHeader from './Header/ChatBoxItemHeader';
import RoomBoxItemHeader from './Header/RoomBoxItemHeader';
import ChatItemSearchDetail from './Search/ChatItemSearchDetail';

export const LIMIT = 10;

function ChatItemDetail() {
  const { activeChat: chat, updateUnreadMessage, setActiveChat, updateChat, selectedMsg, moveActiveRoomToTop } = useChatContainerContext();
  const { auth } = useSelector((state: IReduxState) => state);
  const currentUserId = auth.user?.id.toString() ?? '';
  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [readMessage] = useMutation(READ_MESSAGE);
  const [page, setPage] = useState(0);
  const [messages, setMessages] = useState<any[]>([]);
  const [prevToken, setPrevToken] = useState<string | null>(null);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [getMessages, { data: messageData }] = useLazyQuery(GET_MESSAGES);
  const { data: createdMessage } = useSubscription(CREATE_MESSAGE_SUBSCRIPTION, { variables: { chatId: chat.id } });
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [status, setStatus] = useState(CHAT_STATUSES.OPEN);
  const [roomId, setRoomId] = useState<string>('');
  const [invited, setInvited] = useState(false);
  const [search, setSearch] = useState('');
  const [searchMsgId, setSearchMsgId] = useState('');
  const [direction, setDirection] = useState('prev');
  const [loading, setLoading] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    setSearch('');
    setIsSearchMode(false);
  }, [chat.id]);

  const getMessagesByDirection = (variable: any, d?: string) => {
    if (d) {
      setDirection(d);
    }
    setLoading(true);
    getMessages({
      ...variable,
      fetchPolicy: 'network-only',
    });
  };

  useEffect(() => {
    if (chat.status) {
      setStatus(chat.status);
    } else {
      setStatus(CHAT_STATUSES.OPEN);
    }
    if (chat.groupRoomId) {
      setRoomId(chat.groupRoomId);
      setInvited(true);
    } else {
      setRoomId('');
      setInvited(false);
    }
    setPage(0);
  }, [chat.id]);

  useEffect(() => {
    setPage(0);
    setMessages([]);
    if (chat.id && chat.id !== 'created-chat') {
      setLoading(true);
      if (selectedMsg) {
        getMessagesByDirection(
          {
            variables: {
              input: {
                messageId: selectedMsg.messageId,
                roomId: chat.id,
                limit: LIMIT,
              },
            },
          },
          'all',
        );
        setSearchMsgId(selectedMsg.messageId);
        setSearch(selectedMsg.keyword);
      } else {
        getMessages({
          variables: {
            input: {
              roomId: chat.id,
              limit: LIMIT,
            },
          },
          fetchPolicy: 'network-only',
        });
      }
    }
  }, [chat.id, selectedMsg]);

  useEffect(() => {
    if (direction !== 'next') {
      setPrevToken(messageData?.messages.prevToken);
    }
    if (direction !== 'prev') {
      setNextToken(messageData?.messages.nextToken);
    }

    if (messageData?.messages?.items && messageData?.messages?.items?.length > 0) {
      if (messageData?.messages?.items[0].type === MESSAGE_TYPES.INVITATION && messageData?.messages?.items[0].status === MESSAGE_STATUSES.ACCEPTED) {
        setStatus(CHAT_STATUSES.ARCHIVED);
      }
      const comingMessages = [...messageData.messages.items];

      if (!direction) {
        setMessages([...comingMessages]);
      } else if (direction === 'next') {
        setMessages([...messages, ...comingMessages]);
      } else {
        setMessages(unionBy([...comingMessages, ...messages], (obj) => obj.id));
      }
      if (typeof updateUnreadMessage === 'function') {
        updateUnreadMessage(chat, false);
      }

      if (chat.unreadMessages > 0) {
        readMessage({
          variables: {
            messageId: messageData.messages.items[0].id,
          },
        });
      }
    }

    if (messageData?.messages?.items) {
      setLoading(false);
    }
  }, [messageData]);

  useEffect(() => {
    if (!createdMessage?.messageCreated || createdMessage.messageCreated.roomId !== chat.id) {
      return;
    }

    const receiveMsg = createdMessage.messageCreated;
    if (receiveMsg.ownerId.toString() !== currentUserId || (receiveMsg.ownerId.toString() === currentUserId && receiveMsg.type === 'FILE_UPDATED')) {
      if (receiveMsg.content.text === InvitationAccepted) {
        if (typeof updateChat === 'function') {
          updateChat(chat.id, [
            {
              field: status,
              value: CHAT_STATUSES.ARCHIVED,
            },
          ]);
        }
        setRoomId(receiveMsg.groupRoomId);
        setStatus(CHAT_STATUSES.ARCHIVED);
      } else if (receiveMsg.content.text === InvitationRejected) {
        setInvited(false);
      }
      setMessages([...messages, receiveMsg]);
    }

    if (createdMessage?.messageCreated.roomId === chat.id || createdMessage?.messageCreated.ownerId.toString() === currentUserId) {
      readMessage({
        variables: {
          messageId: createdMessage.messageCreated.id,
        },
      });
    }
  }, [createdMessage]);

  const addMessage = (msg: string) => {
    setIsTyping(true);
    if (typeof moveActiveRoomToTop === 'function') moveActiveRoomToTop();
    if (chat.id === 'created-chat') {
      sendMessage({
        variables: {
          input: {
            chat:
              chat.type === CHAT_TYPES.DIRECT
                ? {
                    userId: chat.participants[0].id,
                  }
                : {
                    itemId: chat.item.id,
                  },
            content: {
              text: msg,
            },
          },
        },
      });
    } else {
      setMessages([
        ...messages,
        {
          content: {
            text: msg,
          },
          id: dayjs().toISOString(),
          updatedAt: dayjs().toISOString(),
          createdAt: dayjs().toISOString(),
          owner: {
            id: currentUserId,
          },
          type: MESSAGE_TYPES.NORMAL,
          status: MESSAGE_STATUSES.NORMAL,
        },
      ]);
      sendMessage({
        variables: {
          input: {
            roomId: chat.id,
            content: {
              text: msg,
            },
          },
        },
      });
    }
    if (typeof updateChat === 'function') {
      updateChat(chat.id, [
        {
          field: 'position',
          value: '1',
        },
      ]);
    }
  };

  const handleInvited = () => {
    setInvited(true);
    setMessages([
      ...messages,
      {
        content: {
          text: InvitedToRoom,
        },
        type: MESSAGE_TYPES.INVITATION,
        owner: {
          id: currentUserId,
        },
      },
    ]);
  };

  const updateMessages = (id: string, field: string, value: string, s: string) => {
    const messageIndex = messages.findIndex((item: any) => item.id === id);

    if (messageIndex > -1) {
      const newMessageList = [...messages];
      newMessageList[messageIndex] = {
        ...messages[messageIndex],
        [field]: value,
      };
      setMessages([
        ...newMessageList,
        {
          content: {
            text: s,
          },
          type: s === InvitationAccepted || s === InvitationRejected ? MESSAGE_TYPES.INVITATION : '',
          owner: {
            id: currentUserId,
          },
        },
      ]);

      if (s === InvitationAccepted) {
        setStatus(CHAT_STATUSES.ARCHIVED);
      }
    }
  };

  const updateAfterChangedRoomName = (message: IMessage, name: string) => {
    setMessages([...messages, message]);

    setActiveChat({
      ...chat,
      name,
    });
  };

  const renderBlockText = (blockText: { title: string; message: string }) => (
    <Box mb={3} mt={2} textAlign="center" px={5}>
      <Typography fontWeight={700} color={colors.BaseText}>
        {blockText.title}
      </Typography>
      <Typography color={colors.BaseSecondaryText}>{blockText.message}</Typography>
    </Box>
  );

  const renderInput = (chatStatus: CHAT_STATUSES, type: string, dealStatus: DEALSTATUS) => {
    switch (true) {
      case dealStatus === DEALSTATUS.BLOCKED:
        return renderBlockText(
          toBlockText(
            type === 'LISTING_GROUP'
              ? { target: 'Room', status: 'cancelled', messageList: ['VIOLATE_TERMS_CONDITION', 'ABLE_VIEW_DOWNLOAD'] }
              : { target: 'chat', status: 'archived', messageList: ['VIOLATE_TERMS_CONDITION'] },
          ),
        );
      case chatStatus === CHAT_STATUSES.OPEN:
        return <InputMessage onSend={addMessage} />;
      case chatStatus === CHAT_STATUSES.ARCHIVED && type === 'LISTING_GROUP':
        return renderBlockText(toBlockText({ target: 'Room', status: 'archived', messageList: ['ABLE_VIEW_DOWNLOAD'] }));
      case chatStatus === CHAT_STATUSES.ARCHIVED && invited:
      case chatStatus === CHAT_STATUSES.ARCHIVED && !!roomId:
        return renderBlockText(toBlockText({ target: 'chat', status: 'archived', messageList: ['CONTINUE_CHAT_IN_ROOM'] }));
      case chatStatus === CHAT_STATUSES.COMPLETED:
        return renderBlockText(toBlockText({ target: 'Room', status: 'Marked as Complete', messageList: ['ABLE_VIEW_DOWNLOAD'] }));
      case chatStatus === CHAT_STATUSES.CANCELLED:
        return renderBlockText(toBlockText({ target: 'Room', status: 'cancelled', messageList: ['ABLE_VIEW_DOWNLOAD'] }));

      default:
    }
  };

  const renderChatDetail = () => {
    if (isSearchMode) {
      return <ChatItemSearchDetail />;
    }
    return <ChatMessageList setRoomId={setRoomId} updateMessages={updateMessages} />;
  };

  const renderMessages = useMemo(
    () => (
      <Box flex={2} display="flex" overflow="hidden" flexDirection="column">
        {loading && page === 0 ? (
          <Box textAlign="center" mt={2}>
            Loading...
          </Box>
        ) : (
          renderChatDetail()
        )}
      </Box>
    ),
    [messages, isSearchMode, loading, page],
  );

  return (
    <ChatMessageContext.Provider
      value={{
        isTyping,
        setIsTyping,
        search,
        setSearch,
        isSearchMode,
        setIsSearchMode,
        prevToken,
        messages,
        getMessages: getMessagesByDirection,
        setPage,
        nextToken,
        page,
        setSearchMsgId,
        searchMsgId,
      }}
    >
      <Box overflow="hidden" display="flex" flex={1} flexDirection="column" height="100%">
        {chat.type === 'LISTING_GROUP' ? (
          <RoomBoxItemHeader updateAfterChangedRoomName={updateAfterChangedRoomName} />
        ) : (
          <ChatBoxItemHeader roomId={roomId} handleInvited={handleInvited} invited={invited} />
        )}
        {renderMessages}
        {!isSearchMode && renderInput(status, chat.type, chat?.item?.status)}
      </Box>
    </ChatMessageContext.Provider>
  );
}

export default ChatItemDetail;
