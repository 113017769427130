import { Typography, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import colors from 'constants/colors';

export type ISortOption = {
  name: string;
  id: string;
  order: 'DESC' | 'ASC';
  disabled?: boolean;
};

function SortField({ options, onChange, value }: { options: ISortOption[]; onChange: (v: string) => void; value?: string }) {
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    if (value) {
      setSelectedId(value);
    }
  }, [value]);

  const handleSortChange = (e: SelectChangeEvent) => {
    setSelectedId(e.target.value);
    onChange(e.target.value);
  };

  return (
    <Select
      value={selectedId}
      onChange={handleSortChange}
      label=""
      MenuProps={{
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        PaperProps: {
          sx: {
            borderRadius: '16px',
          },
        },
      }}
      sx={{
        '&.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '&.MuiInputBase-root': {
          paddingLeft: 0,
          backgroundColor: 'transparent',
        },
        '&.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '100px',
          border: 'none',
        },
        '& .MuiSelect-select.MuiOutlinedInput-input': {
          fontWeight: 500,
          color: colors.BaseText,
          paddingLeft: 0,
        },
      }}
      startAdornment={
        <InputAdornment position="start">
          <Typography sx={{ padding: '12px 0 13px', fontWeight: 500, color: colors.BaseText }}>Sort by: </Typography>
        </InputAdornment>
      }
    >
      {options.map((item) => (
        <MenuItem value={item.id} key={item.id} sx={{ height: '50px', fontWeight: 500, color: colors.BaseText }} disabled={item.disabled}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
}

export default SortField;
