import { AxiosRequestConfig } from 'axios';
import { IUser } from 'interface/user';
import { IFilter } from 'interface/filter';
import customAxios from '../utils/request';

export const getUsers = async (
  filter: IFilter,
): Promise<{
  data: {
    data: IUser[];
  };
}> => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `/app/users`,
    headers: { accept: '*/*' },
    params: filter,
  };
  const response = await customAxios.request({ ...params });
  return { data: response.data ?? [] };
};

export const getUserInfo = async (id: string) => {
  const params: AxiosRequestConfig = {
    method: 'GET',
    url: `/app/users/${id}`,
    headers: { accept: '*/*' },
  };
  const response = await customAxios.request({ ...params });
  return response.data;
};

export const getUserProfileLink = (userID: string, authId?: string) => {
  if (userID === authId) {
    return '/my-profile';
  }
  return `/profile/${userID}`;
};
