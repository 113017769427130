import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import EditAccountSidebar from 'components/Profile/EditProfileMenu';

import Grid from '@mui/material/Unstable_Grid2';
import { Formik, FormikErrors } from 'formik';
import SjInput from 'components/Form/SjInput';
import { changePassword } from 'apis/auth';
import { showToast } from 'redux/toast/action';
import { isPasswordStrength } from 'utils/regex';
import { AxiosError } from 'axios';
import { CustomError } from 'interface/error';
import { List, ListItem } from '@mui/material';
import SjTabsScreen from 'components/Profile/SjTabsScreen';
import SjHelmet from 'components/Layout/SjHelmet';

export const initEditAccountForm = {
  new_password_confirm: '',
  new_password: '',
  old_password: '',
};

export type IEditAccountForm = {
  new_password_confirm: string;
  new_password: string;
  old_password: string;
};

function EditAccount() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submit = (values: IEditAccountForm, { setSubmitting }: any) => {
    changePassword(values)
      .then(() => {
        dispatch(
          showToast({
            type: 'success',
            title: 'Your password has been updated successfully',
          }),
        );
        navigate(`/my-profile`);
      })
      .catch((err: AxiosError<CustomError>) => {
        setSubmitting(false);
        dispatch(
          showToast({
            type: 'error',
            title: 'Can not update password.',
            description: err.response?.data.error.message ?? '',
          }),
        );
      });
  };

  const validate = (values: IEditAccountForm) => {
    const errors: FormikErrors<IEditAccountForm> = {};
    if (!values.old_password) {
      errors.old_password = 'Please enter old password.';
    }
    if (!isPasswordStrength(values.new_password)) {
      const message = 'Please ensure you follow the password rules.';
      errors.new_password = message;
    }
    if (values.new_password_confirm !== values.new_password) {
      errors.new_password_confirm = 'Please ensure the Password and Confirm Password fields are the same.';
    }
    return errors;
  };

  return (
    <>
      <SjHelmet title="Edit Account" />
      <SjTabsScreen
        title="Change Password"
        left={<EditAccountSidebar />}
        right={
          <>
            <Typography display={['none', 'none', 'block']} variant="h2" mb={3}>
              Change Password
            </Typography>
            <Formik initialValues={initEditAccountForm} onSubmit={submit} enableReinitialize validate={validate}>
              {({ handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container padding={0}>
                    <Grid xs={12}>
                      <Box width={['100%', '100%', '50%']}>
                        <SjInput name="old_password" label="Current Password*" type="password" />
                      </Box>
                      <Box width={['100%', '100%', '50%']}>
                        <SjInput
                          name="new_password"
                          label="New Password*"
                          type="password"
                          tooltip={
                            <Box padding={2}>
                              <Typography fontWeight={700} fontSize={12} mb={1}>
                                PASSWORD MUST CONTAIN AT LEAST:
                              </Typography>
                              <List>
                                <ListItem sx={{ py: 0.5 }}>• 8 characters</ListItem>
                                <ListItem sx={{ py: 0.5 }}>• 1 number</ListItem>
                                <ListItem sx={{ py: 0.5 }}>• 1 special character ($ % &, etc)</ListItem>
                                <ListItem sx={{ py: 0.5 }}>• 1 uppercase letter</ListItem>
                              </List>
                            </Box>
                          }
                        />
                      </Box>
                      <Box width={['100%', '100%', '50%']}>
                        <SjInput
                          name="new_password_confirm"
                          label="Confirm New Password*"
                          type="password"
                          tooltip={
                            <Box padding={2}>
                              <Typography fontWeight={700} fontSize={12} mb={1}>
                                PASSWORD MUST CONTAIN AT LEAST:
                              </Typography>
                              <List>
                                <ListItem sx={{ py: 0.5 }}>• 8 characters</ListItem>
                                <ListItem sx={{ py: 0.5 }}>• 1 number</ListItem>
                                <ListItem sx={{ py: 0.5 }}>• 1 special character ($ % &, etc)</ListItem>
                                <ListItem sx={{ py: 0.5 }}>• 1 uppercase letter</ListItem>
                              </List>
                            </Box>
                          }
                        />
                      </Box>

                      <Button type="submit" variant="contained" sx={{ mt: '24px', width: '100%' }} disabled={isSubmitting}>
                        Save Changes
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </>
        }
      />
    </>
  );
}

export default EditAccount;
