import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import SJBreadcrumbs from 'components/Layout/Breadcrumbs';
import { ReactElement } from 'react';
import { useBreakpoints } from 'themes';

export interface ISimplePageDataItem {
  title: string;
  details: ReactElement;
}
export interface ISimplePageData {
  title: string;
  details: ReactElement[] | ReactElement;
}

function SimplePage({ data }: { data: ISimplePageData }) {
  const contactData = data;
  const isUpMd = useBreakpoints((breakpoints) => breakpoints.up('md'));
  return (
    <Container sx={{ my: 3 }}>
      <SJBreadcrumbs title={data.title} />
      <Box alignItems="flex-end" sx={{ mt: [3, 6, 10] }}>
        <Box justifyContent="end">
          <Typography variant="h1" mb={5} ml={['0px', '0px', '96px']} mt={['16px', '20px', '60px']}>
            {contactData.title}
          </Typography>
        </Box>
        {contactData.details}
        {/* {contactData.details.map((dataItem) => {
          return (
            <Box key={dataItem.title} display={['block', 'block', 'flex']} mb={5} ml={['0px', '0px', '186px']} mt={['8px', '20px', '60px']}>
              <Typography variant={isUpMd ? 'h2' : 'h4'} mt="12px" mb="auto" minWidth={['0px', '86px', '240px']}>
                {dataItem.title}
              </Typography>
              <Typography ml={['0px', '0px', '96px']} mt="12px">
                {dataItem.details}
              </Typography>
            </Box>
          );
        })} */}
      </Box>
    </Container>
  );
}

export default SimplePage;
